import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './gatewayoftally.css'
import { LINK_PATH } from '../../constant';
import { useDispatch } from 'react-redux';

function GateWayOfTally(props) {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
        if (location.pathname === '/ledgerbook' || location.pathname==='/current_date' || location.pathname === '/period') {

        } else {
            const handleKeyDown = (event) => {
                if (event.key === 'ArrowDown') {
                    setSelectedIndex((prevIndex) => Math.min(prevIndex + 1, totalLinks - 1));
                } else if (event.key === 'ArrowUp') {
                    setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
                } else if (event.key === 'Enter') {
                    event.preventDefault();
                    // You can navigate to the selected link's path here
                    const selectedLink = master[selectedIndex] || transaction[selectedIndex] || utilities[selectedIndex] || reports[selectedIndex];
                    if (selectedLink) {
                        event.preventDefault();
                        navigate(selectedLink.to)
                    }
                } else if (event.key === 'c' || event.key === 'C') {
                    navigate('/createmasters')
                }
                else if (event.key === 'd' || event.key === 'D') {
                    navigate('/displaymorereports')
                }
                else if (event.key === 'h' || event.key === 'H') {
                    navigate('/chartofaccountmasters')
                }
                else if (event.key === 'v' || event.key === 'V') {
                    navigate('/voucherstransaction')
                }
                else if (event.key === 'k' || event.key === 'K') {
                    navigate('/daybooktransaction')
                }
                else if (event.key === 'n' || event.key === 'N') {
                    navigate('/bankingutilities')
                }
                else if (event.key === 'b' || event.key === 'B') {
                    navigate('/balancesheetreports')
                }
                else if (event.key === 'p' || event.key === 'P') {
                    navigate('/profitandlossac')
                }
                else if (event.key === 's' || event.key === 'S') {
                    navigate('/stocksummary')
                }
                else if (event.key === 'r' || event.key === 'R') {
                    navigate('/displaymorereports')
                }
            };
            window.addEventListener('keydown', handleKeyDown);
            return () => {
                window.removeEventListener('keydown', handleKeyDown);
            };
        }


    }, [selectedIndex,location.pathname]);

    const master = [
        { to: '/createmasters', text: 'Create' },
        { to: '/altermasters', text: 'Alter' },
        { to: '/chartofaccountmasters', text: 'Chart of Accounts' },
    ];
    const transaction = [];
    transaction[3] = { to: '/voucherstransaction', text: 'Vouchers' };
    transaction[4] = { to: '/daybooktransaction', text: 'Day Book' };
    const utilities = [];
    utilities[5] = { to: '/bankingutilities', text: 'Banking' };
    const reports = [];
    reports[6] = { to: `${LINK_PATH}/balancesheetreports`, text: 'Balance Sheet' };
    reports[7] = { to: '/profitandlossac', text: 'Profit & Loss A/c' };
    reports[8] = { to: '/stocksummary', text: 'Stock Summary' };
    reports[9] = { to: '/displaymorereports', text: 'Display More Reports' };

    const totalLinks = (master.length + transaction.length + utilities.length + reports.length) - 14;
    return (
        <div className='card-container border border-dark'>
            <div className='card-title'>
                <p className='title-of'> Gateway of Tally</p>
            </div>
            <div className='gateway_tally_card'>
                <div className='list-1'>
                    <h6 className='list-heading'> MASTERS</h6>
                    <ul className='list-item'>
                        {master.map((link, index) => (
                            <li key={index} className={index === selectedIndex ? 'selected' : ''}>
                                <Link to={index === selectedIndex ? link.to : ''} onClick={() => setSelectedIndex(index)}>
                                    {index === 0 || index === 1 ? (
                                        <span style={{ color: 'red' }}>{link.text.substring(0, 1)}</span>
                                    ) : index === 2 ? (
                                        <>
                                            {link.text.substring(0, 1)}
                                            <span style={{ color: 'red', textTransform: 'uppercase' }}>{link.text.substring(1, 2)}</span>
                                            {link.text.substring(2)}
                                        </>
                                    ) : (
                                        <span>{link.text.substring(0, 1)}</span>
                                    )}
                                    {index === 2 ? null : link.text.substring(1)}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className='list-1'>
                    <h6 className='list-heading'> TRANSATIONS</h6>
                    <ul className='list-item'>
                        {transaction.map((link, index) => (
                            <li key={index} className={index === selectedIndex ? 'selected' : ''}>
                                <Link to={index === selectedIndex ? link.to : ''} onClick={() => setSelectedIndex(index)}>
                                    {index === 3 ? (
                                        <span style={{ color: 'red' }}>{link.text.substring(0, 1)}</span>
                                    ) : index === 4 ? (
                                        <>
                                            {link.text.substring(0, link.text.length - 1)}
                                            <span style={{ color: 'red', textTransform: 'uppercase' }}>{link.text.substring(link.text.length - 1)}</span>
                                        </>
                                    ) : (
                                        <span>{link.text.substring(0, 1)}</span>
                                    )}
                                    {index === 4 ? null : link.text.substring(1)}
                                </Link>
                            </li>
                        ))}
                    </ul>

                </div>
                <div className='list-1'>
                    <h6 className='list-heading'> UTILITIES</h6>
                    <ul className='list-item'>
                        {utilities.map((link, index) => (
                            <li key={index} className={index === selectedIndex ? 'selected' : ''}>
                                <Link to={index === selectedIndex ? link.to : ''} onClick={() => setSelectedIndex(index)}>
                                    {index === 5 ? (
                                        <>
                                            {link.text.substring(0, 2)}
                                            <span style={{ color: 'red', textTransform: 'uppercase' }}>{link.text.substring(2, 3)}</span>
                                            {link.text.substring(3)}
                                        </>
                                    ) : (
                                        <span>{link.text.substring(0, 1)}</span>
                                    )}

                                </Link>
                            </li>
                        ))}
                    </ul>

                </div>
                <div className='list-1'>
                    <h6 className='list-heading'> REPORTS</h6>
                    <ul className='list-item'>
                        {reports.map((link, index) => (
                            <li key={index} className={index === selectedIndex ? 'selected' : ''}>
                                <Link to={index === selectedIndex ? link.to : ''} onClick={() => setSelectedIndex(index)}>
                                    {index === 6 || index === 7 || index === 8 || index === 9 ? (
                                        <span style={{ color: 'red' }}>{link.text.substring(0, 1)}</span>
                                    ) : (
                                        <span>{link.text.substring(0, 1)}</span>
                                    )}
                                    {link.text.substring(1)}
                                </Link>
                            </li>
                        ))}

                    </ul>
                </div>
            </div>

        </div>
    )
}

export default GateWayOfTally






// import React, { useEffect, useState, useCallback } from 'react';
// import { Link, useNavigate, useLocation } from 'react-router-dom';
// import './gatewayoftally.css';
// import { LINK_PATH } from '../../constant';

// function GateWayOfTally({ currentDate }) {
//   const navigate = useNavigate();
//   const location = useLocation();

//   // State for managing selected index
//   const [selectedIndex, setSelectedIndex] = useState(0);

//   // Predefined link data
//   const categories = {
//     master: [
//       { to: '/createmasters', text: 'Create' },
//       { to: '/altermasters', text: 'Alter' },
//       { to: '/chartofaccountmasters', text: 'Chart of Accounts' },
//     ],
//     transaction: [
//       { to: '/voucherstransaction', text: 'Vouchers' },
//       { to: '/daybooktransaction', text: 'Day Book' },
//     ],
//     utilities: [
//       { to: '/bankingutilities', text: 'Banking' },
//     ],
//     reports: [
//       { to: `${LINK_PATH}/balancesheetreports`, text: 'Balance Sheet' },
//       { to: '/profitandlossac', text: 'Profit & Loss A/c' },
//       { to: '/stocksummary', text: 'Stock Summary' },
//       { to: '/displaymorereports', text: 'Display More Reports' },
//     ]
//   };

//   // Total number of links
//   const totalLinks = Object.values(categories).flat().length;

//   // handle key press to navigate
//   const handleKeyDown = useCallback((event) => {
//     if (event.key === 'ArrowDown') {
//       setSelectedIndex((prevIndex) => Math.min(prevIndex + 1, totalLinks - 1));
//     } else if (event.key === 'ArrowUp') {
//       setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
//     } else if (event.key === 'Enter') {
//       event.preventDefault();
//       const allLinks = Object.values(categories).flat();
//       const selectedLink = allLinks[selectedIndex];
//       if (selectedLink) {
//         navigate(selectedLink.to);
//       }
//     } else {
//       const navKeyMap = {
//         'c': '/createmasters',
//         'd': '/displaymorereports',
//         'h': '/chartofaccountmasters',
//         'v': '/voucherstransaction',
//         'k': '/daybooktransaction',
//         'n': '/bankingutilities',
//         'b': '/balancesheetreports',
//         'p': '/profitandlossac',
//         's': '/stocksummary',
//         'r': '/displaymorereports',
//       };
//       const targetPath = navKeyMap[event.key.toLowerCase()];
//       if (targetPath) {
//         navigate(targetPath);
//       }
//     }
//   }, [selectedIndex, totalLinks, categories, navigate]);

//   // Setup keydown listener
//   useEffect(() => {
//     if (location.pathname !== '/ledgerbook' && !currentDate && location.pathname !== '/period') {
//       window.addEventListener('keydown', handleKeyDown);
//       return () => {
//         window.removeEventListener('keydown', handleKeyDown);
//       };
//     }
//   }, [handleKeyDown, location.pathname, currentDate]);

//   // Reusable component to render each list
//   const renderList = (category, categoryName) => (
//     <div className='list-1'>
//       <h6 className='list-heading'>{categoryName}</h6>
//       <ul className='list-item'>
//         {category.map((link, index) => (
//           <li
//             key={index}
//             className={index === selectedIndex ? 'selected' : ''}
//             onClick={() => setSelectedIndex(index)}
//           >
//             <Link to={link.to}>
//               {renderLinkText(link.text, index)}
//             </Link>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );

//   // Reusable function to format the link text
//   const renderLinkText = (text, index) => {
//     if (index === 0 || index === 1) {
//       return <span style={{ color: 'red' }}>{text[0]}</span> + text.substring(1);
//     } else if (index === 2 || index === 6 || index === 7 || index === 8 || index === 9) {
//       return (
//         <>
//           <span style={{ color: 'red' }}>{text[0]}</span>
//           {text.substring(1)}
//         </>
//       );
//     } else {
//       return <span>{text[0]}</span> + text.substring(1);
//     }
//   };

//   return (
//     <div className='card-container border border-dark'>
//       <div className='card-title'>
//         <p className='title-of'> Gateway of Tally</p>
//       </div>
//       <div className='gateway_tally_card'>
//         {renderList(categories.master, 'MASTERS')}
//         {renderList(categories.transaction, 'TRANSACTIONS')}
//         {renderList(categories.utilities, 'UTILITIES')}
//         {renderList(categories.reports, 'REPORTS')}
//       </div>
//     </div>
//   );
// }

// export default React.memo(GateWayOfTally);


