import React from 'react'
import FooterLayout from '../FooterLayout'

function MasterCreationFooter(props) {
    const fields = [
        { id: "1", name: "Quit", shortcutKey: "Q", line: 'double', isDisabled: false },
        { id: "2", name: "", shortcutKey: "", line: '', isDisabled: true },
        { id: "3", name: "", shortcutKey: "", line: '', isDisabled: true },
        { id: "4", name: "Accept", shortcutKey: "A", line: 'double', isDisabled: false, state: props.setHash },
        { id: "5", name: "", shortcutKey: "", line: '', isDisabled: true },
        { id: "6", name: "", shortcutKey: "", line: '', isDisabled: true },
        { id: "7", name: "Delete", shortcutKey: "D", line: 'single', isDisabled: true },
        { id: "8", name: "", shortcutKey: "", line: '', isDisabled: true },
        { id: "9", name: "", shortcutKey: "", line: '', isDisabled: true },
        { id: "10", name: "", shortcutKey: "", line: '', isDisabled: true },
    ]
    return (
        <div className='tomasterfooter'>
            <div className='footer'>
                {
                    fields.map((item, index) => {
                        return (
                            <FooterLayout key={item.id} fields={item} />
                        )
                    })
                }

            </div>
        </div>
    )
}

export default MasterCreationFooter
