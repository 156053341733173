import React, { useEffect, useState, useRef, useCallback } from 'react';
import Modal from 'react-modal';
import FormData from 'form-data';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import '../accounting_masters/ledger.css';
import { useSelector, useDispatch } from 'react-redux';
import Footer from '../../../../../components/home_page/Footer';
import { unitsCreate, unitsListing, uqcCreate, uqcListing } from '../../../../../redux/actions/action-creator';
import YesNoModal from '../../../../../components/model/YesNoModal';
import MasterCreationFooter from '../../../../../components/Footer/Master_Footer/MasterCreationFooter';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};
const type_of_unit_array = [{ id: 1, name: 'Simple' }, { id: 2, name: 'Compound' }]
function Unit() {
  const dispatch = useDispatch();
  const [state, setState] = useState({
    type: 'Simple',
    symbol: '',
    formal_name: '',
    unit_quantity_code: 'Not Applicable',
    uqc_id: 'Not Applicable',
    number_of_decimal_places: '0',
    first_unit: '',
    first_unit_id: '',
    conversions: '',
    secound_unit: '',
    secound_unit_id: '',
    uqc_code_create_name: '',
  });
  // Name input field validation to  check value not empty'state -------------------------------------------------------
  const [isUnitNameEntered, setIsUnitNameEntered] = useState(false);
  const [type_entered, set_type_entered] = useState(false);
  // get state data from redux ------------------------------------------------------------------------------------------
  const { unit_data = null } = useSelector(state => state.common);
  const { uqc_code_response = null } = useSelector(state => state.common);
  const { userData = null } = useSelector(state => state.auth);
  // Mutable object create to user click outside when we use it to dropdown --------------------------------------------------
  const dropdownRef = useRef(null);
  const dropdownRefType = useRef(null);
  const dropdownRefFirst = useRef(null);
  const dropdownRefSecound = useRef(null);
  // Create mutable object of all input to focus ---------------------------------------------------------------------------
  const type_input_ref = useRef(null);
  const symbol_input_ref = useRef(null);
  const formal_name_input_ref = useRef(null);
  const unit_quantity_code_input_ref = useRef(null);
  const number_of_decimal_places_input_ref = useRef(null);
  const first_unit_input_ref = useRef(null);
  const conversions_input_ref = useRef(null);
  const secound_unit_input_ref = useRef(null);
  const isMouseDownInside = useRef(false);

  const [showList, setShowList] = useState(false);
  const [showListType, setShowListType] = useState(false);
  const [showListFirst, setShowListFirst] = useState(false);
  const [showListSecound, setShowListSecound] = useState(false);
  // Search suggestion filter data state-------------------------------------------------------------------------------
  const [filteredDataType, setFilteredDataType] = useState([]);
  const [filteredDataUQC, setFilteredDataUQC] = useState([]);
  const [filteredDataFirstUnit, setFilteredDataFirstUnit] = useState([]);
  const [filteredDataSecondUnit, setFilteredDataSecondUnit] = useState([]);
  // dropdown selection state ------------------------------------------------------------------------------------------
  const [selectedItem, setSelectedItem] = useState(0);
  const [hash, sethash] = useState(false)
  const [unit_confirm, setunit_confirm] = useState('')
  const [backspaceCount, setBackspaceCount] = useState(0)
  const [nameInput, setNameInput] = useState(null)
  // Destructuring object from state-------------------------------------------------------------------------------
  const {
    type, symbol, formal_name, unit_quantity_code,
    number_of_decimal_places, first_unit, conversions,
    secound_unit, uqc_code_create_name
  } = state;

  const getNextInputRef = (name, value) => {
    switch (name) {
      case 'symbol': return formal_name_input_ref;
      case 'formal_name': return unit_quantity_code_input_ref;
      case 'unit_quantity_code': return number_of_decimal_places_input_ref;
      case 'type': return state?.type === 'Simple' ? symbol_input_ref : first_unit_input_ref;
      case 'first_unit': return conversions_input_ref;
      case 'conversions': return secound_unit_input_ref;
      default: return null;
    }
  };
  const getCurrentInputRef = (name, value) => {
    switch (name) {
      case 'symbol': return symbol_input_ref;
      case 'formal_name': return formal_name_input_ref;
      case 'unit_quantity_code': return unit_quantity_code_input_ref;
      case 'type': return type_input_ref;
      case 'first_unit': return first_unit_input_ref;
      case 'conversions': return conversions_input_ref;
      case 'number_of_decimal_places': return number_of_decimal_places_input_ref;
      case 'secound_unit': return secound_unit_input_ref;
      default: return null;
    }
  };

  const getPrevInputRef = (name) => {
    switch (name) {
      case 'symbol': return type_input_ref;
      case 'formal_name': return symbol_input_ref;
      case 'unit_quantity_code': return formal_name_input_ref;
      case 'number_of_decimal_places': return unit_quantity_code_input_ref;
      case 'first_unit': return type_input_ref;
      case 'conversions': return first_unit_input_ref;
      case 'secound_unit': return conversions_input_ref;
      default: return type_input_ref;
    };
  }

  // Trigger focus on input ------------------------------------------------
  const handleFocus = (e) => {
    const { name, value } = e.target;
    const ref = getCurrentInputRef(name);
    if (ref) {
      setNameInput(ref);
    }
    if (name === 'type') {
      setShowListType(true)
      const index = type_of_unit_array.findIndex(item => item.name?.toLowerCase() == state?.type?.toLowerCase());
      setSelectedItem(index)
    }
    if (name === 'unit_quantity_code') {
      setShowList(true)
      const index = uqc_code_response.findIndex(item => item.name?.toLowerCase() == state?.unit_quantity_code?.toLowerCase());
      setSelectedItem(index)
    }
    if (name === 'first_unit') {
      setShowListFirst(true)
      const index = unit_data.findIndex(item => item.symbol?.toLowerCase() == state?.first_unit?.toLowerCase());
      setSelectedItem(index)
    }
    if (name === 'secound_unit') {
      setShowListSecound(true)
      const index = unit_data.findIndex(item => item.symbol?.toLowerCase() == state?.secound_unit?.toLowerCase());
      setSelectedItem(index)
    }
  }

  // Trigger blur on input ------------------------------------------------
  const handleBlur = (e) => {
    const { name, value } = e.target;

    if (name === 'type') {
      setShowListType(false)
    }
    if (name === 'unit_quantity_code') {
      setShowList(false)
    }
    if (name === 'first_unit') {
      setShowListFirst(false)
    }
    if (name === 'secound_unit') {
      setShowListSecound(false)
    }
  }

  // Triger function whenever the change input value ----------------------------------------------------------------------
  const handleChange = (e) => {
    const { name, value } = e.target;
    const capitalizeFirstLetter = (str) => {
      return str.toLowerCase().replace(/(^|\s)\S/g,
        (firstLetter) => firstLetter.toUpperCase());
    };
    if (name === 'type') {
      set_type_entered(!!value.trim());
    }
    if (name === 'symbol') {
      setIsUnitNameEntered(!!value.trim());
    }
    // space key functionality
    if (e.code == 'Space') {
      e.preventDefault();
      const cursorPosition_start = e.target.selectionStart;
      if (cursorPosition_start == 1) {
        setState({ ...state, [name]: '' });
        return;
      }
    }
    // Enter key functionality
    if (e.key === 'Enter' && state?.type !== '') {
      e.preventDefault();
      const nextInputRef = getNextInputRef(name, value)
      if (name === 'number_of_decimal_places') {
        setunit_confirm('Accept')
        number_of_decimal_places_input_ref.current.blur();
      }
      if (name === 'secound_unit') {
        setunit_confirm('Accept')
        secound_unit_input_ref.current.blur();
      }
      if (nextInputRef) {
        nextInputRef.current.focus();
        if (nextInputRef.current) {
          nextInputRef.current.setSelectionRange(0, 0);
        }
      }
      return;
    }
    // Back space key functionality
    if (e.key === 'Backspace') {
      e.preventDefault();
      const nextInputRef = getPrevInputRef(name);
      const cursorPosition = e.target.selectionStart;
      if (cursorPosition === 0) {
        if (backspaceCount === 1) {
          setBackspaceCount(0);
          if (nextInputRef && nextInputRef.current) {
            nextInputRef.current.focus();
            nextInputRef.current.setSelectionRange(0, 0);
          }
        } else {
          if (cursorPosition === 0 && value) {
            if (nextInputRef && nextInputRef.current) {
              nextInputRef.current.focus();
              nextInputRef.current.setSelectionRange(0, 0);
            }
          } else {
            setBackspaceCount(backspaceCount + 1);
          }
        }
      }
      return;
    }
    const cleanString = (str) => str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    switch (name) {
      case 'type':
        const group = type_of_unit_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (group) {
          setState({ ...state, [name]: value })
        }
        break;
      case 'unit_quantity_code':
        const nat_group = uqc_code_response.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (nat_group) {
          setState({ ...state, [name]: value })
        }
        break;
      case 'first_unit':
        const group_behaves = unit_data.some(item => cleanString(item.symbol).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (group_behaves) {
          setState({ ...state, [name]: value })
        }
        break;
      case 'secound_unit':
        const nett_debit = unit_data.some(item => cleanString(item.symbol).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (nett_debit) {
          setState({ ...state, [name]: value })
        }
        break;
      default:
        const updatedValue = name === 'symbol' || name === 'formal_name' ?
          capitalizeFirstLetter(value) : value;
        setState({ ...state, [name]: updatedValue })
        break;
    }
  }

  useEffect(() => {
    const formData = new FormData();
    formData.append('company_id', userData?.id)
    dispatch(uqcListing(formData))
    dispatch(unitsListing(formData))
  }, [])

  //triger when the change value of search input and get filter data ---------------------------------------------------
  useEffect(() => {
    const filteredSuggestions = type_of_unit_array?.filter((item, index) => {
      const lowercaseLedgerName = item?.name?.toLowerCase();
      const lowercaseSearchInput = state?.type?.toLowerCase();
      const matchesSearchInput = lowercaseLedgerName?.includes(state.type !== '' ? lowercaseSearchInput : '');
      return matchesSearchInput;
    })
    setFilteredDataType(state.type !== '' ? type_input_ref?.current?.selectionStart > 0 ? filteredSuggestions : type_of_unit_array : type_of_unit_array);
  }, [state?.type, type_of_unit_array, type_input_ref?.current?.selectionStart])
  //triger when the change value of search input and get filter data ---------------------------------------------------
  useEffect(() => {
    const filteredSuggestions = unit_data?.filter((item, index) => {
      const lowercaseLedgerName = item?.symbol?.toLowerCase();
      const lowercaseSearchInput = state?.first_unit?.toLowerCase();
      const matchesSearchInput = lowercaseLedgerName?.includes(state.first_unit !== '' ? lowercaseSearchInput : '');
      return matchesSearchInput;
    })
    setFilteredDataFirstUnit(state?.first_unit !== '' ? first_unit_input_ref?.current?.selectionStart > 0 ? filteredSuggestions : unit_data : unit_data);
  }, [state?.first_unit, unit_data, first_unit_input_ref?.current?.selectionStart])
  //triger when the change value of search input and get filter data ---------------------------------------------------
  useEffect(() => {
    const filteredSuggestions = unit_data?.filter((item, index) => {
      const lowercaseLedgerName = item?.symbol?.toLowerCase();
      const lowercaseSearchInput = state?.secound_unit?.toLowerCase();
      const matchesSearchInput = lowercaseLedgerName?.includes(state?.secound_unit !== '' ? lowercaseSearchInput : '');
      return matchesSearchInput;
    })
    setFilteredDataSecondUnit(state.secound_unit !== '' ? secound_unit_input_ref?.current?.selectionStart > 0 ? filteredSuggestions : unit_data : unit_data);
  }, [state?.secound_unit, unit_data, secound_unit_input_ref?.current?.selectionStart])
  //triger when the change value of search input and get filter data ---------------------------------------------------
  useEffect(() => {
    if (uqc_code_response[0]?.name === 'Not Applicable') {
      // Do Not Add Any Data 
    } else {
      uqc_code_response?.unshift({ id: 'Not Applicable', name: 'Not Applicable' });
    }
    const filteredSuggestions = uqc_code_response?.filter((item, index) => {
      const lowercaseLedgerName = item?.name?.toLowerCase();
      const lowercaseSearchInput = state?.unit_quantity_code?.toLowerCase();
      const matchesSearchInput = lowercaseLedgerName?.includes(state.unit_quantity_code !== '' ? lowercaseSearchInput : '');
      return matchesSearchInput;
    })
    setFilteredDataUQC(state?.unit_quantity_code !== '' ? unit_quantity_code_input_ref?.current?.selectionStart > 0 ? filteredSuggestions : uqc_code_response : uqc_code_response);
  }, [state?.unit_quantity_code, uqc_code_response, unit_quantity_code_input_ref?.current?.selectionStart])

  //---------------------------------------------------------------------------
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (unit_confirm === 'Accept') {
        if (e.key === 'y' || e.key === 'Y') {
          e.preventDefault();
          controlPlusASubmit();
        }
      }
      if (unit_confirm === 'Accept') {
        if (e.key === 'n' || e.key === 'N') {
          e.preventDefault();
          setunit_confirm('')
          if (state?.type === 'Simple') {
            symbol_input_ref.current.focus();
            if (symbol_input_ref.current) {
              symbol_input_ref.current.setSelectionRange(0, 0)
            }
          } else {
            first_unit_input_ref.current.focus();
            if (first_unit_input_ref.current) {
              first_unit_input_ref.current.setSelectionRange(0, 0)
            }

          }
        }
      }
    }
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [state?.symbol, unit_confirm])

  const handleClickOutside = (event) => {
    let clickedElement = event.target;
    let isInputField = false;
    while (clickedElement) {
      if ((clickedElement.tagName &&
        (clickedElement.tagName.toLowerCase() === 'input' ||
          clickedElement.tagName.toLowerCase() === 'textarea' || clickedElement.tagName.toLowerCase() === 'button'))) {
        isInputField = true;
        break;
      }
      clickedElement = clickedElement.parentNode;
    }

    if (!isInputField) {
      if (nameInput && nameInput.current) {
        event.preventDefault();
        console.log("this is name input :---", nameInput)
        nameInput.current.focus();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [nameInput])


  useEffect(() => {
    const handleClickOutside = (event) => {
      let clickedElement = event.target;
      let isInputField = false;
      while (clickedElement) {
        if ((clickedElement.tagName &&
          (clickedElement.tagName.toLowerCase() === 'input' ||
            clickedElement.tagName.toLowerCase() === 'textarea' || clickedElement.tagName.toLowerCase() === 'button'))) {
          isInputField = true;
          break;
        }
        clickedElement = clickedElement.parentNode;
      }
      if (!isInputField) {
        if (nameInput && nameInput.current) {
          event.preventDefault();
          nameInput.current.focus();
        }
      }
    };

    const handleMouseDown = () => {
      isMouseDownInside.current = true;
    };

    const handleMouseUp = () => {
      isMouseDownInside.current = false;
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [nameInput]);

  const handleKeyDown = useCallback((e) => {
    if (showListType || showListFirst || showListSecound || showList) {
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        setSelectedItem(prevState => Math.max(prevState - 1, 0));
      } else if (e.key === 'ArrowDown') {
        e.preventDefault();
        setSelectedItem(prevState => Math.min(prevState + 1,
          (showListType ? filteredDataType.length :
            showList ? filteredDataUQC.length :
              showListFirst ? filteredDataFirstUnit.length :
                filteredDataSecondUnit.length) - 1));
      } else if (e.key === 'Enter') {
        e.preventDefault();
        if (showListType) {
          const selectedLink = filteredDataType[selectedItem]
          if (selectedLink) {
            setState({
              ...state, type: selectedLink.name
            })
          }
        }
        if (showList) {
          const selectedLink = filteredDataUQC[selectedItem]
          if (selectedLink) {
            setState({
              ...state, unit_quantity_code: selectedLink.name
            })
          }
        }
        if (showListFirst) {
          const selectedLink = filteredDataFirstUnit[selectedItem]
          if (selectedLink) {
            setState({
              ...state, first_unit: selectedLink.symbol, first_unit_id: selectedLink.id
            })
          }
        }
        if (showListSecound) {
          const selectedLink = filteredDataSecondUnit[selectedItem]
          if (selectedLink) {
            setState({
              ...state, secound_unit: selectedLink.symbol, secound_unit_id: selectedLink.id
            })
          }
        }
      }
    }
  }, [selectedItem, filteredDataType, filteredDataUQC, filteredDataFirstUnit, filteredDataSecondUnit, showList, showListFirst, showListSecound, showListType])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  // submit form by ctr + a -------------------------------------------------
  const controlPlusASubmit = async () => {
    if (validateForm()) {
      try {
        const formData = new FormData();
        formData.append('type', state.type)
        formData.append('symbol', state.symbol)
        formData.append('formal_name', state.formal_name)
        formData.append('uqc_id', state.unit_id)
        formData.append('no_decimal_places', state.number_of_decimal_places)
        formData.append('first_unit', state.first_unit_id)
        formData.append('conversion', state.conversions)
        formData.append('second_unit', state.secound_unit_id)
        formData.append('company_id', userData?.id)
        const response = await dispatch(unitsCreate(formData));
        if (response.status) {
          toast.success(response?.message || 'unit created!', {
            position: toast.POSITION.TOP_CENTER
          });
          const formData = new FormData();
          formData.append('company_id', userData?.id)
          dispatch(unitsListing(formData))
          resetForm();
        } else {
          toast.warn(response?.message || 'Fail!', {
            position: toast.POSITION.TOP_CENTER,
          });
          setunit_confirm('')
          if (state?.type === 'Simple') {
            symbol_input_ref.current.focus();
            if (symbol_input_ref.current) {
              symbol_input_ref.current.setSelectionRange(0, 0)
            }
          } else {
            first_unit_input_ref.current.focus();
            if (first_unit_input_ref.current) {
              first_unit_input_ref.current.setSelectionRange(0, 0)
            }

          }
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.message || 'catch error...!', {
          position: toast.POSITION.TOP_CENTER,
        });
        setunit_confirm('')
        sethash(false)
        if (state?.type === 'Simple') {
          symbol_input_ref.current.focus();
          if (symbol_input_ref.current) {
            symbol_input_ref.current.setSelectionRange(0, 0)
          }
        } else {
          first_unit_input_ref.current.focus();
          if (first_unit_input_ref.current) {
            first_unit_input_ref.current.setSelectionRange(0, 0)
          }

        }
      }
    } else {
      toast.error('Please fill all the fields.', {
        position: toast.POSITION.TOP_CENTER
      });
      setunit_confirm('')
      sethash(false)
      if (state?.type === 'Simple') {
        symbol_input_ref.current.focus();
        if (symbol_input_ref.current) {
          symbol_input_ref.current.setSelectionRange(0, 0)
        }
      } else {
        first_unit_input_ref.current.focus();
        if (first_unit_input_ref.current) {
          first_unit_input_ref.current.setSelectionRange(0, 0)
        }

      }
    }
  };

  // Triggered when a form is submitted ----------------------------------------------------------------------------------
  const unitSubmit = (e) => {
    e.preventDefault();
    if (hash) {
      if (validateForm()) {
        try {
          const formData = new FormData();
          formData.append('type', state.type)
          formData.append('symbol', state.symbol)
          formData.append('formal_name', state.formal_name)
          formData.append('uqc_id', state.unit_id)
          formData.append('no_decimal_places', state.number_of_decimal_places)
          formData.append('first_unit', state.first_unit_id)
          formData.append('conversion', state.conversions)
          formData.append('second_unit', state.secound_unit_id)
          formData.append('company_id', userData?.id)
          const response = dispatch(unitsCreate(formData));
          if (response.status) {
            toast.success(response?.message || 'unit created!', {
              position: toast.POSITION.TOP_CENTER
            });
            const formData = new FormData();
            formData.append('company_id', userData?.id)
            dispatch(unitsListing(formData))
            resetForm();
          } else {
            toast.warn(response?.message || 'Fail!', {
              position: toast.POSITION.TOP_CENTER,
            });
            setunit_confirm('')
            if (state?.type === 'Simple') {
              symbol_input_ref.current.focus();
              if (symbol_input_ref.current) {
                symbol_input_ref.current.setSelectionRange(0, 0)
              }
            } else {
              first_unit_input_ref.current.focus();
              if (first_unit_input_ref.current) {
                first_unit_input_ref.current.setSelectionRange(0, 0)
              }

            }
          }
        } catch (error) {
          console.log(error);
          toast.error(error?.message || 'catch error...!', {
            position: toast.POSITION.TOP_CENTER,
          });
          setunit_confirm('')
          sethash(false)
          if (state?.type === 'Simple') {
            symbol_input_ref.current.focus();
            if (symbol_input_ref.current) {
              symbol_input_ref.current.setSelectionRange(0, 0)
            }
          } else {
            first_unit_input_ref.current.focus();
            if (first_unit_input_ref.current) {
              first_unit_input_ref.current.setSelectionRange(0, 0)
            }

          }
        }
      } else {
        toast.error('Please fill all the fields.', {
          position: toast.POSITION.TOP_CENTER
        });
        setunit_confirm('')
        sethash(false)
        if (state?.type === 'Simple') {
          symbol_input_ref.current.focus();
          if (symbol_input_ref.current) {
            symbol_input_ref.current.setSelectionRange(0, 0)
          }
        } else {
          first_unit_input_ref.current.focus();
          if (first_unit_input_ref.current) {
            first_unit_input_ref.current.setSelectionRange(0, 0)
          }

        }
      }
    }
  }

  // Function to validate form fields---------------------------------------
  const validateForm = () => {
    const condition1 = state?.type === 'Simple'
    const condition2 = state?.symbol !== '' &&
      state?.formal_name !== '' &&
      state.unit_quantity_code !== '' &&
      state.number_of_decimal_places !== ''
    const condtion3 = state?.first_unit !== '' &&
      state?.first_unit_id !== '' &&
      state.conversions !== '' &&
      state.secound_unit !== '' &&
      state.secound_unit_id !== ''

    return (
      userData?.id !== '' &&
        state?.type !== '' &&
        condition1 ? condition2 : condtion3
    );
  };
  // Function to reset form fields-----------------------------------------
  const resetForm = () => {
    setState(prevState => ({
      ...prevState,
      type: 'Simple',
      symbol: '',
      formal_name: '',
      unit_quantity_code: 'Not Applicable',
      uqc_id: 'Not Applicable',
      number_of_decimal_places: '0',
      first_unit: '',
      first_unit_id: '',
      conversions: '',
      secound_unit: '',
      secound_unit_id: '',
    }));
    setunit_confirm('');
    setTimeout(() => {
      symbol_input_ref.current.focus();
    }, 50);
    sethash(false)
    setIsUnitNameEntered(false);
    set_type_entered(false)
  };
  // Triggered when a form is submitted of uqc ----------------------------------------------------------------------------------
  const handleCreate = (e) => {
    e.preventDefault();
    if (!state.uqc_code_create_name) {
      toast.error('Please enter a valid UQC code.', {
        position: toast.POSITION.TOP_CENTER
      });
      return;
    }
    const formData = new FormData();
    formData.append('name', state.uqc_code_create_name)
    formData.append('company_id', userData?.id)
    dispatch(uqcCreate(formData))
      .then((response) => {
        if (response.status) {
          const data = new FormData();
          data.append('company_id', userData?.id)
          dispatch(uqcListing(data))
          setState({ ...state, uqc_code_create_name: '' })
        }
      })
      .catch((error) => {
        toast.error(error?.message || ' Failed!', {
          position: toast.POSITION.TOP_CENTER
        });
      });
  };

  return (
    <div className='section'>

      <div className='detail border'>
        {
          unit_confirm === 'Accept' ?
            <YesNoModal>
              <div className='container'>
                <div className='row'>
                  <div className='col-12'>
                    <p className='modal_message'>
                      {unit_confirm}
                    </p>
                  </div>
                  <div className='col-3 offset-1'>
                    <Link className="modal_link" onClick={controlPlusASubmit} >Yes</Link>
                  </div>
                  <div className='col-2'>or</div>
                  <div className='col-3'>
                    <Link className='modal_link'>No</Link>
                  </div>
                </div>
              </div>
            </YesNoModal> : null
        }
        <div className='container-fluid p-0' style={{ background: 'rgba(0,0,0,0.4)', height: '91.7vh' }}>
          <div className='row'>
            <form
              autoComplete='off'
              onSubmit={unitSubmit}
              onKeyDown={(e) => {
                if (e.ctrlKey && (e.key === 'a' || e.key === 'A')) {
                  e.preventDefault();
                  controlPlusASubmit(e);
                }
              }}>
              <div className='col-6 p-3' style={{ background: '#fff' }}>
                <div className='row mt-3 mb-4'>
                  <div className='d-flex justify-content-between align-item-center'>
                    <label className='lab-1'>Type</label>
                    <label> :</label>
                    <input
                      type="text"
                      name='type'
                      id='type'
                      className={`lab-right`}
                      ref={type_input_ref}
                      onKeyUp={handleChange}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      value={type || ""} />
                    <div id="myDropdown-list" className={`dropdown-content-list ${showListType ? 'show-list' : ''}`} ref={dropdownRefType}>
                      <div className='col' style={{ background: '#162070', color: 'white', position: 'sticky', top: 0 }}>
                        Type of Units
                      </div>
                      <div id='data'>
                        {filteredDataType.length > 0 ? (
                          filteredDataType.map((key, number) => {
                            const name = key?.name;
                            const index = name.toLowerCase().indexOf(type.toLowerCase());
                            return (
                              <Link
                                className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                id='list'
                                key={number}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setSelectedItem(number)
                                  if (number === selectedItem) {
                                    setState({ ...state, type: name })
                                    setShowListType(false)
                                    if (type === 'Simple') {
                                      symbol_input_ref.current.focus();
                                    } else if (type === 'Compound') {
                                      first_unit_input_ref.current.focus();
                                    }

                                  }
                                }}
                              >
                                {index !== -1 ? (
                                  < >
                                    {name.substring(0, index)}
                                    <span style={{ color: 'red' }}>{name.substring(index, index + type.length)}</span>
                                    {name.substring(index + type.length)}
                                  </>
                                ) : (
                                  name
                                )}
                              </Link>
                            );
                          })
                        ) : (
                          <p style={{ color: 'red' }}>No matching items found</p>
                        )}
                      </div>
                    </div>
                  </div>
                  {
                    type === 'Simple' ?
                      <>
                        <div className='d-flex justify-content-between align-item-center' id='alis'>
                          <label className='lab-1'> Symbol </label>
                          <label> :</label>
                          <input
                            type="text"
                            autoFocus
                            name='symbol'
                            id='symbol'
                            className={`lab-right`}
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            ref={symbol_input_ref}
                            value={symbol || ""} />
                        </div>
                        <div className='d-flex justify-content-between align-item-center' id='alis'>
                          <label className='lab-1'> Formal Name </label>
                          <label> :</label>
                          <input
                            type="text"
                            name='formal_name'
                            id='formal_name'
                            className={`lab-right ${isUnitNameEntered ? '' : 'disable-field'}`}
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            ref={formal_name_input_ref}
                            value={formal_name || ""} />
                        </div>
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1'> Unit Quantity Code (UQC) </label>
                          <label> :</label>
                          <input
                            type='text'
                            id='unit_quantity_code'
                            name="unit_quantity_code"
                            ref={unit_quantity_code_input_ref}
                            value={unit_quantity_code || ""}
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            className={`lab-right ${isUnitNameEntered ? '' : 'disable-field'}`} />
                          <div id="myDropdown-list" className={`dropdown-content-list ${showList ? 'show-list' : ''}`} ref={dropdownRef}>
                            <div className='col' style={{ background: '#162070', color: 'white', position: 'sticky', top: 0 }}>
                              List Of UQCs
                            </div>
                            <div className='col ' style={{ textAlign: 'right' }}>
                              <Link style={{ textAlign: 'right', paddingRight: 10 }}>New UQC</Link>
                              <div className='border'></div>
                            </div>
                            <div id='data'>
                              {filteredDataUQC.length > 0 ? (
                                filteredDataUQC.map((key, number) => {
                                  const name = key?.name;
                                  const index = name?.toLowerCase()?.indexOf(unit_quantity_code?.toLowerCase());
                                  return (
                                    <Link
                                      className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                      id='list'
                                      key={number}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setSelectedItem(number)
                                        if (number === selectedItem) {
                                          setState({ ...state, unit_quantity_code: name })
                                        }
                                      }}
                                    >
                                      {index !== -1 ? (
                                        < >
                                          {name?.substring(0, index)}
                                          <span style={{ color: 'red' }}>{name?.substring(index, index + unit_quantity_code?.length)}</span>
                                          {name?.substring(index + unit_quantity_code?.length)}
                                        </>
                                      ) : (
                                        name
                                      )}
                                    </Link>
                                  );
                                })
                              ) : (
                                <p style={{ color: 'red' }}>No matching items found</p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='d-flex justify-content-between align-item-center' id='alis'>
                          <label className='lab-1'>Number of Decimal Places </label>
                          <label> :</label>
                          <input
                            type="text"
                            name='number_of_decimal_places'
                            id='number_of_decimal_places'
                            className={`lab-right ${isUnitNameEntered ? '' : 'disable-field'}`}
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            ref={number_of_decimal_places_input_ref}
                            value={number_of_decimal_places || ""} />
                        </div>
                      </> :
                      <div className='row'>
                        <div className='col-12 d-flex align-items-center justify-content-center flex-column'>
                          <p className='m-0 mt-1 p-0'><strong><u>Units with multiplier Factors</u></strong></p>
                          <p className='m-0 p-0'>(example: kgs of 1000 gms)</p>
                        </div>
                        <div className='col-3 d-flex  p-0 mt-1'>
                          <label className='lab-1'>First Unit </label>
                          <label>:</label>
                          <input
                            type="text"
                            name='first_unit'
                            id='first_unit'
                            className={`lab-right ${type_entered ? '' : 'disable-field'}`}
                            ref={first_unit_input_ref}
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            value={first_unit || ""} />
                          <div id="myDropdown-list" className={`dropdown-content-list ${showListFirst ? 'show-list' : ''}`} ref={dropdownRefFirst}>
                            <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                              Units
                            </div>
                            <div className='col ' style={{ textAlign: 'right' }}>
                              <Link style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                              <div className='border'></div>
                            </div>
                            <div id='data'>
                              {filteredDataFirstUnit.length > 0 ? (
                                filteredDataFirstUnit.map((key, number) => {
                                  const name = key?.symbol;
                                  const index = name.toLowerCase().indexOf(first_unit.toLowerCase());
                                  return (
                                    <Link
                                      className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                      id='list'
                                      key={number}
                                      // onClick={(e) => {displayList(data[key]?.name, data[key]?.id); }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setSelectedItem(number)
                                        if (number === selectedItem) {
                                          setState({ ...state, first_unit: name, first_unit_id: unit_data[key].id })
                                          setShowListFirst(false)
                                          conversions_input_ref.current.focus();
                                        }
                                      }}
                                    >
                                      {index !== -1 ? (
                                        < >
                                          {name.substring(0, index)}
                                          <span style={{ color: 'red' }}>{name.substring(index, index + first_unit.length)}</span>
                                          {name.substring(index + first_unit.length)}
                                        </>
                                      ) : (
                                        name
                                      )}
                                    </Link>
                                  );
                                })
                              ) : (
                                <p style={{ color: 'red' }}>No matching items found</p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className='col-5 p-0 m-0 mt-1'>
                          <label className='lab-1'>of conversions </label>
                          <label>:</label>
                          <input
                            type="text"
                            name='conversions'
                            id='conversions'
                            className={`lab-right ${type_entered ? '' : 'disable-field'}`}
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            ref={conversions_input_ref}
                            value={conversions || ""} />
                        </div>
                        <div className='col-4 p-0 m-0 mt-1'>
                          <label className='lab-1'>Secound Unit </label>
                          <label>:</label>
                          <input
                            type="text"
                            name='secound_unit'
                            id='secound_unit'
                            className={`lab-right ${type_entered ? '' : 'disable-field'}`}
                            ref={secound_unit_input_ref}
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            value={secound_unit || ""} />
                          <div id="myDropdown-list" className={`dropdown-content-list ${showListSecound ? 'show-list' : ''}`} ref={dropdownRefSecound}>
                            <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                              Units
                            </div>
                            <div className='col ' style={{ textAlign: 'right' }}>
                              <Link style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                              <div className='border'></div>
                            </div>
                            <div id='data'>
                              {filteredDataSecondUnit.length > 0 ? (
                                filteredDataSecondUnit.map((key, number) => {
                                  const name = key?.symbol;
                                  const index = name.toLowerCase().indexOf(secound_unit.toLowerCase());
                                  return (
                                    <Link
                                      className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                      id='list'
                                      key={number}
                                    // onClick={(e) => {displayList(data[key]?.name, data[key]?.id); }}
                                    // onClick={(e) => {
                                    //   e.preventDefault();
                                    //   setSelectedItem(number)
                                    //   if (number === selectedItem) {
                                    //     setState({ ...state, under: name })
                                    //     setShowList(false)
                                    //     setShow_category(true)
                                    //     setTimeout(() => {
                                    //       inputCategoryRef.current.focus();
                                    //     }, 50);
                                    //   }
                                    // }}
                                    >
                                      {index !== -1 ? (
                                        < >
                                          {name.substring(0, index)}
                                          <span style={{ color: 'red' }}>{name.substring(index, index + secound_unit.length)}</span>
                                          {name.substring(index + secound_unit.length)}
                                        </>
                                      ) : (
                                        name
                                      )}
                                    </Link>
                                  );
                                })
                              ) : (
                                <p style={{ color: 'red' }}>No matching items found</p>
                              )}
                            </div>
                          </div>
                        </div>

                      </div>
                  }
                </div>
              </div>
              <MasterCreationFooter
                setHash={sethash}
              />
            </form>
          </div>
        </div >
      </div >
      <div className='help'>
      </div>
    </div >
  )
}

export default Unit
