import {
    // Group Action Type 
    GROUP_LIST_REQUEST, GROUP_LIST_SUCCESS, GROUP_LIST_FAILURE,
    GROUP_CREATE_REQUEST, GROUP_CREATE_SUCCESS, GROUP_CREATE_FAILURE,
    GROUP_UPDATE_REQUEST, GROUP_UPDATE_SUCCESS, GROUP_UPDATE_FAILURE,
    GROUP_DELETE_REQUEST, GROUP_DELETE_SUCCESS, GROUP_DELETE_FAILURE,
    GROUP_SINGLE_REQUEST, GROUP_SINGLE_SUCCESS, GROUP_SINGLE_FAILURE,
    GROUP_LIST_UNDER_REQUEST, GROUP_LIST_UNDER_SUCCESS, GROUP_LIST_UNDER_FAILURE,
    // Ledger Action Type 
    LEDGER_CREATE_REQUEST, LEDGER_CREATE_SUCCESS, LEDGER_CREATE_FAILURE,
    LEDGER_SINGLE_DATA_REQUEST, LEDGER_SINGLE_DATA_SUCCESS, LEDGER_SINGLE_DATA_FAILURE,
    LEDGER_UPDATE_REQUEST, LEDGER_UPDATE_SUCCESS, LEDGER_UPDATE_FAILURE,
    LEDGER_COMMON_LIST_REQUEST, LEDGER_COMMON_LIST_SUCCESS, LEDGER_COMMON_LIST_FAILURE,
    LEDGER_DELETE_REQUEST, LEDGER_DELETE_SUCCESS, LEDGER_DELETE_FAILURE,
    LEDGER_LIST_REQUEST, LEDGER_LIST_SUCCESS, LEDGER_LIST_FAILURE,
    // Unit Action Type 
    UNIT_CREATE_REQUEST, UNIT_CREATE_SUCCESS, UNIT_CREATE_FAILURE,
    UNIT_LIST_REQUEST, UNIT_LIST_SUCCESS, UNIT_LIST_FAILURE,
    UNIT_DELETE_REQUEST, UNIT_DELETE_SUCCESS, UNIT_DELETE_FAILURE,
    UNIT_UPDATE_REQUEST, UNIT_UPDATE_SUCCESS, UNIT_UPDATE_FAILURE,
    UNIT_SINGLE_DATA_REQUEST, UNIT_SINGLE_DATA_SUCCESS, UNIT_SINGLE_DATA_FAILURE,
    // UQC CODE  Action Type 
    UQC_CODE_LIST_REQUEST, UQC_CODE_LIST_SUCCESS, UQC_CODE_LIST_FAILURE,
    UQC_CODE_CREATE_REQUEST, UQC_CODE_CREATE_SUCCESS, UQC_CODE_CREATE_FAILURE,
    UQC_CODE_DELETE_REQUEST, UQC_CODE_DELETE_SUCCESS, UQC_CODE_DELETE_FAILURE,
    UQC_CODE_UPDATE_REQUEST, UQC_CODE_UPDATE_SUCCESS, UQC_CODE_UPDATE_FAILURE,
    UQC_CODE_SINGLE_DATA_REQUEST, UQC_CODE_SINGLE_DATA_SUCCESS, UQC_CODE_SINGLE_DATA_FAILURE,
    // STOCK CATEGORY Action Type 
    STOCK_CATEGORY_CREATE_REQUEST, STOCK_CATEGORY_CREATE_SUCCESS, STOCK_CATEGORY_CREATE_FAILURE,
    STOCK_CATEGORY_NAME_VALIDATE_REQUEST, STOCK_CATEGORY_NAME_VALIDATE_SUCCESS, STOCK_CATEGORY_NAME_VALIDATE_FAILURE,
    STOCK_CATEGORY_UPDATE_REQUEST, STOCK_CATEGORY_UPDATE_SUCCESS, STOCK_CATEGORY_UPDATE_FAILURE,
    STOCK_CATEGORY_LIST_REQUEST, STOCK_CATEGORY_LIST_SUCCESS, STOCK_CATEGORY_LIST_FAILURE,
    STOCK_CATEGORY_UNDER_LIST_REQUEST, STOCK_CATEGORY_UNDER_LIST_SUCCESS, STOCK_CATEGORY_UNDER_LIST_FAILURE,
    STOCK_CATEGORY_DELETE_REQUEST, STOCK_CATEGORY_DELETE_SUCCESS, STOCK_CATEGORY_DELETE_FAILURE,
    STOCK_CATEGORY_SINGLE_REQUEST, STOCK_CATEGORY_SINGLE_SUCCESS, STOCK_CATEGORY_SINGLE_FAILURE,
    // STOCK GROUP Action Type 
    STOCK_GROUP_CREATE_REQUEST, STOCK_GROUP_CREATE_SUCCESS, STOCK_GROUP_CREATE_FAILURE,
    STOCK_GROUP_UPDATE_REQUEST, STOCK_GROUP_UPDATE_SUCCESS, STOCK_GROUP_UPDATE_FAILURE,
    STOCK_GROUP_LIST_REQUEST, STOCK_GROUP_LIST_SUCCESS, STOCK_GROUP_LIST_FAILURE,
    STOCK_GROUP_UNDER_LIST_REQUEST, STOCK_GROUP_UNDER_LIST_SUCCESS, STOCK_GROUP_UNDER_LIST_FAILURE,
    STOCK_GROUP_DELETE_REQUEST, STOCK_GROUP_DELETE_SUCCESS, STOCK_GROUP_DELETE_FAILURE,
    STOCK_GROUP_SINGLE_DATA_REQUEST, STOCK_GROUP_SINGLE_DATA_SUCCESS, STOCK_GROUP_SINGLE_DATA_FAILURE,
    // STOCK COMPANY Action Type 
    STOCK_COMPANY_CREATE_REQUEST, STOCK_COMPANY_CREATE_SUCCESS, STOCK_COMPANY_CREATE_FAILURE,
    STOCK_COMPANY_UPDATE_REQUEST, STOCK_COMPANY_UPDATE_SUCCESS, STOCK_COMPANY_UPDATE_FAILURE,
    STOCK_COMPANY_LIST_REQUEST, STOCK_COMPANY_LIST_SUCCESS, STOCK_COMPANY_LIST_FAILURE,
    STOCK_COMPANY_DELETE_REQUEST, STOCK_COMPANY_DELETE_SUCCESS, STOCK_COMPANY_DELETE_FAILURE,
    STOCK_COMPANY_SINGLE_DATA_REQUEST, STOCK_COMPANY_SINGLE_DATA_SUCCESS, STOCK_COMPANY_SINGLE_DATA_FAILURE,
    // STOCK ITEM Action Type
    STOCK_ITEM_CREATE_REQUEST, STOCK_ITEM_CREATE_SUCCESS, STOCK_ITEM_CREATE_FAILURE,
    STOCK_ITEM_UPDATE_REQUEST, STOCK_ITEM_UPDATE_SUCCESS, STOCK_ITEM_UPDATE_FAILURE,
    STOCK_ITEM_LIST_REQUEST, STOCK_ITEM_LIST_SUCCESS, STOCK_ITEM_LIST_FAILURE,
    STOCK_ITEM_DELETE_REQUEST, STOCK_ITEM_DELETE_SUCCESS, STOCK_ITEM_DELETE_FAILURE,
    STOCK_ITEM_SINGLE_DATA_REQUEST, STOCK_ITEM_SINGLE_DATA_SUCCESS, STOCK_ITEM_SINGLE_DATA_FAILURE,
    STOCK_ITEM_LIST_TO_DROPDOWN_REQUEST, STOCK_ITEM_LIST_TO_DROPDOWN_SUCCESS, STOCK_ITEM_LIST_TO_DROPDOWN_FAILURE,
    // VOUCHER TYPE Action Type
    VOUCHER_TYPE_CREATE_REQUEST, VOUCHER_TYPE_CREATE_SUCCESS, VOUCHER_TYPE_CREATE_FAILURE,
    VOUCHER_TYPE_UPDATE_REQUEST, VOUCHER_TYPE_UPDATE_SUCCESS, VOUCHER_TYPE_UPDATE_FAILURE,
    VOUCHER_TYPE_LIST_REQUEST, VOUCHER_TYPE_LIST_SUCCESS, VOUCHER_TYPE_LIST_FAILURE,
    VOUCHER_TYPE_DELETE_REQUEST, VOUCHER_TYPE_DELETE_SUCCESS, VOUCHER_TYPE_DELETE_FAILURE,
    VOUCHER_TYPE_SINGLE_DATA_REQUEST, VOUCHER_TYPE_SINGLE_DATA_SUCCESS, VOUCHER_TYPE_SINGLE_DATA_FAILURE,
    VOUCHER_TYPE_DEFAULT_LIST_REQUEST, VOUCHER_TYPE_DEFAULT_LIST_SUCCSESS, VOUCHER_TYPE_DEFAULT_LIST_FAILURE,
    VOUCHER_TYPE_NAME_CHECK_REQUEST, VOUCHER_TYPE_NAME_CHECK_SUCCESS, VOUCHER_TYPE_NAME_CHECK_FAILURE,
    // Number Series For Voucher Type Action Type
    NUMBER_SERIES_CREATE_REQUEST, NUMBER_SERIES_CREATE_SUCCESS, NUMBER_SERIES_CREATE_FAILURE,
    NUMBER_SERIES_LIST_REQUEST, NUMBER_SERIES_LIST_SUCCESS, NUMBER_SERIES_LIST_FAILURE,
    NUMBER_SERIES_SINGLE_LIST_REQUEST, NUMBER_SERIES_SINGLE_LIST_SUCCESS, NUMBER_SERIES_SINGLE_LIST_FAILURE,
    NUMBER_SERIES_UPDATE_REQUEST, NUMBER_SERIES_UPDATE_SUCCESS, NUMBER_SERIES_UPDATE_FAILURE,
    // GODOWN Action Type
    GODOWN_CREATE_REQUEST, GODOWN_CREATE_SUCCESS, GODOWN_CREATE_FAILURE,
    GODOWN_UPDATE_REQUEST, GODOWN_UPDATE_SUCCESS, GODOWN_UPDATE_FAILURE,
    GODOWN_LIST_REQUEST, GODOWN_LIST_SUCCESS, GODOWN_LIST_FAILURE,
    GODOWN_DELETE_REQUEST, GODOWN_DELETE_SUCCESS, GODOWN_DELETE_FAILURE,
    GODOWN_SINGLE_DATA_REQUEST, GODOWN_SINGLE_DATA_SUCCESS, GODOWN_SINGLE_DATA_FAILURE,
    GODOWN_PRIMARY_LIST_REQUEST, GODOWN_PRIMARY_LIST_SUCCESS, GODOWN_PRIMARY_LIST_FAILURE,
    // CONTRA LEDGER LISTING IN VOUCHER ENTRY Action Type
    CONTRA_LEDGER_LIST_REQUEST, CONTRA_LEDGER_LIST_SUCCESS, CONTRA_LEDGER_LIST_FAILURE,
    SERIAL_NUMBER_REQUEST, SERIAL_NUMBER_SUCCESS, SERIAL_NUMBER_FAILURE,
    CONTRA_VOUCHER_ENTRY_CREATE_REQUEST, CONTRA_VOUCHER_ENTRY_CREATE_SUCCESS, CONTRA_VOUCHER_ENTRY_CREATE_FAILURE,
    CONTRA_VOUCHER_ENTRY_LIST_REQUEST, CONTRA_VOUCHER_ENTRY_LIST_SUCCESS, CONTRA_VOUCHER_ENTRY_LIST_FAILURE,
    CONTRA_VOUCHER_ENTRY_SINGLE_REQUEST, CONTRA_VOUCHER_ENTRY_SINGLE_SUCCESS, CONTRA_VOUCHER_ENTRY_SINGLE_FAILURE,
    CONTRA_VOUCHER_ENTRY_UPDATE_REQUEST, CONTRA_VOUCHER_ENTRY_UPDATE_SUCCESS, CONTRA_VOUCHER_ENTRY_UPDATE_FAILURE,
    VOUCHER_TYPE_SAVE,
    VOUCHER_ENTRY_DELETE_REQUEST, VOUCHER_ENTRY_DELETE_SUCCESS, VOUCHER_ENTRY_DELETE_FAILURE,
    // ledger book listing Action Type
    LEDGER_BOOK_REQUEST, LEDGER_BOOK_SUCCESS, LEDGER_BOOK_FAILURE,
    // LEDGER POSTING LIST Action Type
    LEDGER_POSTING_LIST_REQUEST, LEDGER_POSTING_LIST_SUCCESS, LEDGER_POSTING_LIST_FAILURE,
    // CURRENT PERIOD And Current Date  Action Type
    CURRENT_DATE_SAVE, CURRENT_PERIOD_SAVE,
    // Stock summary list
    STOCK_SUMMARY_LIST_REQUEST, STOCK_SUMMARY_LIST_SUCCESS, STOCK_SUMMARY_LIST_FAILURE,
    STOCK_SUMMARY_GODOWN_FILTER_LIST_REQUEST, STOCK_SUMMARY_GODOWN_FILTER_LIST_SUCCESS, STOCK_SUMMARY_GODOWN_FILTER_LIST_FAILURE,
    STOCK_SUMMARY_ITEM_VOUCHER_REQUEST, STOCK_SUMMARY_ITEM_VOUCHER_SUCCESS, STOCK_SUMMARY_ITEM_VOUCHER_FAILURE,
    // Max Date of Entry --------------
    MAX_DATE_OF_ENTRY_REQUEST, MAX_DATE_OF_ENTRY_SUCCESS, MAX_DATE_OF_ENTRY_FAILURE,
    // Filter voucher type data ----
    FILTER_VOUCHER_TYPE_DATA,
    FILTER_API_CALL,
    PROFIT_AND_LOSS_REQUEST,
    PROFIT_AND_LOSS_SUCCESS,
    PROFIT_AND_LOSS_FAILURE,
    GROUP_SUMMARY_REQUEST,
    GROUP_SUMMARY_SUCCESS,
    GROUP_SUMMARY_FAILURE,
    RESET_DATA

} from '../../actions/action-types';
const currentDate = new Date();
const currentMonth = currentDate.getMonth();
const currentYear = currentDate.getFullYear();
const financialYearStartMonth = 3;
const financialYearStartYear = currentMonth < financialYearStartMonth ? currentYear - 1 : currentYear;
const financialYearEndYear = financialYearStartYear + 1;
const date_1 = new Date(`${financialYearStartYear}-${financialYearStartMonth + 1}-01`)
const date_2 = new Date(`${financialYearEndYear}-${financialYearStartMonth}-31`)
const financialYear = `${date_1.getDate()}-${date_1.toLocaleString('default', { month: 'short' })}-${date_1.getFullYear()} To ${date_2.getDate()}-${date_2.toLocaleString('default', { month: 'short' })}-${date_2.getFullYear()}`;
const current_date_redux = `${currentDate.toLocaleString('en-US', { weekday: 'long' })},  ${currentDate.getDate()}-${currentDate.toLocaleString('default', { month: 'short' })}-${currentDate.getFullYear()}`
const current_date = `${currentDate.getDate()}-${currentDate.toLocaleString('default', { month: 'short' })}-${currentDate.getFullYear()}`
const initialState = {
    store_voucher_entry: [],
    company_session: {
        current_date: current_date_redux,
        current_date_1: current_date,
        financial_year: financialYear,
        start_financial_year: date_1,
        end_financial_year: date_2
    },
    groupResponse: null,
    data: {},
    profit_loss: [],
    group_summary: [],
    serial_no: '',
    ledger_book: [],
    filter_voucher: [],
    company_session_period: [],
    contra_create_response: [],
    contra_voucher_data: [],
    contra_voucher__single_data: [],
    group_all_list: [],
    contra_ledger_data: [],
    stock_summary_data: [],
    stock_summary_filter_api: {
        item_wise: true,
        group_wise: false,
        godown_wise: false,
        category_wise: false
    },
    ledger_data: [],
    ledger_single: [],
    stock_summary_item_voucher: [],
    ledger_posting_data: [],
    number_series_data: [],
    number_series_single_data: [],
    voucher_type_name_check: {},
    uqc_code_response: [],
    uqc_code_single_data: {},
    unit_data: [],
    godown_data: [],
    godown_primary_data: {},
    godown_single_data: {},
    max_date_of_entry_date: '',
    unit_single_data: {},
    stock_group_nested_data: [],
    stock_group_data: [],
    stock_group_single_data: {},
    stock_category_data: [],
    stock_category_under_data: [],
    stock_category_single_data: {},
    stock_company_data: [],
    stock_comapny_single_data: {},
    stock_item_data: {},
    stock_item_data_TO_Dropdown: [],
    stock_item_single_data: {},
    voucher_type_default_data: [],
    voucher_type_data: [],
    single_voucher_type_data: {},
    voucher_data: {},
    single_voucher_data: {},
    ledgerDelete: null,
    ledgerUpdate: null,
    ledgerCreate: null,
    name_validate: []
}

export const common = (state = initialState, { type, payload }) => {
    switch (type) {
        case FILTER_API_CALL: return {
            ...state, stock_summary_filter_api: payload
        }
        // For GROUP Module ===========================
        case GROUP_LIST_REQUEST:
            return {
                ...state, data: null
            }
        case GROUP_LIST_SUCCESS:
            return {
                ...state, data: payload.data || null,
            }
        case GROUP_LIST_FAILURE:
            return {
                ...state, data: null
            }
        case GROUP_LIST_UNDER_REQUEST:
            return {
                ...state, group_all_list: []
            }
        case GROUP_LIST_UNDER_SUCCESS:
            return {
                ...state, group_all_list: payload.data || [],
            }
        case GROUP_LIST_UNDER_FAILURE:
            return {
                ...state, group_all_list: []
            }
        case GROUP_CREATE_REQUEST:
            return {
                ...state, groupResponse: null
            }
        case GROUP_CREATE_SUCCESS:
            return {
                ...state, groupResponse: payload.data || null,
            }
        case GROUP_CREATE_FAILURE:
            return {
                ...state, groupResponse: null
            }
        case GROUP_DELETE_REQUEST:
            return {
                ...state, groupResponse: null
            }
        case GROUP_DELETE_SUCCESS:
            return {
                ...state, groupResponse: payload.data || null,
            }
        case GROUP_DELETE_FAILURE:
            return {
                ...state, groupResponse: null
            }
        case GROUP_SINGLE_REQUEST:
            return {
                ...state, groupResponse: null
            }
        case GROUP_SINGLE_SUCCESS:
            return {
                ...state, groupResponse: payload.data || null,
            }
        case GROUP_SINGLE_FAILURE:
            return {
                ...state, groupResponse: null
            }
        case GROUP_UPDATE_REQUEST:
            return {
                ...state, groupResponse: null
            }
        case GROUP_UPDATE_SUCCESS:
            return {
                ...state, groupResponse: payload.data || null,
            }
        case GROUP_UPDATE_FAILURE:
            return {
                ...state, groupResponse: null
            }
        // For Ledger Module ===================================
        case LEDGER_CREATE_REQUEST:
            return {
                ...state, ledgerCreate: null
            }
        case LEDGER_CREATE_SUCCESS:
            return {
                ...state, ledgerCreate: payload || null,
            }
        case LEDGER_CREATE_FAILURE:
            return {
                ...state, ledgerCreate: null
            }

        // For Ledger Module ===================================
        case LEDGER_SINGLE_DATA_REQUEST:
            return {
                ...state, ledger_single: []
            }
        case LEDGER_SINGLE_DATA_SUCCESS:
            return {
                ...state, ledger_single: payload || [],
            }
        case LEDGER_SINGLE_DATA_FAILURE:
            return {
                ...state, ledger_single: []
            }

        // For Ledger Module ===================================
        case LEDGER_UPDATE_REQUEST:
            return {
                ...state, ledgerCreate: null
            }
        case LEDGER_UPDATE_SUCCESS:
            return {
                ...state, ledgerCreate: payload || null,
            }
        case LEDGER_UPDATE_FAILURE:
            return {
                ...state, ledgerCreate: null
            }





        case LEDGER_LIST_REQUEST:
            return {
                ...state, ledger_data: []
            }
        case LEDGER_LIST_SUCCESS:
            return {
                ...state, ledger_data: payload.data || [],
            }
        case LEDGER_LIST_FAILURE:
            return {
                ...state, ledger_data: []
            }
        case LEDGER_DELETE_REQUEST:
            return {
                ...state, ledgerDelete: null,
            }
        case LEDGER_DELETE_SUCCESS:
            return {
                ...state, ledgerDelete: payload.massage || null,
            }
        case LEDGER_DELETE_FAILURE:
            return {
                ...state, ledgerDelete: null,
            }
        // For Unit Module ========================================
        case UNIT_CREATE_REQUEST:
            return {
                ...state, ledgerDelete: null,
            }
        case UNIT_CREATE_SUCCESS:
            return {
                ...state, ledgerDelete: payload.massage || null,
            }
        case UNIT_CREATE_FAILURE:
            return {
                ...state, ledgerDelete: null,
            }
        case UNIT_LIST_REQUEST:
            return {
                ...state, unit_data: [],
            }
        case UNIT_LIST_SUCCESS:
            return {
                ...state, unit_data: payload.data || [],
            }
        case UNIT_LIST_FAILURE:
            return {
                ...state, unit_data: [],
            }
        case UNIT_DELETE_REQUEST:
            return {
                ...state, ledgerDelete: null,
            }
        case UNIT_DELETE_SUCCESS:
            return {
                ...state, ledgerDelete: payload.massage || null,
            }
        case UNIT_DELETE_FAILURE:
            return {
                ...state, ledgerDelete: null,
            }
        // unit update ---------- ----------
        case UNIT_UPDATE_REQUEST:
            return {
                ...state, ledgerDelete: null,
            }
        case UNIT_UPDATE_SUCCESS:
            return {
                ...state, ledgerDelete: payload.massage || null,
            }
        case UNIT_UPDATE_FAILURE:
            return {
                ...state, ledgerDelete: null,
            }
        case UNIT_SINGLE_DATA_REQUEST:
            return {
                ...state, unit_single_data: {},
            }
        case UNIT_SINGLE_DATA_SUCCESS:
            return {
                ...state, unit_single_data: payload.data || {},
            }
        case UNIT_SINGLE_DATA_FAILURE:
            return {
                ...state, unit_single_data: {},
            }
        // For UQC Module ======================================
        case UQC_CODE_LIST_REQUEST:
            return {
                ...state, uqc_code_response: [],
            }
        case UQC_CODE_LIST_SUCCESS:
            return {
                ...state, uqc_code_response: payload.data || [],
            }
        case UQC_CODE_LIST_FAILURE:
            return {
                ...state, uqc_code_response: [],
            }
        case UQC_CODE_CREATE_REQUEST:
            return {
                ...state, ledgerDelete: null,
            }
        case UQC_CODE_CREATE_SUCCESS:
            return {
                ...state, ledgerDelete: payload.massage || null,
            }
        case UQC_CODE_CREATE_FAILURE:
            return {
                ...state, ledgerDelete: null,
            }
        case UQC_CODE_DELETE_REQUEST:
            return {
                ...state, ledgerDelete: null,
            }
        case UQC_CODE_DELETE_SUCCESS:
            return {
                ...state, ledgerDelete: payload.massage || null,
            }
        case UQC_CODE_DELETE_FAILURE:
            return {
                ...state, ledgerDelete: null,
            }
        case UQC_CODE_UPDATE_REQUEST:
            return {
                ...state, ledgerDelete: null,
            }
        case UQC_CODE_UPDATE_SUCCESS:
            return {
                ...state, ledgerDelete: payload.massage || null,
            }
        case UQC_CODE_UPDATE_FAILURE:
            return {
                ...state, ledgerDelete: null,
            }
        case UQC_CODE_SINGLE_DATA_REQUEST:
            return {
                ...state, uqc_code_single_data: {},
            }
        case UQC_CODE_SINGLE_DATA_SUCCESS:
            return {
                ...state, uqc_code_single_data: payload.data || {},
            }
        case UQC_CODE_SINGLE_DATA_FAILURE:
            return {
                ...state, uqc_code_single_data: {}
            }
        // For Stock Category Module ===============================
        case STOCK_CATEGORY_CREATE_REQUEST:
            return {
                ...state, ledgerCreate: null,
            }
        case STOCK_CATEGORY_CREATE_SUCCESS:
            return {
                ...state, ledgerCreate: payload.massage || null,
            }
        case STOCK_CATEGORY_CREATE_FAILURE:
            return {
                ...state, ledgerCreate: null
            }
        case STOCK_CATEGORY_NAME_VALIDATE_REQUEST:
            return {
                ...state, name_validate: null,
            }
        case STOCK_CATEGORY_NAME_VALIDATE_SUCCESS:
            return {
                ...state, name_validate: payload.data || null,
            }
        case STOCK_CATEGORY_NAME_VALIDATE_FAILURE:
            return {
                ...state, name_validate: null
            }
        case STOCK_CATEGORY_UPDATE_REQUEST:
            return {
                ...state, ledgerCreate: null,
            }
        case STOCK_CATEGORY_UPDATE_SUCCESS:
            return {
                ...state, ledgerCreate: payload.massage || null,
            }
        case STOCK_CATEGORY_UPDATE_FAILURE:
            return {
                ...state, ledgerCreate: null
            }
        case STOCK_CATEGORY_LIST_REQUEST:
            return {
                ...state, stock_category_data: [],
            }
        case STOCK_CATEGORY_LIST_SUCCESS:
            return {
                ...state, stock_category_data: payload.data || [],
            }
        case STOCK_CATEGORY_LIST_FAILURE:
            return {
                ...state, stock_category_data: []
            }
        case STOCK_CATEGORY_UNDER_LIST_REQUEST:
            return {
                ...state, stock_category_under_data: [],
            }
        case STOCK_CATEGORY_UNDER_LIST_SUCCESS:
            return {
                ...state, stock_category_under_data: payload.data || [],
            }
        case STOCK_CATEGORY_UNDER_LIST_FAILURE:
            return {
                ...state, stock_category_under_data: []
            }
        case STOCK_CATEGORY_DELETE_REQUEST:
            return {
                ...state, ledgerCreate: null,
            }
        case STOCK_CATEGORY_DELETE_SUCCESS:
            return {
                ...state, ledgerCreate: payload.massage || null,
            }
        case STOCK_CATEGORY_DELETE_FAILURE:
            return {
                ...state, ledgerCreate: null
            }
        case STOCK_CATEGORY_SINGLE_REQUEST:
            return {
                ...state, stock_category_single_data: {},
            }
        case STOCK_CATEGORY_SINGLE_SUCCESS:
            return {
                ...state, stock_category_single_data: payload.data || {},
            }
        case STOCK_CATEGORY_SINGLE_FAILURE:
            return {
                ...state, stock_category_single_data: {}
            }
        // For Stock Group Module ====================================
        case STOCK_GROUP_CREATE_REQUEST:
            return {
                ...state, ledgerCreate: null,
            }
        case STOCK_GROUP_CREATE_SUCCESS:
            return {
                ...state, ledgerCreate: payload.massage || null,
            }
        case STOCK_GROUP_CREATE_FAILURE:
            return {
                ...state, ledgerCreate: null
            }
        case STOCK_GROUP_UPDATE_REQUEST:
            return {
                ...state, ledgerCreate: null,
            }
        case STOCK_GROUP_UPDATE_SUCCESS:
            return {
                ...state, ledgerCreate: payload.massage || null,
            }
        case STOCK_GROUP_UPDATE_FAILURE:
            return {
                ...state, ledgerCreate: null
            }
        case STOCK_GROUP_LIST_REQUEST:
            return {
                ...state, stock_group_nested_data: [],
            }
        case STOCK_GROUP_LIST_SUCCESS:
            return {
                ...state, stock_group_nested_data: payload.data || [],
            }
        case STOCK_GROUP_LIST_FAILURE:
            return {
                ...state, stock_group_nested_data: []
            }
        case STOCK_GROUP_UNDER_LIST_REQUEST:
            return {
                ...state, stock_group_data: [],
            }
        case STOCK_GROUP_UNDER_LIST_SUCCESS:
            return {
                ...state, stock_group_data: payload.data || [],
            }
        case STOCK_GROUP_UNDER_LIST_FAILURE:
            return {
                ...state, stock_group_data: []
            }
        case STOCK_GROUP_DELETE_REQUEST:
            return {
                ...state, ledgerCreate: null,
            }
        case STOCK_GROUP_DELETE_SUCCESS:
            return {
                ...state, ledgerCreate: payload.massage || null,
            }
        case STOCK_GROUP_DELETE_FAILURE:
            return {
                ...state, ledgerCreate: null
            }
        case STOCK_GROUP_SINGLE_DATA_REQUEST:
            return {
                ...state, stock_group_single_data: {},
            }
        case STOCK_GROUP_SINGLE_DATA_SUCCESS:
            return {
                ...state, stock_group_single_data: payload.data || {},
            }
        case STOCK_GROUP_SINGLE_DATA_FAILURE:
            return {
                ...state, stock_group_single_data: {}
            }
        // For Stock Company Module ==============================
        case STOCK_COMPANY_CREATE_REQUEST:
            return {
                ...state, ledgerCreate: null,
            }
        case STOCK_COMPANY_CREATE_SUCCESS:
            return {
                ...state, ledgerCreate: payload.massage || null,
            }
        case STOCK_COMPANY_CREATE_FAILURE:
            return {
                ...state, ledgerCreate: null
            }
        case STOCK_COMPANY_UPDATE_REQUEST:
            return {
                ...state, ledgerCreate: null,
            }
        case STOCK_COMPANY_UPDATE_SUCCESS:
            return {
                ...state, ledgerCreate: payload.massage || null,
            }
        case STOCK_COMPANY_UPDATE_FAILURE:
            return {
                ...state, ledgerCreate: null
            }
        case STOCK_COMPANY_LIST_REQUEST:
            return {
                ...state, stock_company_data: [],
            }
        case STOCK_COMPANY_LIST_SUCCESS:
            return {
                ...state, stock_company_data: payload.data || [],
            }
        case STOCK_COMPANY_LIST_FAILURE:
            return {
                ...state, stock_company_data: []
            }
        case STOCK_COMPANY_DELETE_REQUEST:
            return {
                ...state, ledgerCreate: null,
            }
        case STOCK_COMPANY_DELETE_SUCCESS:
            return {
                ...state, ledgerCreate: payload.massage || null,
            }
        case STOCK_COMPANY_DELETE_FAILURE:
            return {
                ...state, ledgerCreate: null
            }
        case STOCK_COMPANY_SINGLE_DATA_REQUEST:
            return {
                ...state, stock_comapny_single_data: {},
            }
        case STOCK_COMPANY_SINGLE_DATA_SUCCESS:
            return {
                ...state, stock_comapny_single_data: payload.data || {},
            }
        case STOCK_COMPANY_SINGLE_DATA_FAILURE:
            return {
                ...state, stock_comapny_single_data: {}
            }
        // For Stock Item Module ==================================
        case STOCK_ITEM_CREATE_REQUEST:
            return {
                ...state, ledgerUpdate: null,
            }
        case STOCK_ITEM_CREATE_SUCCESS:
            return {
                ...state, ledgerUpdate: payload.data || null,
            }
        case STOCK_ITEM_CREATE_FAILURE:
            return {
                ...state, ledgerUpdate: null
            }
        case STOCK_ITEM_UPDATE_REQUEST:
            return {
                ...state, ledgerUpdate: null,
            }
        case STOCK_ITEM_UPDATE_SUCCESS:
            return {
                ...state, ledgerUpdate: payload.data || null,
            }
        case STOCK_ITEM_UPDATE_FAILURE:
            return {
                ...state, ledgerUpdate: null
            }
        case STOCK_ITEM_LIST_REQUEST:
            return {
                ...state, stock_item_data: {},
            }
        case STOCK_ITEM_LIST_SUCCESS:
            return {
                ...state, stock_item_data: payload.data || {},
            }
        case STOCK_ITEM_LIST_FAILURE:
            return {
                ...state, stock_item_data: {}
            }
        case STOCK_ITEM_LIST_TO_DROPDOWN_REQUEST:
            return {
                ...state, stock_item_data_TO_Dropdown: [],
            }
        case STOCK_ITEM_LIST_TO_DROPDOWN_SUCCESS:
            return {
                ...state, stock_item_data_TO_Dropdown: payload.data || [],
            }
        case STOCK_ITEM_LIST_TO_DROPDOWN_FAILURE:
            return {
                ...state, stock_item_data_TO_Dropdown: []
            }
        case STOCK_ITEM_DELETE_REQUEST:
            return {
                ...state, ledgerUpdate: null,
            }
        case STOCK_ITEM_DELETE_SUCCESS:
            return {
                ...state, ledgerUpdate: payload.massage || null,
            }
        case STOCK_ITEM_DELETE_FAILURE:
            return {
                ...state, ledgerUpdate: null
            }
        case STOCK_ITEM_SINGLE_DATA_REQUEST:
            return {
                ...state, stock_item_single_data: {},
            }
        case STOCK_ITEM_SINGLE_DATA_SUCCESS:
            return {
                ...state, stock_item_single_data: payload.data || {},
            }
        case STOCK_ITEM_SINGLE_DATA_FAILURE:
            return {
                ...state, stock_item_single_data: {}
            }
        // For Voucher Type ===================================
        case VOUCHER_TYPE_CREATE_REQUEST:
            return {
                ...state, ledgerUpdate: null,
            }
        case VOUCHER_TYPE_CREATE_SUCCESS:
            return {
                ...state, ledgerUpdate: payload.data || null,
            }
        case VOUCHER_TYPE_CREATE_FAILURE:
            return {
                ...state, ledgerUpdate: null
            }
        case VOUCHER_TYPE_UPDATE_REQUEST:
            return {
                ...state, ledgerUpdate: null,
            }
        case VOUCHER_TYPE_UPDATE_SUCCESS:
            return {
                ...state, ledgerUpdate: payload.data || null,
            }
        case VOUCHER_TYPE_UPDATE_FAILURE:
            return {
                ...state, ledgerUpdate: null
            }
        case VOUCHER_TYPE_LIST_REQUEST:
            return {
                ...state, voucher_type_data: [],
            }
        case VOUCHER_TYPE_LIST_SUCCESS:
            return {
                ...state, voucher_type_data: payload.data || [],
            }
        case VOUCHER_TYPE_LIST_FAILURE:
            return {
                ...state, voucher_type_data: []
            }
        case VOUCHER_TYPE_DEFAULT_LIST_REQUEST:
            return {
                ...state, voucher_type_default_data: [],
            }
        case VOUCHER_TYPE_DEFAULT_LIST_SUCCSESS:
            return {
                ...state, voucher_type_default_data: payload.data || [],
            }
        case VOUCHER_TYPE_DEFAULT_LIST_FAILURE:
            return {
                ...state, voucher_type_default_data: []
            }
        case VOUCHER_TYPE_DELETE_REQUEST:
            return {
                ...state, ledgerUpdate: null,
            }
        case VOUCHER_TYPE_DELETE_SUCCESS:
            return {
                ...state, ledgerUpdate: payload.data || null,
            }
        case VOUCHER_TYPE_DELETE_FAILURE:
            return {
                ...state, ledgerUpdate: null
            }
        case VOUCHER_TYPE_SINGLE_DATA_REQUEST:
            return {
                ...state, single_voucher_type_data: {},
            }
        case VOUCHER_TYPE_SINGLE_DATA_SUCCESS:
            return {
                ...state, single_voucher_type_data: payload.data || {},
            }
        case VOUCHER_TYPE_SINGLE_DATA_FAILURE:
            return {
                ...state, single_voucher_type_data: {}
            }
        case VOUCHER_TYPE_NAME_CHECK_REQUEST:
            return {
                ...state, voucher_type_name_check: {},
            }
        case VOUCHER_TYPE_NAME_CHECK_SUCCESS:
            return {
                ...state, voucher_type_name_check: payload.data || {},
            }
        case VOUCHER_TYPE_NAME_CHECK_FAILURE:
            return {
                ...state, voucher_type_name_check: {}
            }
        // For Number Series Module ===================================
        case NUMBER_SERIES_CREATE_REQUEST:
            return {
                ...state, voucher_type_name_check: {},
            }
        case NUMBER_SERIES_CREATE_SUCCESS:
            return {
                ...state, voucher_type_name_check: payload.data || {},
            }
        case NUMBER_SERIES_CREATE_FAILURE:
            return {
                ...state, voucher_type_name_check: {}
            }
        case NUMBER_SERIES_LIST_REQUEST:
            return {
                ...state, number_series_data: [],
            }
        case NUMBER_SERIES_LIST_SUCCESS:
            return {
                ...state, number_series_data: payload.data || [],
            }
        case NUMBER_SERIES_LIST_FAILURE:
            return {
                ...state, number_series_data: []
            }
        case NUMBER_SERIES_SINGLE_LIST_REQUEST:
            return {
                ...state, number_series_single_data: [],
            }
        case NUMBER_SERIES_SINGLE_LIST_SUCCESS:
            return {
                ...state, number_series_single_data: payload.data || [],
            }
        case NUMBER_SERIES_SINGLE_LIST_FAILURE:
            return {
                ...state, number_series_single_data: []
            }
        case NUMBER_SERIES_UPDATE_REQUEST:
            return {
                ...state, voucher_type_name_check: {},
            }
        case NUMBER_SERIES_UPDATE_SUCCESS:
            return {
                ...state, voucher_type_name_check: payload.data || {},
            }
        case NUMBER_SERIES_UPDATE_FAILURE:
            return {
                ...state, voucher_type_name_check: {}
            }
        // For Godown Module ===================================
        case GODOWN_CREATE_REQUEST:
            return {
                ...state, ledgerUpdate: {},
            }
        case GODOWN_CREATE_REQUEST:
            return {
                ...state, ledgerUpdate: payload.data || {},
            }
        case GODOWN_CREATE_FAILURE:
            return {
                ...state, ledgerUpdate: {}
            }
        case GODOWN_UPDATE_REQUEST:
            return {
                ...state, ledgerUpdate: {},
            }
        case GODOWN_UPDATE_SUCCESS:
            return {
                ...state, ledgerUpdate: payload.data || {},
            }
        case GODOWN_UPDATE_FAILURE:
            return {
                ...state, ledgerUpdate: {}
            }
        case GODOWN_LIST_REQUEST:
            return {
                ...state, godown_data: [],
            }
        case GODOWN_LIST_SUCCESS:
            return {
                ...state, godown_data: payload.data || [],
            }
        case GODOWN_LIST_FAILURE:
            return {
                ...state, godown_data: []
            }
        case GODOWN_DELETE_REQUEST:
            return {
                ...state, ledgerUpdate: {},
            }
        case GODOWN_DELETE_SUCCESS:
            return {
                ...state, ledgerUpdate: payload.data || {},
            }
        case GODOWN_DELETE_FAILURE:
            return {
                ...state, ledgerUpdate: {}
            }
        case GODOWN_SINGLE_DATA_REQUEST:
            return {
                ...state, godown_single_data: {},
            }
        case GODOWN_SINGLE_DATA_SUCCESS:
            return {
                ...state, godown_single_data: payload.data || {},
            }
        case GODOWN_SINGLE_DATA_FAILURE:
            return {
                ...state, godown_single_data: {}
            }
        case GODOWN_PRIMARY_LIST_REQUEST:
            return {
                ...state, godown_primary_data: {},
            }
        case GODOWN_PRIMARY_LIST_SUCCESS:
            return {
                ...state, godown_primary_data: payload.data || {},
            }
        case GODOWN_PRIMARY_LIST_FAILURE:
            return {
                ...state, godown_primary_data: {}
            }
        // Contra Voucher Entry Module ================================
        case CONTRA_LEDGER_LIST_REQUEST:
            return {
                ...state, contra_ledger_data: [],
            }
        case CONTRA_LEDGER_LIST_SUCCESS:
            return {
                ...state, contra_ledger_data: payload.data || [],
            }
        case CONTRA_LEDGER_LIST_FAILURE:
            return {
                ...state, contra_ledger_data: []
            }
        case SERIAL_NUMBER_REQUEST:
            return {
                ...state, serial_no: payload,
            }
        case SERIAL_NUMBER_SUCCESS:
            return {
                ...state, serial_no: payload || '',
            }
        case SERIAL_NUMBER_FAILURE:
            return {
                ...state, serial_no: payload
            }
        case CONTRA_VOUCHER_ENTRY_CREATE_REQUEST:
            return {
                ...state, contra_create_response: [],
            }
        case CONTRA_VOUCHER_ENTRY_CREATE_SUCCESS:
            return {
                ...state, contra_create_response: payload.data || [],
            }
        case CONTRA_VOUCHER_ENTRY_CREATE_FAILURE:
            return {
                ...state, contra_create_response: []
            }
        case VOUCHER_ENTRY_DELETE_REQUEST:
            return {
                ...state, contra_create_response: [],
            }
        case VOUCHER_ENTRY_DELETE_SUCCESS:
            return {
                ...state, contra_create_response: payload.data || [],
            }
        case VOUCHER_ENTRY_DELETE_FAILURE:
            return {
                ...state, contra_create_response: []
            }
        case CONTRA_VOUCHER_ENTRY_UPDATE_REQUEST:
            return {
                ...state, contra_create_response: [],
            }
        case CONTRA_VOUCHER_ENTRY_UPDATE_SUCCESS:
            return {
                ...state, contra_create_response: payload.data || [],
            }
        case CONTRA_VOUCHER_ENTRY_UPDATE_FAILURE:
            return {
                ...state, contra_create_response: []
            }
        case CONTRA_VOUCHER_ENTRY_LIST_REQUEST:
            return {
                ...state, contra_voucher_data: [],
            }
        case CONTRA_VOUCHER_ENTRY_LIST_SUCCESS:
            return {
                ...state, contra_voucher_data: payload.data || [],
            }
        case CONTRA_VOUCHER_ENTRY_LIST_FAILURE:
            return {
                ...state, contra_voucher_data: []
            }
        case CONTRA_VOUCHER_ENTRY_SINGLE_REQUEST:
            return {
                ...state, contra_voucher_single_data: [],
            }
        case CONTRA_VOUCHER_ENTRY_SINGLE_SUCCESS:
            return {
                ...state, contra_voucher_single_data: payload.data || [],
            }
        case CONTRA_VOUCHER_ENTRY_SINGLE_FAILURE:
            return {
                ...state, contra_voucher_single_data: []
            }
        case VOUCHER_TYPE_SAVE:
            return {
                ...state, store_voucher_entry: payload
            }
        // For Ledger Book Module ===========================
        case LEDGER_BOOK_REQUEST:
            return {
                ...state, ledger_book: [],
            }
        case LEDGER_BOOK_SUCCESS:
            return {
                ...state, ledger_book: payload.data || [],
            }
        case LEDGER_BOOK_FAILURE:
            return {
                ...state, ledger_book: []
            }
        // For Ledger Posting Module ==================================
        case LEDGER_POSTING_LIST_REQUEST:
            return {
                ...state, ledger_posting_data: [],
            }
        case LEDGER_POSTING_LIST_SUCCESS:
            return {
                ...state, ledger_posting_data: payload.data || [],
            }
        case LEDGER_POSTING_LIST_FAILURE:
            return {
                ...state, ledger_posting_data: []
            }
        // For Current Date and Current Period Module ======================
        case CURRENT_DATE_SAVE:
            return {
                ...state, company_session: { ...state.company_session, current_date: payload }
            }
        case CURRENT_PERIOD_SAVE:
            return {
                ...state, company_session: { ...state.company_session, financial_year: payload.financl_year, start_financial_year: payload.start_fincl_year, end_financial_year: payload.end_fincl_year, current_date: payload.cur_date }
            }
        // Stock Summary LIst -------------
        case STOCK_SUMMARY_LIST_REQUEST:
            return {
                ...state, stock_summary_data: []
            }
        case STOCK_SUMMARY_LIST_SUCCESS:
            return {
                ...state, stock_summary_data: payload.data || []
            }
        case STOCK_SUMMARY_LIST_FAILURE:
            return {
                ...state, stock_summary_data: []
            }
        // Stock Summary LIst -------------
        case STOCK_SUMMARY_ITEM_VOUCHER_REQUEST:
            return {
                ...state, stock_summary_item_voucher: []
            }
        case STOCK_SUMMARY_ITEM_VOUCHER_SUCCESS:
            return {
                ...state, stock_summary_item_voucher: payload.data || []
            }
        case STOCK_SUMMARY_ITEM_VOUCHER_FAILURE:
            return {
                ...state, stock_summary_item_voucher: []
            }
        // Stock Summary LIst -------------
        case STOCK_SUMMARY_GODOWN_FILTER_LIST_REQUEST:
            return {
                ...state, stock_summary_data: []
            }
        case STOCK_SUMMARY_GODOWN_FILTER_LIST_SUCCESS:
            return {
                ...state, stock_summary_data: payload.data || []
            }
        case STOCK_SUMMARY_GODOWN_FILTER_LIST_FAILURE:
            return {
                ...state, stock_summary_data: []
            }


        // Max Date of Entry  -------------
        case MAX_DATE_OF_ENTRY_REQUEST:
            return {
                ...state, max_date_of_entry_date: ''
            }
        case MAX_DATE_OF_ENTRY_SUCCESS:
            return {
                ...state, max_date_of_entry_date: payload.data || ''
            }
        case MAX_DATE_OF_ENTRY_FAILURE:
            return {
                ...state, max_date_of_entry_date: ''
            }


        case FILTER_VOUCHER_TYPE_DATA:
            return {
                ...state, filter_voucher: payload || []
            }


        // Max Date of Entry  -------------
        case PROFIT_AND_LOSS_REQUEST:
            return {
                ...state, profit_loss: []
            }
        case PROFIT_AND_LOSS_SUCCESS:
            return {
                ...state, profit_loss: payload.data || []
            }
        case PROFIT_AND_LOSS_FAILURE:
            return {
                ...state, profit_loss: []
            }


        // Max Date of Entry  -------------
        case GROUP_SUMMARY_REQUEST:
            return {
                ...state, group_summary: []
            }
        case GROUP_SUMMARY_SUCCESS:
            return {
                ...state, group_summary: payload.data || []
            }
        case GROUP_SUMMARY_FAILURE:
            return {
                ...state, group_summary: []
            }
        case RESET_DATA:
            return { ...initialState };
        default:
            return state
    }
}