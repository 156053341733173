import React from 'react'
import FooterLayout from './FooterLayout'

function VoucherFooter(props) {
    const fields = [
        { id: "1", name: "Quit", shortcutKey: "Q", line: 'double', isDisabled: false, state: '' },
        { id: "2", name: "", shortcutKey: "", line: '', isDisabled: true, state: '' },
        { id: "3", name: "", shortcutKey: "", line: '', isDisabled: true, },
        { id: "4", name: "Accept", shortcutKey: "A", line: 'double', isDisabled: false, state: props?.setHash },
        { id: "5", name: "", shortcutKey: "", line: '', isDisabled: true, state: '' },
        { id: "6", name: "", shortcutKey: "", line: '', isDisabled: true, state: '' },
        { id: "7", name: "Delete", shortcutKey: "D", line: 'single', isDisabled: true, },
        { id: "8", name: "Cancel", shortcutKey: "X", line: '', isDisabled: false, },
        { id: "9", name: "Create Ledger", shortcutKey: "C", line: 'single', isDisabled: !props?.isDisable, state:props?.navigate},
        { id: "10", name: "", shortcutKey: "", line: '', isDisabled: true, state: '' },
    ]
    return (
        <div className='tomasterfooter'>
            <div className='footer'>
                {
                    fields.map((item, index) => {
                        return (
                            <FooterLayout key={item.id} fields={item} />
                        )
                    })
                }

            </div>
        </div>
    )
}

export default VoucherFooter
