import React, { forwardRef } from 'react'
import { Link, useNavigate } from 'react-router-dom';



const DropdownLayout = forwardRef((props, ref) => {
    const navigate = useNavigate();
    const { state, filteredData, input_value, input_key, input_key_id, input_key_bal, setSelectedItem, selectedItem, setData, data, to_navigate, setState } = props;
    return (
        <div id="myDropdown-list" className={`dropdown-content-list ${state ? 'show-list' : ''}`}>
            <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                List Of ledger account
            </div>
            {/* <div className='col' style={{ textAlign: 'right' }}>
                <Link className='list text-end pe-1 ' to='/create-master-ledger' onClick={() => navigate(to_navigate)}>Create</Link>
                <div className='border'></div>
            </div> */}
            <div id='data'>
                {
                    Array.isArray(filteredData) && filteredData?.map((key, number) => {
                        const name = key?.ledger_name || key?.name;
                        const balance = key?.opening_balance;
                        const id = key?.id;
                        const index = name?.toLowerCase()?.indexOf(input_value?.toLowerCase());
                        return (
                            <Link
                                className={`${name === 'Create' ? `list text-end pe-3` : `list font-weight-bold`} ${number === selectedItem ? 'selected' : ''}`}
                                id='list'
                                key={number}
                                onClick={(e) => {
                                    e.preventDefault();
                                    setSelectedItem(number)
                                    if (number === selectedItem) {
                                        setData({ ...data, [input_key]: name, [input_key_id]: id, [input_key_bal]: balance })
                                        setState(false)
                                        setTimeout(() => {
                                            ref.focus();
                                        }, 100);
                                    }
                                }}
                            >
                                {index !== -1 ? (
                                    < >
                                        {name?.substring(0, index)}
                                        <span style={{ color: 'red' }}>{name?.substring(index, index + input_value?.length)}</span>
                                        {name?.substring(index + input_value?.length)}
                                    </>
                                ) : (
                                    name
                                )}
                            </Link>
                        );
                    })
                }

            </div>
        </div>
    )
})

export default DropdownLayout
