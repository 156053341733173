import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { stockCategoryCreate, stockCategoryPrimaryList, stockCategoryNameValidate } from '../../../../../redux/actions/action-creator';
import { useSelector, useDispatch } from 'react-redux';
import FormData from 'form-data';
import { toast } from 'react-toastify';
import '../accounting_masters/ledger.css';
import YesNoModal from '../../../../../components/model/YesNoModal';
import MasterCreationFooter from '../../../../../components/Footer/Master_Footer/MasterCreationFooter';

function StockCatagory() {
  const initialState = {
    stock_category_name: '',
    alise_name: '',
    under: 'Primary',
    under_id: 'Primary',
  }
  const { stock_category_under_data = null } = useSelector(state => state.common);
  const { userData = null } = useSelector(state => state.auth);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(0);
  const [stock_submit, setstock_submit] = useState('')
  const [backspaceCount, setBackspaceCount] = useState(0)
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const isMouseDownInside = useRef(false);
  const stock_category_name_input_ref = useRef(null);
  const alias_name_input_ref = useRef(null);
  const inputRef = useRef(null);
  const [showList, setShowList] = useState(false);
  const [isStockCategoryNameEntered, setIsStockCategoryNameEntered] = useState(false);
  const [hashtag, setHashtag] = useState(false)
  const [state, setState] = useState(initialState);
  const [nameInput, setNameInput] = useState(null)
  const [loading, setLoading] = useState(true)
  function getCurrentInputRef(name) {
    switch (name) {
      case 'stock_name': return stock_category_name_input_ref;
      case 'alise_name': return alias_name_input_ref;
      case 'under': return inputRef;
      default: return null;
    }
  }

  const handleFocus = (e) => {
    const { name, value } = e.target;
    const ref = getCurrentInputRef(name);
    if (ref) {
      setNameInput(ref);
    }
    if (name === 'under') {
      setShowList(true)
      const index = stock_category_under_data.findIndex(item => item.name?.toLowerCase() == state?.under?.toLowerCase());
      setSelectedItem(index)
    }
  }
  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (name === 'under') {
      if (value === '') {
        inputRef.current.focus()
      } else {
        setShowList(false)
      }
    }
    if (name === 'stock_category_name') {
      const formData = new FormData();
      formData.append('company_id', userData?.id);
      formData.append('name', value);
      dispatch(stockCategoryNameValidate(formData)).then((response) => {
        console.log('this is responese :---', response)
        if (response.status) {
          toast.warn(response?.message, {
            position: toast.POSITION.TOP_CENTER,
          });
          stock_category_name_input_ref.current.focus();
        }
      })
      if (value === '') {
        stock_category_name_input_ref.current.focus()
      }
    }

  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    // Function to capitalize the first letter of each word
    const capitalizeFirstLetter = (str) => {
      return str.toLowerCase().replace(/(^|\s)\S/g,
        (firstLetter) => firstLetter.toUpperCase());
    };
    // To check the value is not empty of group name if empty then other field disabled
    if (name === 'stock_category_name') {
      setIsStockCategoryNameEntered(!!value.trim());
    }
    // space key functionality
    if (e.code == 'Space') {
      e.preventDefault();
      const cursorPosition_start = e.target.selectionStart;
      if (cursorPosition_start == 1) {
        setState({ ...state, [name]: '' });
        return;
      }
    }
    // Enter key functionality
    if (e.key === 'Enter' && name !== 'under' && state?.stock_category_name !== '') {
      e.preventDefault();
      const nextInputRef = name === 'stock_category_name' ? alias_name_input_ref :
        name === 'alise_name' ? inputRef : null
      if (nextInputRef) {
        nextInputRef.current.focus();
        if (nextInputRef.current) {
          nextInputRef.current.setSelectionRange(0, 0);
        }
      }

      return;
    }
    // Back space key functionality
    if (e.key === 'Backspace') {
      e.preventDefault();
      const nextInputRef = name === 'under' ? alias_name_input_ref :
        name === 'alise_name' ? stock_category_name_input_ref : null;
      const cursorPosition = e.target.selectionStart;
      if (cursorPosition === 0) {
        if (backspaceCount === 1) {
          setBackspaceCount(0);
          if (nextInputRef && nextInputRef.current) {
            nextInputRef.current.focus();
            nextInputRef.current.setSelectionRange(0, 0);
          }
        } else {
          if (cursorPosition === 0 && value) {
            if (nextInputRef && nextInputRef.current) {
              nextInputRef.current.focus();
              nextInputRef.current.setSelectionRange(0, 0);
            }
          } else {
            setBackspaceCount(backspaceCount + 1);
          }
        }
      }
      return;
    }
    if (name === 'under') {
      if (state.stock_category_name !== '' && state.under !== '' && e.key === 'Enter') {
        e.preventDefault();
        inputRef.current.blur();
        setstock_submit('Accept');
      }
    }
    // Capitalize the first letter of each word for certain input fields
    const cleanString = (str) => str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    switch (name) {
      case 'under':
        const group = stock_category_under_data.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (group) {
          setState({ ...state, [name]: value })
        }
        break;

      default:
        const updatedValue = name === 'stock_category_name' || name === 'alise_name' ?
          capitalizeFirstLetter(value) : value;
        setState({ ...state, [name]: updatedValue })
        break;
    }

  }


  useEffect(() => {
    const formData = new FormData();
    formData.append('company_id', userData?.id)
    dispatch(stockCategoryPrimaryList(formData)).then((response) => {
      if (response.status) {
        setLoading(false)
      } else {
        toast.error(response?.message || 'catch error ...!', {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    })
  }, [])
  //triger when the change value of search input and get filter data ---------------------------------------------------
  useEffect(() => {
    if (stock_category_under_data[0]?.name === 'Primary') {
      // Do Not Add Any Data 
    } else {
      stock_category_under_data?.unshift({ id: 'Primary', name: 'Primary' });
    }
    const filteredSuggestions = stock_category_under_data?.filter((item, index) => {
      const lowercaseLedgerName = item?.name?.toLowerCase();
      const lowercaseSearchInput = state?.under?.toLowerCase();
      const matchesSearchInput = lowercaseLedgerName?.includes(state?.under !== '' ? lowercaseSearchInput : '');
      return matchesSearchInput;
    })
    setFilteredData(state?.under !== '' ? inputRef?.current?.selectionStart > 0 ? filteredSuggestions : stock_category_under_data : stock_category_under_data);
  }, [state?.under, stock_category_under_data, inputRef?.current?.selectionStart])
  const handleKeyDown = useCallback((e) => {
    if (showList) {
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        setSelectedItem(prevState => Math.max(prevState - 1, 0));
      } else if (e.key === 'ArrowDown') {
        e.preventDefault();
        setSelectedItem(prevState => Math.min(prevState + 1, filteredData.length - 1));
      } else if (e.key === 'Enter') {
        e.preventDefault();
        const selectedLink = filteredData[selectedItem]
        if (selectedLink) {
          setState({
            ...state, under: selectedLink.name, under_id: selectedLink.id
          })
          setShowList(false)
        }
      }
    }
  }, [selectedItem, filteredData, showList])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const handleClickOutside = (event) => {
    let clickedElement = event.target;
    let isInputField = false;
    while (clickedElement) {
      if ((clickedElement.tagName &&
        (clickedElement.tagName.toLowerCase() === 'input' ||
          clickedElement.tagName.toLowerCase() === 'textarea' || clickedElement.tagName.toLowerCase() === 'button'))) {
        isInputField = true;
        break;
      }
      clickedElement = clickedElement.parentNode;
    }

    if (!isInputField) {
      if (nameInput && nameInput.current) {
        event.preventDefault();
        console.log("this is name input :---", nameInput)
        nameInput.current.focus();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [nameInput])


  useEffect(() => {
    const handleClickOutside = (event) => {
      let clickedElement = event.target;
      let isInputField = false;
      while (clickedElement) {
        if ((clickedElement.tagName &&
          (clickedElement.tagName.toLowerCase() === 'input' ||
            clickedElement.tagName.toLowerCase() === 'textarea' || clickedElement.tagName.toLowerCase() === 'button'))) {
          isInputField = true;
          break;
        }
        clickedElement = clickedElement.parentNode;
      }
      if (!isInputField) {
        if (nameInput && nameInput.current) {
          event.preventDefault();
          nameInput.current.focus();
        }
      }
    };

    const handleMouseDown = () => {
      isMouseDownInside.current = true;
    };

    const handleMouseUp = () => {
      isMouseDownInside.current = false;
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [nameInput]);


  useEffect(() => {
    const handleKeyDown = (e) => {
      if (stock_submit === 'Accept') {
        if (e.key === 'y' || e.key === 'Y') {
          e.preventDefault();
          controlPlusASubmit();
        }
      }
      if (stock_submit === 'Accept') {
        if (e.key === 'n' || e.key === 'N') {
          e.preventDefault();
          setstock_submit('')
          stock_category_name_input_ref.current.focus();
        }
      }
    }
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [state?.stock_category_name, stock_submit])

  const handleSubmit = (e) => {
    e.preventDefault();
    if (hashtag) {
      if (validateForm()) {
        try {
          const formData = new FormData();
          formData.append('company_id', userData?.id);
          formData.append('name', state.stock_category_name);
          formData.append('alias_name', state.alise_name);
          formData.append('under', state.under_id);
          const response = dispatch(stockCategoryCreate(formData));
          if (response.status) {
            toast.success(response?.message || 'created successfully!', {
              position: toast.POSITION.TOP_CENTER
            });
            const formData = new FormData();
            formData.append('company_id', userData?.id)
            dispatch(stockCategoryPrimaryList(formData))
            resetForm();
          } else {
            toast.warn(response?.message || 'Fail!', {
              position: toast.POSITION.TOP_CENTER,
            });
            setstock_submit('')
            stock_category_name_input_ref.current.focus();
          }
        } catch (error) {
          console.log(error);
          toast.error(error?.message || 'catch error ...!', {
            position: toast.POSITION.TOP_CENTER,
          });
          setstock_submit('')
          stock_category_name_input_ref.current.focus();
        }
      } else {
        toast.error('Please fill all the fields.', {
          position: toast.POSITION.TOP_CENTER
        });
        setstock_submit('')
        stock_category_name_input_ref.current.focus();
      }
    }
  }
  const { stock_category_name, alise_name, under } = state;


  const stockCategoryNameValidateApiFun = async () => {
    try {
      const formData = new FormData();
      formData.append('company_id', userData?.id);
      formData.append('name', stock_category_name);
      formData.append('alias_name', '');
      formData.append('under', '');
      const response = dispatch(stockCategoryCreate(formData));
      console.log('this is responese :---', response)
      if (response.status) {
        toast.warn(response?.message, {
          position: toast.POSITION.TOP_CENTER,
        });
        stock_category_name_input_ref.current.focus();
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.message || 'Failed!', {
        position: toast.POSITION.TOP_CENTER,
      });
      stock_category_name_input_ref.current.focus();
    }
  }

  // submit form by ctr + a =============
  const controlPlusASubmit = async () => {
    if (validateForm()) {
      try {
        const formData = new FormData();
        formData.append('company_id', userData?.id);
        formData.append('name', state.stock_category_name);
        formData.append('alias_name', state.alise_name);
        formData.append('under', state.under_id);
        const response = await dispatch(stockCategoryCreate(formData));
        if (response.status) {
          toast.success(response?.message || 'Group created!', {
            position: toast.POSITION.TOP_CENTER
          });
          const formData = new FormData();
          formData.append('company_id', userData?.id)
          dispatch(stockCategoryPrimaryList(formData))
          resetForm();
        } else {
          toast.warn(response?.message || 'Fail!', {
            position: toast.POSITION.TOP_CENTER,
          });
          setstock_submit('')
          stock_category_name_input_ref.current.focus();
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.message || 'Failed!', {
          position: toast.POSITION.TOP_CENTER,
        });
        setstock_submit('')
        stock_category_name_input_ref.current.focus();
      }
    } else {
      toast.error('Please fill all the fields.', {
        position: toast.POSITION.TOP_CENTER
      });
      setstock_submit('')
      stock_category_name_input_ref.current.focus();
    }
  };
  // Function to validate form fields
  const validateForm = () => {
    return (
      userData?.id !== '' &&
      state?.stock_category_name !== '' &&
      state?.under !== ''
    );
  };
  // Function to reset form fields
  const resetForm = () => {
    setState(prevState => ({
      ...prevState,
      stock_category_name: '',
      alise_name: '',
      under_id: prevState?.under_id,
      under: prevState?.under,
    }));
    setstock_submit('');
    setTimeout(() => {
      stock_category_name_input_ref.current.focus();
    }, 50);
    setIsStockCategoryNameEntered(false);
  };

  if (loading) {
    return <div>Loading ..</div>
  }

  return (
    <div className='section'>
      <div className='detail border'>
        {
          stock_submit === 'Accept' ?
            <YesNoModal>
              <div className='container'>
                <div className='row'>
                  <div className='col-12'>
                    <p className='modal_message'>
                      {stock_submit}
                    </p>
                  </div>
                  <div className='col-3 offset-1'>
                    <Link className="modal_link" onClick={controlPlusASubmit} >Yes</Link>
                  </div>
                  <div className='col-2'>or</div>
                  <div className='col-3'>
                    <Link className='modal_link'>No</Link>
                  </div>
                </div>
              </div>
            </YesNoModal> : null
        }
        <div className='container-fluid p-0' style={{ background: 'rgba(0,0,0,0.4)', height: '91.7vh' }}>
          <div className='row'>
            <form
              autoComplete='off'
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                if (e.ctrlKey && (e.key === 'a' || e.key === 'A')) {
                  e.preventDefault();
                  controlPlusASubmit(e);
                }
              }}
            >
              <div className='col-6 p-3' style={stock_submit == 'Accept' ? { background: '#fff', pointerEvents: 'none' } : { background: '#fff' }}>
                <div className='row mt-3 mb-4'>
                  <div className='d-flex justify-content-between align-item-center'>
                    <label className='lab-1'> Name</label>
                    <label> :</label>
                    <input
                      type="text"
                      name='stock_category_name'
                      id='stock_category_name'
                      className='lab-right'
                      onChange={handleChange}
                      onKeyUp={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      value={stock_category_name || ""}
                      ref={stock_category_name_input_ref}
                      autoFocus />
                  </div>
                  <div className='d-flex justify-content-between align-item-center' id='alis'>
                    <label className='lab-1'> (alise) </label>
                    <label> :</label>
                    <input
                      type="text"
                      name='alise_name'
                      id='alise_name'
                      className={`lab-right ${isStockCategoryNameEntered || !stock_submit ? '' : 'disable-field'}`}
                      onKeyUp={handleChange}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      ref={alias_name_input_ref}
                      value={alise_name || ""}
                      disabled={state.stock_category_name === ''} />
                  </div>
                  <div className='d-flex justify-content-between align-item-center'>
                    <label className='lab-1'> Under </label>
                    <label> :</label>
                    <input
                      type='text'
                      id='under'
                      name="under"
                      ref={inputRef}
                      value={under || ""}
                      onKeyUp={handleChange}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      className={`lab-right ${isStockCategoryNameEntered || !stock_submit ? '' : 'disable-field'}`}
                      disabled={state.stock_category_name === ''} />
                    <div id="myDropdown-list" className={`dropdown-content-list ${showList ? 'show-list' : ''}`} ref={dropdownRef}>
                      <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                        List Of Group
                      </div>
                      <div className='col ' style={{ textAlign: 'right' }}>
                        <Link style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                        <div className='border'></div>
                      </div>
                      <div id='data'>
                        {filteredData.length > 0 ? (
                          filteredData.map((key, number) => {
                            const name = key?.name;
                            const index = name.toLowerCase().indexOf(under.toLowerCase());
                            return (
                              <Link
                                className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                id='list'
                                key={number}
                                // onClick={(e) => {displayList(data[key]?.name, data[key]?.id); }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setSelectedItem(number)
                                  if (number === selectedItem) {
                                    setState({ ...state, under: name })
                                    setShowList(false)
                                  }
                                }}
                              >
                                {index !== -1 ? (
                                  < >
                                    {name.substring(0, index)}
                                    <span style={{ color: 'red' }}>{name.substring(index, index + under.length)}</span>
                                    {name.substring(index + under.length)}
                                  </>
                                ) : (
                                  name
                                )}
                              </Link>
                            );
                          })
                        ) : (
                          <p style={{ color: 'red' }}>No matching items found</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <MasterCreationFooter
                setHash={setHashtag}
              />
            </form>
          </div>
        </div >
      </div >
      <div className='help'>
      </div>
    </div >
  )
}

export default StockCatagory
