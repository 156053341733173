import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { voucherTypeCreate, voucherTypeNameCheck, voucherTypeDefaultList }
  from '../../../../../redux/actions/action-creator';
import FormData from 'form-data';
import { toast } from 'react-toastify';
import DefineNumberSeries from './DefineNumberSeries';
import YesNoModal from '../../../../../components/model/YesNoModal';
import MasterCreationFooter from '../../../../../components/Footer/Master_Footer/MasterCreationFooter';
const initialState = {
  voucher_type_name: '',
  alise_name: '',
  select_type_of_voucher: 'Contra',
  select_type_of_voucher_id: '1',
  abbreviation: 'Ctr',
  active_this_voucher_type: 'Yes',
  define_multiple_numbering_series_for_vouchers: 'No',
  show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour: 'Yes',
  use_effective_dates_for_vouchers: 'No',
  allow_zero_valued_transactions: 'No',
  make_this_voucher_type_as_optional_by_default: 'No',
  allow_narration_in_voucher: 'Yes',
  provide_narration_for_each_ledger_in_voucher: 'No',
  print_voucher_after_saving: 'No',
  enable_default_accounting_allocations: 'No',
  set_alter_default_accounting_entries: 'No',
  track_additional_costs_for_purchases: 'No',
  default_jurisdiction: '',
  default_title_to_print: '',
  print_formal_receipt_after_saving: 'No',
  use_for_pos_invoicing: '',
  message_to_print_1: '',
  message_to_print_2: '',
  default_bank: 'Not Applicable',
  set_alter_declaration: 'No',
  use_a_manufacturing_journal: 'No',
  name_of_class: '',
  number_series: ''
}
const yes_no_array =
  [
    { name: 'Yes' },
    { name: 'No' },
  ]
function VouchersType() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState([]);
  const [isVoucherTypeNameEntered, setIsVoucherTypeNameEntered] = useState(false);
  const [state, setState] = useState(initialState);
  const [defineNumberModal, setDefineNumberModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(0);
  const [showList, setShowList] = useState(false);
  const [hashtag, setHashtag] = useState(false)
  const [backspaceCount, setBackspaceCount] = useState(0)
  const [voucher_confirm, setvoucher_comfirm] = useState('')
  const [nameInput, setNameInput] = useState(null)
  const voucher_type_default_data = useSelector(state => state.common.voucher_type_default_data);
  const { userData = null } = useSelector(state => state.auth);
  const dropdownRef = useRef(null);

  const voucher_type_name_input_ref = useRef(null);
  const alise_name_input_ref = useRef(null);
  const select_type_of_voucher_input_ref = useRef(null);
  const abbreviation_input_ref = useRef(null);
  const active_this_voucher_type_input_ref = useRef(null);
  const define_multiple_numbering_series_for_vouchers_input_ref = useRef(null);
  const show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour_input_ref = useRef(null);
  const use_effective_dates_for_vouchers_input_ref = useRef(null);
  const allow_zero_valued_transactions_input_ref = useRef(null);
  const make_this_voucher_type_as_optional_by_default_input_ref = useRef(null);
  const allow_narration_in_voucher_input_ref = useRef(null);
  const provide_narration_for_each_ledger_in_voucher_input_ref = useRef(null);
  const print_voucher_after_saving_input_ref = useRef(null);
  const enable_default_accounting_allocations_input_ref = useRef(null);
  const set_alter_default_accounting_entries_input_ref = useRef(null);
  const track_additional_costs_for_purchases_input_ref = useRef(null);
  const default_jurisdiction_input_ref = useRef(null);
  const default_title_to_print_input_ref = useRef(null);
  const print_formal_receipt_after_saving_input_ref = useRef(null);
  const use_for_pos_invoicing_input_ref = useRef(null);
  const message_to_print_1_input_ref = useRef(null);
  const message_to_print_2_input_ref = useRef(null);
  const default_bank_input_ref = useRef(null);
  const set_alter_declaration_input_ref = useRef(null);
  const use_a_manufacturing_journal_input_ref = useRef(null);
  const name_of_class_input_ref = useRef(null);

  const isMouseDownInside = useRef(false);
  const [filteredDataYesNo, setFilteredDataYesNo] = useState([])
  const [activeThis, setActiveThis] = useState(false);
  const [defineMultiple, setDefineMultiple] = useState(false);
  const [showUsedVch, setShowUsedVch] = useState(false);
  const [useEffective, setUseEffective] = useState(false);
  const [allowZero, setAllowZero] = useState(false);
  const [makeThis, setMakeThis] = useState(false);
  const [allowNarration, setAllowNarration] = useState(false);
  const [provideNarration, setProvideNarration] = useState(false);
  const [printVch, setPrintVch] = useState(false);
  const [enableDefault, setEnableDefault] = useState(false);
  const [setAlterEntries, setSetAlterEntries] = useState(false);
  const [trackAdd, setTrackAdd] = useState(false);
  const [printFormal, setPrintFormal] = useState(false);
  const [setAltDec, setSetAltDec] = useState(false);
  const [useManu, setUseManu] = useState(false);



  const getNextInputRef = (name, value) => {
    switch (name) {
      case 'voucher_type_name':
        return alise_name_input_ref;
      case 'alise_name':
        return select_type_of_voucher_input_ref;
      case 'select_type_of_voucher':
        return abbreviation_input_ref;
      case 'abbreviation':
        return active_this_voucher_type_input_ref;
      case 'active_this_voucher_type':
        return define_multiple_numbering_series_for_vouchers_input_ref;
      case 'define_multiple_numbering_series_for_vouchers':
        if (value === 'Yes') {
          define_multiple_numbering_series_for_vouchers_input_ref?.current?.blur();
          setDefineNumberModal(true);
        } else {
          return show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour_input_ref;
        }
        break;
      case 'show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour':
        return use_effective_dates_for_vouchers_input_ref;
      case 'use_effective_dates_for_vouchers':
        return allow_zero_valued_transactions_input_ref;
      case 'allow_zero_valued_transactions':
        return (
          ['Contra', 'Credit Note', 'Debit Note', 'Payment', 'Purchase', 'Receipt', 'Sales', 'Delivery Note', 'Receipt Note', 'Purchase Order', 'Sales Order', 'Journal', 'Stock Journal'].includes(state?.select_type_of_voucher)
            ? make_this_voucher_type_as_optional_by_default_input_ref
            : allow_narration_in_voucher_input_ref
        );
      case 'make_this_voucher_type_as_optional_by_default':
        return allow_narration_in_voucher_input_ref;
      case 'allow_narration_in_voucher':
        if (
          ['Contra', 'Credit Note', 'Debit Note', 'Payment', 'Purchase', 'Receipt', 'Sale', 'Journal'].includes(state?.select_type_of_voucher)
        ) {
          return provide_narration_for_each_ledger_in_voucher_input_ref;
        } else if (state?.select_type_of_voucher === 'Stock Journal') {
          return use_a_manufacturing_journal_input_ref;
        } else if (
          ['Physical Stock'].includes(state?.select_type_of_voucher)
        ) {
          return track_additional_costs_for_purchases_input_ref;
        } else {
          return enable_default_accounting_allocations_input_ref;
        }
      case 'provide_narration_for_each_ledger_in_voucher':
        if (state?.select_type_of_voucher === 'Stock Journal') {
          return use_a_manufacturing_journal_input_ref;
        } else if (
          ['Debit Note', 'Payment', 'Purchase', 'Physical Stock', 'Stock Journal', 'Journal'].includes(state?.select_type_of_voucher)
        ) {
          return track_additional_costs_for_purchases_input_ref;
        } else {
          return enable_default_accounting_allocations_input_ref;
        }
      case 'use_a_manufacturing_journal':
        return (
          ['Debit Note', 'Payment', 'Purchase', 'Physical Stock', 'Stock Journal', 'Journal'].includes(state?.select_type_of_voucher)
            ? track_additional_costs_for_purchases_input_ref
            : enable_default_accounting_allocations_input_ref
        );
      case 'track_additional_costs_for_purchases':
        return (
          ['Contra', 'Credit Note', 'Debit Note', 'Payment', 'Purchase', 'Receipt', 'Sales', 'Delivery Note', 'Receipt Note', 'Purchase Order', 'Sales Order'].includes(state?.select_type_of_voucher)
            ? enable_default_accounting_allocations_input_ref
            : print_voucher_after_saving_input_ref
        );
      case 'enable_default_accounting_allocations':
        return value === 'Yes' ? set_alter_default_accounting_entries_input_ref : print_voucher_after_saving_input_ref;
      case 'set_alter_default_accounting_entries':
        return print_voucher_after_saving_input_ref;
      case 'print_voucher_after_saving':
        switch (state?.select_type_of_voucher) {
          case 'Sales':
            return use_for_pos_invoicing_input_ref;
          case 'Purchase Order':
          case 'Credit Note':
          case 'Delivery Note':
            return default_jurisdiction_input_ref;
          case 'Debit Note':
            return default_title_to_print_input_ref;
          case 'Receipt':
            return print_formal_receipt_after_saving_input_ref;
          case 'Contra':
          case 'Credit Note':
          case 'Debit Note':
          case 'Delivery Note':
          case 'Payment':
          case 'Purchase':
          case 'Purchase Order':
          case 'Receipt':
          case 'Reciept Note':
          case 'Sales':
            return name_of_class_input_ref;
          default:
            return set_alter_declaration_input_ref;
        }
      case 'use_for_pos_invoicing':
        return value === 'Yes' ? message_to_print_1_input_ref : default_bank_input_ref;
      case 'message_to_print_1':
        return message_to_print_2_input_ref;
      case 'message_to_print_2':
        return default_bank_input_ref;
      case 'default_bank':
        return default_jurisdiction_input_ref;
      case 'default_jurisdiction':
        return (
          ['Purchase Order', 'Sales Order'].includes(state?.select_type_of_voucher)
            ? set_alter_declaration_input_ref
            : default_title_to_print_input_ref
        );
      case 'default_title_to_print':
        return (
          ['Contra', 'Credit Note', 'Debit Note', 'Delivery Note', 'Payment', 'Purchase', 'Purchase Order', 'Receipt', 'Receipt Note', 'Sales Order'].includes(state?.select_type_of_voucher)
            ? name_of_class_input_ref
            : set_alter_declaration_input_ref
        );
      case 'print_formal_receipt_after_saving':
        return (
          ['Contra', 'Credit Note', 'Debit Note', 'Delivery Note', 'Payment', 'Purchase', 'Purchase Order', 'Receipt', 'Receipt Note', 'Sales', 'Sales Order'].includes(state?.select_type_of_voucher)
            ? name_of_class_input_ref
            : null
        );
      case 'set_alter_declaration':
        return (
          ['Contra', 'Credit Note', 'Debit Note', 'Delivery Note', 'Payment', 'Purchase', 'Purchase Order', 'Receipt', 'Receipt Note', 'Sales', 'Sales Order'].includes(state?.select_type_of_voucher)
            ? name_of_class_input_ref
            : null
        );
      default:
        return null;
    }
  };

  const getPreviousInputRef = (name, value) => {
    switch (name) {
      case 'alise_name':
        return voucher_type_name_input_ref;
      case 'select_type_of_voucher':
        return alise_name_input_ref;
      case 'abbreviation':
        return select_type_of_voucher_input_ref;
      case 'active_this_voucher_type':
        return abbreviation_input_ref;
      case 'define_multiple_numbering_series_for_vouchers':
        return active_this_voucher_type_input_ref;
      case 'show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour':
        return define_multiple_numbering_series_for_vouchers_input_ref;
      case 'use_effective_dates_for_vouchers':
        return show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour_input_ref;
      case 'allow_zero_valued_transactions':
        return use_effective_dates_for_vouchers_input_ref;
      case 'make_this_voucher_type_as_optional_by_default':
        return allow_zero_valued_transactions_input_ref;
      case 'allow_narration_in_voucher':
        if (
          ['Contra', 'Credit Note', 'Debit Note', 'Payment', 'Purchase', 'Purchase Order', 'Receipt', 'Reciept Note', 'Sales', 'Sales Order', 'Journal', 'Delivery Note', 'Stock Journal'].includes(state?.select_type_of_voucher)
        ) {
          return make_this_voucher_type_as_optional_by_default_input_ref;
        } else if (
          ['Physical Stock',].includes(state?.select_type_of_voucher)
        ) {
          return allow_zero_valued_transactions_input_ref;
        }
      case 'provide_narration_for_each_ledger_in_voucher':
        if (
          ['Contra', 'Credit Note', 'Debit Note', 'Journal', 'Payment', 'Purchase', 'Receipt'].includes(state?.select_type_of_voucher)
        ) {
          return allow_narration_in_voucher_input_ref;
        } else {
          // return enable_default_accounting_allocations_input_ref;
        }
      case 'use_a_manufacturing_journal':
        return (
          ['Stock Journal'].includes(state?.select_type_of_voucher)
            ? allow_narration_in_voucher_input_ref
            : null
        );
      case 'track_additional_costs_for_purchases':
        return (
          ['Debit Note', 'Journal', 'Payment', 'Purchase'].includes(state?.select_type_of_voucher)
            ? provide_narration_for_each_ledger_in_voucher_input_ref
            : ['Stock Journal'].includes(state?.select_type_of_voucher) ?
              use_a_manufacturing_journal_input_ref : allow_narration_in_voucher_input_ref
        );
      case 'enable_default_accounting_allocations':
        return (
          ['Contra', 'Credit Note', 'Receipt'].includes(state?.select_type_of_voucher)
            ? provide_narration_for_each_ledger_in_voucher_input_ref
            : ['Debit Note', 'Payment', 'Purchase'].includes(state?.select_type_of_voucher) ?
              track_additional_costs_for_purchases_input_ref : ['Sales', 'Delivery Note', 'Reciept Note', 'Purchase Order', 'Sales Order'].includes(state?.select_type_of_voucher) ?
                allow_narration_in_voucher_input_ref : null
        );
      case 'set_alter_default_accounting_entries':
        return enable_default_accounting_allocations_input_ref;
      case 'print_voucher_after_saving':
        switch (state?.select_type_of_voucher) {
          case 'Contra':
          case 'Credit Note':
          case 'Debit Note':
          case 'Payment':
          case 'Purchase':
          case 'Receipt':
          case 'Sales':
          case 'Delivery Note':
          case 'Reciept Note':
          case 'Purchase Order':
          case 'Sales Order':
            return state?.enable_default_accounting_allocations === 'Yes' ? set_alter_default_accounting_entries_input_ref : enable_default_accounting_allocations_input_ref;
          case 'Journal':
          case 'Physical Stock':
          case 'Stock Journal':
            return track_additional_costs_for_purchases_input_ref
          default:
            return null;
        }
      case 'use_for_pos_invoicing':
        return print_voucher_after_saving_input_ref;
      case 'message_to_print_1':
        return message_to_print_2_input_ref;
      case 'message_to_print_2':
        return default_bank_input_ref;
      case 'default_bank':
        return use_for_pos_invoicing_input_ref;
      case 'default_jurisdiction':
        return (
          ['Credit Note', 'Delivery Note', 'Purchase Order'].includes(state?.select_type_of_voucher)
            ? print_voucher_after_saving_input_ref
            : ['Sales'].includes(state?.select_type_of_voucher) ?
              default_bank_input_ref : null
        );
      case 'default_title_to_print':
        return (
          ['Contra', 'Credit Note', 'Delivery Note', 'Payment', 'Purchase', 'Purchase Order', 'Receipt', 'Receipt Note', 'Sales', 'Sales Order'].includes(state?.select_type_of_voucher)
            ? default_jurisdiction_input_ref
            : ['Debit Note'].includes(state?.select_type_of_voucher) ?
              print_voucher_after_saving_input_ref : null
        );
      case 'print_formal_receipt_after_saving':
        return (
          ['Receipt'].includes(state?.select_type_of_voucher)
            ? print_voucher_after_saving_input_ref
            : null
        );
      case 'set_alter_declaration':
        return (
          ['Sales'].includes(state?.select_type_of_voucher)
            ? default_title_to_print_input_ref
            : ['Sales Order'].includes(state?.select_type_of_voucher)
              ? print_voucher_after_saving_input_ref : default_jurisdiction_input_ref
        );
      case 'name_of_class':
        return (
          ['Contra', 'Payment', 'Purchase', 'Reciept Note'].includes(state?.select_type_of_voucher)
            ? print_voucher_after_saving_input_ref
            : ['Credit Note', 'Debit Note', 'Delivery Note'].includes(state?.select_type_of_voucher) ?
              default_title_to_print_input_ref : ['Receipt'].includes(state?.select_type_of_voucher) ?
                print_formal_receipt_after_saving_input_ref : ['Sales', 'Purchase Order', 'Sales Order'].includes(state?.select_type_of_voucher) ?
                  set_alter_declaration_input_ref : null
        );
      default:
        return null;
    }
  };

  const getCurrentINputRef = (name, value) => {
    switch (name) {
      case 'voucher_type_name': return voucher_type_name_input_ref
      case 'alise_name': return alise_name_input_ref
      case 'select_type_of_voucher': return select_type_of_voucher_input_ref
      case 'abbreviation': return abbreviation_input_ref
      case 'active_this_voucher_type': return active_this_voucher_type_input_ref
      case 'define_multiple_numbering_series_for_vouchers': return define_multiple_numbering_series_for_vouchers_input_ref
      case 'show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour': return show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour_input_ref
      case 'use_effective_dates_for_vouchers': return use_effective_dates_for_vouchers_input_ref
      case 'allow_zero_valued_transactions': return allow_zero_valued_transactions_input_ref
      case 'make_this_voucher_type_as_optional_by_default': return make_this_voucher_type_as_optional_by_default_input_ref
      case 'allow_narration_in_voucher': return allow_narration_in_voucher_input_ref
      case 'provide_narration_for_each_ledger_in_voucher': return provide_narration_for_each_ledger_in_voucher_input_ref
      case 'print_voucher_after_saving': return print_voucher_after_saving_input_ref
      case 'enable_default_accounting_allocations': return enable_default_accounting_allocations_input_ref
      case 'set_alter_default_accounting_entries': return set_alter_default_accounting_entries_input_ref
      case 'track_additional_costs_for_purchases': return track_additional_costs_for_purchases_input_ref
      case 'default_jurisdiction': return default_jurisdiction_input_ref
      case 'default_title_to_print': return default_title_to_print_input_ref
      case 'print_formal_receipt_after_saving': return print_formal_receipt_after_saving_input_ref
      case 'use_for_pos_invoicing': return use_for_pos_invoicing_input_ref
      case 'message_to_print_1': return message_to_print_1_input_ref
      case 'message_to_print_2': return message_to_print_2_input_ref
      case 'default_bank': return default_bank_input_ref
      case 'set_alter_declaration': return set_alter_declaration_input_ref
      case 'use_a_manufacturing_journal': return use_a_manufacturing_journal_input_ref
      case 'name_of_class': return name_of_class_input_ref
    }
  }
  const {
    voucher_type_name, alise_name, select_type_of_voucher, abbreviation, active_this_voucher_type,
    define_multiple_numbering_series_for_vouchers, show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour,
    method_of_voucher_number, use_effective_dates_for_vouchers, allow_zero_valued_transactions,
    make_this_voucher_type_as_optional_by_default, allow_narration_in_voucher,
    provide_narration_for_each_ledger_in_voucher, print_voucher_after_saving,
    enable_default_accounting_allocations, set_alter_default_accounting_entries,
    track_additional_costs_for_purchases, default_jurisdiction, default_title_to_print,
    print_formal_receipt_after_saving, use_for_pos_invoicing, message_to_print_1, message_to_print_2,
    default_bank, set_alter_declaration, use_a_manufacturing_journal, name_of_class,
  } = state;


  const handleFocus = (e) => {
    const { name, value } = e.target
    const ref = getCurrentINputRef(name);
    if (ref) {
      setNameInput(ref);
    }
    if (name === 'select_type_of_voucher') {
      setShowList(true)
      const index = voucher_type_default_data?.findIndex(item => item?.voucher_type_name?.toLowerCase() == state?.select_type_of_voucher?.toLowerCase());
      setSelectedItem(index)
    }
  };



  const handleBlur = (e) => {
    const { name, value } = e.target
    if (name === 'voucher_type_name' && value === '') {
      voucher_type_name_input_ref.current.focus();
    }
    if (name === 'select_type_of_voucher') {
      setShowList(false)
    }
    if (name === 'active_this_voucher_type') {
      setActiveThis(false)
      yesNoValidate(name, value)

    }
    if (name === 'define_multiple_numbering_series_for_vouchers') {
      setDefineMultiple(false)
      yesNoValidate(name, value)
    }
    if (name === 'show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour') {
      setShowUsedVch(false)
      yesNoValidate(name, value)
    }
    if (name === 'use_effective_dates_for_vouchers') {
      setUseEffective(false)
      yesNoValidate(name, value)
    }
    if (name === 'allow_zero_valued_transactions') {
      setAllowZero(false)
      yesNoValidate(name, value)
    }
    if (name === 'make_this_voucher_type_as_optional_by_default') {
      setMakeThis(false)
      yesNoValidate(name, value)
    }
    if (name === 'allow_narration_in_voucher') {
      setAllowNarration(false)
      yesNoValidate(name, value)
    }
    if (name === 'provide_narration_for_each_ledger_in_voucher') {
      setProvideNarration(false)
      yesNoValidate(name, value)
    }
    if (name === 'print_voucher_after_saving') {
      setPrintVch(false)
      yesNoValidate(name, value)
    }
    if (name === 'enable_default_accounting_allocations') {
      setEnableDefault(false)
      yesNoValidate(name, value)
    }
    if (name === 'set_alter_default_accounting_entries') {
      setSetAlterEntries(false)
      yesNoValidate(name, value)
    }
    if (name === 'track_additional_costs_for_purchases') {
      setTrackAdd(false)
      yesNoValidate(name, value)
    }
    if (name === 'print_formal_receipt_after_saving') {
      setPrintFormal(false)
      yesNoValidate(name, value)
    }
    if (name === 'set_alter_declaration') {
      setSetAltDec(false)
      yesNoValidate(name, value)
    }
    if (name === 'use_a_manufacturing_journal') {
      setUseManu(false)
      yesNoValidate(name, value)
    }
  }
  const yesNoValidate = (name, value) => {
    const normalizedValue = value.toLowerCase().trim();
    const validPatternYes = /^(y|e|s|ye|es|yes)?$/;
    const validPatternNo = /^(n|o|no)?$/;
    if (normalizedValue === '') {
      setState({ ...state, [name]: 'No' })
    } else if (validPatternNo.test(normalizedValue)) {
      setState({ ...state, [name]: 'No' })
    } else if (validPatternYes.test(normalizedValue)) {
      setState({ ...state, [name]: 'Yes' })
    }
  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    const capitalizeFirstLetter = (str) => {
      return str.toLowerCase().replace(/(^|\s)\S/g,
        (firstLetter) => firstLetter.toUpperCase());
    };
    if (name === 'voucher_type_name') {
      setIsVoucherTypeNameEntered(!!value.trim());
    }
    // space key functionality
    if (e.code == 'Space') {
      e.preventDefault();
      const cursorPosition_start = e.target.selectionStart;
      if (cursorPosition_start == 1) {
        setState({ ...state, [name]: '' });
        return;
      }
    }
    // Enter key functionality
    if (e.key === 'Enter' && state?.voucher_type_name !== '') {
      e.preventDefault();
      const nextInputRef = getNextInputRef(name, value);
      // if (name === 'define_multiple_numbering_series_for_vouchers') {
      //   if (/^(y|e|s|ye|es|yes)?$/.test(value)) {
      //     define_multiple_numbering_series_for_vouchers_input_ref?.current?.blur();
      //     setDefineNumberModal(true)
      //   }
      // }
      if (name === 'define_multiple_numbering_series_for_vouchers') {
        const normalizedValue = value.toLowerCase().trim();
        const validPatternYes = /^(y|e|s|ye|es|yes)?$/;
        const validPatternNo = /^(n|o|no)?$/;
        if (normalizedValue === '') {
          setState({ ...state, [name]: 'No' })
          setDefineNumberModal(false)
        } else if (validPatternNo.test(normalizedValue)) {
          setState({ ...state, [name]: 'No' })
          setDefineNumberModal(false)
        } else if (validPatternYes.test(normalizedValue)) {
          setState({ ...state, [name]: 'Yes' })
          setDefineNumberModal(true)
          define_multiple_numbering_series_for_vouchers_input_ref?.current?.blur();
        }
      }
      if (name === 'select_type_of_voucher') {
        const cleanString = (str) => str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
        const matchFound = voucher_type_default_data.find(item => cleanString(item.voucher_type_name) === cleanString(value));
        if (!matchFound) {
          select_type_of_voucher_input_ref?.current?.focus();
        }
      }

      if (name === 'name_of_class') {
        name_of_class_input_ref?.current?.blur();
        setvoucher_comfirm('Accept')
      }
      if (state?.select_type_of_voucher === 'Journal' || state?.select_type_of_voucher === 'Physical Stock' || state?.select_type_of_voucher === 'Stock Journal') {
        if (name === 'print_voucher_after_saving') {
          print_voucher_after_saving_input_ref?.current?.blur();
          setvoucher_comfirm('Accept');
        }
      }

      if (nextInputRef) {
        nextInputRef?.current?.focus();
        if (nextInputRef?.current) {
          nextInputRef?.current?.setSelectionRange(0, 0);
        }
      }
      return;
    }
    // Back space key functionality
    if (e.key === 'Backspace') {
      e.preventDefault();
      const nextInputRef = getPreviousInputRef(name, value);
      const cursorPosition = e.target.selectionStart;
      if (cursorPosition === 0) {
        if (backspaceCount === 1) {
          setBackspaceCount(0);
          if (nextInputRef && nextInputRef.current) {
            nextInputRef.current.focus();
            nextInputRef.current.setSelectionRange(0, 0);
          }
        } else {
          if (cursorPosition === 0 && value) {
            if (nextInputRef && nextInputRef.current) {
              nextInputRef.current.focus();
              nextInputRef.current.setSelectionRange(0, 0);
            }
          } else {
            setBackspaceCount(backspaceCount + 1);
          }
        }
      }
      return;
    }
    // Set data in state accourding to name and validate the value -------
    const cleanString = (str) => str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    switch (name) {
      case 'select_type_of_voucher':
        const group = voucher_type_default_data.some(item => cleanString(item.voucher_type_name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (group) {
          setState({ ...state, [name]: value })
        }
        break;
      case 'active_this_voucher_type':
        const nat_group = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (nat_group) {
          setState({ ...state, [name]: value })
        } else {
          setActiveThis(true)
        }
        break;
      case 'define_multiple_numbering_series_for_vouchers':
        const group_behaves = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (group_behaves) {
          setState({ ...state, [name]: value })
        } else {
          setDefineMultiple(true)
        }
        break;
      case 'show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour':
        const nett_debit = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (nett_debit) {
          setState({ ...state, [name]: value })
        } else {
          setShowUsedVch(true)
        }
        break;
      case 'use_effective_dates_for_vouchers':
        const used_for = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (used_for) {
          setState({ ...state, [name]: value })
        } else {
          setUseEffective(true)
        }
        break;
      case 'allow_zero_valued_transactions':
        const allow_zero = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (allow_zero) {
          setState({ ...state, [name]: value })
        } else {
          setAllowZero(true)
        }
        break;
      case 'make_this_voucher_type_as_optional_by_default':
        const amke_this = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (amke_this) {
          setState({ ...state, [name]: value })
        } else {
          setMakeThis(true)
        }
        break;
      case 'allow_narration_in_voucher':
        const allow_narr = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (allow_narr) {
          setState({ ...state, [name]: value })
        } else {
          setAllowNarration(true)
        }
        break;
      case 'provide_narration_for_each_ledger_in_voucher':
        const provide = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (provide) {
          setState({ ...state, [name]: value })
        } else {
          setProvideNarration(true)
        }
        break;
      case 'print_voucher_after_saving':
        const print_vou = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (print_vou) {
          setState({ ...state, [name]: value })
        } else {
          setPrintVch(true)
        }
        break;
      case 'enable_default_accounting_allocations':
        const enable = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (enable) {
          setState({ ...state, [name]: value })
        } else {
          setEnableDefault(true)
        }
        break;
      case 'set_alter_default_accounting_entries':
        const set_al = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (set_al) {
          setState({ ...state, [name]: value })
        } else {
          setSetAlterEntries(true)
        }
        break;
      case 'track_additional_costs_for_purchases':
        const track = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (track) {
          setState({ ...state, [name]: value })
        } else {
          setTrackAdd(true)
        }
        break;
      case 'print_formal_receipt_after_saving':
        const prt_formal = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (prt_formal) {
          setState({ ...state, [name]: value })
        } else {
          setPrintFormal(true)
        }
        break;
      case 'set_alter_declaration':
        const set_dec = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (set_dec) {
          setState({ ...state, [name]: value })
        } else {
          setSetAltDec(true)
        }
        break;
      case 'use_a_manufacturing_journal':
        const use_manu = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (use_manu) {
          setState({ ...state, [name]: value })
        } else {
          setUseManu(true)
        }
        break;
      default:
        const updatedValue = name === 'voucher_type_name' || name === 'alise_name' ?
          capitalizeFirstLetter(value) : value;
        setState({ ...state, [name]: updatedValue })
        break;
    }

  }
  //triger when the change value of search input and get filter data -------------
  useEffect(() => {
    const filteredSuggestions = voucher_type_default_data?.filter((item, index) => {
      const lowercaseLedgerName = item?.voucher_type_name?.toLowerCase();
      const lowercaseSearchInput = state?.select_type_of_voucher?.toLowerCase();
      const matchesSearchInput = lowercaseLedgerName?.includes(state.select_type_of_voucher !== '' ? lowercaseSearchInput : '');
      return matchesSearchInput;
    })
    setFilteredData(state.select_type_of_voucher !== '' ? select_type_of_voucher_input_ref.current.selectionStart > 0 ? filteredSuggestions : voucher_type_default_data : voucher_type_default_data);
  }, [state.select_type_of_voucher, voucher_type_default_data, select_type_of_voucher_input_ref?.current?.selectionStart])

  useEffect(() => {
    const filteredSuggestions = yes_no_array?.filter((item, index) => {
      const lowercaseLedgerName = item?.name?.toLowerCase();
      const lowercaseSearchInput = (
        activeThis ? state?.active_this_voucher_type :
          defineMultiple ? state?.define_multiple_numbering_series_for_vouchers :
            showUsedVch ? state?.show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour :
              useEffective ? state?.use_effective_dates_for_vouchers :
                allowZero ? state?.allow_zero_valued_transactions :
                  makeThis ? state?.make_this_voucher_type_as_optional_by_default :
                    allowNarration ? state?.allow_narration_in_voucher :
                      provideNarration ? state?.provide_narration_for_each_ledger_in_voucher :
                        printVch ? state?.print_voucher_after_saving :
                          enableDefault ? state?.enable_default_accounting_allocations :
                            setAlterEntries ? state?.set_alter_default_accounting_entries :
                              trackAdd ? state?.track_additional_costs_for_purchases :
                                printFormal ? state?.print_formal_receipt_after_saving :
                                  setAltDec ? state?.set_alter_declaration :
                                    state?.use_a_manufacturing_journal)?.toLowerCase();
      const matchesSearchInput = lowercaseLedgerName?.includes(
        (
          activeThis ? state?.active_this_voucher_type :
            defineMultiple ? state?.define_multiple_numbering_series_for_vouchers :
              showUsedVch ? state?.show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour :
                useEffective ? state?.use_effective_dates_for_vouchers :
                  allowZero ? state?.allow_zero_valued_transactions :
                    makeThis ? state?.make_this_voucher_type_as_optional_by_default :
                      allowNarration ? state?.allow_narration_in_voucher :
                        provideNarration ? state?.provide_narration_for_each_ledger_in_voucher :
                          printVch ? state?.print_voucher_after_saving :
                            enableDefault ? state?.enable_default_accounting_allocations :
                              setAlterEntries ? state?.set_alter_default_accounting_entries :
                                trackAdd ? state?.track_additional_costs_for_purchases :
                                  printFormal ? state?.print_formal_receipt_after_saving :
                                    setAltDec ? state?.set_alter_declaration :
                                      state?.use_a_manufacturing_journal)
          !== '' ? lowercaseSearchInput : '');
      return matchesSearchInput;
    })
    setFilteredDataYesNo(
      (
        activeThis ? state?.active_this_voucher_type :
          defineMultiple ? state?.define_multiple_numbering_series_for_vouchers :
            showUsedVch ? state?.show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour :
              useEffective ? state?.use_effective_dates_for_vouchers :
                allowZero ? state?.allow_zero_valued_transactions :
                  makeThis ? state?.make_this_voucher_type_as_optional_by_default :
                    allowNarration ? state?.allow_narration_in_voucher :
                      provideNarration ? state?.provide_narration_for_each_ledger_in_voucher :
                        printVch ? state?.print_voucher_after_saving :
                          enableDefault ? state?.enable_default_accounting_allocations :
                            setAlterEntries ? state?.set_alter_default_accounting_entries :
                              trackAdd ? state?.track_additional_costs_for_purchases :
                                printFormal ? state?.print_formal_receipt_after_saving :
                                  setAltDec ? state?.set_alter_declaration :
                                    state?.use_a_manufacturing_journal)
        !== '' ?
        (
          activeThis ? active_this_voucher_type_input_ref :
            defineMultiple ? define_multiple_numbering_series_for_vouchers_input_ref :
              showUsedVch ? show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour_input_ref :
                useEffective ? use_effective_dates_for_vouchers_input_ref :
                  allowZero ? allow_zero_valued_transactions_input_ref :
                    makeThis ? make_this_voucher_type_as_optional_by_default_input_ref :
                      allowNarration ? allow_narration_in_voucher_input_ref :
                        provideNarration ? provide_narration_for_each_ledger_in_voucher_input_ref :
                          printVch ? print_voucher_after_saving_input_ref :
                            enableDefault ? enable_default_accounting_allocations_input_ref :
                              setAlterEntries ? set_alter_default_accounting_entries_input_ref :
                                trackAdd ? track_additional_costs_for_purchases_input_ref :
                                  printFormal ? print_formal_receipt_after_saving_input_ref :
                                    setAltDec ? set_alter_declaration_input_ref :
                                      use_a_manufacturing_journal_input_ref)
          ?.current?.selectionStart > 0 ? filteredSuggestions : yes_no_array : yes_no_array);
    setSelectedItem(0)
  }, [
    active_this_voucher_type,
    define_multiple_numbering_series_for_vouchers,
    show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour,
    use_effective_dates_for_vouchers,
    allow_zero_valued_transactions,
    make_this_voucher_type_as_optional_by_default,
    allow_narration_in_voucher,
    provide_narration_for_each_ledger_in_voucher,
    print_voucher_after_saving,
    enable_default_accounting_allocations,
    set_alter_declaration,
    track_additional_costs_for_purchases,
    print_formal_receipt_after_saving,
    set_alter_default_accounting_entries,
    use_a_manufacturing_journal,
    yes_no_array
  ])

  const handleClickOutside = (event) => {
    let clickedElement = event.target;
    let isInputField = false;
    while (clickedElement) {
      if ((clickedElement.tagName &&
        (clickedElement.tagName.toLowerCase() === 'input' ||
          clickedElement.tagName.toLowerCase() === 'textarea' || clickedElement.tagName.toLowerCase() === 'button'))) {
        isInputField = true;
        break;
      }
      clickedElement = clickedElement.parentNode;
    }

    if (!isInputField) {
      if (nameInput && nameInput.current) {
        event.preventDefault();
        nameInput.current.focus();
      }
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [nameInput])


  useEffect(() => {
    const handleClickOutside = (event) => {
      let clickedElement = event.target;
      let isInputField = false;
      while (clickedElement) {
        if ((clickedElement.tagName &&
          (clickedElement.tagName.toLowerCase() === 'input' ||
            clickedElement.tagName.toLowerCase() === 'textarea' || clickedElement.tagName.toLowerCase() === 'button'))) {
          isInputField = true;
          break;
        }
        clickedElement = clickedElement.parentNode;
      }
      if (!isInputField) {
        if (nameInput && nameInput.current) {
          event.preventDefault();
          nameInput.current.focus();
        }
      }
    };

    const handleMouseDown = () => {
      isMouseDownInside.current = true;
    };

    const handleMouseUp = () => {
      isMouseDownInside.current = false;
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [nameInput]);


  useEffect(() => {
    const formData = new FormData();
    formData.append('company_id', userData?.id)
    dispatch(voucherTypeDefaultList(formData));
  }, [])
  //---------------------------------------------------------------------------
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (defineNumberModal) {

      } else {
        if (voucher_confirm === 'Accept') {
          if (e.key === 'y' || e.key === 'Y') {
            e.preventDefault();
            controlPlusASubmit();
          }
        }
        if (voucher_confirm === 'Accept') {
          if (e.key === 'n' || e.key === 'N') {
            e.preventDefault();
            setvoucher_comfirm('')
            voucher_type_name_input_ref?.current?.focus();
          }
        }
      }

    }
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [state?.voucher_type_name, voucher_confirm])
  // submit form by ctr + a -------------------------------------------------
  const controlPlusASubmit = async () => {
    if (validateForm()) {
      try {
        const data = new FormData();
        data.append('company_id', userData?.id)
        data.append('voucher_type_name', state.voucher_type_name);
        data.append('alias_name', state.alise_name);
        data.append('voucher_type_id', state.select_type_of_voucher_id);
        data.append('select_type_of_voucher', state.select_type_of_voucher);
        data.append('abbreviation', state.abbreviation);
        data.append('activate_this_voucher_type', state.active_this_voucher_type === 'Yes' ? 1 : 0);
        data.append('define_multiple_numbering_series_for_vouchers', state.define_multiple_numbering_series_for_vouchers);
        data.append('show_unsed_vch_nos_in_transaction_for_retian_original_vch_no_beh', state.show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour);
        data.append('use_effective_date_for_vouchers', state.use_effective_dates_for_vouchers);
        data.append('allow_zero_valued_transactions', state.allow_zero_valued_transactions);
        data.append('make_this_voucher_type_as_optional_by_default', state.make_this_voucher_type_as_optional_by_default);
        data.append('allow_narration_in_voucher', state.allow_narration_in_voucher);
        data.append('provide_narrations_for_each_ledger_in_voucher', state.provide_narration_for_each_ledger_in_voucher);
        data.append('enable_default_accounting_allocations', state.enable_default_accounting_allocations);
        data.append('set_alter_default_accounting_entries', state.set_alter_default_accounting_entries);
        data.append('printing_voucher_after_saving', state.print_voucher_after_saving);
        data.append('default_jurisdiction', state.default_jurisdiction);
        data.append('default_title_to_print', state.default_title_to_print);
        data.append('track_additional_costs_for_purchases', state.track_additional_costs_for_purchases);
        data.append('allow_access_for_user', '');
        data.append('use_for_pos_invoicing', state.use_for_pos_invoicing);
        data.append('message_to_print_1', state.message_to_print_1);
        data.append('message_to_print_2', state.message_to_print_2);
        data.append('default_bank', state.default_bank);
        data.append('set_alter_declaration', state.set_alter_declaration);
        const response = await dispatch(voucherTypeCreate(data));
        if (response.status) {
          toast.success(response?.message || 'Group created!', {
            position: toast.POSITION.TOP_CENTER
          });

          resetForm();
        } else {
          toast.warn(response?.message || 'Fail!', {
            position: toast.POSITION.TOP_CENTER,
          });
          setvoucher_comfirm('')
          voucher_type_name_input_ref.current.focus();
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.message || 'Failed!', {
          position: toast.POSITION.TOP_CENTER,
        });
        setvoucher_comfirm('')
        voucher_type_name_input_ref.current.focus();
      }
    } else {
      toast.error('Please fill all the fields.', {
        position: toast.POSITION.TOP_CENTER
      });
      setvoucher_comfirm('')
      voucher_type_name_input_ref.current.focus();
    }
  };
  // Function to validate form fields---------------------------------------
  const validateForm = () => {
    return (
      userData?.id !== '' &&
      state?.voucher_type_name !== '' &&
      state?.select_type_of_voucher !== '' &&
      state?.select_type_of_voucher_id !== '' &&
      state?.abbreviation !== '' &&
      state?.active_this_voucher_type !== '' &&
      state?.define_multiple_numbering_series_for_vouchers !== '' &&
      state?.show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour !== '' &&
      state?.use_effective_dates_for_vouchers !== '' &&
      state?.allow_zero_valued_transactions !== '' &&
      state?.make_this_voucher_type_as_optional_by_default !== '' &&
      state?.allow_narration_in_voucher !== '' &&
      state?.provide_narration_for_each_ledger_in_voucher !== '' &&
      state?.print_voucher_after_saving !== '' &&
      state?.enable_default_accounting_allocations !== '' &&
      state?.set_alter_default_accounting_entries !== '' &&
      state?.track_additional_costs_for_purchases !== '' &&
      state?.print_formal_receipt_after_saving !== '' &&
      state?.default_bank !== '' &&
      state?.set_alter_declaration !== '' &&
      state?.use_a_manufacturing_journal !== ''
    );
  };
  // Function to reset form fields-----------------------------------------
  const resetForm = () => {
    setState(prevState => ({
      ...prevState,
      voucher_type_name: '',
      alise_name: '',
      select_type_of_voucher: 'Contra',
      select_type_of_voucher_id: '1',
      abbreviation: 'Ctr',
      active_this_voucher_type: 'Yes',
      define_multiple_numbering_series_for_vouchers: 'No',
      show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour: 'Yes',
      use_effective_dates_for_vouchers: 'No',
      allow_zero_valued_transactions: 'No',
      make_this_voucher_type_as_optional_by_default: 'No',
      allow_narration_in_voucher: 'Yes',
      provide_narration_for_each_ledger_in_voucher: 'No',
      print_voucher_after_saving: 'No',
      enable_default_accounting_allocations: 'No',
      set_alter_default_accounting_entries: 'No',
      track_additional_costs_for_purchases: 'No',
      default_jurisdiction: '',
      default_title_to_print: '',
      print_formal_receipt_after_saving: 'No',
      use_for_pos_invoicing: '',
      message_to_print_1: '',
      message_to_print_2: '',
      default_bank: 'Not Applicable',
      set_alter_declaration: 'No',
      use_a_manufacturing_journal: 'No',
      name_of_class: '',
    }));
    setvoucher_comfirm('');
    setTimeout(() => {
      voucher_type_name_input_ref.current.focus();
    }, 50);
    setIsVoucherTypeNameEntered(false);
  };

  const groupSubmit = (e) => {
    e.preventDefault();
    if (hashtag) {
      let data = new FormData();
      data.append('company_id', userData?.id)
      data.append('voucher_type_name', state.voucher_type_name);
      data.append('alias_name', state.alise_name);
      data.append('voucher_type_id', state.select_type_of_voucher_id);
      data.append('select_type_of_voucher', state.select_type_of_voucher);
      data.append('abbreviation', state.abbreviation);
      data.append('activate_this_voucher_type', state.active_this_voucher_type === 'Yes' ? 1 : 0);
      data.append('define_multiple_numbering_series_for_vouchers', state.define_multiple_numbering_series_for_vouchers);
      data.append('show_unsed_vch_nos_in_transaction_for_retian_original_vch_no_beh', state.show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour);
      data.append('use_effective_date_for_vouchers', state.use_effective_dates_for_vouchers);
      data.append('allow_zero_valued_transactions', state.allow_zero_valued_transactions);
      data.append('make_this_voucher_type_as_optional_by_default', state.make_this_voucher_type_as_optional_by_default);
      data.append('allow_narration_in_voucher', state.allow_narration_in_voucher);
      data.append('provide_narrations_for_each_ledger_in_voucher', state.provide_narration_for_each_ledger_in_voucher);
      data.append('enable_default_accounting_allocations', state.enable_default_accounting_allocations);
      data.append('set_alter_default_accounting_entries', state.set_alter_default_accounting_entries);
      data.append('printing_voucher_after_saving', state.print_voucher_after_saving);
      data.append('default_jurisdiction', state.default_jurisdiction);
      data.append('default_title_to_print', state.default_title_to_print);
      data.append('track_additional_costs_for_purchases', state.track_additional_costs_for_purchases);
      data.append('allow_access_for_user', '');
      data.append('use_for_pos_invoicing', state.use_for_pos_invoicing);
      data.append('message_to_print_1', state.message_to_print_1);
      data.append('message_to_print_2', state.message_to_print_2);
      data.append('default_bank', state.default_bank);
      data.append('set_alter_declaration', state.set_alter_declaration);
      dispatch(voucherTypeCreate(data)).then((response) => {
        if (response.status) {
          toast.success(response?.message || 'group created!', {
            position: toast.POSITION.TOP_CENTER
          });
        }
        else {
          toast.warn(response?.message || 'Fail !', {
            position: toast.POSITION.TOP_CENTER
          });
        }
      }).catch(error => {
        console.log(error)
        toast.error(error?.message || ' Failed!', {
          position: toast.POSITION.TOP_CENTER
        });
      })
    }
  }


  const handleKeyDown = useCallback((e) => {
    if (showList || activeThis || defineMultiple || showUsedVch || useEffective || allowZero || allowNarration || makeThis || provideNarration || printVch || enableDefault || setAlterEntries || trackAdd || printFormal || setAltDec || useManu) {
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        setSelectedItem(prevState => Math.max(prevState - 1, 0));
      } else if (e.key === 'ArrowDown') {
        e.preventDefault();
        setSelectedItem(prevState => {
          return Math.min(prevState + 1, (activeThis || defineMultiple || showUsedVch || useEffective || allowZero || allowNarration || makeThis || provideNarration || printVch || enableDefault || setAlterEntries || trackAdd || printFormal || setAltDec || useManu ? filteredDataYesNo?.length : filteredData.length) - 1)
        });
      } else if (e.key === 'Enter') {
        e.preventDefault();
        const selectedLink = activeThis || defineMultiple || showUsedVch || useEffective || allowZero || allowNarration || makeThis || provideNarration || printVch || enableDefault || setAlterEntries || trackAdd || printFormal || setAltDec || useManu ? filteredDataYesNo[selectedItem] : filteredData[selectedItem];
        if (selectedLink) {
          if (showList) {
            setState({ ...state, select_type_of_voucher: selectedLink.voucher_type_name, select_type_of_voucher_id: selectedLink.id, abbreviation: selectedLink?.abbreviation });
          }
          if (activeThis) {
            setState(prevState => ({
              ...prevState,
              active_this_voucher_type: selectedLink.name
            }));
            setActiveThis(false);
          }
          if (defineMultiple) {
            setState(prevState => ({
              ...prevState,
              define_multiple_numbering_series_for_vouchers: selectedLink.name
            }));
            setDefineMultiple(false);
          }
          if (showUsedVch) {
            setState(prevState => ({
              ...prevState,
              show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour: selectedLink.name
            }));
            setShowUsedVch(false);
          }
          if (useEffective) {
            setState(prevState => ({
              ...prevState,
              use_effective_dates_for_vouchers: selectedLink.name
            }));
            setUseEffective(false);
          }
          if (allowZero) {
            setState(prevState => ({
              ...prevState,
              allow_zero_valued_transactions: selectedLink.name
            }));
            setAllowZero(false);
          }
          if (makeThis) {
            setState(prevState => ({
              ...prevState,
              make_this_voucher_type_as_optional_by_default: selectedLink.name
            }));
            setMakeThis(false);
          }
          if (allowNarration) {
            setState(prevState => ({
              ...prevState,
              allow_narration_in_voucher: selectedLink.name
            }));
            setAllowNarration(false);
          }
          if (provideNarration) {
            setState(prevState => ({
              ...prevState,
              provide_narration_for_each_ledger_in_voucher: selectedLink.name
            }));
            setProvideNarration(false);
          }
          if (printVch) {
            setState(prevState => ({
              ...prevState,
              print_voucher_after_saving: selectedLink.name
            }));
            setPrintVch(false);
          }
          if (enableDefault) {
            setState(prevState => ({
              ...prevState,
              enable_default_accounting_allocations: selectedLink.name
            }));
            setEnableDefault(false);
          }
          if (setAlterEntries) {
            setState(prevState => ({
              ...prevState,
              set_alter_default_accounting_entries: selectedLink.name
            }));
            setSetAlterEntries(false);
          }
          if (trackAdd) {
            setState(prevState => ({
              ...prevState,
              track_additional_costs_for_purchases: selectedLink.name
            }));
            setTrackAdd(false);
          }
          if (printFormal) {
            setState(prevState => ({
              ...prevState,
              print_formal_receipt_after_saving: selectedLink.name
            }));
            setPrintFormal(false);
          }
          if (setAltDec) {
            setState(prevState => ({
              ...prevState,
              set_alter_declaration: selectedLink.name
            }));
            setAltDec(false);
          }
          if (useManu) {
            setState(prevState => ({
              ...prevState,
              use_a_manufacturing_journal: selectedLink.name
            }));
            setUseManu(false);
          }
        }
      }
    }
  }, [selectedItem, state, showList, activeThis, defineMultiple, showUsedVch, useEffective, allowZero, allowNarration, makeThis, provideNarration, printVch, enableDefault, setAlterEntries, trackAdd, printFormal, setAltDec, useManu, filteredDataYesNo, filteredData]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);
  return (
    <div className='section'>
      <div className='detail border position-relative'>
        {
          voucher_confirm === 'Accept' ?
            <YesNoModal>
              <div className='container'>
                <div className='row'>
                  <div className='col-12'>
                    <p className='modal_message'>
                      {voucher_confirm}
                    </p>
                  </div>
                  <div className='col-3 offset-1'>
                    <Link className="modal_link" onClick={controlPlusASubmit} >Yes</Link>
                  </div>
                  <div className='col-2'>or</div>
                  <div className='col-3'>
                    <Link className='modal_link'>No</Link>
                  </div>
                </div>
              </div>
            </YesNoModal> : null
        }
        {
          defineNumberModal ?
            <div style={{ position: 'absolute', width: '100%', height: '89.6', zIndex: 10000 }}>
              <DefineNumberSeries
                setDefineNumberModal={setDefineNumberModal}
                name={state.voucher_type_name}
                ref_name={show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour_input_ref} />
            </div> : null
        }
        <div className='container-fluid p-0' style={{ background: 'rgba(0,0,0,0.4)', height: '91.7vh' }}>
          <div className='row border border-dark table_date_header' >
            <div className='col-12 ps-3  d-flex justify-content-between  table_header_font'>
              <b> Voucher Type Creation </b>
            </div>
          </div>
          <div className='row'>
            <form
              autoComplete='off'
              onSubmit={groupSubmit}
              onKeyDown={(e) => {
                if (e.ctrlKey && (e.key === 'a' || e.key === 'A')) {
                  e.preventDefault();
                  controlPlusASubmit(e);
                }
              }}
            >
              <div className='col-11 p-1' style={{ background: '#fff', overflow: 'hidden', width: '95%', borderRight: '1px solid black', borderBottom: '1px solid black' }}>
                <div className='row mt-2'>
                  <div className='col-6'>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1'> Name</label>
                      <label>:</label>
                      <input
                        type="text"
                        name='voucher_type_name'
                        autoFocus
                        id='voucher_type_name'
                        className={`lab-right`}
                        onKeyUp={handleChange}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        ref={voucher_type_name_input_ref}
                        value={voucher_type_name || ""} />
                    </div>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1'> (alise) </label>
                      <label> :</label>
                      <input
                        type="text"
                        name='alise_name'
                        id='alise_name'
                        className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`}
                        onKeyUp={handleChange}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        ref={alise_name_input_ref}
                        value={alise_name || ""} />
                    </div>
                  </div>
                </div>
                <div className='row height'>
                  <div className='col-6 border'>
                    <div className='row'>
                      <div className='col text-decoration-underline'>
                        <b>General</b>
                      </div>
                    </div>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1 text-truncate'>Select type of voucher  </label>
                      <label> :</label>
                      <input
                        type="text"
                        name='select_type_of_voucher'
                        id='select_type_of_voucher'
                        className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`}
                        ref={select_type_of_voucher_input_ref}
                        onKeyUp={handleChange}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        value={select_type_of_voucher || ""} />
                      <div id="myDropdown-list" className={`dropdown-content-list ${showList ? 'show-list' : ''}`} ref={dropdownRef}>
                        <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                          List Of Voucher Type
                        </div>
                        <div className='col' style={{ textAlign: 'right' }}>
                          <Link style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                          <div className='border'></div>
                        </div>
                        <div id='data'>
                          {filteredData.length > 0 ? (
                            filteredData.map((key, number) => {
                              const name = key?.voucher_type_name;
                              const id = key?.id;
                              const index = name?.toLowerCase()?.indexOf(select_type_of_voucher?.toLowerCase());
                              return (
                                <Link
                                  className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                  id='list'
                                  key={number}
                                  onClick={() => {
                                    setSelectedItem(number)
                                    if (number === selectedItem) {
                                      setState({ ...state, select_type_of_voucher: name, select_type_of_voucher_id: id })
                                      abbreviation_input_ref?.current?.setSelectionRange(0, 0)
                                      abbreviation_input_ref?.current?.focus();
                                      setShowList(false)
                                    }
                                  }}
                                >
                                  {index !== -1 ? (
                                    < >
                                      {name?.substring(0, index)}
                                      <span style={{ color: 'red' }}>{name?.substring(index, index + select_type_of_voucher?.length)}</span>
                                      {name?.substring(index + select_type_of_voucher?.length)}
                                    </>
                                  ) : (
                                    name
                                  )}
                                </Link>
                              );
                            })
                          ) : (
                            <p style={{ color: 'red' }}>No matching items found</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1 text-truncate'>Abbreviation </label>
                      <label> :</label>
                      <input
                        type="text"
                        name='abbreviation'
                        id='abbreviation'
                        className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`}
                        onKeyUp={handleChange}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        ref={abbreviation_input_ref}
                        value={abbreviation || ""} />
                    </div>
                    <div className='d-flex justify-content-between align-item-center position-relative'>
                      <label className='lab-1 text-truncate'>Activate this voucher Type </label>
                      <label> :</label>
                      <input
                        type="text"
                        name='active_this_voucher_type'
                        id='active_this_voucher_type'
                        className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`}
                        onKeyUp={handleChange}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        ref={active_this_voucher_type_input_ref}
                        value={active_this_voucher_type || ""} />
                      <div id="myDropdown-list" className={`dropdown_yes_no_list ${activeThis ? 'show-list' : ''}`} ref={dropdownRef}>
                        <div className='col dropdown_header'>
                          Yes / No
                        </div>
                        <div id='data'>
                          {filteredDataYesNo?.length > 0 ? (
                            filteredDataYesNo?.map((key, number) => {
                              const name = key?.name;
                              const id = key?.id;
                              const index = name?.toLowerCase()?.indexOf(active_this_voucher_type?.toLowerCase());
                              return (
                                <Link
                                  className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                  id='list'
                                  key={number}
                                  onClick={() => {
                                    setSelectedItem(number)
                                    if (number === selectedItem) {
                                      setState({ ...state, active_this_voucher_type: name })
                                      define_multiple_numbering_series_for_vouchers.current.focus();
                                      define_multiple_numbering_series_for_vouchers.current.setSelectionRange(0, 0);
                                      setActiveThis(false)
                                    }
                                  }}
                                >
                                  {index !== -1 ? (
                                    < >
                                      {name.substring(0, index)}
                                      <span style={{ color: 'red' }}>{name.substring(index, index + active_this_voucher_type.length)}</span>
                                      {name.substring(index + active_this_voucher_type.length)}
                                    </>
                                  ) : (
                                    name
                                  )}
                                </Link>
                              );
                            })
                          ) : (
                            <p style={{ color: 'red' }}>No matching items found</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='border'></div>
                    <div className='d-flex justify-content-between align-item-center position-relative'>
                      <label className='lab-1 text-truncate'>Define multiple numbering series for vouchers </label>
                      <label> :</label>
                      <input
                        type="text"
                        name='define_multiple_numbering_series_for_vouchers'
                        id='define_multiple_numbering_series_for_vouchers'
                        className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`}
                        onKeyUp={handleChange}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        ref={define_multiple_numbering_series_for_vouchers_input_ref}
                        value={define_multiple_numbering_series_for_vouchers || ""} />
                      <div id="myDropdown-list" className={`dropdown_yes_no_list ${defineMultiple ? 'show-list' : ''}`} ref={dropdownRef}>
                        <div className='col dropdown_header'>
                          Yes / No
                        </div>
                        <div id='data'>
                          {filteredDataYesNo?.length > 0 ? (
                            filteredDataYesNo?.map((key, number) => {
                              const name = key?.name;
                              const id = key?.id;
                              const index = name?.toLowerCase()?.indexOf(define_multiple_numbering_series_for_vouchers?.toLowerCase());
                              return (
                                <Link
                                  className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                  id='list'
                                  key={number}
                                  onClick={() => {
                                    setSelectedItem(number)
                                    if (number === selectedItem) {
                                      setState({ ...state, define_multiple_numbering_series_for_vouchers: name })
                                      // nett_debit_credit_balance_for_reporting_input_ref.current.focus();
                                      // nett_debit_credit_balance_for_reporting_input_ref.current.setSelectionRange(0, 0);
                                      setDefineMultiple(false)
                                    }
                                  }}
                                >
                                  {index !== -1 ? (
                                    < >
                                      {name.substring(0, index)}
                                      <span style={{ color: 'red' }}>{name.substring(index, index + define_multiple_numbering_series_for_vouchers.length)}</span>
                                      {name.substring(index + define_multiple_numbering_series_for_vouchers.length)}
                                    </>
                                  ) : (
                                    name
                                  )}
                                </Link>
                              );
                            })
                          ) : (
                            <p style={{ color: 'red' }}>No matching items found</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='d-flex justify-content-between align-item-center position-relative'>
                      <label className='lab-1 text-truncate'>Show unsed vch nos. in transaction for retain original voucher  no. behaviour </label>
                      <label> :</label>
                      <input
                        type="text"
                        name='show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour'
                        id='show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour'
                        className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`}
                        onKeyUp={handleChange}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        ref={show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour_input_ref}
                        value={show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour || ""} />
                      <div id="myDropdown-list" className={`dropdown_yes_no_list ${showUsedVch ? 'show-list' : ''}`} ref={dropdownRef}>
                        <div className='col dropdown_header'>
                          Yes / No
                        </div>
                        <div id='data'>
                          {filteredDataYesNo?.length > 0 ? (
                            filteredDataYesNo?.map((key, number) => {
                              const name = key?.name;
                              const id = key?.id;
                              const index = name?.toLowerCase()?.indexOf(show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour?.toLowerCase());
                              return (
                                <Link
                                  className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                  id='list'
                                  key={number}
                                  onClick={() => {
                                    setSelectedItem(number)
                                    if (number === selectedItem) {
                                      setState({ ...state, show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour: name })
                                      use_effective_dates_for_vouchers_input_ref.current.focus();
                                      use_effective_dates_for_vouchers_input_ref.current.setSelectionRange(0, 0);
                                      setShowUsedVch(false)
                                    }
                                  }}
                                >
                                  {index !== -1 ? (
                                    < >
                                      {name.substring(0, index)}
                                      <span style={{ color: 'red' }}>{name.substring(index, index + show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour.length)}</span>
                                      {name.substring(index + show_unsed_vch_nos_in_transaction_for_retain_original_voucher_no_behaviour.length)}
                                    </>
                                  ) : (
                                    name
                                  )}
                                </Link>
                              );
                            })
                          ) : (
                            <p style={{ color: 'red' }}>No matching items found</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='border'></div>
                    <div className='d-flex justify-content-between align-item-center position-relative'>
                      <label className='lab-1 text-truncate'>Use effective dates for Vouchers  </label>
                      <label> :</label>
                      <input
                        type="text"
                        name='use_effective_dates_for_vouchers'
                        id='use_effective_dates_for_vouchers'
                        className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`}
                        onKeyUp={handleChange}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        ref={use_effective_dates_for_vouchers_input_ref}
                        value={use_effective_dates_for_vouchers || ""} />
                      <div id="myDropdown-list" className={`dropdown_yes_no_list ${useEffective ? 'show-list' : ''}`} ref={dropdownRef}>
                        <div className='col dropdown_header'>
                          Yes / No
                        </div>
                        <div id='data'>
                          {filteredDataYesNo?.length > 0 ? (
                            filteredDataYesNo?.map((key, number) => {
                              const name = key?.name;
                              const id = key?.id;
                              const index = name?.toLowerCase()?.indexOf(use_effective_dates_for_vouchers?.toLowerCase());
                              return (
                                <Link
                                  className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                  id='list'
                                  key={number}
                                  onClick={() => {
                                    setSelectedItem(number)
                                    if (number === selectedItem) {
                                      setState({ ...state, use_effective_dates_for_vouchers: name })
                                      allow_zero_valued_transactions_input_ref.current.focus();
                                      allow_zero_valued_transactions_input_ref.current.setSelectionRange(0, 0);
                                      setUseEffective(false)
                                    }
                                  }}
                                >
                                  {index !== -1 ? (
                                    < >
                                      {name.substring(0, index)}
                                      <span style={{ color: 'red' }}>{name.substring(index, index + use_effective_dates_for_vouchers.length)}</span>
                                      {name.substring(index + use_effective_dates_for_vouchers.length)}
                                    </>
                                  ) : (
                                    name
                                  )}
                                </Link>
                              );
                            })
                          ) : (
                            <p style={{ color: 'red' }}>No matching items found</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='d-flex justify-content-between align-item-center position-relative'>
                      <label className='lab-1 text-truncate'>Allow zero-valued transactions  </label>
                      <label> :</label>
                      <input
                        type="text"
                        name='allow_zero_valued_transactions'
                        id='allow_zero_valued_transactions'
                        className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`}
                        onKeyUp={handleChange}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        ref={allow_zero_valued_transactions_input_ref}
                        value={allow_zero_valued_transactions || ""} />
                      <div id="myDropdown-list" className={`dropdown_yes_no_list ${allowZero ? 'show-list' : ''}`} ref={dropdownRef}>
                        <div className='col dropdown_header'>
                          Yes / No
                        </div>
                        <div id='data'>
                          {filteredDataYesNo?.length > 0 ? (
                            filteredDataYesNo?.map((key, number) => {
                              const name = key?.name;
                              const id = key?.id;
                              const index = name?.toLowerCase()?.indexOf(allow_zero_valued_transactions?.toLowerCase());
                              return (
                                <Link
                                  className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                  id='list'
                                  key={number}
                                  onClick={() => {
                                    setSelectedItem(number)
                                    if (number === selectedItem) {
                                      setState({ ...state, allow_zero_valued_transactions: name })
                                      if (['Contra', 'Credit Note', 'Debit Note', 'Payment', 'Purchase', 'Receipt', 'Sales', 'Delivery Note', 'Receipt Note', 'Purchase Order', 'Sales Order', 'Journal', 'Stock Journal'].includes(state?.select_type_of_voucher)) {
                                        make_this_voucher_type_as_optional_by_default_input_ref.current.setSelectionRange(0, 0)
                                        make_this_voucher_type_as_optional_by_default_input_ref.current.focus()
                                      } else {
                                        allow_narration_in_voucher_input_ref.current.setSelectionRange(0, 0)
                                        allow_narration_in_voucher_input_ref.current.focus();
                                      }
                                      setAllowZero(false)
                                    }
                                  }}
                                >
                                  {index !== -1 ? (
                                    < >
                                      {name.substring(0, index)}
                                      <span style={{ color: 'red' }}>{name.substring(index, index + allow_zero_valued_transactions.length)}</span>
                                      {name.substring(index + allow_zero_valued_transactions.length)}
                                    </>
                                  ) : (
                                    name
                                  )}
                                </Link>
                              );
                            })
                          ) : (
                            <p style={{ color: 'red' }}>No matching items found</p>
                          )}
                        </div>
                      </div>
                    </div>
                    {
                      state?.select_type_of_voucher === 'Contra' ||
                        state?.select_type_of_voucher === 'Credit Note' ||
                        state?.select_type_of_voucher === 'Debit Note' ||
                        state?.select_type_of_voucher === 'Payment' ||
                        state?.select_type_of_voucher === 'Purchase' ||
                        state?.select_type_of_voucher === 'Receipt' ||
                        state?.select_type_of_voucher === 'Sales' ||
                        state?.select_type_of_voucher === 'Delivery Note' ||
                        state?.select_type_of_voucher === 'Reciept Note' ||
                        state?.select_type_of_voucher === 'Purchase Order' ||
                        state?.select_type_of_voucher === 'Sales Order' ||
                        state?.select_type_of_voucher === 'Journal' ||
                        state?.select_type_of_voucher === 'Stock Journal'
                        ?
                        <div className='d-flex justify-content-between align-item-center position-relative'>
                          <label className='lab-1 text-truncate'>Make this voucher type as 'Optional' by default  </label>
                          <label> :</label>
                          <input
                            type="text"
                            name='make_this_voucher_type_as_optional_by_default'
                            id='make_this_voucher_type_as_optional_by_default'
                            className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`}
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            ref={make_this_voucher_type_as_optional_by_default_input_ref}
                            value={make_this_voucher_type_as_optional_by_default || ""} />
                          <div id="myDropdown-list" className={`dropdown_yes_no_list ${makeThis ? 'show-list' : ''}`} ref={dropdownRef}>
                            <div className='col dropdown_header'>
                              Yes / No
                            </div>
                            <div id='data'>
                              {filteredDataYesNo?.length > 0 ? (
                                filteredDataYesNo?.map((key, number) => {
                                  const name = key?.name;
                                  const id = key?.id;
                                  const index = name?.toLowerCase()?.indexOf(make_this_voucher_type_as_optional_by_default?.toLowerCase());
                                  return (
                                    <Link
                                      className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                      id='list'
                                      key={number}
                                      onClick={() => {
                                        setSelectedItem(number)
                                        if (number === selectedItem) {
                                          setState({ ...state, make_this_voucher_type_as_optional_by_default: name })
                                          allow_narration_in_voucher_input_ref?.current?.setSelectionRange(0, 0);
                                          allow_narration_in_voucher_input_ref?.current?.focus();
                                          setMakeThis(false)
                                        }
                                      }}
                                    >
                                      {index !== -1 ? (
                                        < >
                                          {name.substring(0, index)}
                                          <span style={{ color: 'red' }}>{name.substring(index, index + make_this_voucher_type_as_optional_by_default.length)}</span>
                                          {name.substring(index + make_this_voucher_type_as_optional_by_default.length)}
                                        </>
                                      ) : (
                                        name
                                      )}
                                    </Link>
                                  );
                                })
                              ) : (
                                <p style={{ color: 'red' }}>No matching items found</p>
                              )}
                            </div>
                          </div>
                        </div> : null
                    }
                    <div className='d-flex justify-content-between align-item-center position-relative'>
                      <label className='lab-1 text-truncate'>Allow narration in voucher </label>
                      <label> :</label>
                      <input
                        type="text"
                        name='allow_narration_in_voucher'
                        id='allow_narration_in_voucher'
                        className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`}
                        onKeyUp={handleChange}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        ref={allow_narration_in_voucher_input_ref}
                        value={allow_narration_in_voucher || ""} />
                      <div id="myDropdown-list" className={`dropdown_yes_no_list ${allowNarration ? 'show-list' : ''}`} ref={dropdownRef}>
                        <div className='col dropdown_header'>
                          Yes / No
                        </div>
                        <div id='data'>
                          {filteredDataYesNo?.length > 0 ? (
                            filteredDataYesNo?.map((key, number) => {
                              const name = key?.name;
                              const id = key?.id;
                              const index = name?.toLowerCase()?.indexOf(allow_narration_in_voucher?.toLowerCase());
                              return (
                                <Link
                                  className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                  id='list'
                                  key={number}
                                  onClick={() => {
                                    setSelectedItem(number)
                                    if (number === selectedItem) {
                                      setState({ ...state, allow_narration_in_voucher: name })
                                      if (
                                        ['Contra', 'Credit Note', 'Debit Note', 'Payment', 'Purchase', 'Receipt', 'Sale', 'Journal'].includes(state?.select_type_of_voucher)
                                      ) {
                                        provide_narration_for_each_ledger_in_voucher_input_ref?.current?.setSelectionRange(0, 0);
                                        provide_narration_for_each_ledger_in_voucher_input_ref?.current?.focus();
                                      } else if (state?.select_type_of_voucher === 'Stock Journal') {
                                        use_a_manufacturing_journal_input_ref?.current?.setSelectionRange(0, 0);
                                        use_a_manufacturing_journal_input_ref?.current?.focus();
                                      } else if (
                                        ['Physical Stock'].includes(state?.select_type_of_voucher)
                                      ) {
                                        track_additional_costs_for_purchases_input_ref?.current?.setSelectionRange(0, 0);
                                        track_additional_costs_for_purchases_input_ref?.current?.focus();
                                      } else {
                                        enable_default_accounting_allocations_input_ref?.current?.setSelectionRange(0, 0);
                                        enable_default_accounting_allocations_input_ref?.current?.focus();
                                      }
                                      setAllowNarration(false)
                                    }
                                  }}
                                >
                                  {index !== -1 ? (
                                    < >
                                      {name.substring(0, index)}
                                      <span style={{ color: 'red' }}>{name.substring(index, index + allow_narration_in_voucher.length)}</span>
                                      {name.substring(index + allow_narration_in_voucher.length)}
                                    </>
                                  ) : (
                                    name
                                  )}
                                </Link>
                              );
                            })
                          ) : (
                            <p style={{ color: 'red' }}>No matching items found</p>
                          )}
                        </div>
                      </div>
                    </div>
                    {
                      state?.select_type_of_voucher === 'Contra' ||
                        state?.select_type_of_voucher === 'Credit Note' ||
                        state?.select_type_of_voucher === 'Debit Note' ||
                        state?.select_type_of_voucher === 'Payment' ||
                        state?.select_type_of_voucher === 'Purchase' ||
                        state?.select_type_of_voucher === 'Receipt' ||
                        state?.select_type_of_voucher === 'Sale' ||
                        state?.select_type_of_voucher === 'Journal'
                        ?
                        <div className='d-flex justify-content-between align-item-center position-relative'>
                          <label className='lab-1 text-truncate'>Provide narration for each ledger in voucher  </label>
                          <label> :</label>
                          <input
                            type="text"
                            name='provide_narration_for_each_ledger_in_voucher'
                            id='provide_narration_for_each_ledger_in_voucher'
                            className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`}
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            ref={provide_narration_for_each_ledger_in_voucher_input_ref}
                            value={provide_narration_for_each_ledger_in_voucher || ""} />
                          <div id="myDropdown-list" className={`dropdown_yes_no_list ${provideNarration ? 'show-list' : ''}`} ref={dropdownRef}>
                            <div className='col dropdown_header'>
                              Yes / No
                            </div>
                            <div id='data'>
                              {filteredDataYesNo?.length > 0 ? (
                                filteredDataYesNo?.map((key, number) => {
                                  const name = key?.name;
                                  const id = key?.id;
                                  const index = name?.toLowerCase()?.indexOf(provide_narration_for_each_ledger_in_voucher?.toLowerCase());
                                  return (
                                    <Link
                                      className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                      id='list'
                                      key={number}
                                      onClick={() => {
                                        setSelectedItem(number)
                                        if (number === selectedItem) {
                                          setState({ ...state, provide_narration_for_each_ledger_in_voucher: name })
                                          if (state?.select_type_of_voucher === 'Stock Journal') {
                                            use_a_manufacturing_journal_input_ref?.current?.setSelectionRange(0, 0)
                                            use_a_manufacturing_journal_input_ref?.current?.focus();

                                          } else if (
                                            ['Debit Note', 'Payment', 'Purchase', 'Physical Stock', 'Stock Journal', 'Journal'].includes(state?.select_type_of_voucher)
                                          ) {
                                            track_additional_costs_for_purchases_input_ref?.current?.setSelectionRange(0, 0)
                                            track_additional_costs_for_purchases_input_ref?.current?.focus();
                                          } else {
                                            enable_default_accounting_allocations_input_ref?.current?.setSelectionRange(0, 0)
                                            enable_default_accounting_allocations_input_ref?.current?.focus();
                                          }
                                          setProvideNarration(false)
                                        }
                                      }}
                                    >
                                      {index !== -1 ? (
                                        < >
                                          {name.substring(0, index)}
                                          <span style={{ color: 'red' }}>{name.substring(index, index + provide_narration_for_each_ledger_in_voucher.length)}</span>
                                          {name.substring(index + provide_narration_for_each_ledger_in_voucher.length)}
                                        </>
                                      ) : (
                                        name
                                      )}
                                    </Link>
                                  );
                                })
                              ) : (
                                <p style={{ color: 'red' }}>No matching items found</p>
                              )}
                            </div>
                          </div>
                        </div> : null
                    }
                    {
                      state?.select_type_of_voucher === 'Stock Journal' ?
                        <div className='d-flex justify-content-between align-item-center position-relative'>
                          <label className='lab-1 text-truncate'>Use a Manufacturing Journal  </label>
                          <label> :</label>
                          <input
                            type="text"
                            name='use_a_manufacturing_journal'
                            id='use_a_manufacturing_journal'
                            className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`}
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            ref={use_a_manufacturing_journal_input_ref}
                            value={use_a_manufacturing_journal || ""} />
                          <div id="myDropdown-list" className={`dropdown_yes_no_list ${useManu ? 'show-list' : ''}`} ref={dropdownRef}>
                            <div className='col dropdown_header'>
                              Yes / No
                            </div>
                            <div id='data'>
                              {filteredDataYesNo?.length > 0 ? (
                                filteredDataYesNo?.map((key, number) => {
                                  const name = key?.name;
                                  const id = key?.id;
                                  const index = name?.toLowerCase()?.indexOf(use_a_manufacturing_journal?.toLowerCase());
                                  return (
                                    <Link
                                      className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                      id='list'
                                      key={number}
                                      onClick={() => {
                                        setSelectedItem(number)
                                        if (number === selectedItem) {
                                          setState({ ...state, use_a_manufacturing_journal: name })
                                          if (['Debit Note', 'Payment', 'Purchase', 'Physical Stock', 'Stock Journal', 'Journal'].includes(state?.select_type_of_voucher)) {
                                            track_additional_costs_for_purchases_input_ref?.current?.setSelectionRange(0, 0)
                                            track_additional_costs_for_purchases_input_ref?.current?.focus();
                                          } else {
                                            enable_default_accounting_allocations_input_ref?.current?.setSelectionRange(0, 0)
                                            enable_default_accounting_allocations_input_ref?.current?.focus();
                                          }
                                          setUseManu(false)
                                        }
                                      }}
                                    >
                                      {index !== -1 ? (
                                        < >
                                          {name.substring(0, index)}
                                          <span style={{ color: 'red' }}>{name.substring(index, index + use_a_manufacturing_journal.length)}</span>
                                          {name.substring(index + use_a_manufacturing_journal.length)}
                                        </>
                                      ) : (
                                        name
                                      )}
                                    </Link>
                                  );
                                })
                              ) : (
                                <p style={{ color: 'red' }}>No matching items found</p>
                              )}
                            </div>
                          </div>
                        </div> : null
                    }
                    {
                      state?.select_type_of_voucher === 'Debit Note' ||
                        state?.select_type_of_voucher === 'Payment' ||
                        state?.select_type_of_voucher === 'Purchase' ||
                        state?.select_type_of_voucher === 'Physical Stock' ||
                        state?.select_type_of_voucher === 'Stock Journal' ||
                        state?.select_type_of_voucher === 'Journal'
                        ?
                        <div className='d-flex justify-content-between align-item-center position-relative'>
                          <label className='lab-1 text-truncate'>Track Additional Costs for Purchases  </label>
                          <label> :</label>
                          <input
                            type="text"
                            name='track_additional_costs_for_purchases'
                            id='track_additional_costs_for_purchases'
                            className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`}
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            ref={track_additional_costs_for_purchases_input_ref}
                            value={track_additional_costs_for_purchases || ""} />
                          <div id="myDropdown-list" className={`dropdown_yes_no_list ${trackAdd ? 'show-list' : ''}`} ref={dropdownRef}>
                            <div className='col dropdown_header'>
                              Yes / No
                            </div>
                            <div id='data'>
                              {filteredDataYesNo?.length > 0 ? (
                                filteredDataYesNo?.map((key, number) => {
                                  const name = key?.name;
                                  const id = key?.id;
                                  const index = name?.toLowerCase()?.indexOf(track_additional_costs_for_purchases?.toLowerCase());
                                  return (
                                    <Link
                                      className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                      id='list'
                                      key={number}
                                      onClick={() => {
                                        setSelectedItem(number)
                                        if (number === selectedItem) {
                                          setState({ ...state, track_additional_costs_for_purchases: name })
                                          if (['Contra', 'Credit Note', 'Debit Note', 'Payment', 'Purchase', 'Receipt', 'Sales', 'Delivery Note', 'Receipt Note', 'Purchase Order', 'Sales Order'].includes(state?.select_type_of_voucher)) {
                                            enable_default_accounting_allocations_input_ref?.current?.setSelectionRange(0, 0)
                                            enable_default_accounting_allocations_input_ref?.current?.focus();
                                          } else {
                                            print_voucher_after_saving_input_ref?.current?.setSelectionRange(0, 0)
                                            print_voucher_after_saving_input_ref?.current?.focus();
                                          }
                                          setTrackAdd(false)
                                        }
                                      }}
                                    >
                                      {index !== -1 ? (
                                        < >
                                          {name.substring(0, index)}
                                          <span style={{ color: 'red' }}>{name.substring(index, index + track_additional_costs_for_purchases.length)}</span>
                                          {name.substring(index + track_additional_costs_for_purchases.length)}
                                        </>
                                      ) : (
                                        name
                                      )}
                                    </Link>
                                  );
                                })
                              ) : (
                                <p style={{ color: 'red' }}>No matching items found</p>
                              )}
                            </div>
                          </div>
                        </div>
                        : null
                    }
                    {
                      state?.select_type_of_voucher === 'Contra' ||
                        state?.select_type_of_voucher === 'Credit Note' ||
                        state?.select_type_of_voucher === 'Debit Note' ||
                        state?.select_type_of_voucher === 'Payment' ||
                        state?.select_type_of_voucher === 'Purchase' ||
                        state?.select_type_of_voucher === 'Receipt' ||
                        state?.select_type_of_voucher === 'Sales' ||
                        state?.select_type_of_voucher === 'Delivery Note' ||
                        state?.select_type_of_voucher === 'Reciept Note' ||
                        state?.select_type_of_voucher === 'Purchase Order' ||
                        state?.select_type_of_voucher === 'Sales Order'
                        ?
                        <>
                          <div className='d-flex justify-content-between align-item-center position-relative'>
                            <label className='lab-1 text-truncate'>Enable defaul accounting allocation  </label>
                            <label> :</label>
                            <input
                              type="text"
                              name='enable_default_accounting_allocations'
                              id='enable_default_accounting_allocations'
                              className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={enable_default_accounting_allocations_input_ref}
                              value={enable_default_accounting_allocations || ""} />
                            <div id="myDropdown-list" className={`dropdown_yes_no_list ${enableDefault ? 'show-list' : ''}`} ref={dropdownRef}>
                              <div className='col dropdown_header'>
                                Yes / No
                              </div>
                              <div id='data'>
                                {filteredDataYesNo?.length > 0 ? (
                                  filteredDataYesNo?.map((key, number) => {
                                    const name = key?.name;
                                    const id = key?.id;
                                    const index = name?.toLowerCase()?.indexOf(enable_default_accounting_allocations?.toLowerCase());
                                    return (
                                      <Link
                                        className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                        id='list'
                                        key={number}
                                        onClick={() => {
                                          setSelectedItem(number)
                                          if (number === selectedItem) {
                                            setState({ ...state, enable_default_accounting_allocations: name })
                                            if (name === 'Yes') {
                                              set_alter_default_accounting_entries_input_ref?.current?.setSelectionRange(0, 0)
                                              set_alter_default_accounting_entries_input_ref?.current?.focus();
                                            } else {
                                              print_voucher_after_saving_input_ref?.current?.setSelectionRange(0, 0)
                                              print_voucher_after_saving_input_ref?.current?.focus();
                                            }
                                            setEnableDefault(false)
                                          }
                                        }}
                                      >
                                        {index !== -1 ? (
                                          < >
                                            {name.substring(0, index)}
                                            <span style={{ color: 'red' }}>{name.substring(index, index + enable_default_accounting_allocations.length)}</span>
                                            {name.substring(index + enable_default_accounting_allocations.length)}
                                          </>
                                        ) : (
                                          name
                                        )}
                                      </Link>
                                    );
                                  })
                                ) : (
                                  <p style={{ color: 'red' }}>No matching items found</p>
                                )}
                              </div>
                            </div>
                          </div>
                          {
                            enable_default_accounting_allocations == 'Yes' ?
                              <div className='d-flex justify-content-between align-item-center position-relative'>
                                <label className='lab-1 text-truncate'>Set/Alter default Accounting Entries </label>
                                <label> :</label>
                                <input
                                  type="text"
                                  name='set_alter_default_accounting_entries'
                                  id='set_alter_default_accounting_entries'
                                  className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`}
                                  onKeyUp={handleChange}
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  ref={set_alter_default_accounting_entries_input_ref}
                                  value={set_alter_default_accounting_entries || ""} />
                                <div id="myDropdown-list" className={`dropdown_yes_no_list ${setAlterEntries ? 'show-list' : ''}`} ref={dropdownRef}>
                                  <div className='col dropdown_header'>
                                    Yes / No
                                  </div>
                                  <div id='data'>
                                    {filteredDataYesNo?.length > 0 ? (
                                      filteredDataYesNo?.map((key, number) => {
                                        const name = key?.name;
                                        const id = key?.id;
                                        const index = name?.toLowerCase()?.indexOf(set_alter_default_accounting_entries?.toLowerCase());
                                        return (
                                          <Link
                                            className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                            id='list'
                                            key={number}
                                            onClick={() => {
                                              setSelectedItem(number)
                                              if (number === selectedItem) {
                                                setState({ ...state, set_alter_default_accounting_entries: name })
                                                print_voucher_after_saving_input_ref?.current?.setSelectionRange(0, 0)
                                                print_voucher_after_saving_input_ref?.current?.focus();
                                                setSetAlterEntries(false)
                                              }
                                            }}
                                          >
                                            {index !== -1 ? (
                                              < >
                                                {name.substring(0, index)}
                                                <span style={{ color: 'red' }}>{name.substring(index, index + set_alter_default_accounting_entries.length)}</span>
                                                {name.substring(index + set_alter_default_accounting_entries.length)}
                                              </>
                                            ) : (
                                              name
                                            )}
                                          </Link>
                                        );
                                      })
                                    ) : (
                                      <p style={{ color: 'red' }}>No matching items found</p>
                                    )}
                                  </div>
                                </div>
                              </div> : null
                          }
                        </>
                        : null
                    }

                  </div>
                  <div className='col-4 border'>
                    <div className='row'>
                      <div className='col text-decoration-underline'>
                        <b>Printing</b>
                      </div>
                    </div>
                    <div className='d-flex justify-content-between align-item-center position-relative'>
                      <label className='lab-1 text-truncate'>Print voucher after saving </label>
                      <label> :</label>
                      <input
                        type="text"
                        name='print_voucher_after_saving'
                        id='print_voucher_after_saving'
                        className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`}
                        onKeyUp={handleChange}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        ref={print_voucher_after_saving_input_ref}
                        value={print_voucher_after_saving || ""} />
                      <div id="myDropdown-list" className={`dropdown_yes_no_list ${printVch ? 'show-list' : ''}`} ref={dropdownRef}>
                        <div className='col dropdown_header'>
                          Yes / No
                        </div>
                        <div id='data'>
                          {filteredDataYesNo?.length > 0 ? (
                            filteredDataYesNo?.map((key, number) => {
                              const name = key?.name;
                              const id = key?.id;
                              const index = name?.toLowerCase()?.indexOf(print_voucher_after_saving?.toLowerCase());
                              return (
                                <Link
                                  className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                  id='list'
                                  key={number}
                                  onClick={() => {
                                    setSelectedItem(number)
                                    if (number === selectedItem) {
                                      setState({ ...state, print_voucher_after_saving: name })
                                      if (name === 'Yes') {
                                        set_alter_default_accounting_entries_input_ref?.current?.setSelectionRange(0, 0)
                                        set_alter_default_accounting_entries_input_ref?.current?.focus();
                                      } else {
                                        print_voucher_after_saving_input_ref?.current?.setSelectionRange(0, 0)
                                        print_voucher_after_saving_input_ref?.current?.focus();
                                      }
                                      setPrintVch(false)
                                    }
                                  }}
                                >
                                  {index !== -1 ? (
                                    < >
                                      {name.substring(0, index)}
                                      <span style={{ color: 'red' }}>{name.substring(index, index + print_voucher_after_saving.length)}</span>
                                      {name.substring(index + print_voucher_after_saving.length)}
                                    </>
                                  ) : (
                                    name
                                  )}
                                </Link>
                              );
                            })
                          ) : (
                            <p style={{ color: 'red' }}>No matching items found</p>
                          )}
                        </div>
                      </div>
                    </div>
                    {
                      state?.select_type_of_voucher === 'Sales' ?
                        <div>
                          <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1 text-truncate'>Use for POS invoicing </label>
                            <label> :</label>
                            <input
                              type="text"
                              list='ufpi'
                              name='use_for_pos_invoicing'
                              id='use_for_pos_invoicing'
                              className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={use_for_pos_invoicing_input_ref}
                              value={use_for_pos_invoicing || ""} />
                            <datalist id='ufpi'>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </datalist>
                          </div>
                          {
                            use_for_pos_invoicing == 'Yes' ?
                              <div>
                                <div className='d-flex justify-content-between align-item-center'>
                                  <label className='lab-1 text-truncate'>Message to print (1) </label>
                                  <label> :</label>
                                  <input
                                    type="text"
                                    name='message_to_print_1'
                                    id='message_to_print_1'
                                    className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`}
                                    onKeyUp={handleChange}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    ref={message_to_print_1_input_ref}
                                    value={message_to_print_1 || ""} />
                                </div>
                                <div className='d-flex justify-content-between align-item-center'>
                                  <label className='lab-1 text-truncate'>Message to print (2) </label>
                                  <label> :</label>
                                  <input
                                    type="text"
                                    name='message_to_print_2'
                                    id='message_to_print_2'
                                    className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`}
                                    onKeyUp={handleChange}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    ref={message_to_print_2_input_ref}
                                    value={message_to_print_2 || ""} />
                                </div>
                              </div> : null
                          }
                        </div> : null
                    }
                    {
                      state?.select_type_of_voucher === 'Sales' ?
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1 text-truncate'>Default Bank </label>
                          <label> :</label>
                          <input
                            type="text"
                            name='default_bank'
                            id='default_bank'
                            className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`}
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            ref={default_bank_input_ref}
                            value={default_bank || ""} />
                        </div> : null
                    }
                    {
                      state?.select_type_of_voucher === 'Purchase Order'
                        ?
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1 text-truncate'>Default Jurisdiction </label>
                          <label> :</label>
                          <input
                            type="text"
                            name='default_jurisdiction'
                            id='default_jurisdiction'
                            className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`}
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            ref={default_jurisdiction_input_ref}
                            value={default_jurisdiction || ""} />
                        </div> : null
                    }
                    {
                      state?.select_type_of_voucher === 'Credit Note' ||
                        state?.select_type_of_voucher === 'Sales' ||
                        state?.select_type_of_voucher === 'Delivery Note'
                        ?
                        <div>
                          <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1 text-truncate'>Default Jurisdiction </label>
                            <label> :</label>
                            <input
                              type="text"
                              name='default_jurisdiction'
                              id='default_jurisdiction'
                              className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={default_jurisdiction_input_ref}
                              value={default_jurisdiction || ""} />
                          </div>
                          <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1 text-truncate'>Default Title to print </label>
                            <label> :</label>
                            <input
                              type="text"
                              name='default_title_to_print'
                              id='default_title_to_print'
                              className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={default_title_to_print_input_ref}
                              value={default_title_to_print || ""} />
                          </div>
                        </div> : null
                    }
                    {
                      state?.select_type_of_voucher === 'Debit Note' ?
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1 text-truncate'>Default Title to print </label>
                          <label> :</label>
                          <input
                            type="text"
                            name='default_title_to_print'
                            id='default_title_to_print'
                            className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`}
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            ref={default_title_to_print_input_ref}
                            value={default_title_to_print || ""} />
                        </div> : null
                    }
                    {
                      state?.select_type_of_voucher === 'Receipt' ?
                        <div className='d-flex justify-content-between align-item-center'>
                          <label className='lab-1 text-truncate'>Print formal receipt after saving </label>
                          <label> :</label>
                          <input
                            type="text"
                            name='print_formal_receipt_after_saving'
                            id='print_formal_receipt_after_saving'
                            className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`}
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            ref={print_formal_receipt_after_saving_input_ref}
                            value={print_formal_receipt_after_saving || ""} />
                        </div> : null
                    }
                    {
                      state?.select_type_of_voucher === 'Purchase Order' ||
                        state?.select_type_of_voucher === 'Sales' ||
                        state?.select_type_of_voucher === 'Sales Order'
                        ?
                        <div className='d-flex justify-content-between align-item-center position-relative'>
                          <label className='lab-1 text-truncate'>Set/alter declaration </label>
                          <label> :</label>
                          <input
                            type="text"
                            name='set_alter_declaration'
                            id='set_alter_declaration'
                            className={`lab-right ${isVoucherTypeNameEntered ? '' : 'disable-field'}`}
                            onKeyUp={handleChange}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            ref={set_alter_declaration_input_ref}
                            value={set_alter_declaration || ""} />
                          <div id="myDropdown-list" className={`dropdown_yes_no_list ${setAltDec ? 'show-list' : ''}`} ref={dropdownRef}>
                            <div className='col dropdown_header'>
                              Yes / No
                            </div>
                            <div id='data'>
                              {filteredDataYesNo?.length > 0 ? (
                                filteredDataYesNo?.map((key, number) => {
                                  const name = key?.name;
                                  const id = key?.id;
                                  const index = name?.toLowerCase()?.indexOf(set_alter_declaration?.toLowerCase());
                                  return (
                                    <Link
                                      className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                      id='list'
                                      key={number}
                                      onClick={() => {
                                        setSelectedItem(number)
                                        if (number === selectedItem) {
                                          setState({ ...state, set_alter_declaration: name })
                                          if (name === 'Yes') {
                                            set_alter_default_accounting_entries_input_ref?.current?.setSelectionRange(0, 0)
                                            set_alter_default_accounting_entries_input_ref?.current?.focus();
                                          } else {
                                            print_voucher_after_saving_input_ref?.current?.setSelectionRange(0, 0)
                                            print_voucher_after_saving_input_ref?.current?.focus();
                                          }
                                          setSetAltDec(false)
                                        }
                                      }}
                                    >
                                      {index !== -1 ? (
                                        < >
                                          {name.substring(0, index)}
                                          <span style={{ color: 'red' }}>{name.substring(index, index + set_alter_declaration.length)}</span>
                                          {name.substring(index + set_alter_declaration.length)}
                                        </>
                                      ) : (
                                        name
                                      )}
                                    </Link>
                                  );
                                })
                              ) : (
                                <p style={{ color: 'red' }}>No matching items found</p>
                              )}
                            </div>
                          </div>
                        </div> : null
                    }

                  </div>
                  <div className='col-2 border'>
                    <div className='row'>
                      <div className='col text-decoration-underline'>
                        <b>Name of class</b>
                        {
                          state?.select_type_of_voucher === 'Contra' ||
                            state?.select_type_of_voucher === 'Credit Note' ||
                            state?.select_type_of_voucher === 'Debit Note' ||
                            state?.select_type_of_voucher === 'Delivery Note' ||
                            state?.select_type_of_voucher === 'Payment' ||
                            state?.select_type_of_voucher === 'Purchase' ||
                            state?.select_type_of_voucher === 'Purchase Order' ||
                            state?.select_type_of_voucher === 'Receipt' ||
                            state?.select_type_of_voucher === 'Reciept Note' ||
                            state?.select_type_of_voucher === 'Sales' ||
                            state?.select_type_of_voucher === 'Sales Order'
                            ?
                            <input
                              type="text"
                              name='name_of_class'
                              id='name_of_class'
                              className={`input_name_of_class ${isVoucherTypeNameEntered ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={name_of_class_input_ref}
                              value={name_of_class || ""}
                            /> :
                            null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {
                defineNumberModal ? null :
                  <MasterCreationFooter
                    setHash={setHashtag}
                  />
              }
            </form>
          </div>
        </div>
      </div >
      <div className='help'>
      </div>
    </div >
  )
}

export default VouchersType;




