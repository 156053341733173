import { useEffect, useRef } from 'react';

/**
 * Custom hook to detect clicks outside of a specified element.
 * @param {React.RefObject} targetRef - The reference to the target element (input, div, etc.)
 * @param {Function} onClickOutside - Callback function to execute when a click outside occurs
 * @param {Function} onClickInside - Callback function to execute when a click inside the element occurs
 */
const useClickOutside = (targetRef, onClickOutside, onClickInside = () => { }) => {
  const isMouseDownInside = useRef(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      let clickedElement = event.target;
      let isInputField = false;

      // Check if the clicked element is inside the target element
      while (clickedElement) {
        if (clickedElement?.tagName &&
          (clickedElement?.tagName.toLowerCase() === 'input' ||
            clickedElement?.tagName.toLowerCase() === 'textarea')) {
          isInputField = true;
          break;
        }
        clickedElement = clickedElement?.parentNode;
      }

      if (isInputField) {
        onClickInside(event); // Execute when the click is inside
      } else {
        onClickOutside(event); // Execute when the click is outside
      }
    };

    const handleMouseDown = () => {
      isMouseDownInside.current = true;
    };

    const handleMouseUp = () => {
      isMouseDownInside.current = false;
    };

    // Attach event listeners for mousedown and mouseup
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);

    // Cleanup event listeners on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [targetRef, onClickOutside, onClickInside]);
};

export default useClickOutside;
