import React, { useEffect, useState, useRef } from 'react';
import Country1 from '../../../../K_Company/Country.json';
import State from '../../../../K_Company/State.json';
import Footer from '../../../../../components/home_page/Footer';
import { useSelector, useDispatch } from 'react-redux';
import './ledger.css';
import FormData from 'form-data';
import { Link } from 'react-router-dom';
import { groupListCreate, ledgerCreate } from '../../../../../redux/actions/action-creator';
import { ToastContainer, toast } from 'react-toastify';
import YesNoModal from '../../../../../components/model/YesNoModal';
import DropdownLayout from '../../../../../components/Dropdown/DropdownLayout';
import MasterCreationFooter from '../../../../../components/Footer/Master_Footer/MasterCreationFooter';
const typesOfLederList = [{ id: 1, name: 'Not Applicable' }, { id: 2, name: 'Discount' }, { id: 3, name: 'Invoice Rounding' }]
const registrationType = [{ id: 1, name: 'Unkown' }, { id: 2, name: 'Composition' }, { id: 3, name: 'Regular' }, { id: 4, name: 'Unregistered' }]
const listOfMethod = [{ id: 1, name: 'Not Applicable' }, { id: 2, name: 'Appropriate by Qty' }, { id: 3, name: 'Appropriate by Value' }]
const yesNo = [{ id: 1, name: 'No' }, { id: 2, name: 'Yes' }]
const is_gst_applicable_array =
  [
    { name: 'Applicable' },
    { name: 'Not Applicable' },
  ]
const yes_no_array =
  [
    { name: 'Yes' },
    { name: 'No' },
  ]
const taxability_array =
  [
    { name: 'Exempt' },
    { name: 'Nill Rated' },
    { name: 'None-Gst' },
    { name: 'Taxable' },
  ]
const hsn_detail_gst_rate_array =
  [
    { name: 'As per Company/Stock Group' },
    { name: 'Specify Details Here' },
    { name: 'Specify in Voucher' },
  ]
const type_of_supply_array =
  [
    { name: 'Capital Goods' },
    { name: 'Goods' },
    { name: 'Services' },
  ]


const Ledger = (props) => {
  const dispatch = useDispatch();
  const { userData = null } = useSelector(state => state.auth);
  const { group_all_list = [] } = useSelector(state => state.common);
  const [loading, setLoading] = useState(false)
  const [backspaceCount, setBackspaceCount] = useState(0)
  const [hashTag, setHashtag] = useState(false)
  const [filteredData, setFilteredData] = useState([]);
  const [filteredDataYesNo, setFilteredDataYesNo] = useState([])
  const [filteredDataIsGstApplicable, setFilteredDataIsGstApplicable] = useState([])
  const [filteredDataTypeOfSupply, setFilteredDataTypeOfSupply] = useState([])
  const [filteredDataHsnDetailGstRate, setFilteredDataHsnDetailGstRate] = useState([])
  const [filteredDataTaxability, setFilteredDataTaxability] = useState([])
  const [nameInput, setNameInput] = useState(null)
  const isMouseDownInside = useRef(false);
  const dropdownRef = useRef(null)
  const [ledgerSubmitConfirm, setLedgerSubitConfirm] = useState('')
  // dropdown state 
  const [showSetAlterRange, setShowSetAlterRange] = useState(false)
  const [showEnableCheqPrinting, setShowEnableCheqPrinting] = useState(false)
  const [showSetAlterCheqPrinting, setShowSetAlterCheqPrinting] = useState(false)
  const [showUseAsNational, setShowUseAsNational] = useState(false)
  const [showMaintainBalance, setShowMaintainBalance] = useState(false)
  const [showInventoryValue, setShowInventoryValue] = useState(false)
  const [showCheckForCredit, setShowCheckForCredit] = useState(false)
  const [showBehaveAsDuties, setShowBehaveAsDuties] = useState(false)
  const [isGstAppShow, setIsGstAppShow] = useState(false)
  const [hsnSacDetailShow, setHsnSacDetailShow] = useState(false)
  const [gstRateDetailsShow, setGstRateDetailsShow] = useState(false)
  const [taxabilityShow, setTaxabilityShow] = useState(false)
  const [showIgnorePrefix, setShowIgnorePrefix] = useState(false)
  const [showSetAlterAdditional, setShowAlterAdditional] = useState(false)
  const [showProvideBankDetail, setShowProvideBankDetail] = useState(false)
  const [showProvideContact, setShowProvideContact] = useState(false)
  const [typeOfSupplyShow, setTypeOfSupplyShow] = useState(false)
  const [applicableForResShow, setApplicableForResShow] = useState(false)
  const [eligibleForTaxshow, setEligibleForTaxShow] = useState(false)


  const [showList, setShowList] = useState(false);
  const [showListMaintain, setShowListMaintain] = useState(false);
  const [showListTypeOfLedger, setShowListTypeOfLedger] = useState(false);
  const [showListState, setShowListState] = useState(false);
  const [showListCountry, setShowListCountry] = useState(false);
  const [showListRegistrationType, setShowListRegistrationType] = useState(false);
  const [showListCheckForCredit, setShowListCheckForCredit] = useState(false);
  const [showListInventory, setShowListInventory] = useState(false);
  const [isLedgerNameEnterd, setIsLedgerNameEntered] = useState(false);
  const [state, setState] = useState(
    {
      account_no: '',
      ledger_name: '',
      alise_name: '',
      under: userData?.group_data[1]?.name,
      group_id: userData?.group_data[1]?.id,
      effective_date_for_reconciliation: '',
      set_od_limit: '',
      maintain_balance_bill_by_bill: 'No',
      default_credit_period: '',
      specify_credit_limit: '',
      inventry_values_are_affected: 'No',
      account_holder_name: '',
      account_number: '',
      ifsc_code: '',
      swift_code: '',
      bank_name: 'Not Applicable',
      branch: '',
      set_alter_range_for_cheque_book: 'No',
      enable_cheque_printing: 'No',
      set_alter_cheque_printing_configuration: 'No',
      use_as_national_bank_for_post_dated_vouchers: 'No',
      behave_as_duties_and_taxes_ledger: '',
      type_of_duty_tax: '',
      tax_type: '',
      valuation_type: '',
      rate_per_unit: '',
      percentage_of_calculation: '',
      rounding_method: '',
      rounding_limit: '',
      is_gst_applicable: 'Not Applicable',
      set_alter_gst_details: '',
      include_in_assessable_value_calculation_for: 'Not Applicable',
      type_of_ledger: 'Not Applicable',
      hsn_sac_details: '',
      source_of_details: '',
      hsn_sac: '',
      description: '',
      gst_rate_details: '',
      taxability_type: '',
      nature_of_transaction: '',
      igst_rate: '',
      cgst_rate: '',
      sgst_utst_rate: '',
      cess_valuation_type: '',
      cess_rate: '',
      applicable_for_reverse_charge: '',
      eligible_for_tax_input_credit: '',
      type_of_supply: '',
      check_for_credit_days_during_voucher_entry: '',
      method_of_appropriation_to_allocate_additional_costs_in_purchase_invoice: 'Not Applicable',
      mailing_name: '',
      address1: '',
      state_name: '',
      country_name: 'India',
      pincode: '',
      mobile: '',
      provide_contact_details: 'No',
      provide_bank_details: 'No',
      pan_ItNo: '',
      registration_type: '',
      assessee_of_other_territory: '',
      gstin_uin: '',
      use_ledger_as_common_party: '',
      set_alter_additional_gst_details: '',
      email: '',
      aadhaar: '',
      transport_detail: '',
      ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation: '',
      opening_balance: '',
      type: '',
    }
  );
  const [click, setClick] = useState(0)

  // create muted object every input field ================================
  const account_no_input_ref = useRef(null)
  const ledger_name_input_ref = useRef(null);
  const alise_name_input_ref = useRef(null);
  const under_input_ref = useRef(null);
  const effective_date_for_reconciliation_input_ref = useRef(null);
  const set_od_limit_input_ref = useRef(null);
  const maintain_balance_bill_by_bill_input_ref = useRef(null);
  const default_credit_period_input_ref = useRef(null);
  const specify_credit_limit_input_ref = useRef(null);
  const inventry_values_are_affected_input_ref = useRef(null);
  const account_holder_name_input_ref = useRef(null);
  const account_number_input_ref = useRef(null);
  const ifsc_code_input_ref = useRef(null);
  const swift_code_input_ref = useRef(null);
  const bank_name_input_ref = useRef(null);
  const branch_input_ref = useRef(null);
  const set_alter_range_for_cheque_book_input_ref = useRef(null);
  const enable_cheque_printing_input_ref = useRef(null);
  const set_alter_cheque_printing_configuration_input_ref = useRef(null);
  const use_as_national_bank_for_post_dated_vouchers_input_ref = useRef(null);
  const behave_as_duties_and_taxes_ledger_input_ref = useRef(null);
  const type_of_duty_tax_input_ref = useRef(null);
  const tax_type_input_ref = useRef(null);
  const valuation_type_input_ref = useRef(null);
  const rate_per_unit_input_ref = useRef(null);
  const percentage_of_calculation_input_ref = useRef(null);
  const rounding_method_input_ref = useRef(null);
  const rounding_limit_input_ref = useRef(null);
  const is_gst_applicable_input_ref = useRef(null);
  const set_alter_gst_details_input_ref = useRef(null);
  const include_in_assessable_value_calculation_for_input_ref = useRef(null);
  const type_of_ledger_input_ref = useRef(null);
  const hsn_sac_details_input_ref = useRef(null);
  const gst_rate_details_input_ref = useRef(null);
  const source_of_details_input_ref = useRef(null);
  const hsn_sac_input_ref = useRef(null);
  const description_input_ref = useRef(null);
  const taxability_type_input_ref = useRef(null);
  const nature_of_transaction_input_ref = useRef(null);
  const igst_rate_input_ref = useRef(null);
  const cgst_rate_input_ref = useRef(null);
  const sgst_utst_rate_input_ref = useRef(null);
  const cess_valuation_type_input_ref = useRef(null);
  const cess_rate_input_ref = useRef(null);
  const applicable_for_reverse_charge_input_ref = useRef(null);
  const eligible_for_tax_input_credit_input_ref = useRef(null);
  const type_of_supply_input_ref = useRef(null);
  const check_for_credit_days_during_voucher_entry_input_ref = useRef(null);
  const method_of_appropriation_to_allocate_additional_costs_in_purchase_invoice_input_ref = useRef(null);
  const mailing_name_input_ref = useRef(null);
  const address1_input_ref = useRef(null);
  const state_name_input_ref = useRef(null);
  const country_name_input_ref = useRef(null);
  const pincode_input_ref = useRef(null);
  const mobile_input_ref = useRef(null);
  const provide_contact_details_input_ref = useRef(null);
  const provide_bank_details_input_ref = useRef(null);
  const pan_ItNo_input_ref = useRef(null);
  const registration_type_input_ref = useRef(null);
  const assessee_of_other_territory_input_ref = useRef(null);
  const gstin_uin_input_ref = useRef(null);
  const use_ledger_as_common_party_input_ref = useRef(null);
  const set_alter_additional_gst_details_input_ref = useRef(null);
  const ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation_input_ref = useRef(null);
  const opening_balance_input_ref = useRef(null);
  const type_input_ref = useRef(null);

  // get data of group list ---------------------------------

  const [selectedItem, setSelectedItem] = useState(0);
  const {
    ledger_name, alise_name, under, group_id, effective_date_for_reconciliation, set_od_limit, maintain_balance_bill_by_bill, default_credit_period, specify_credit_limit, inventry_values_are_affected, account_holder_name, account_number, ifsc_code, swift_code,
    bank_name, branch, set_alter_range_for_cheque_book, enable_cheque_printing, set_alter_cheque_printing_configuration, use_as_national_bank_for_post_dated_vouchers, behave_as_duties_and_taxes_ledger, type_of_duty_tax,
    tax_type, valuation_type, rate_per_unit, percentage_of_calculation, rounding_method, rounding_limit, is_gst_applicable, set_alter_gst_details, include_in_assessable_value_calculation_for, type_of_ledger, hsn_sac_details,
    gst_rate_details, source_of_details, hsn_sac, description, taxability_type, nature_of_transaction, igst_rate, cgst_rate, sgst_utst_rate, cess_valuation_type, cess_rate,
    applicable_for_reverse_charge, eligible_for_tax_input_credit, type_of_supply, check_for_credit_days_during_voucher_entry, method_of_appropriation_to_allocate_additional_costs_in_purchase_invoice, mailing_name,
    address1, state_name, country_name, pincode, mobile, provide_contact_details, provide_bank_details,
    pan_ItNo, registration_type, assessee_of_other_territory, gstin_uin, use_ledger_as_common_party,
    set_alter_additional_gst_details, ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation, opening_balance, type
  } = state;
  const getNextInputRef = (name, value) => {
    switch (name) {
      case 'ledger_name': return alise_name_input_ref;
      case 'alise_name': return under_input_ref;
      case 'under':
        return under === 'Bank Account' ||
          under === 'Bank OD A/C' ?
          effective_date_for_reconciliation_input_ref :
          under === 'Branch/Divisions' ||
            under === 'Sundry Creditors' ||
            under === 'Sundry Debtors' ||
            under === 'Capital Account' ||
            under === 'Reserves & Surplus' ||
            under === 'Current Assets' ||
            under === 'Fixed Assets' ||
            under === 'Indirect Expenses' ||
            under === 'Indirect Incomes' ||
            under === 'Deposits(Asset)' ||
            under === 'Current Liabilities' ||
            under === 'Direct Expenses' ||
            under === 'Loans(Liability)' ||
            under === 'Investments' ||
            under === 'Provisions' ||
            under === 'Purchase Accounts' ||
            under === 'Sales Accounts' ||
            under === 'Misc. Expenses' ||
            under === 'Secured Loans' ||
            under === 'Unsecured Loans' ||
            under === 'Suspense A/C' ||
            under === 'Loans & Advance (Asset)' ||
            under === 'Direct Incomes' ?
            maintain_balance_bill_by_bill_input_ref :
            under === 'Cash-in-Hand' ?
              mailing_name_input_ref :
              under === 'Stock-in-Hand' ? opening_balance_input_ref : null;
      case 'effective_date_for_reconciliation':
        return under === 'Bank Account' ?
          account_holder_name_input_ref :
          set_od_limit_input_ref;
      case 'set_od_limit': return bank_name_input_ref
      case 'maintain_balance_bill_by_bill':
        return maintain_balance_bill_by_bill === 'Yes' ?
          default_credit_period_input_ref :
          under === 'Capital Account' ||
            under === 'Deposits(Asset)' ||
            under === 'Fixed Assets' ||
            under === 'Investments' ||
            under === 'Loans & Advance (Asset)' ||
            under === 'Loans(Liability)' ||
            under === 'Misc. Expenses' ||
            under === 'Provisions' ||
            under === 'Secured Loans' ||
            under === 'Unsecured Loans' ||
            under === 'Reserves & Surplus' ?
            mailing_name_input_ref :
            under === 'Current Assets' ||
              under === 'Current Liabilities' ?
              method_of_appropriation_to_allocate_additional_costs_in_purchase_invoice_input_ref :
              under === 'Direct Expenses' ||
                under === 'Indirect Expenses' ||
                under === 'Indirect Incomes' ||
                under === 'Direct Incomes' ?
                type_of_ledger_input_ref :
                under === 'Purchase Accounts' ||
                  under === 'Sales Accounts' ||
                  under === 'Suspense A/C' ?
                  opening_balance_input_ref :
                  specify_credit_limit_input_ref
      case 'default_credit_period':
        return under === 'Capital Account' ||
          under === 'Deposits(Asset)' ||
          under === 'Reserves & Surplus' ||
          under === 'Loans & Advance (Asset)' ||
          under === 'Loans(Liability)' ||
          under === 'Misc. Expenses' ||
          under === 'Provisions' ||
          under === 'Secured Loans' ||
          under === 'Unsecured Loans' ||
          under === 'Fixed Assets' ?
          mailing_name_input_ref :
          under === 'Current Assets' ||
            under === 'Current Liabilities' ?
            method_of_appropriation_to_allocate_additional_costs_in_purchase_invoice_input_ref :
            under === 'Direct Expenses' ||
              under === 'Indirect Expenses' ||
              under === 'Indirect Incomes' ||
              under === 'Direct Incomes' ?
              type_of_ledger_input_ref :
              under === 'Purchase Accounts' ||
                under === 'Sales Accounts' ||
                under === 'Suspense A/C' ?
                opening_balance_input_ref :
                check_for_credit_days_during_voucher_entry_input_ref
      case 'specify_credit_limit': return mailing_name_input_ref
      case 'inventry_values_are_affected': return;
      case 'account_holder_name': return account_number_input_ref;
      case 'account_number': return ifsc_code_input_ref;
      case 'ifsc_code': return swift_code_input_ref;
      case 'swift_code': return bank_name_input_ref;
      case 'bank_name': return branch_input_ref;
      case 'branch': return set_alter_range_for_cheque_book_input_ref;
      case 'set_alter_range_for_cheque_book': return enable_cheque_printing_input_ref;
      case 'enable_cheque_printing':
        return enable_cheque_printing === 'Yes' ?
          set_alter_cheque_printing_configuration_input_ref :
          use_as_national_bank_for_post_dated_vouchers_input_ref
      case 'set_alter_cheque_printing_configuration': return use_as_national_bank_for_post_dated_vouchers_input_ref
      case 'use_as_national_bank_for_post_dated_vouchers':
        return mailing_name_input_ref;
      case 'behave_as_duties_and_taxes_ledger': return;
      case 'type_of_duty_tax': return;
      case 'tax_type': return;
      case 'valuation_type': return;
      case 'rate_per_unit': return;
      case 'percentage_of_calculation': return;
      case 'rounding_method': return rounding_limit_input_ref
      case 'rounding_limit': return mailing_name_input_ref
      case 'is_gst_applicable': return;
      case 'set_alter_gst_details': return;
      case 'include_in_assessable_value_calculation_for': return;
      case 'type_of_ledger':
        return under === 'Indirect Expenses' ||
          under === 'Indirect Incomes' ? rounding_method_input_ref : method_of_appropriation_to_allocate_additional_costs_in_purchase_invoice_input_ref
      case 'hsn_sac_details': return
      case 'source_of_details': return
      case 'hsn_sac': return
      case 'description': return
      case 'gst_rate_details': return
      case 'taxability_type': return
      case 'nature_of_transaction': return
      case 'igst_rate': return
      case 'cgst_rate': return
      case 'sgst_utst_rate': return
      case 'cess_valuation_type': return
      case 'cess_rate': return
      case 'applicable_for_reverse_charge': return
      case 'eligible_for_tax_input_credit': return
      case 'type_of_supply': return
      case 'check_for_credit_days_during_voucher_entry': return specify_credit_limit_input_ref
      case 'method_of_appropriation_to_allocate_additional_costs_in_purchase_invoice':
        return (under === 'Direct Expenses' || under === 'Direct Incomes') && type_of_ledger === 'Invoice Rounding' ? rounding_method_input_ref : mailing_name_input_ref
      case 'mailing_name': return address1_input_ref
      case 'address1':
        return under === 'Cash-in-Hand' || under === 'Provisions' || under === 'Reserves & Surplus' || under === 'Misc. Expenses' ? mobile_input_ref : state_name_input_ref
      case 'state_name': return country_name_input_ref
      case 'country_name': return pincode_input_ref
      case 'pincode': return mobile_input_ref
      case 'mobile': return provide_contact_details_input_ref
      case 'provide_contact_details':
        return under === 'Branch/Divisions' ||
          under === 'Capital Account' ||
          under === 'Sundry Creditors' ||
          under === 'Sundry Debtors' ||
          under === 'Reserves & Surplus' ||
          under === 'Cash-in-Hand' ||
          under === 'Current Assets' ||
          under === 'Fixed Assets' ||
          under === 'Deposits(Asset)' ||
          under === 'Current Liabilities' ||
          under === 'Direct Expenses' ||
          under === 'Indirect Expenses' ||
          under === 'Indirect Incomes' ||
          under === 'Investments' ||
          under === 'Loans & Advance (Asset)' ||
          under === 'Loans(Liability)' ||
          under === 'Misc. Expenses' ||
          under === 'Provisions' ||
          under === 'Secured Loans' ||
          under === 'Unsecured Loans' ||
          under === 'Direct Incomes' ?
          provide_bank_details_input_ref :
          opening_balance_input_ref
      case 'provide_bank_details': return pan_ItNo_input_ref
      case 'pan_ItNo': return opening_balance_input_ref
      case 'registration_type': return
      case 'assessee_of_other_territory': return
      case 'gstin_uin': return
      case 'use_ledger_as_common_party': return
      case 'set_alter_additional_gst_details': return
      case 'email': return
      case 'aadhaar': return
      case 'transport_detail': return
      case 'ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation': return
      case 'opening_balance': return
      default: return null;
    }
  }
  const getCurrentInputRef = (name, value) => {
    switch (name) {
      case 'ledger_name': return ledger_name_input_ref;
      case 'alise_name': return alise_name_input_ref;
      case 'under': return under_input_ref
      case 'effective_date_for_reconciliation': return effective_date_for_reconciliation_input_ref
      case 'set_od_limit': return set_od_limit_input_ref
      case 'maintain_balance_bill_by_bill': return maintain_balance_bill_by_bill_input_ref
      case 'default_credit_period': return default_credit_period_input_ref
      case 'specify_credit_limit': return specify_credit_limit_input_ref
      case 'inventry_values_are_affected': return inventry_values_are_affected_input_ref
      case 'account_holder_name': return account_holder_name_input_ref;
      case 'account_number': return account_number_input_ref;
      case 'ifsc_code': return ifsc_code_input_ref;
      case 'swift_code': return swift_code_input_ref;
      case 'bank_name': return bank_name_input_ref;
      case 'branch': return branch_input_ref;
      case 'set_alter_range_for_cheque_book': return set_alter_range_for_cheque_book_input_ref;
      case 'enable_cheque_printing': return enable_cheque_printing_input_ref
      case 'set_alter_cheque_printing_configuration': return set_alter_cheque_printing_configuration_input_ref
      case 'use_as_national_bank_for_post_dated_vouchers': return use_as_national_bank_for_post_dated_vouchers_input_ref;
      case 'behave_as_duties_and_taxes_ledger': return behave_as_duties_and_taxes_ledger_input_ref
      case 'type_of_duty_tax': return type_of_duty_tax_input_ref
      case 'tax_type': return tax_type_input_ref
      case 'valuation_type': return valuation_type_input_ref
      case 'rate_per_unit': return rate_per_unit_input_ref
      case 'percentage_of_calculation': return percentage_of_calculation_input_ref
      case 'rounding_method': return rounding_method_input_ref
      case 'rounding_limit': return rounding_limit_input_ref
      case 'is_gst_applicable': return is_gst_applicable_input_ref
      case 'set_alter_gst_details': return set_alter_gst_details_input_ref
      case 'include_in_assessable_value_calculation_for': return include_in_assessable_value_calculation_for_input_ref
      case 'type_of_ledger': return type_of_ledger_input_ref
      case 'hsn_sac_details': return hsn_sac_details_input_ref
      case 'source_of_details': return source_of_details_input_ref
      case 'hsn_sac': return hsn_sac_input_ref
      case 'description': return description_input_ref
      case 'gst_rate_details': return gst_rate_details_input_ref
      case 'taxability_type': return taxability_type_input_ref
      case 'nature_of_transaction': return nature_of_transaction_input_ref
      case 'igst_rate': return igst_rate_input_ref
      case 'cgst_rate': return cgst_rate_input_ref
      case 'sgst_utst_rate': return sgst_utst_rate_input_ref
      case 'cess_valuation_type': return cess_valuation_type_input_ref
      case 'cess_rate': return cess_rate_input_ref
      case 'applicable_for_reverse_charge': return applicable_for_reverse_charge_input_ref
      case 'eligible_for_tax_input_credit': return eligible_for_tax_input_credit_input_ref
      case 'type_of_supply': return type_of_supply_input_ref
      case 'check_for_credit_days_during_voucher_entry': return check_for_credit_days_during_voucher_entry_input_ref
      case 'method_of_appropriation_to_allocate_additional_costs_in_purchase_invoice': return method_of_appropriation_to_allocate_additional_costs_in_purchase_invoice_input_ref
      case 'mailing_name': return mailing_name_input_ref
      case 'address1': return address1_input_ref
      case 'state_name': return state_name_input_ref
      case 'country_name': return country_name_input_ref
      case 'pincode': return pincode_input_ref
      case 'mobile': return mobile_input_ref
      case 'provide_contact_details': return provide_contact_details_input_ref
      case 'provide_bank_details': return provide_bank_details_input_ref
      case 'pan_ItNo': return pan_ItNo_input_ref
      case 'registration_type': return registration_type_input_ref
      case 'assessee_of_other_territory': return assessee_of_other_territory_input_ref
      case 'gstin_uin': return gstin_uin_input_ref
      case 'use_ledger_as_common_party': return use_ledger_as_common_party_input_ref
      case 'set_alter_additional_gst_details': return set_alter_additional_gst_details_input_ref
      case 'email': return
      case 'aadhaar': return
      case 'transport_detail': return
      case 'ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation': return ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation_input_ref
      case 'opening_balance': return opening_balance_input_ref
      default: return null;
    }
  }
  const getPrevInputRef = (name, value) => {
    switch (name) {
      case 'alise_name': return ledger_name_input_ref;
      case 'under': return alise_name_input_ref;
      case 'effective_date_for_reconciliation': return under_input_ref;
      case 'set_od_limit': return effective_date_for_reconciliation_input_ref;
    }
  }

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (props?.ledgerCreate) {
        if (e.key === 'Escape') {
          e.preventDefault();
          props.setLedgerCreate(false)
          e.stopPropagation();
        }
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [props?.ledgerCreate])

  useEffect(() => {
    const formdata = new FormData();
    formdata.append('company_id', userData?.id)
    dispatch(groupListCreate(formdata))
  }, []);

  const handleClickOutside = (event) => {
    let clickedElement = event.target;
    let isInputField = false;
    while (clickedElement) {
      if ((clickedElement?.tagName &&
        (clickedElement?.tagName.toLowerCase() === 'input' ||
          clickedElement?.tagName.toLowerCase() === 'textarea' || clickedElement.tagName.toLowerCase() === 'button'))) {
        isInputField = true;
        break;
      }
      clickedElement = clickedElement?.parentNode;
    }

    if (!isInputField) {
      if (nameInput && nameInput?.current) {
        event?.preventDefault();
        nameInput?.current.focus();
      }
    }
  };
  useEffect(() => {
    document?.addEventListener('click', handleClickOutside);
    return () => {
      document?.removeEventListener('click', handleClickOutside);
    };
  }, [nameInput])


  useEffect(() => {
    const handleClickOutside = (event) => {
      let clickedElement = event?.target;
      let isInputField = false;

      while (clickedElement) {
        if ((clickedElement?.tagName &&
          (clickedElement?.tagName.toLowerCase() === 'input' ||
            clickedElement?.tagName.toLowerCase() === 'textarea' || clickedElement.tagName.toLowerCase() === 'button')) && !clickedElement.disabled) {
          isInputField = true;
          break;
        }
        clickedElement = clickedElement?.parentNode;
      }
      if (!isInputField) {
        if (ledgerSubmitConfirm === 'Accept') {
          setNameInput(null)
        } else {
          if (nameInput && nameInput?.current) {
            event?.preventDefault();
            nameInput?.current.focus();
          }
        }
      }
    };

    const handleMouseDown = () => {
      isMouseDownInside.current = true;
    };

    const handleMouseUp = () => {
      isMouseDownInside.current = false;
    };

    document?.addEventListener('mousedown', handleClickOutside);
    document?.addEventListener('mousedown', handleMouseDown);
    document?.addEventListener('mouseup', handleMouseUp);

    return () => {
      document?.removeEventListener('mousedown', handleClickOutside);
      document?.removeEventListener('mousedown', handleMouseDown);
      document?.removeEventListener('mouseup', handleMouseUp);
    };
  }, [nameInput, ledgerSubmitConfirm]);






  const handleFocus = (e) => {
    const { name, value } = e.target;
    const ref1 = getCurrentInputRef(name)
    if (ref1) {
      setNameInput(ref1);
      // ref1?.current?.setSelectionRange(0, ref1?.current?.value?.length)
    }
    if (name === 'under') {
      setShowList(true);
    }
  };
  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (name === 'ledger_name' && value === '') {
      ledger_name_input_ref.current.focus();
    }
    if (name === 'under') {
      setShowList(false)
    }
  }
  const handleChange = (e) => {
    const { name, value } = e.target;

    const capitalizeFirstLetter = (str) => {
      return str.toLowerCase().replace(/(^|\s)\S/g,
        (firstLetter) => firstLetter.toUpperCase());
    };
    setState({ ...state, [name]: value })
    if (name === 'ledger_name') {
      setIsLedgerNameEntered(!!value.trim());
    }
    // space key functionality
    if (e.code == 'Space') {
      e.preventDefault();
      const cursorPosition_start = e.target.selectionStart;
      if (cursorPosition_start == 1) {
        setState({ ...state, [name]: '' });
        setSelectedItem(0)
        return;
      }
    }

    // Enter key functionality
    if (e.key === 'Enter' && state?.group_name !== '') {
      e.preventDefault();
      const nextInputRef = getNextInputRef(name, value)
      if (name === 'opening_balance') {
        if (value) {
          type_input_ref?.current?.focus()
        } else {
          opening_balance_input_ref?.current?.blur()
          setLedgerSubitConfirm('Accept')
        }
      }
      if (name === 'type') {
        type_input_ref?.current?.blur()
        setLedgerSubitConfirm('Accept')
      }
      if (nextInputRef) {
        nextInputRef.current.focus();
        nextInputRef.current.setSelectionRange(0, nextInputRef.current.value.length);

      }
      return;
    }
    // Back space key functionality
    if (e.key === 'Backspace') {
      e.preventDefault();
      const nextInputRef = getPrevInputRef(name);
      const cursorPosition = e.target.selectionStart;
      if (cursorPosition === 0) {
        if (backspaceCount === 1) {
          setBackspaceCount(0);
          if (nextInputRef && nextInputRef.current) {
            nextInputRef.current.focus();
            nextInputRef.current.setSelectionRange(0, 0);
          }
        } else {
          if (cursorPosition === 0 && value) {
            if (nextInputRef && nextInputRef.current) {
              nextInputRef.current.focus();
              nextInputRef.current.setSelectionRange(0, 0);
            }
          } else {
            setBackspaceCount(backspaceCount + 1);
          }
        }
      }
      return;
    }
    const cleanString = (str) => str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    switch (name) {
      case 'under':
        const group = group_all_list.some(item => cleanString(item?.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (group) {
          setState({ ...state, [name]: value })
        }
        break;
      default:
        const updatedValue = name === 'ledger_name' || name === 'alise_name' || name === 'type' ?
          capitalizeFirstLetter(value) : value;
        setState({ ...state, [name]: updatedValue })
        break;
    }
  }

  //triger when the change value of search input and get filter data -------------
  useEffect(() => {
    const filteredSuggestions = group_all_list?.filter((item, index) => {
      const lowercaseLedgerName = item?.name?.toLowerCase();
      const lowercaseSearchInput = state?.under?.toLowerCase();
      const matchesSearchInput = lowercaseLedgerName?.includes(state.under !== '' ? lowercaseSearchInput : '');
      return matchesSearchInput;
    })
    setFilteredData(state?.under !== '' ? under_input_ref?.current?.selectionStart > 0 ? filteredSuggestions : group_all_list : group_all_list);
    if (filteredSuggestions?.length > 0 && under_input_ref?.current?.selectionStart > 0) {
      setSelectedItem(0);
    }
  }, [state?.under, group_all_list])


  useEffect(() => {
    const handleKeyDown = (e) => {
      if (showList == true) {
        if (e.key === 'ArrowUp') {
          e.preventDefault();
          setSelectedItem(prevState => Math.max(prevState - 1, 0));
        } else if (e.key === 'ArrowDown') {
          e.preventDefault();
          setSelectedItem(prevState => Math.min(prevState + 1, filteredData.length - 1));
        }
        else if (e.key === 'Enter') {
          e.preventDefault();
          // You can navigate to the selected link's path here
          const selectedLink = filteredData[selectedItem]
          if (selectedLink) {
            setState({ ...state, under: selectedLink.name, group_id: selectedLink.id })
            setShowList(false)
          }
        }
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedItem, under, state]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (ledgerSubmitConfirm === 'Accept') {
        if (e.key === 'y' || e.key === 'Y') {
          e.preventDefault();
          controlPlusASubmit();
        }
      }
      if (ledgerSubmitConfirm === 'Accept') {
        if (e.key === 'n' || e.key === 'N') {
          e.preventDefault();
          setLedgerSubitConfirm('')
          ledger_name_input_ref.current.focus();
        }
      }
    }
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [state?.ledger_name, ledgerSubmitConfirm])


  // scroll functionality ---------------------------
  useEffect(() => {
    if (dropdownRef?.current) {
      setClick(parseInt((dropdownRef?.current?.scrollHeight - dropdownRef?.current?.offsetHeight - dropdownRef?.current?.scrollTop) / 19.5))
      const selectedElement = dropdownRef?.current?.querySelector('.selected')
      if (selectedElement) {
        const boxHeight = dropdownRef?.current?.offsetHeight;
        const elementBottom = selectedElement?.offsetTop - 20
        const elementBottom1 = selectedElement?.offsetTop + selectedElement?.offsetHeight;
        if (elementBottom > boxHeight) {
          setClick(parseInt((dropdownRef.current.scrollHeight - dropdownRef.current.offsetHeight - dropdownRef.current.scrollTop) / 20.5))
          dropdownRef.current.scrollTop = elementBottom - boxHeight;
        } else {
          if (elementBottom1 > boxHeight) {
            dropdownRef.current.scrollTop = elementBottom - boxHeight;
          }
        }
      }
    }
    const handleWheel = (event) => {
      // event?.preventDefault();
      // if (isScrolling) retussssrn;
      // isScrolling = true;
      // const box = document.getElementById('box'); // Replace 'yourBoxId' with the ID of your box
      // const boxHeight = dropdownRef.current.clientHeight;
      // if (event.deltaY > 0 && selectedItem < group_all_list.length) {
      // setSelectedItem((prevIndex) => Math.min(prevIndex + 1, group_all_list.length - 1));
      // } else if (event.deltaY < 0 && selectedItem > 0) {
      // setSelectedItem((prevIndex) => Math.max(prevIndex - 1, 0));
      // }
      // isScrolling = false;
    };
    const dropdownElement = dropdownRef?.current;
    dropdownElement?.addEventListener('wheel', handleWheel, { passive: false });
    return () => {
      dropdownElement?.removeEventListener('wheel', handleWheel);
    };
  }, [selectedItem]);

  const controlPlusASubmit = async () => {
    if (validateForm()) {
      setLoading(true)
      try {
        let data = new FormData();
        data.append('company_id', userData?.id);
        data.append('ledger_name', state.ledger_name);
        data.append('alise_name', state.alise_name);
        data.append('group_id', state.group_id);
        data.append('address', state.address1);
        data.append('mailing_name', state.mailing_name);
        data.append('effective_date_for_reconciliation', state.effective_date_for_reconciliation);
        data.append('ac_holders_name', state.account_holder_name);
        data.append('ac_no', state.account_number);
        data.append('ifs_code', state.ifsc_code);
        data.append('swift_code', state.swift_code);
        data.append('bank_name', state.bank_name);
        data.append('branch', state.branch);
        data.append('set_alter_range_for_checque_books', state.set_alter_range_for_cheque_book);
        data.append('enable_check_printing', state.enable_cheque_printing);
        data.append('set_alter_cheque_printing_configuration', state.set_alter_cheque_printing_configuration);
        data.append('use_as_notional_bank_for_post_dated_vouchers', state.use_as_national_bank_for_post_dated_vouchers);
        data.append('state', state.state_name);
        data.append('country', state.country_name);
        data.append('pincode', state.pincode);
        data.append('mobile_no', state.mobile);
        data.append('provide_contact_details', state.provide_contact_details);
        data.append('set_od_limit', state.set_od_limit);
        data.append('provide_bank_details', state.provide_bank_details);
        data.append('pan_it_no', state.pan_ItNo);
        data.append('registration_type', state.registration_type);
        data.append('assessee_of_other_territory', state.assessee_of_other_territory);
        data.append('gst_in_uin', state.gstin_uin);
        data.append('use_ledger_as_common_party', state.use_ledger_as_common_party);
        data.append('set_alter_additional_gst_details', state.set_alter_additional_gst_details);
        data.append('ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation', state.ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation);
        data.append('maintain_balance_bill_by_bill', state.maintain_balance_bill_by_bill);
        data.append('default_credit_period', state.default_credit_period);
        data.append('check_for_credit_days_duriing_voucher_entry', state.check_for_credit_days_during_voucher_entry);
        data.append('specify_credit_limit', state.specify_credit_limit);
        data.append('override_credit_limit_using_post_dated_transaction', '');
        data.append('inventory_values_are_affected', state.inventry_values_are_affected);
        data.append('method_of_appropriation_to_allocate_additional_consts_in_purchas', state.method_of_appropriation_to_allocate_additional_costs_in_purchase_invoice);
        data.append('include_in_assessable_value_calculation', state.include_in_assessable_value_calculation_for);
        data.append('appropriate_to', state.voucher_type_name);
        data.append('method_of_calculation', state.voucher_type_name);
        data.append('gst_applicability', state.is_gst_applicable);
        data.append('hsn_sac_details', state.hsn_sac_details);
        data.append('source_of_details', state.source_of_details);
        data.append('hsn_sac', state.hsn_sac);
        data.append('description', state.description);
        data.append('gst_rate_details', state.voucher_type_name);
        data.append('taxability_type', state.taxability_type);
        data.append('nature_of_transaction', state.nature_of_transaction);
        data.append('igst_rate', state.igst_rate);
        data.append('cgst_rate', state.cgst_rate);
        data.append('sgst_utgst_rate', state.sgst_utst_rate);
        data.append('cess_valution_type', state.cess_valuation_type);
        data.append('cess_rate', state.cess_rate);
        data.append('applicable_for_reverse_charge', state.applicable_for_reverse_charge);
        data.append('eligible_for_input_tax_credit', state.voucher_type_name);
        data.append('classification', state.voucher_type_name);
        data.append('type_of_supply', state.type_of_supply);
        data.append('type_of_ledger', state.type_of_ledger);
        data.append('rounding_method', state.rounding_method);
        data.append('rounding_limit', state.rounding_limit);
        data.append('percentage_of_calculation', state.percentage_of_calculation);
        data.append('rate_per_unit', state?.rate_per_unit);
        data.append('valuation_type', state?.valuation_type);
        data.append('email', '');
        data.append('aadhaar', '');
        data.append('transport_detail', '');
        data.append('opening_balance', state.opening_balance);
        data.append('type', state.type?.toUpperCase());
        const response = await dispatch(ledgerCreate(data));
        if (response.status) {
          toast.success(response?.message || 'Group created!', {
            position: toast.POSITION.TOP_CENTER
          });
          resetForm();
        } else {
          toast.warn(response?.message || 'Fail !', {
            position: toast.POSITION.TOP_CENTER
          });
          setLoading(false)
          setHashtag(false)
          ledger_name_input_ref?.current?.focus()
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.message || 'Failed!', {
          position: toast.POSITION.TOP_CENTER,
        });
        setLoading(false)
        setHashtag(false)
        setLedgerSubitConfirm('')
        ledger_name_input_ref.current.focus();
      }
    } else {
      toast.error('Please fill all the fields.', {
        position: toast.POSITION.TOP_CENTER
      });
      setLedgerSubitConfirm('')
      setLoading(false)
      setHashtag(false)
      ledger_name_input_ref.current.focus();
    }
  };

  const ledgerSubmit = async (e1) => {
    e1.preventDefault();
    if (hashTag) {
      if (validateForm()) {
        setLoading(true)
        let data = new FormData();
        data.append('company_id', userData?.id);
        data.append('ledger_name', state.ledger_name);
        data.append('alise_name', state.alise_name);
        data.append('group_id', state.group_id);
        data.append('address', state.address1);
        data.append('mailing_name', state.mailing_name);
        data.append('effective_date_for_reconciliation', state.effective_date_for_reconciliation);
        data.append('ac_holders_name', state.account_holder_name);
        data.append('ac_no', state.account_number);
        data.append('ifs_code', state.ifsc_code);
        data.append('swift_code', state.swift_code);
        data.append('bank_name', state.bank_name);
        data.append('branch', state.branch);
        data.append('set_alter_range_for_checque_books', state.set_alter_range_for_cheque_book);
        data.append('enable_check_printing', state.enable_cheque_printing);
        data.append('set_alter_cheque_printing_configuration', state.set_alter_cheque_printing_configuration);
        data.append('use_as_notional_bank_for_post_dated_vouchers', state.use_as_national_bank_for_post_dated_vouchers);
        data.append('state', state.state_name);
        data.append('country', state.country_name);
        data.append('pincode', state.pincode);
        data.append('mobile_no', state.mobile);
        data.append('provide_contact_details', state.provide_contact_details);
        data.append('set_od_limit', state.set_od_limit);
        data.append('provide_bank_details', state.provide_bank_details);
        data.append('pan_it_no', state.pan_ItNo);
        data.append('registration_type', state.registration_type);
        data.append('assessee_of_other_territory', state.assessee_of_other_territory);
        data.append('gst_in_uin', state.gstin_uin);
        data.append('use_ledger_as_common_party', state.use_ledger_as_common_party);
        data.append('set_alter_additional_gst_details', state.set_alter_additional_gst_details);
        data.append('ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation', state.ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation);
        data.append('maintain_balance_bill_by_bill', state.maintain_balance_bill_by_bill);
        data.append('default_credit_period', state.default_credit_period);
        data.append('check_for_credit_days_duriing_voucher_entry', state.check_for_credit_days_during_voucher_entry);
        data.append('specify_credit_limit', state.specify_credit_limit);
        data.append('override_credit_limit_using_post_dated_transaction', '');
        data.append('inventory_values_are_affected', state.inventry_values_are_affected);
        data.append('method_of_appropriation_to_allocate_additional_consts_in_purchas', state.method_of_appropriation_to_allocate_additional_costs_in_purchase_invoice);
        data.append('include_in_assessable_value_calculation', state.include_in_assessable_value_calculation_for);
        data.append('appropriate_to', state.voucher_type_name);
        data.append('method_of_calculation', state.voucher_type_name);
        data.append('gst_applicability', state.is_gst_applicable);
        data.append('hsn_sac_details', state.hsn_sac_details);
        data.append('source_of_details', state.source_of_details);
        data.append('hsn_sac', state.hsn_sac);
        data.append('description', state.description);
        data.append('gst_rate_details', state.voucher_type_name);
        data.append('taxability_type', state.taxability_type);
        data.append('nature_of_transaction', state.nature_of_transaction);
        data.append('igst_rate', state.igst_rate);
        data.append('cgst_rate', state.cgst_rate);
        data.append('sgst_utgst_rate', state.sgst_utst_rate);
        data.append('cess_valution_type', state.cess_valuation_type);
        data.append('cess_rate', state.cess_rate);
        data.append('applicable_for_reverse_charge', state.applicable_for_reverse_charge);
        data.append('eligible_for_input_tax_credit', state.voucher_type_name);
        data.append('classification', state.voucher_type_name);
        data.append('type_of_supply', state.type_of_supply);
        data.append('type_of_ledger', state.type_of_ledger);
        data.append('rounding_method', state.rounding_method);
        data.append('rounding_limit', state.rounding_limit);
        data.append('percentage_of_calculation', state.percentage_of_calculation);
        data.append('rate_per_unit', state?.rate_per_unit);
        data.append('valuation_type', state?.valuation_type);
        data.append('email', '');
        data.append('aadhaar', '');
        data.append('transport_detail', '');
        data.append('opening_balance', state.opening_balance);
        data.append('type', state.type?.toUpperCase());
        dispatch(ledgerCreate(data)).then((response) => {
          if (response.status) {
            toast.success(response?.message || 'Ledger create successfully!', {
              position: toast.POSITION.TOP_CENTER
            });
            resetForm();
          }
          else {
            toast.warn(response?.message || 'Fail !', {
              position: toast.POSITION.TOP_CENTER
            });
            setLoading(false)
            setHashtag(false)
            ledger_name_input_ref?.current?.focus()
          }
        }).catch(error => {
          console.log(error)
          toast.error(error?.message || ' Failed!', {
            position: toast.POSITION.TOP_CENTER
          });
          setLoading(false)
          setHashtag(false)
          setLedgerSubitConfirm('')
          ledger_name_input_ref.current.focus();
        })
      } else {
        toast.error('Please fill all the fields.', {
          position: toast.POSITION.TOP_CENTER
        });
        setLedgerSubitConfirm('')
        setLoading(false)
        setHashtag(false)
        ledger_name_input_ref.current.focus();
      }
    }
  }

  // Function to validate form fields---------------------------------------
  const validateForm = () => {
    return (
      userData?.id !== '' &&
      ledger_name !== '' &&
      under !== '' &&
      group_id !== ''
    );
  };
  // Function to reset form fields-----------------------------------------
  const resetForm = () => {
    setState(prevState => ({
      ...prevState,
      ledger_name: '',
      alise_name: '',
      group_id: prevState?.group_id,
      under: prevState?.under,
      effective_date_for_reconciliation: '',
      set_od_limit: '',
      maintain_balance_bill_by_bill: 'No',
      default_credit_period: '',
      specify_credit_limit: '',
      inventry_values_are_affected: 'No',
      account_holder_name: '',
      account_number: '',
      ifsc_code: '',
      swift_code: '',
      bank_name: 'Not Applicable',
      branch: '',
      set_alter_range_for_cheque_book: 'No',
      enable_cheque_printing: 'No',
      set_alter_cheque_printing_configuration: 'No',
      use_as_national_bank_for_post_dated_vouchers: 'No',
      behave_as_duties_and_taxes_ledger: '',
      type_of_duty_tax: '',
      tax_type: '',
      valuation_type: '',
      rate_per_unit: '',
      percentage_of_calculation: '',
      rounding_method: '',
      rounding_limit: '',
      is_gst_applicable: 'Not Applicable',
      set_alter_gst_details: '',
      include_in_assessable_value_calculation_for: 'Not Applicable',
      type_of_ledger: 'Not Applicable',
      hsn_sac_details: '',
      source_of_details: '',
      hsn_sac: '',
      description: '',
      taxability_type: '',
      nature_of_transaction: '',
      igst_rate: '',
      cgst_rate: '',
      sgst_utst_rate: '',
      cess_valuation_type: '',
      cess_rate: '',
      applicable_for_reverse_charge: '',
      type_of_supply: '',
      check_for_credit_days_during_voucher_entry: '',
      method_of_appropriation_to_allocate_additional_costs_in_purchase_invoice: 'Not Applicable',
      mailing_name: '',
      address1: '',
      state_name: '',
      country_name: 'India',
      pincode: '',
      mobile: '',
      provide_contact_details: 'No',
      provide_bank_details: 'No',
      pan_ItNo: '',
      registration_type: '',
      assessee_of_other_territory: '',
      gstin_uin: '',
      use_ledger_as_common_party: '',
      set_alter_additional_gst_details: '',
      ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation: '',
      opening_balance: ''
    }));
    setLoading(false)
    setHashtag(false)
    setLedgerSubitConfirm('');
    setTimeout(() => {
      ledger_name_input_ref.current.focus();
    }, 50);
    // setIsGroupNameEntered(false);
  };

  if (loading) {
    return <div>Loading...</div>
  }
  return (
    <div className='section'>
      <div className='detail'>
        {
          ledgerSubmitConfirm === 'Accept' ?
            <YesNoModal>
              <div className='container'>
                <div className='row'>
                  <div className='col-12'>
                    <p className='modal_message'>
                      {ledgerSubmitConfirm}?
                    </p>
                  </div>
                  <div className='col-3 offset-1'>
                    <Link className="modal_link" onClick={controlPlusASubmit} >Yes</Link>
                  </div>
                  <div className='col-2'>or</div>
                  <div className='col-3'>
                    <Link className='modal_link'>No</Link>
                  </div>
                </div>
              </div>
            </YesNoModal> : null
        }
        <ToastContainer />
        <div className='container-fluid ' style={{ height: '90vh', pointerEvents: ledgerSubmitConfirm === 'Accept' ? 'none' : 'auto' }} >
          <div className='row border border-dark table_date_header' >
            <div className='col-12 ps-3  d-flex justify-content-between  table_header_font'>
              <b> Ledger Creation </b>
            </div>
          </div>
          <form
            method='POST'
            autoComplete='off'
            onSubmit={ledgerSubmit}
            onKeyDown={(e) => {
              if (e.ctrlKey && (e.key === 'a' || e.key === 'A')) {
                e.preventDefault();
                controlPlusASubmit(e);
              }
            }}
          >
            <div className='row'>
              <div className='col-md-12 border-bottom border-dark' style={{ height: 70 }}>
                <div className='row'>
                  <div className=' col-md-6 m-0 p-0 ps-1 mt-1'>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1'> Account No</label>
                      <label> :</label>
                      <input
                        type="text"
                        name='account_no'
                        id='account_no'
                        className='lab-right'
                        onKeyUp={handleChange}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        value={state.account_no || ""}
                        ref={account_no_input_ref}
                      />
                    </div>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1'> Name</label>
                      <label> :</label>
                      <input
                        type="text"
                        name='ledger_name'
                        id='ledger_name'
                        autoFocus
                        className='lab-right'
                        onKeyUp={handleChange}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        value={ledger_name || ""}
                        ref={ledger_name_input_ref}
                      />
                    </div>
                    <div className='d-flex justify-content-between align-item-center' id='alis'>
                      <label className='lab-1'> (alise) </label>
                      <label> :</label>
                      <input
                        type="text"
                        name='alise_name'
                        ref={alise_name_input_ref}
                        id='alise_name'
                        className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                        onKeyUp={handleChange}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        value={alise_name || ""} />
                    </div>
                  </div>
                </div>
              </div>


              <div className='col-md-12 m-0 p-0 ps-1' style={{ height: '70vh' }}>
                <div className='row'>
                  <div className=' col-lg-6 pt-1 border-end border-dark' style={{ height: '70vh' }}>
                    <div className='d-flex justify-content-between align-item-center'>
                      <label className='lab-1'> Under </label>
                      <label> :</label>
                      <input
                        type='text'
                        id='under'
                        name="under"
                        ref={under_input_ref}
                        value={under || ""}
                        onKeyUp={handleChange}
                        onChange={handleChange}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} />
                      {/* <DropdownLayout
                        dropdownRef={dropdownRef}
                        ref={null}
                        state={showList}
                        setState={setShowList}
                        filteredData={filteredData}
                        input_value={under}
                        input_key='under'
                        input_key_id='group_id'
                        setSelectedItem={setSelectedItem}
                        selectedItem={selectedItem}
                        setData={setState}
                        data={state}
                      /> */}
                      <div id="myDropdown-list" className={`dropdown-content-list ${showList ? 'show-list' : ''}`}>
                        <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                          List Of Group
                        </div>
                        <div className='col ' style={{ textAlign: 'right' }}>
                          <Link style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                          <div className='border'></div>
                        </div>
                        <div id='data' ref={dropdownRef} style={{ overflowY: 'auto', maxHeight: '80.5vh' }}>
                          {filteredData.length > 0 ? (
                            filteredData?.map((key, number) => {
                              const name = key?.name;
                              const id = key?.id;
                              const index = name?.toLowerCase()?.indexOf(under?.toLowerCase());
                              return (
                                <Link
                                  className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                  id='list'
                                  key={number}
                                  onClick={() => {
                                    setSelectedItem(number)
                                    if (number === selectedItem) {
                                      setState({ ...state, under: name, group_id: id })
                                      setShowList(false)
                                    }
                                  }}
                                >
                                  {index !== -1 ? (
                                    < >
                                      {name.substring(0, index)}
                                      <span style={{ color: 'red' }}>{name.substring(index, index + under.length)}</span>
                                      {name.substring(index + under.length)}
                                    </>
                                  ) : (
                                    name
                                  )}
                                </Link>
                              );
                            })
                          ) : (
                            <p style={{ color: 'red' }}>No matching items found</p>
                          )}
                        </div>
                      </div>
                    </div>
                    {
                      under === 'Bank Account' || under === 'Bank OD A/C' ?
                        // bankAccountRendering || bankOccAcRendering || bankOdAcRendering ?
                        <div>
                          <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Effective Date for Reconciliation </label>
                            <label> :</label>
                            <input
                              type="text"
                              name='effective_date_for_reconciliation'
                              id='effective_date_for_reconciliation'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={effective_date_for_reconciliation_input_ref}
                              value={effective_date_for_reconciliation || ""} />
                          </div>
                          {under === 'Bank OD A/C' ?
                            // bankOdAcRendering ?
                            <div className='d-flex justify-content-between align-item-center'>
                              <label className='lab-1'> Set OD Limit </label>
                              <label> :</label>
                              <input
                                type="text"
                                name='set_od_limit'
                                id='set_od_limit'
                                className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                onKeyUp={handleChange}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                ref={set_od_limit_input_ref}
                                value={set_od_limit || ""} />
                            </div> : null}
                          <div>
                            <p className='m-0 p-0 text-start text-info font_size'>Bank Account Details</p>
                            <div className='d-flex justify-content-between align-item-center'>
                              <label className='lab-1'>A/c Holder's Name </label>
                              <label> :</label>
                              <input
                                type="text"
                                name='account_holder_name'
                                id='account_holder_name'
                                className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                onKeyUp={handleChange}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                ref={account_holder_name_input_ref}
                                value={account_holder_name || ""} />
                            </div>
                            <div className='d-flex justify-content-between align-item-center'>
                              <label className='lab-1'>A/c No. </label>
                              <label> :</label>
                              <input
                                type="text"
                                name='account_number'
                                id='account_number'
                                className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                onKeyUp={handleChange}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                ref={account_number_input_ref}
                                value={account_number || ""} />
                            </div>
                            <div className='d-flex justify-content-between align-item-center'>
                              <label className='lab-1'>IFSC Code </label>
                              <label> :</label>
                              <input
                                type="text"
                                name='ifsc_code'
                                id='ifsc_code'
                                className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                onKeyUp={handleChange}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                ref={ifsc_code_input_ref}
                                value={ifsc_code || ""} />
                            </div>
                            <div className='d-flex justify-content-between align-item-center'>
                              <label className='lab-1'>SWIFT Code </label>
                              <label> :</label>
                              <input
                                type="text"
                                name='swift_code'
                                id='swift_code'
                                className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                onKeyUp={handleChange}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                ref={swift_code_input_ref}
                                value={swift_code || ""} />
                            </div>
                            <div className='d-flex justify-content-between align-item-center'>
                              <label className='lab-1'>Bank Name </label>
                              <label> :</label>
                              <input
                                type="text"
                                name='bank_name'
                                id='bank_name'
                                className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                onKeyUp={handleChange}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                ref={bank_name_input_ref}
                                value={bank_name || ""} />
                            </div>
                            <div className='d-flex justify-content-between align-item-center'>
                              <label className='lab-1'>Branch </label>
                              <label> :</label>
                              <input
                                type="text"
                                name='branch'
                                id='branch'
                                className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                onKeyUp={handleChange}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                ref={branch_input_ref}
                                value={branch || ""} />
                            </div>
                            <p className='m-0 p-0 ps-1 text-start text-info font_size'>Bank Configuration</p>
                            <div className='d-flex justify-content-between align-item-center position-relative'>
                              <label className='lab-1'> Set/Alter range for Cheque Books </label>
                              <label> :</label>
                              <input
                                type="text"
                                name='set_alter_range_for_cheque_book'
                                id='set_alter_range_for_cheque_book'
                                className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                onKeyUp={handleChange}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                ref={set_alter_range_for_cheque_book_input_ref}
                                value={set_alter_range_for_cheque_book || ""} />
                              <div id="myDropdown-list" className={`dropdown_yes_no_list ${showSetAlterRange ? 'show-list' : ''}`}>
                                <div className='col dropdown_header'>
                                  Yes / No
                                </div>
                                <div id='data'>
                                  {filteredDataYesNo?.length > 0 ? (
                                    filteredDataYesNo?.map((key, number) => {
                                      const name = key?.name;
                                      const id = key?.id;
                                      const index = name?.toLowerCase()?.indexOf(set_alter_range_for_cheque_book?.toLowerCase());
                                      return (
                                        <Link
                                          className={`list ${number === selectedItem ? 'selected' : ''}`}
                                          id='list'
                                          key={number}
                                          onClick={() => {
                                            setSelectedItem(number)
                                            if (number === selectedItem) {
                                              setState({ ...state, set_alter_range_for_cheque_book: name })
                                              // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                              // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                              setShowSetAlterRange(false)
                                            }
                                          }}
                                        >
                                          {index !== -1 ? (
                                            < >
                                              {name.substring(0, index)}
                                              <span style={{ color: 'red' }}>{name.substring(index, index + set_alter_range_for_cheque_book.length)}</span>
                                              {name.substring(index + set_alter_range_for_cheque_book.length)}
                                            </>
                                          ) : (
                                            name
                                          )}
                                        </Link>
                                      );
                                    })
                                  ) : (
                                    <p style={{ color: 'red' }}>No matching items found</p>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className='d-flex justify-content-between align-item-center position-relative'>
                              <label className='lab-1'> Enable Cheque Printing </label>
                              <label> :</label>
                              <input
                                type="text"
                                name='enable_cheque_printing'
                                id='enable_cheque_printing'
                                className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                onKeyUp={handleChange}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                ref={enable_cheque_printing_input_ref}
                                value={enable_cheque_printing || ""} />
                              <div id="myDropdown-list" className={`dropdown_yes_no_list ${showEnableCheqPrinting ? 'show-list' : ''}`}>
                                <div className='col dropdown_header'>
                                  Yes / No
                                </div>
                                <div id='data'>
                                  {filteredDataYesNo?.length > 0 ? (
                                    filteredDataYesNo?.map((key, number) => {
                                      const name = key?.name;
                                      const id = key?.id;
                                      const index = name?.toLowerCase()?.indexOf(enable_cheque_printing?.toLowerCase());
                                      return (
                                        <Link
                                          className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                          id='list'
                                          key={number}
                                          onClick={() => {
                                            setSelectedItem(number)
                                            if (number === selectedItem) {
                                              setState({ ...state, enable_cheque_printing: name })
                                              // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                              // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                              setShowEnableCheqPrinting(false)
                                            }
                                          }}
                                        >
                                          {index !== -1 ? (
                                            < >
                                              {name.substring(0, index)}
                                              <span style={{ color: 'red' }}>{name.substring(index, index + enable_cheque_printing.length)}</span>
                                              {name.substring(index + enable_cheque_printing.length)}
                                            </>
                                          ) : (
                                            name
                                          )}
                                        </Link>
                                      );
                                    })
                                  ) : (
                                    <p style={{ color: 'red' }}>No matching items found</p>
                                  )}
                                </div>
                              </div>
                            </div>
                            {
                              enable_cheque_printing == 'Yes' ?
                                <div className='d-flex justify-content-between align-item-center position-relative' id='Enable_Cheque_printing'>
                                  <label className='lab-1'>Set/Alter Cheque Printing configuration </label>
                                  <label> :</label>
                                  <input
                                    type="text"
                                    name='set_alter_cheque_printing_configuration'
                                    id='set_alter_cheque_printing_configuration'
                                    className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                    onKeyUp={handleChange}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    ref={set_alter_cheque_printing_configuration_input_ref}
                                    value={set_alter_cheque_printing_configuration || ""} />
                                  <div id="myDropdown-list" className={`dropdown_yes_no_list ${showSetAlterCheqPrinting ? 'show-list' : ''}`}>
                                    <div className='col dropdown_header'>
                                      Yes / No
                                    </div>
                                    <div id='data'>
                                      {filteredDataYesNo?.length > 0 ? (
                                        filteredDataYesNo?.map((key, number) => {
                                          const name = key?.name;
                                          const id = key?.id;
                                          const index = name?.toLowerCase()?.indexOf(set_alter_cheque_printing_configuration?.toLowerCase());
                                          return (
                                            <Link
                                              className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                              id='list'
                                              key={number}
                                              onClick={() => {
                                                setSelectedItem(number)
                                                if (number === selectedItem) {
                                                  setState({ ...state, set_alter_cheque_printing_configuration: name })
                                                  // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                                  // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                                  setShowSetAlterCheqPrinting(false)
                                                }
                                              }}
                                            >
                                              {index !== -1 ? (
                                                < >
                                                  {name.substring(0, index)}
                                                  <span style={{ color: 'red' }}>{name.substring(index, index + set_alter_cheque_printing_configuration.length)}</span>
                                                  {name.substring(index + set_alter_cheque_printing_configuration.length)}
                                                </>
                                              ) : (
                                                name
                                              )}
                                            </Link>
                                          );
                                        })
                                      ) : (
                                        <p style={{ color: 'red' }}>No matching items found</p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                : null
                            }
                            <div className='d-flex justify-content-between align-item-center position-relative' id='Enable_Cheque_printing'>
                              <label className='lab-1'>Use as National Bank for Post-Dated Vouchers </label>
                              <label> :</label>
                              <input
                                type="text"
                                name='use_as_national_bank_for_post_dated_vouchers'
                                id='use_as_national_bank_for_post_dated_vouchers'
                                className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                onKeyUp={handleChange}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                ref={use_as_national_bank_for_post_dated_vouchers_input_ref}
                                value={use_as_national_bank_for_post_dated_vouchers || ""} />
                              <div id="myDropdown-list" className={`dropdown_yes_no_list ${showUseAsNational ? 'show-list' : ''}`}>
                                <div className='col dropdown_header'>
                                  Yes / No
                                </div>
                                <div id='data'>
                                  {filteredDataYesNo?.length > 0 ? (
                                    filteredDataYesNo?.map((key, number) => {
                                      const name = key?.name;
                                      const id = key?.id;
                                      const index = name?.toLowerCase()?.indexOf(use_as_national_bank_for_post_dated_vouchers?.toLowerCase());
                                      return (
                                        <Link
                                          className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                          id='list'
                                          key={number}
                                          onClick={() => {
                                            setSelectedItem(number)
                                            if (number === selectedItem) {
                                              setState({ ...state, use_as_national_bank_for_post_dated_vouchers: name })
                                              // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                              // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                              setShowUseAsNational(false)
                                            }
                                          }}
                                        >
                                          {index !== -1 ? (
                                            < >
                                              {name.substring(0, index)}
                                              <span style={{ color: 'red' }}>{name.substring(index, index + use_as_national_bank_for_post_dated_vouchers.length)}</span>
                                              {name.substring(index + use_as_national_bank_for_post_dated_vouchers.length)}
                                            </>
                                          ) : (
                                            name
                                          )}
                                        </Link>
                                      );
                                    })
                                  ) : (
                                    <p style={{ color: 'red' }}>No matching items found</p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> : null
                    }
                    {
                      under === 'Capital Account' ?
                        // capitalAccountRendering ?
                        <div>
                          <div className='d-flex justify-content-between align-item-center  position-relative'>
                            <label className='lab-1'> Maintain balance bill-by-bill </label>
                            <label> :</label>
                            <input
                              type="text"
                              ref={maintain_balance_bill_by_bill_input_ref}
                              name='maintain_balance_bill_by_bill'
                              id='Maintainbalance'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              value={maintain_balance_bill_by_bill || ""} />
                            <div id="myDropdown-list" className={`dropdown_yes_no_list ${showMaintainBalance ? 'show-list' : ''}`}>
                              <div className='col dropdown_header'>
                                Yes / No
                              </div>
                              <div id='data'>
                                {filteredDataYesNo?.length > 0 ? (
                                  filteredDataYesNo?.map((key, number) => {
                                    const name = key?.name;
                                    const id = key?.id;
                                    const index = name?.toLowerCase()?.indexOf(maintain_balance_bill_by_bill?.toLowerCase());
                                    return (
                                      <Link
                                        className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                        id='list'
                                        key={number}
                                        onClick={() => {
                                          setSelectedItem(number)
                                          if (number === selectedItem) {
                                            setState({ ...state, maintain_balance_bill_by_bill: name })
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                            setShowMaintainBalance(false)
                                          }
                                        }}
                                      >
                                        {index !== -1 ? (
                                          < >
                                            {name.substring(0, index)}
                                            <span style={{ color: 'red' }}>{name.substring(index, index + maintain_balance_bill_by_bill.length)}</span>
                                            {name.substring(index + maintain_balance_bill_by_bill.length)}
                                          </>
                                        ) : (
                                          name
                                        )}
                                      </Link>
                                    );
                                  })
                                ) : (
                                  <p style={{ color: 'red' }}>No matching items found</p>
                                )}
                              </div>
                            </div>
                          </div>
                          {
                            maintain_balance_bill_by_bill == 'Yes' ?
                              <div className='d-flex justify-content-between align-item-center'>
                                <label className='lab-1'> Default Credit period </label>
                                <label> :</label>
                                <input
                                  type="text"
                                  name='default_credit_period'
                                  id='default-credit-period'
                                  className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                  onKeyUp={handleChange}
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  ref={default_credit_period_input_ref}
                                  value={default_credit_period || ""} />
                              </div> : null
                          }
                          <div className='d-flex justify-content-between align-item-center position-relative'>
                            <label className='lab-1'> Inventory values are affected </label>
                            <label> :</label>
                            <input
                              type="text"
                              name='inventry_values_are_affected'
                              id='inventry_values_are_affected'
                              ref={inventry_values_are_affected_input_ref}
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              value={inventry_values_are_affected || ""} />
                            <div id="myDropdown-list" className={`dropdown_yes_no_list ${showInventoryValue ? 'show-list' : ''}`}>
                              <div className='col dropdown_header'>
                                Yes / No
                              </div>
                              <div id='data'>
                                {filteredDataYesNo?.length > 0 ? (
                                  filteredDataYesNo?.map((key, number) => {
                                    const name = key?.name;
                                    const id = key?.id;
                                    const index = name?.toLowerCase()?.indexOf(inventry_values_are_affected?.toLowerCase());
                                    return (
                                      <Link
                                        className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                        id='list'
                                        key={number}
                                        onClick={() => {
                                          setSelectedItem(number)
                                          if (number === selectedItem) {
                                            setState({ ...state, inventry_values_are_affected: name })
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                            setShowInventoryValue(false)
                                          }
                                        }}
                                      >
                                        {index !== -1 ? (
                                          < >
                                            {name.substring(0, index)}
                                            <span style={{ color: 'red' }}>{name.substring(index, index + inventry_values_are_affected.length)}</span>
                                            {name.substring(index + inventry_values_are_affected.length)}
                                          </>
                                        ) : (
                                          name
                                        )}
                                      </Link>
                                    );
                                  })
                                ) : (
                                  <p style={{ color: 'red' }}>No matching items found</p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div> : null
                    }
                    {
                      under === 'Branch/Divisions' || under === 'Sundry Creditors' || under === 'Sundry Debtors' ?
                        // branchDivisionRendering || sundryCreditorsRendering || sundryDebtorsRendering ?
                        <div>
                          <div className='d-flex justify-content-between align-item-center  position-relative'>
                            <label className='lab-1'> Maintain balance bill-by-bill </label>
                            <label> :</label>
                            <input
                              type="text"
                              ref={maintain_balance_bill_by_bill_input_ref}
                              name='maintain_balance_bill_by_bill'
                              id='Maintainbalance'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              value={maintain_balance_bill_by_bill || ""} />
                            <div id="myDropdown-list" className={`dropdown_yes_no_list ${showMaintainBalance ? 'show-list' : ''}`}>
                              <div className='col dropdown_header'>
                                Yes / No
                              </div>
                              <div id='data'>
                                {filteredDataYesNo?.length > 0 ? (
                                  filteredDataYesNo?.map((key, number) => {
                                    const name = key?.name;
                                    const id = key?.id;
                                    const index = name?.toLowerCase()?.indexOf(maintain_balance_bill_by_bill?.toLowerCase());
                                    return (
                                      <Link
                                        className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                        id='list'
                                        key={number}
                                        onClick={() => {
                                          setSelectedItem(number)
                                          if (number === selectedItem) {
                                            setState({ ...state, maintain_balance_bill_by_bill: name })
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                            setShowMaintainBalance(false)
                                          }
                                        }}
                                      >
                                        {index !== -1 ? (
                                          < >
                                            {name.substring(0, index)}
                                            <span style={{ color: 'red' }}>{name.substring(index, index + maintain_balance_bill_by_bill.length)}</span>
                                            {name.substring(index + maintain_balance_bill_by_bill.length)}
                                          </>
                                        ) : (
                                          name
                                        )}
                                      </Link>
                                    );
                                  })
                                ) : (
                                  <p style={{ color: 'red' }}>No matching items found</p>
                                )}
                              </div>
                            </div>
                          </div>
                          {
                            maintain_balance_bill_by_bill == 'Yes' ?
                              <div>
                                <div className='d-flex justify-content-between align-item-center'>
                                  <label className='lab-1'> Default Credit period </label>
                                  <label> :</label>
                                  <input
                                    type="text"
                                    name='default_credit_period'
                                    id='default-credit-period'
                                    className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                    onKeyUp={handleChange}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    ref={default_credit_period_input_ref}
                                    value={default_credit_period || ""} />
                                </div>
                                <div className='d-flex justify-content-between align-item-center position-relative'>
                                  <label className='lab-1'> check for credit days during voucher entry </label>
                                  <label> :</label>
                                  <input
                                    type="text"
                                    name='check_for_credit_days_during_voucher_entry'
                                    id='check_for_credit_days_during_voucher_entry'
                                    ref={check_for_credit_days_during_voucher_entry_input_ref}
                                    className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                    onKeyUp={handleChange}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    value={check_for_credit_days_during_voucher_entry || ""} />
                                  <div id="myDropdown-list" className={`dropdown_yes_no_list ${showCheckForCredit ? 'show-list' : ''}`}>
                                    <div className='col dropdown_header'>
                                      Yes / No
                                    </div>
                                    <div id='data'>
                                      {filteredDataYesNo?.length > 0 ? (
                                        filteredDataYesNo?.map((key, number) => {
                                          const name = key?.name;
                                          const id = key?.id;
                                          const index = name?.toLowerCase()?.indexOf(check_for_credit_days_during_voucher_entry?.toLowerCase());
                                          return (
                                            <Link
                                              className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                              id='list'
                                              key={number}
                                              onClick={() => {
                                                setSelectedItem(number)
                                                if (number === selectedItem) {
                                                  setState({ ...state, check_for_credit_days_during_voucher_entry: name })
                                                  // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                                  // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                                  setShowCheckForCredit(false)
                                                }
                                              }}
                                            >
                                              {index !== -1 ? (
                                                < >
                                                  {name.substring(0, index)}
                                                  <span style={{ color: 'red' }}>{name.substring(index, index + check_for_credit_days_during_voucher_entry.length)}</span>
                                                  {name.substring(index + check_for_credit_days_during_voucher_entry.length)}
                                                </>
                                              ) : (
                                                name
                                              )}
                                            </Link>
                                          );
                                        })
                                      ) : (
                                        <p style={{ color: 'red' }}>No matching items found</p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div> : null
                          }

                          <div className='d-flex justify-content-between align-item-center'>
                            <label className='lab-1'>Specify Credit Limit  </label>
                            <label> :</label>
                            <input
                              type="text"
                              name='specify_credit_limit'
                              id='specify_credit_limit'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={specify_credit_limit_input_ref}
                              value={specify_credit_limit || ""} />
                          </div>
                          <div className='d-flex justify-content-between align-item-center position-relative'>
                            <label className='lab-1'> Inventory values are affected </label>
                            <label> :</label>
                            <input
                              type="text"
                              name='inventry_values_are_affected'
                              id='inventry_values_are_affected'
                              ref={inventry_values_are_affected_input_ref}
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              value={inventry_values_are_affected || ""} />
                            <div id="myDropdown-list" className={`dropdown_yes_no_list ${showInventoryValue ? 'show-list' : ''}`}>
                              <div className='col dropdown_header'>
                                Yes / No
                              </div>
                              <div id='data'>
                                {filteredDataYesNo?.length > 0 ? (
                                  filteredDataYesNo?.map((key, number) => {
                                    const name = key?.name;
                                    const id = key?.id;
                                    const index = name?.toLowerCase()?.indexOf(inventry_values_are_affected?.toLowerCase());
                                    return (
                                      <Link
                                        className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                        id='list'
                                        key={number}
                                        onClick={() => {
                                          setSelectedItem(number)
                                          if (number === selectedItem) {
                                            setState({ ...state, inventry_values_are_affected: name })
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                            setShowInventoryValue(false)
                                          }
                                        }}
                                      >
                                        {index !== -1 ? (
                                          < >
                                            {name.substring(0, index)}
                                            <span style={{ color: 'red' }}>{name.substring(index, index + inventry_values_are_affected.length)}</span>
                                            {name.substring(index + inventry_values_are_affected.length)}
                                          </>
                                        ) : (
                                          name
                                        )}
                                      </Link>
                                    );
                                  })
                                ) : (
                                  <p style={{ color: 'red' }}>No matching items found</p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div> : null
                    }
                    {
                      under === 'Current Assets' || under === 'Current Liabilities' ?
                        // currentAssetsRendering || currentLiabilitiesRendering ?
                        <div>
                          {/* <div className='d-flex justify-content-between align-item-center position-relative'>
                            <label className='lab-1'> Behave as Duties & Taxes Ledger </label>
                            <label> :</label>
                            <input
                              type="text"
                              name='behave_as_duties_and_taxes_ledger'
                              id='behave_as_duties_and_taxes_ledger'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={behave_as_duties_and_taxes_ledger_input_ref}
                              value={behave_as_duties_and_taxes_ledger || ""} />
                            <div id="myDropdown-list" className={`dropdown_yes_no_list ${showBehaveAsDuties ? 'show-list' : ''}`}>
                              <div className='col dropdown_header'>
                                Yes / No
                              </div>
                              <div id='data'>
                                {filteredDataYesNo?.length > 0 ? (
                                  filteredDataYesNo?.map((key, number) => {
                                    const name = key?.name;
                                    const id = key?.id;
                                    const index = name?.toLowerCase()?.indexOf(behave_as_duties_and_taxes_ledger?.toLowerCase());
                                    return (
                                      <Link
                                        className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                        id='list'
                                        key={number}
                                        onClick={() => {
                                          setSelectedItem(number)
                                          if (number === selectedItem) {
                                            setState({ ...state, behave_as_duties_and_taxes_ledger: name })
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                            setShowBehaveAsDuties(false)
                                          }
                                        }}
                                      >
                                        {index !== -1 ? (
                                          < >
                                            {name.substring(0, index)}
                                            <span style={{ color: 'red' }}>{name.substring(index, index + behave_as_duties_and_taxes_ledger.length)}</span>
                                            {name.substring(index + behave_as_duties_and_taxes_ledger.length)}
                                          </>
                                        ) : (
                                          name
                                        )}
                                      </Link>
                                    );
                                  })
                                ) : (
                                  <p style={{ color: 'red' }}>No matching items found</p>
                                )}
                              </div>
                            </div>
                          </div> */}
                          <div className='d-flex justify-content-between align-item-center  position-relative'>
                            <label className='lab-1'> Maintain balance bill-by-bill </label>
                            <label> :</label>
                            <input
                              type="text"
                              ref={maintain_balance_bill_by_bill_input_ref}
                              name='maintain_balance_bill_by_bill'
                              id='Maintainbalance'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              value={maintain_balance_bill_by_bill || ""} />
                            <div id="myDropdown-list" className={`dropdown_yes_no_list ${showMaintainBalance ? 'show-list' : ''}`}>
                              <div className='col dropdown_header'>
                                Yes / No
                              </div>
                              <div id='data'>
                                {filteredDataYesNo?.length > 0 ? (
                                  filteredDataYesNo?.map((key, number) => {
                                    const name = key?.name;
                                    const id = key?.id;
                                    const index = name?.toLowerCase()?.indexOf(maintain_balance_bill_by_bill?.toLowerCase());
                                    return (
                                      <Link
                                        className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                        id='list'
                                        key={number}
                                        onClick={() => {
                                          setSelectedItem(number)
                                          if (number === selectedItem) {
                                            setState({ ...state, maintain_balance_bill_by_bill: name })
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                            setShowMaintainBalance(false)
                                          }
                                        }}
                                      >
                                        {index !== -1 ? (
                                          < >
                                            {name.substring(0, index)}
                                            <span style={{ color: 'red' }}>{name.substring(index, index + maintain_balance_bill_by_bill.length)}</span>
                                            {name.substring(index + maintain_balance_bill_by_bill.length)}
                                          </>
                                        ) : (
                                          name
                                        )}
                                      </Link>
                                    );
                                  })
                                ) : (
                                  <p style={{ color: 'red' }}>No matching items found</p>
                                )}
                              </div>
                            </div>
                          </div>
                          {
                            maintain_balance_bill_by_bill == 'Yes' ?
                              <div className='d-flex justify-content-between align-item-center'>
                                <label className='lab-1'> Default Credit period </label>
                                <label> :</label>
                                <input
                                  type="text"
                                  name='default_credit_period'
                                  id='default-credit-period'
                                  className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                  onKeyUp={handleChange}
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  ref={default_credit_period_input_ref}
                                  value={default_credit_period || ""} />
                              </div> : null
                          }
                          <div className='d-flex justify-content-between align-item-center position-relative'>
                            <label className='lab-1'> Method of Appropriation to Allocate Additional cost in purchase invoice </label>
                            <label> :</label>
                            <input
                              type="text"
                              name='method_of_appropriation_to_allocate_additional_costs_in_purchase_invoice'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={method_of_appropriation_to_allocate_additional_costs_in_purchase_invoice_input_ref}
                              value={method_of_appropriation_to_allocate_additional_costs_in_purchase_invoice || ""} />
                            <div id="myDropdown-list" className={`dropdown_yes_no_list ${isGstAppShow ? 'show-list' : ''}`}>
                              <div className='col dropdown_header'>
                                Select List
                              </div>
                              <div id='data'>
                                {filteredDataIsGstApplicable?.length > 0 ? (
                                  filteredDataIsGstApplicable?.map((key, number) => {
                                    const name = key?.name;
                                    const id = key?.id;
                                    const index = name?.toLowerCase()?.indexOf(method_of_appropriation_to_allocate_additional_costs_in_purchase_invoice?.toLowerCase());
                                    return (
                                      <Link
                                        className={`list ${number === selectedItem ? 'selected' : ''}`}
                                        id='list'
                                        key={number}
                                        onClick={() => {
                                          setSelectedItem(number)
                                          if (number === selectedItem) {
                                            setState({ ...state, method_of_appropriation_to_allocate_additional_costs_in_purchase_invoice: name })
                                            // nett_debit_credit_balance_for_reporting_input_ref.current.focus();
                                            // nett_debit_credit_balance_for_reporting_input_ref.current.setSelectionRange(0, 0);
                                            setIsGstAppShow(false)
                                          }
                                        }}
                                      >
                                        {index !== -1 ? (
                                          < >
                                            {name.substring(0, index)}
                                            <span style={{ color: 'red' }}>{name.substring(index, index + method_of_appropriation_to_allocate_additional_costs_in_purchase_invoice.length)}</span>
                                            {name.substring(index + method_of_appropriation_to_allocate_additional_costs_in_purchase_invoice.length)}
                                          </>
                                        ) : (
                                          name
                                        )}
                                      </Link>
                                    );
                                  })
                                ) : (
                                  <p style={{ color: 'red' }}>No matching items found</p>
                                )}
                              </div>
                            </div>
                          </div>
                          {/* <div className='d-flex justify-content-between align-item-center position-relative'>
                            <label className='lab-1'> Inventory values are affected </label>
                            <label> :</label>
                            <input
                              type="text"
                              name='inventry_values_are_affected'
                              id='inventry_values_are_affected'
                              ref={inventry_values_are_affected_input_ref}
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              value={inventry_values_are_affected || ""} />
                            <div id="myDropdown-list" className={`dropdown_yes_no_list ${showInventoryValue ? 'show-list' : ''}`}>
                              <div className='col dropdown_header'>
                                Yes / No
                              </div>
                              <div id='data'>
                                {filteredDataYesNo?.length > 0 ? (
                                  filteredDataYesNo?.map((key, number) => {
                                    const name = key?.name;
                                    const id = key?.id;
                                    const index = name?.toLowerCase()?.indexOf(inventry_values_are_affected?.toLowerCase());
                                    return (
                                      <Link
                                        className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                        id='list'
                                        key={number}
                                        onClick={() => {
                                          setSelectedItem(number)
                                          if (number === selectedItem) {
                                            setState({ ...state, inventry_values_are_affected: name })
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                            setShowInventoryValue(false)
                                          }
                                        }}
                                      >
                                        {index !== -1 ? (
                                          < >
                                            {name.substring(0, index)}
                                            <span style={{ color: 'red' }}>{name.substring(index, index + inventry_values_are_affected.length)}</span>
                                            {name.substring(index + inventry_values_are_affected.length)}
                                          </>
                                        ) : (
                                          name
                                        )}
                                      </Link>
                                    );
                                  })
                                ) : (
                                  <p style={{ color: 'red' }}>No matching items found</p>
                                )}
                              </div>
                            </div>
                          </div> */}
                          {/* {
                            behave_as_duties_and_taxes_ledger == 'Yes' ?
                              <div>
                                <div className='d-flex justify-content-between align-item-center position-relative'>
                                  <label className='lab-1'> Type of Duty/Tax </label>
                                  <label> :</label>
                                  <input
                                    type="text"
                                    list='todt'
                                    name='type_of_duty_tax'
                                    id='type_of_duty_tax'
                                    className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                    onKeyUp={handleChange}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    ref={type_of_duty_tax_input_ref}
                                    value={type_of_duty_tax || ""} />
                                  <datalist id='todt' >
                                    <option value="GST">GST</option>
                                    <option value="Others">Others</option>
                                  </datalist>
                                </div>
                                {
                                  type_of_duty_tax === 'GST' ?
                                    <div className='d-flex justify-content-between align-item-center position-relative'>
                                      <label className='lab-1'> Tax Type </label>
                                      <label> :</label>
                                      <input
                                        type="text"
                                        list='taxtype'
                                        name='tax_type'
                                        id='tax_type'
                                        className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                        onKeyUp={handleChange}
                                        onChange={handleChange}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        ref={tax_type_input_ref}
                                        value={tax_type || ""} />
                                      <datalist id='taxtype' >
                                        <option value="IGST">IGST</option>
                                        <option value="CGST">CGST</option>
                                        <option value="SGST/UTGST">SGST/UTGST</option>
                                        <option value="Cess">Cess</option>
                                      </datalist>
                                    </div> : null
                                }
                                {
                                  tax_type === 'Cess' ?
                                    <div className='d-flex justify-content-between align-item-center position-relative'>
                                      <label className='lab-1'> Valuation Type </label>
                                      <label> :</label>
                                      <input
                                        type="text"
                                        list='vt'
                                        name='valuation_type'
                                        id='valuation_type'
                                        className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                        onKeyUp={handleChange}
                                        onChange={handleChange}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        ref={valuation_type_input_ref}
                                        value={valuation_type || ""} />
                                      <datalist id='vt' >
                                        <option value="Any">Any</option>
                                        <option value="Based On Quantity">Based On Quantity</option>
                                        <option value="Based On Value">Based On Value</option>
                                      </datalist>
                                    </div> : null
                                }
                                {valuation_type === 'Based On Quantity' ?
                                  <div className='d-flex justify-content-between align-item-center'>
                                    <label className='lab-1'> Rate per Unit </label>
                                    <label> :</label>
                                    <input
                                      type="text"
                                      name='rate_per_unit'
                                      id='rate_per_unit'
                                      className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                      onKeyUp={handleChange}
                                      onChange={handleChange}
                                      onFocus={handleFocus}
                                      onBlur={handleBlur}
                                      ref={rate_per_unit_input_ref}
                                      value={rate_per_unit || ""} />
                                  </div> : null
                                }
                                {
                                  valuation_type === 'Based On Quantity' ? null :
                                    <div className='d-flex justify-content-between align-item-center'>
                                      <label className='lab-1'> Percentage of Calculation </label>
                                      <label> :</label>
                                      <input
                                        type="number"
                                        name='percentage_of_calculation'
                                        id='percentage_of_calculation'
                                        className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                        onKeyUp={handleChange}
                                        onChange={handleChange}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        ref={percentage_of_calculation_input_ref}
                                        value={percentage_of_calculation || ""} />
                                    </div>
                                }
                                {
                                  percentage_of_calculation || rate_per_unit > 0 ?
                                    <div className='d-flex justify-content-between align-item-center position-relative'>
                                      <label className='lab-1'> Rounding Method </label>
                                      <label> :</label>
                                      <input
                                        type="text"
                                        list='rd'
                                        name='rounding_method'
                                        id='rounding_method'
                                        className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                        onKeyUp={handleChange}
                                        onChange={handleChange}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        ref={rounding_method_input_ref}
                                        value={rounding_method || ""} />
                                      <datalist id='rd' >
                                        <option value="Not Applicable">Not Applicable</option>
                                        <option value="Downward Rounding">Downward Rounding</option>
                                        <option value="Normal Rounding">Normal Rounding</option>
                                        <option value="Upward Rounding">Upward Rounding</option>
                                      </datalist>
                                    </div> : null
                                }
                                {
                                  rounding_method === 'Downward Rounding' || 'Normal Rounding' || 'Upward Rounding' ?
                                    <div className='d-flex justify-content-between align-item-center'>
                                      <label className='lab-1'> Rounding Limit </label>
                                      <label> :</label>
                                      <input
                                        type="number"
                                        name='rounding_limit'
                                        id='rounding_limit'
                                        className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                        onKeyUp={handleChange}
                                        onChange={handleChange}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        ref={rounding_limit_input_ref}
                                        value={rounding_limit || ""} />
                                    </div> : null
                                }
                              </div> : null
                          }
                          <p className='m-0 p-0 ps-1 text-start text-info font_size'>Statutory Details</p>
                          <div className='d-flex justify-content-between align-item-center position-relative'>
                            <label className='lab-1'> Is GST applicable </label>
                            <label> :</label>
                            <input
                              type="text"
                              name='is_gst_applicable'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={is_gst_applicable_input_ref}
                              value={is_gst_applicable || ""} />
                            <div id="myDropdown-list" className={`dropdown_yes_no_list ${isGstAppShow ? 'show-list' : ''}`}>
                              <div className='col dropdown_header'>
                                Select List
                              </div>
                              <div id='data'>
                                {filteredDataIsGstApplicable?.length > 0 ? (
                                  filteredDataIsGstApplicable?.map((key, number) => {
                                    const name = key?.name;
                                    const id = key?.id;
                                    const index = name?.toLowerCase()?.indexOf(is_gst_applicable?.toLowerCase());
                                    return (
                                      <Link
                                        className={`list ${number === selectedItem ? 'selected' : ''}`}
                                        id='list'
                                        key={number}
                                        onClick={() => {
                                          setSelectedItem(number)
                                          if (number === selectedItem) {
                                            setState({ ...state, is_gst_applicable: name })
                                            // nett_debit_credit_balance_for_reporting_input_ref.current.focus();
                                            // nett_debit_credit_balance_for_reporting_input_ref.current.setSelectionRange(0, 0);
                                            setIsGstAppShow(false)
                                          }
                                        }}
                                      >
                                        {index !== -1 ? (
                                          < >
                                            {name.substring(0, index)}
                                            <span style={{ color: 'red' }}>{name.substring(index, index + is_gst_applicable.length)}</span>
                                            {name.substring(index + is_gst_applicable.length)}
                                          </>
                                        ) : (
                                          name
                                        )}
                                      </Link>
                                    );
                                  })
                                ) : (
                                  <p style={{ color: 'red' }}>No matching items found</p>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className='d-flex justify-content-between align-item-center' >
                            <label className='lab-1'> Set/Alter GST Details </label>
                            <label> :</label>
                            <input
                              type="text"
                              name='set_alter_gst_details'
                              id='set_alter_gst_details'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={set_alter_gst_details_input_ref}
                              value={set_alter_gst_details || ""} />
                          </div>
                          <div className='d-flex justify-content-between align-item-center' >
                            <label className='lab-1'>Include in assessable value calculation for </label>
                            <label> :</label>
                            <input
                              type="number"
                              name='include_in_assessable_value_calculation_for'
                              id='include_in_assessable_value_calculation_for'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              value={include_in_assessable_value_calculation_for || ""}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={include_in_assessable_value_calculation_for_input_ref} />
                          </div> */}
                        </div> : null
                    }
                    {
                      under === 'Deposits(Asset)' ?
                        // depositsAssetsRendering ?
                        <div>
                          <div className='d-flex justify-content-between align-item-center  position-relative'>
                            <label className='lab-1'> Maintain balance bill-by-bill </label>
                            <label> :</label>
                            <input
                              type="text"
                              ref={maintain_balance_bill_by_bill_input_ref}
                              name='maintain_balance_bill_by_bill'
                              id='Maintainbalance'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              value={maintain_balance_bill_by_bill || ""} />
                            <div id="myDropdown-list" className={`dropdown_yes_no_list ${showMaintainBalance ? 'show-list' : ''}`}>
                              <div className='col dropdown_header'>
                                Yes / No
                              </div>
                              <div id='data'>
                                {filteredDataYesNo?.length > 0 ? (
                                  filteredDataYesNo?.map((key, number) => {
                                    const name = key?.name;
                                    const id = key?.id;
                                    const index = name?.toLowerCase()?.indexOf(maintain_balance_bill_by_bill?.toLowerCase());
                                    return (
                                      <Link
                                        className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                        id='list'
                                        key={number}
                                        onClick={() => {
                                          setSelectedItem(number)
                                          if (number === selectedItem) {
                                            setState({ ...state, maintain_balance_bill_by_bill: name })
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                            setShowMaintainBalance(false)
                                          }
                                        }}
                                      >
                                        {index !== -1 ? (
                                          < >
                                            {name.substring(0, index)}
                                            <span style={{ color: 'red' }}>{name.substring(index, index + maintain_balance_bill_by_bill.length)}</span>
                                            {name.substring(index + maintain_balance_bill_by_bill.length)}
                                          </>
                                        ) : (
                                          name
                                        )}
                                      </Link>
                                    );
                                  })
                                ) : (
                                  <p style={{ color: 'red' }}>No matching items found</p>
                                )}
                              </div>
                            </div>
                          </div>
                          {
                            maintain_balance_bill_by_bill == 'Yes' ?
                              <div className='d-flex justify-content-between align-item-center'>
                                <label className='lab-1'> Default Credit period </label>
                                <label> :</label>
                                <input
                                  type="text"
                                  name='default_credit_period'
                                  id='default-credit-period'
                                  className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                  onKeyUp={handleChange}
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  ref={default_credit_period_input_ref}
                                  value={default_credit_period || ""} />
                              </div> : null
                          }
                          <div className='d-flex justify-content-between align-item-center position-relative'>
                            <label className='lab-1'> Inventory values are affected </label>
                            <label> :</label>
                            <input
                              type="text"
                              name='inventry_values_are_affected'
                              id='inventry_values_are_affected'
                              ref={inventry_values_are_affected_input_ref}
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              value={inventry_values_are_affected || ""} />
                            <div id="myDropdown-list" className={`dropdown_yes_no_list ${showInventoryValue ? 'show-list' : ''}`}>
                              <div className='col dropdown_header'>
                                Yes / No
                              </div>
                              <div id='data'>
                                {filteredDataYesNo?.length > 0 ? (
                                  filteredDataYesNo?.map((key, number) => {
                                    const name = key?.name;
                                    const id = key?.id;
                                    const index = name?.toLowerCase()?.indexOf(inventry_values_are_affected?.toLowerCase());
                                    return (
                                      <Link
                                        className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                        id='list'
                                        key={number}
                                        onClick={() => {
                                          setSelectedItem(number)
                                          if (number === selectedItem) {
                                            setState({ ...state, inventry_values_are_affected: name })
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                            setShowInventoryValue(false)
                                          }
                                        }}
                                      >
                                        {index !== -1 ? (
                                          < >
                                            {name.substring(0, index)}
                                            <span style={{ color: 'red' }}>{name.substring(index, index + inventry_values_are_affected.length)}</span>
                                            {name.substring(index + inventry_values_are_affected.length)}
                                          </>
                                        ) : (
                                          name
                                        )}
                                      </Link>
                                    );
                                  })
                                ) : (
                                  <p style={{ color: 'red' }}>No matching items found</p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div> : null
                    }
                    {
                      under === 'Direct Expenses' || under === 'Direct Incomes' ?
                        // directExpensesRendering ?
                        <div>
                          <div className='d-flex justify-content-between align-item-center  position-relative'>
                            <label className='lab-1'> Maintain balance bill-by-bill </label>
                            <label> :</label>
                            <input
                              type="text"
                              ref={maintain_balance_bill_by_bill_input_ref}
                              name='maintain_balance_bill_by_bill'
                              id='Maintainbalance'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              value={maintain_balance_bill_by_bill || ""} />
                            <div id="myDropdown-list" className={`dropdown_yes_no_list ${showMaintainBalance ? 'show-list' : ''}`}>
                              <div className='col dropdown_header'>
                                Yes / No
                              </div>
                              <div id='data'>
                                {filteredDataYesNo?.length > 0 ? (
                                  filteredDataYesNo?.map((key, number) => {
                                    const name = key?.name;
                                    const id = key?.id;
                                    const index = name?.toLowerCase()?.indexOf(maintain_balance_bill_by_bill?.toLowerCase());
                                    return (
                                      <Link
                                        className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                        id='list'
                                        key={number}
                                        onClick={() => {
                                          setSelectedItem(number)
                                          if (number === selectedItem) {
                                            setState({ ...state, maintain_balance_bill_by_bill: name })
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                            setShowMaintainBalance(false)
                                          }
                                        }}
                                      >
                                        {index !== -1 ? (
                                          < >
                                            {name.substring(0, index)}
                                            <span style={{ color: 'red' }}>{name.substring(index, index + maintain_balance_bill_by_bill.length)}</span>
                                            {name.substring(index + maintain_balance_bill_by_bill.length)}
                                          </>
                                        ) : (
                                          name
                                        )}
                                      </Link>
                                    );
                                  })
                                ) : (
                                  <p style={{ color: 'red' }}>No matching items found</p>
                                )}
                              </div>
                            </div>
                          </div>
                          {
                            maintain_balance_bill_by_bill == 'Yes' ?
                              <div className='d-flex justify-content-between align-item-center'>
                                <label className='lab-1'> Default Credit period </label>
                                <label> :</label>
                                <input
                                  type="text"
                                  name='default_credit_period'
                                  id='default-credit-period'
                                  className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                  onKeyUp={handleChange}
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  ref={default_credit_period_input_ref}
                                  value={default_credit_period || ""} />
                              </div> : null
                          }
                          <div className='d-flex justify-content-between align-item-center position-relative'>
                            <label className='lab-1'> Type of Ledger </label>
                            <label> :</label>
                            <input
                              type="text"
                              name='type_of_ledger'
                              id='type_of_ledger'
                              ref={type_of_ledger_input_ref}
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              value={type_of_ledger || ""} />
                            <div id="myDropdown-list-type" className={`dropdown-content-list-for-small-component ${showListTypeOfLedger ? 'show-list' : ''}`}>
                              <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                Types of Ledger
                              </div>

                              <div id='data'>
                                {typesOfLederList.map((item, index) =>
                                  <Link className='list' id='list' key={index} >{item?.name}</Link>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className='d-flex justify-content-between align-item-center position-relative'>
                            <label className='lab-1'> Method of Appropriation to Allocate Additional cost in purchase invoice </label>
                            <label> :</label>
                            <input
                              type="text"
                              name='method_of_appropriation_to_allocate_additional_costs_in_purchase_invoice'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={method_of_appropriation_to_allocate_additional_costs_in_purchase_invoice_input_ref}
                              value={method_of_appropriation_to_allocate_additional_costs_in_purchase_invoice || ""} />
                            <div id="myDropdown-list" className={`dropdown_yes_no_list ${isGstAppShow ? 'show-list' : ''}`}>
                              <div className='col dropdown_header'>
                                Select List
                              </div>
                              <div id='data'>
                                {filteredDataIsGstApplicable?.length > 0 ? (
                                  filteredDataIsGstApplicable?.map((key, number) => {
                                    const name = key?.name;
                                    const id = key?.id;
                                    const index = name?.toLowerCase()?.indexOf(method_of_appropriation_to_allocate_additional_costs_in_purchase_invoice?.toLowerCase());
                                    return (
                                      <Link
                                        className={`list ${number === selectedItem ? 'selected' : ''}`}
                                        id='list'
                                        key={number}
                                        onClick={() => {
                                          setSelectedItem(number)
                                          if (number === selectedItem) {
                                            setState({ ...state, method_of_appropriation_to_allocate_additional_costs_in_purchase_invoice: name })
                                            // nett_debit_credit_balance_for_reporting_input_ref.current.focus();
                                            // nett_debit_credit_balance_for_reporting_input_ref.current.setSelectionRange(0, 0);
                                            setIsGstAppShow(false)
                                          }
                                        }}
                                      >
                                        {index !== -1 ? (
                                          < >
                                            {name.substring(0, index)}
                                            <span style={{ color: 'red' }}>{name.substring(index, index + method_of_appropriation_to_allocate_additional_costs_in_purchase_invoice.length)}</span>
                                            {name.substring(index + method_of_appropriation_to_allocate_additional_costs_in_purchase_invoice.length)}
                                          </>
                                        ) : (
                                          name
                                        )}
                                      </Link>
                                    );
                                  })
                                ) : (
                                  <p style={{ color: 'red' }}>No matching items found</p>
                                )}
                              </div>
                            </div>
                          </div>
                          {
                            type_of_ledger === 'Invoice Rounding' ?
                              <div>
                                <div className='d-flex justify-content-between align-item-center position-relative'>
                                  <label className='lab-1'> Rounding Method </label>
                                  <label> :</label>
                                  <input
                                    type="text"
                                    list='rd'
                                    name='rounding_method'
                                    id='rounding_method'
                                    className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                    onKeyUp={handleChange}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    ref={rounding_method_input_ref}
                                    value={rounding_method || ""} />
                                  <datalist id='rd' >
                                    <option value="Not Applicable">Not Applicable</option>
                                    <option value="Downward Rounding">Downward Rounding</option>
                                    <option value="Normal Rounding">Normal Rounding</option>
                                    <option value="Upward Rounding">Upward Rounding</option>
                                  </datalist>
                                </div>
                                {
                                  rounding_method === 'Downward Rounding' || 'Normal Rounding' || 'Upward Rounding' ?
                                    <div className='d-flex justify-content-between align-item-center'>
                                      <label className='lab-1'> Rounding Limit </label>
                                      <label> :</label>
                                      <input
                                        type="text"
                                        name='rounding_limit'
                                        id='rounding_limit'
                                        className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                        onKeyUp={handleChange}
                                        onChange={handleChange}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        ref={rounding_limit_input_ref}
                                        value={rounding_limit || ""} />
                                    </div> : null
                                }
                              </div> : null
                          }
                        </div> : null
                    }

                    {
                      under === 'Duties & Taxes' ?
                        // dutiesAndTaxesRendering ?
                        <div>
                          <div className='d-flex justify-content-between align-item-center position-relative'>
                            <label className='lab-1'> Type of Duty/Tax </label>
                            <label> :</label>
                            <input
                              type="text"
                              list='todt'
                              name='type_of_duty_tax'
                              id='type_of_duty_tax'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={type_of_duty_tax_input_ref}
                              value={type_of_duty_tax || ""} />
                            <datalist id='todt' >
                              <option value="GST">GST</option>
                              <option value="Others">Others</option>
                            </datalist>
                          </div>
                          {
                            type_of_duty_tax === 'GST' ?
                              <div className='d-flex justify-content-between align-item-center position-relative'>
                                <label className='lab-1'> Tax Type </label>
                                <label> :</label>
                                <input
                                  type="text"
                                  list='taxtype'
                                  name='tax_type'
                                  id='tax_type'
                                  className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                  onKeyUp={handleChange}
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  ref={tax_type_input_ref}
                                  value={tax_type || ""} />
                                <datalist id='taxtype' >
                                  <option value="IGST">IGST</option>
                                  <option value="CGST">CGST</option>
                                  <option value="SGST/UTGST">SGST/UTGST</option>
                                  <option value="Cess">Cess</option>
                                </datalist>
                              </div> : null
                          }
                          {
                            tax_type === 'Cess' ?
                              <div className='d-flex justify-content-between align-item-center position-relative'>
                                <label className='lab-1'> Valuation Type </label>
                                <label> :</label>
                                <input
                                  type="text"
                                  list='vt'
                                  name='valuation_type'
                                  id='valuation_type'
                                  className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                  onKeyUp={handleChange}
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  ref={valuation_type_input_ref}
                                  value={valuation_type || ""} />
                                <datalist id='vt' >
                                  <option value="Any">Any</option>
                                  <option value="Based On Quantity">Based On Quantity</option>
                                  <option value="Based On Value">Based On Value</option>
                                </datalist>
                              </div> : null
                          }
                          {valuation_type === 'Based On Quantity' ?
                            <div className='d-flex justify-content-between align-item-center'>
                              <label className='lab-1'> Rate per Unit </label>
                              <label> :</label>
                              <input
                                type="text"
                                name='rate_per_unit'
                                id='rate_per_unit'
                                className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                onKeyUp={handleChange}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                ref={rate_per_unit_input_ref}
                                value={rate_per_unit || ""} />
                            </div> : null
                          }
                          {
                            valuation_type === 'Based On Quantity' ? null :
                              <div className='d-flex justify-content-between align-item-center'>
                                <label className='lab-1'> Percentage of Calculation </label>
                                <label> :</label>
                                <input
                                  type="number"
                                  name='percentage_of_calculation'
                                  id='percentage_of_calculation'
                                  className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                  onKeyUp={handleChange}
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  ref={percentage_of_calculation_input_ref}
                                  value={percentage_of_calculation || ""} />
                              </div>
                          }
                          {
                            percentage_of_calculation || rate_per_unit > 0 ?
                              <div className='d-flex justify-content-between align-item-center position-relative'>
                                <label className='lab-1'> Rounding Method </label>
                                <label> :</label>
                                <input
                                  type="text"
                                  list='rd'
                                  name='rounding_method'
                                  id='rounding_method'
                                  className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                  onKeyUp={handleChange}
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  ref={rounding_method_input_ref}
                                  value={rounding_method || ""} />
                                <datalist id='rd' >
                                  <option value="Not Applicable">Not Applicable</option>
                                  <option value="Downward Rounding">Downward Rounding</option>
                                  <option value="Normal Rounding">Normal Rounding</option>
                                  <option value="Upward Rounding">Upward Rounding</option>
                                </datalist>
                              </div> : null
                          }
                          {
                            rounding_method === 'Downward Rounding' || 'Normal Rounding' || 'Upward Rounding' ?
                              <div className='d-flex justify-content-between align-item-center'>
                                <label className='lab-1'> Rounding Limit </label>
                                <label> :</label>
                                <input
                                  type="text"
                                  name='rounding_limit'
                                  id='rounding_limit'
                                  className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                  onKeyUp={handleChange}
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  ref={rounding_limit_input_ref}
                                  value={rounding_limit || ""} />
                              </div> : null
                          }
                          <div className='d-flex justify-content-between align-item-center  position-relative'>
                            <label className='lab-1'> Maintain balance bill-by-bill </label>
                            <label> :</label>
                            <input
                              type="text"
                              ref={maintain_balance_bill_by_bill_input_ref}
                              name='maintain_balance_bill_by_bill'
                              id='Maintainbalance'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              value={maintain_balance_bill_by_bill || ""} />
                            <div id="myDropdown-list" className={`dropdown_yes_no_list ${showMaintainBalance ? 'show-list' : ''}`}>
                              <div className='col dropdown_header'>
                                Yes / No
                              </div>
                              <div id='data'>
                                {filteredDataYesNo?.length > 0 ? (
                                  filteredDataYesNo?.map((key, number) => {
                                    const name = key?.name;
                                    const id = key?.id;
                                    const index = name?.toLowerCase()?.indexOf(maintain_balance_bill_by_bill?.toLowerCase());
                                    return (
                                      <Link
                                        className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                        id='list'
                                        key={number}
                                        onClick={() => {
                                          setSelectedItem(number)
                                          if (number === selectedItem) {
                                            setState({ ...state, maintain_balance_bill_by_bill: name })
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                            setShowMaintainBalance(false)
                                          }
                                        }}
                                      >
                                        {index !== -1 ? (
                                          < >
                                            {name.substring(0, index)}
                                            <span style={{ color: 'red' }}>{name.substring(index, index + maintain_balance_bill_by_bill.length)}</span>
                                            {name.substring(index + maintain_balance_bill_by_bill.length)}
                                          </>
                                        ) : (
                                          name
                                        )}
                                      </Link>
                                    );
                                  })
                                ) : (
                                  <p style={{ color: 'red' }}>No matching items found</p>
                                )}
                              </div>
                            </div>
                          </div>
                          {
                            maintain_balance_bill_by_bill == 'Yes' ?
                              <div className='d-flex justify-content-between align-item-center'>
                                <label className='lab-1'> Default Credit period </label>
                                <label> :</label>
                                <input
                                  type="text"
                                  name='default_credit_period'
                                  id='default-credit-period'
                                  className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                  onKeyUp={handleChange}
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  ref={default_credit_period_input_ref}
                                  value={default_credit_period || ""} />
                              </div> : null
                          }
                        </div> : null
                    }
                    {
                      under === 'Fixed Assets' ?
                        // fixedAssetsRendering ?
                        <div>
                          <div className='d-flex justify-content-between align-item-center  position-relative'>
                            <label className='lab-1'> Maintain balance bill-by-bill </label>
                            <label> :</label>
                            <input
                              type="text"
                              ref={maintain_balance_bill_by_bill_input_ref}
                              name='maintain_balance_bill_by_bill'
                              id='Maintainbalance'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              value={maintain_balance_bill_by_bill || ""} />
                            <div id="myDropdown-list" className={`dropdown_yes_no_list ${showMaintainBalance ? 'show-list' : ''}`}>
                              <div className='col dropdown_header'>
                                Yes / No
                              </div>
                              <div id='data'>
                                {filteredDataYesNo?.length > 0 ? (
                                  filteredDataYesNo?.map((key, number) => {
                                    const name = key?.name;
                                    const id = key?.id;
                                    const index = name?.toLowerCase()?.indexOf(maintain_balance_bill_by_bill?.toLowerCase());
                                    return (
                                      <Link
                                        className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                        id='list'
                                        key={number}
                                        onClick={() => {
                                          setSelectedItem(number)
                                          if (number === selectedItem) {
                                            setState({ ...state, maintain_balance_bill_by_bill: name })
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                            setShowMaintainBalance(false)
                                          }
                                        }}
                                      >
                                        {index !== -1 ? (
                                          < >
                                            {name.substring(0, index)}
                                            <span style={{ color: 'red' }}>{name.substring(index, index + maintain_balance_bill_by_bill.length)}</span>
                                            {name.substring(index + maintain_balance_bill_by_bill.length)}
                                          </>
                                        ) : (
                                          name
                                        )}
                                      </Link>
                                    );
                                  })
                                ) : (
                                  <p style={{ color: 'red' }}>No matching items found</p>
                                )}
                              </div>
                            </div>
                          </div>
                          {
                            maintain_balance_bill_by_bill == 'Yes' ?
                              <div className='d-flex justify-content-between align-item-center'>
                                <label className='lab-1'> Default Credit period </label>
                                <label> :</label>
                                <input
                                  type="text"
                                  name='default_credit_period'
                                  id='default-credit-period'
                                  className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                  onKeyUp={handleChange}
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  ref={default_credit_period_input_ref}
                                  value={default_credit_period || ""} />
                              </div> : null
                          }
                        </div> : null
                    }
                    {
                      under === 'Purchase Accounts' || under === 'Sales Accounts' ?
                        // indirectExpensesRendering || purchaseAccountsRendering ?
                        <div>
                          <div className='d-flex justify-content-between align-item-center  position-relative'>
                            <label className='lab-1'> Maintain balance bill-by-bill </label>
                            <label> :</label>
                            <input
                              type="text"
                              ref={maintain_balance_bill_by_bill_input_ref}
                              name='maintain_balance_bill_by_bill'
                              id='Maintainbalance'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              value={maintain_balance_bill_by_bill || ""} />
                            <div id="myDropdown-list" className={`dropdown_yes_no_list ${showMaintainBalance ? 'show-list' : ''}`}>
                              <div className='col dropdown_header'>
                                Yes / No
                              </div>
                              <div id='data'>
                                {filteredDataYesNo?.length > 0 ? (
                                  filteredDataYesNo?.map((key, number) => {
                                    const name = key?.name;
                                    const id = key?.id;
                                    const index = name?.toLowerCase()?.indexOf(maintain_balance_bill_by_bill?.toLowerCase());
                                    return (
                                      <Link
                                        className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                        id='list'
                                        key={number}
                                        onClick={() => {
                                          setSelectedItem(number)
                                          if (number === selectedItem) {
                                            setState({ ...state, maintain_balance_bill_by_bill: name })
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                            setShowMaintainBalance(false)
                                          }
                                        }}
                                      >
                                        {index !== -1 ? (
                                          < >
                                            {name.substring(0, index)}
                                            <span style={{ color: 'red' }}>{name.substring(index, index + maintain_balance_bill_by_bill.length)}</span>
                                            {name.substring(index + maintain_balance_bill_by_bill.length)}
                                          </>
                                        ) : (
                                          name
                                        )}
                                      </Link>
                                    );
                                  })
                                ) : (
                                  <p style={{ color: 'red' }}>No matching items found</p>
                                )}
                              </div>
                            </div>
                          </div>
                          {
                            maintain_balance_bill_by_bill == 'Yes' ?
                              <div className='d-flex justify-content-between align-item-center'>
                                <label className='lab-1'> Default Credit period </label>
                                <label> :</label>
                                <input
                                  type="text"
                                  name='default_credit_period'
                                  id='default-credit-period'
                                  className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                  onKeyUp={handleChange}
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  ref={default_credit_period_input_ref}
                                  value={default_credit_period || ""} />
                              </div> : null
                          }
                        </div> : null
                    }
                    {
                      under === 'Indirect Incomes' || under === 'Indirect Expenses' ?
                        // indirectIncomesRendering ?
                        <div>
                          <div className='d-flex justify-content-between align-item-center  position-relative'>
                            <label className='lab-1'> Maintain balance bill-by-bill </label>
                            <label> :</label>
                            <input
                              type="text"
                              ref={maintain_balance_bill_by_bill_input_ref}
                              name='maintain_balance_bill_by_bill'
                              id='Maintainbalance'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              value={maintain_balance_bill_by_bill || ""} />
                            <div id="myDropdown-list" className={`dropdown_yes_no_list ${showMaintainBalance ? 'show-list' : ''}`}>
                              <div className='col dropdown_header'>
                                Yes / No
                              </div>
                              <div id='data'>
                                {filteredDataYesNo?.length > 0 ? (
                                  filteredDataYesNo?.map((key, number) => {
                                    const name = key?.name;
                                    const id = key?.id;
                                    const index = name?.toLowerCase()?.indexOf(maintain_balance_bill_by_bill?.toLowerCase());
                                    return (
                                      <Link
                                        className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                        id='list'
                                        key={number}
                                        onClick={() => {
                                          setSelectedItem(number)
                                          if (number === selectedItem) {
                                            setState({ ...state, maintain_balance_bill_by_bill: name })
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                            setShowMaintainBalance(false)
                                          }
                                        }}
                                      >
                                        {index !== -1 ? (
                                          < >
                                            {name.substring(0, index)}
                                            <span style={{ color: 'red' }}>{name.substring(index, index + maintain_balance_bill_by_bill.length)}</span>
                                            {name.substring(index + maintain_balance_bill_by_bill.length)}
                                          </>
                                        ) : (
                                          name
                                        )}
                                      </Link>
                                    );
                                  })
                                ) : (
                                  <p style={{ color: 'red' }}>No matching items found</p>
                                )}
                              </div>
                            </div>
                          </div>
                          {
                            maintain_balance_bill_by_bill == 'Yes' ?
                              <div className='d-flex justify-content-between align-item-center'>
                                <label className='lab-1'> Default Credit period </label>
                                <label> :</label>
                                <input
                                  type="text"
                                  name='default_credit_period'
                                  id='default-credit-period'
                                  className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                  onKeyUp={handleChange}
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  ref={default_credit_period_input_ref}
                                  value={default_credit_period || ""} />
                              </div> : null
                          }
                          <div className='d-flex justify-content-between align-item-center position-relative'>
                            <label className='lab-1'> Type of Ledger </label>
                            <label> :</label>
                            <input
                              type="text"
                              name='type_of_ledger'
                              id='type_of_ledger'
                              ref={type_of_ledger_input_ref}
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              value={type_of_ledger || ""} />
                            <div id="myDropdown-list-type" className={`dropdown-content-list-for-small-component ${showListTypeOfLedger ? 'show-list' : ''}`} >
                              <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                Types of Ledger
                              </div>

                              <div id='data'>
                                {typesOfLederList.map((item, index) =>
                                  <Link className='list' id='list' key={index}>{item?.name}</Link>
                                )}
                              </div>
                            </div>
                          </div>
                          {
                            type_of_ledger === 'Invoice Rounding' ?
                              <div>
                                <div className='d-flex justify-content-between align-item-center position-relative'>
                                  <label className='lab-1'> Rounding Method </label>
                                  <label> :</label>
                                  <input
                                    type="text"
                                    list='rd'
                                    name='rounding_method'
                                    id='rounding_method'
                                    className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                    onKeyUp={handleChange}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    ref={rounding_method_input_ref}
                                    value={rounding_method || ""} />
                                  <datalist id='rd' >
                                    <option value="Not Applicable">Not Applicable</option>
                                    <option value="Downward Rounding">Downward Rounding</option>
                                    <option value="Normal Rounding">Normal Rounding</option>
                                    <option value="Upward Rounding">Upward Rounding</option>
                                  </datalist>
                                </div>
                                {
                                  rounding_method === 'Downward Rounding' || 'Normal Rounding' || 'Upward Rounding' ?
                                    <div className='d-flex justify-content-between align-item-center'>
                                      <label className='lab-1'> Rounding Limit </label>
                                      <label> :</label>
                                      <input
                                        type="text"
                                        name='rounding_limit'
                                        id='rounding_limit'
                                        className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                        onKeyUp={handleChange}
                                        onChange={handleChange}
                                        onFocus={handleFocus}
                                        onBlur={handleBlur}
                                        ref={rounding_limit_input_ref}
                                        value={rounding_limit || ""} />
                                    </div> : null
                                }
                              </div> : null
                          }
                        </div> : null
                    }
                    {
                      under === 'Investments' || under === 'Loans & Advance (Asset)' || under === 'Loans(Liability)' ?
                        // investmentsRendering ?
                        <div>
                          <div className='d-flex justify-content-between align-item-center  position-relative'>
                            <label className='lab-1'> Maintain balance bill-by-bill </label>
                            <label> :</label>
                            <input
                              type="text"
                              ref={maintain_balance_bill_by_bill_input_ref}
                              name='maintain_balance_bill_by_bill'
                              id='Maintainbalance'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              value={maintain_balance_bill_by_bill || ""} />
                            <div id="myDropdown-list" className={`dropdown_yes_no_list ${showMaintainBalance ? 'show-list' : ''}`}>
                              <div className='col dropdown_header'>
                                Yes / No
                              </div>
                              <div id='data'>
                                {filteredDataYesNo?.length > 0 ? (
                                  filteredDataYesNo?.map((key, number) => {
                                    const name = key?.name;
                                    const id = key?.id;
                                    const index = name?.toLowerCase()?.indexOf(maintain_balance_bill_by_bill?.toLowerCase());
                                    return (
                                      <Link
                                        className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                        id='list'
                                        key={number}
                                        onClick={() => {
                                          setSelectedItem(number)
                                          if (number === selectedItem) {
                                            setState({ ...state, maintain_balance_bill_by_bill: name })
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                            setShowMaintainBalance(false)
                                          }
                                        }}
                                      >
                                        {index !== -1 ? (
                                          < >
                                            {name.substring(0, index)}
                                            <span style={{ color: 'red' }}>{name.substring(index, index + maintain_balance_bill_by_bill.length)}</span>
                                            {name.substring(index + maintain_balance_bill_by_bill.length)}
                                          </>
                                        ) : (
                                          name
                                        )}
                                      </Link>
                                    );
                                  })
                                ) : (
                                  <p style={{ color: 'red' }}>No matching items found</p>
                                )}
                              </div>
                            </div>
                          </div>
                          {
                            maintain_balance_bill_by_bill == 'Yes' ?
                              <div className='d-flex justify-content-between align-item-center'>
                                <label className='lab-1'> Default Credit period </label>
                                <label> :</label>
                                <input
                                  type="text"
                                  name='default_credit_period'
                                  id='default-credit-period'
                                  className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                  onKeyUp={handleChange}
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  ref={default_credit_period_input_ref}
                                  value={default_credit_period || ""} />
                              </div> : null
                          }
                        </div> : null
                    }
                    {
                      under === 'Loan & Advance (Asset)' || under === 'Misc. Expenses' || under === 'Provisions' || under === 'Secured Loans' || under === 'Unsecured Loans' ?
                        // loanAndAdvanceAssetRendering || loanLiabilityRendering || miscExpensesRendering || provisionRendering || securedLoansRendering || unsecuredLoansRendering ?
                        <div>
                          <div className='d-flex justify-content-between align-item-center  position-relative'>
                            <label className='lab-1'> Maintain balance bill-by-bill </label>
                            <label> :</label>
                            <input
                              type="text"
                              ref={maintain_balance_bill_by_bill_input_ref}
                              name='maintain_balance_bill_by_bill'
                              id='Maintainbalance'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              value={maintain_balance_bill_by_bill || ""} />
                            <div id="myDropdown-list" className={`dropdown_yes_no_list ${showMaintainBalance ? 'show-list' : ''}`}>
                              <div className='col dropdown_header'>
                                Yes / No
                              </div>
                              <div id='data'>
                                {filteredDataYesNo?.length > 0 ? (
                                  filteredDataYesNo?.map((key, number) => {
                                    const name = key?.name;
                                    const id = key?.id;
                                    const index = name?.toLowerCase()?.indexOf(maintain_balance_bill_by_bill?.toLowerCase());
                                    return (
                                      <Link
                                        className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                        id='list'
                                        key={number}
                                        onClick={() => {
                                          setSelectedItem(number)
                                          if (number === selectedItem) {
                                            setState({ ...state, maintain_balance_bill_by_bill: name })
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                            setShowMaintainBalance(false)
                                          }
                                        }}
                                      >
                                        {index !== -1 ? (
                                          < >
                                            {name.substring(0, index)}
                                            <span style={{ color: 'red' }}>{name.substring(index, index + maintain_balance_bill_by_bill.length)}</span>
                                            {name.substring(index + maintain_balance_bill_by_bill.length)}
                                          </>
                                        ) : (
                                          name
                                        )}
                                      </Link>
                                    );
                                  })
                                ) : (
                                  <p style={{ color: 'red' }}>No matching items found</p>
                                )}
                              </div>
                            </div>
                          </div>
                          {
                            maintain_balance_bill_by_bill == 'Yes' ?
                              <div className='d-flex justify-content-between align-item-center'>
                                <label className='lab-1'> Default Credit period </label>
                                <label> :</label>
                                <input
                                  type="text"
                                  name='default_credit_period'
                                  id='default-credit-period'
                                  className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                  onKeyUp={handleChange}
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  ref={default_credit_period_input_ref}
                                  value={default_credit_period || ""} />
                              </div> : null
                          }
                        </div> : null
                    }
                    {
                      under === 'Reserves & Surplus' || under === 'Suspense A/C' ?
                        // reservesAndSurplusRendering || suspenseAcRendering ?
                        <div>
                          <div className='d-flex justify-content-between align-item-center  position-relative'>
                            <label className='lab-1'> Maintain balance bill-by-bill </label>
                            <label> :</label>
                            <input
                              type="text"
                              ref={maintain_balance_bill_by_bill_input_ref}
                              name='maintain_balance_bill_by_bill'
                              id='Maintainbalance'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              value={maintain_balance_bill_by_bill || ""} />
                            <div id="myDropdown-list" className={`dropdown_yes_no_list ${showMaintainBalance ? 'show-list' : ''}`}>
                              <div className='col dropdown_header'>
                                Yes / No
                              </div>
                              <div id='data'>
                                {filteredDataYesNo?.length > 0 ? (
                                  filteredDataYesNo?.map((key, number) => {
                                    const name = key?.name;
                                    const id = key?.id;
                                    const index = name?.toLowerCase()?.indexOf(maintain_balance_bill_by_bill?.toLowerCase());
                                    return (
                                      <Link
                                        className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                        id='list'
                                        key={number}
                                        onClick={() => {
                                          setSelectedItem(number)
                                          if (number === selectedItem) {
                                            setState({ ...state, maintain_balance_bill_by_bill: name })
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                            setShowMaintainBalance(false)
                                          }
                                        }}
                                      >
                                        {index !== -1 ? (
                                          < >
                                            {name.substring(0, index)}
                                            <span style={{ color: 'red' }}>{name.substring(index, index + maintain_balance_bill_by_bill.length)}</span>
                                            {name.substring(index + maintain_balance_bill_by_bill.length)}
                                          </>
                                        ) : (
                                          name
                                        )}
                                      </Link>
                                    );
                                  })
                                ) : (
                                  <p style={{ color: 'red' }}>No matching items found</p>
                                )}
                              </div>
                            </div>
                          </div>
                          {
                            maintain_balance_bill_by_bill == 'Yes' ?
                              <div className='d-flex justify-content-between align-item-center'>
                                <label className='lab-1'> Default Credit period </label>
                                <label> :</label>
                                <input
                                  type="text"
                                  name='default_credit_period'
                                  id='default-credit-period'
                                  className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                  onKeyUp={handleChange}
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  ref={default_credit_period_input_ref}
                                  value={default_credit_period || ""} />
                              </div> : null
                          }
                        </div> : null
                    }
                  </div>
                  <div className='col-md-6'>
                    {
                      under === 'Bank Account' || under === 'Bank OD A/C' ?
                        // bankAccountRendering || bankOdAcRendering ?
                        <div>
                          <p className='font_size'>Mailing Details</p>
                          <div className='d-flex justify-content-between align-item-center' id='name'>
                            <label className='lab-1'>Name </label>
                            <label> :</label>
                            <input
                              type="text"
                              name='mailing_name'
                              id='mailing_name'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={mailing_name_input_ref}
                              value={mailing_name || ledger_name}
                              contentEditable="true" />
                          </div>
                          <div className='d-flex justify-content-between align-item-center'
                            id='address'>
                            <label className='lab-1'>Address</label>
                            <label> :</label>
                            <input
                              type="text"
                              name='address1'
                              id='address1'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={address1_input_ref}
                              value={address1 || ""} />
                          </div>
                          <div className='d-flex justify-content-between align-item-center position-relative'
                            id='state'>
                            <label className='lab-1'> State </label>
                            <label> :</label>
                            <input
                              type='text'
                              name='state_name'
                              id='state_name'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              ref={state_name_input_ref}
                              value={state_name || ""}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                            />
                            <div id="myDropdown-list-state" className={`dropdown-content-list-for-state-component ${showListState ? 'show-list' : ''}`} >
                              <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                List Of States
                              </div>

                              <div id='data'>
                                {State.map((item, index) =>
                                  <Link className='list' id='list' key={index}  >{item?.name}</Link>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className='d-flex justify-content-between align-item-center position-relative' id='country'>
                            <label className='lab-1'> Country </label>
                            <label> :</label>
                            <input
                              type='text'
                              name='country_name'
                              list='country_list'
                              ref={country_name_input_ref}
                              id='country_name'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              value={country_name || ""}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur} />
                            <div id="myDropdown-list-country" className={`dropdown-content-list-for-state-component ${showListCountry ? 'show-list' : ''}`} >
                              <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                List Of country
                              </div>

                              <div id='data'>
                                {Country1.map((item, index) =>
                                  <Link className='list' id='list' key={index}>{item?.name}</Link>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className='d-flex justify-content-between align-item-center' id='pincode'>
                            <label className='lab-1'> Pincode </label>
                            <label> :</label>
                            <input
                              type="text"
                              name='pincode'
                              id='pincode'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={pincode_input_ref}
                              value={pincode || ""} />
                          </div>

                          <div className='d-flex justify-content-between align-item-center' id='pincode'>
                            <label className='lab-1'> Mobile </label>
                            <label> :</label>
                            <input
                              type="text"
                              name='mobile'
                              id='mobile'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={mobile_input_ref}
                              value={mobile || ""} />
                          </div>
                          <div className='d-flex justify-content-between align-item-center position-relative' id='provide-bank-details'>
                            <label className='lab-1'> Provide Contact details </label>
                            <label> :</label>
                            <input
                              type="text"
                              name='provide_contact_details'
                              id='provide_contact_details'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={provide_contact_details_input_ref}
                              value={provide_contact_details || ""} />
                            <div id="myDropdown-list" className={`dropdown_yes_no_list ${showProvideContact ? 'show-list' : ''}`}>
                              <div className='col dropdown_header'>
                                Yes / No
                              </div>
                              <div id='data'>
                                {filteredDataYesNo?.length > 0 ? (
                                  filteredDataYesNo?.map((key, number) => {
                                    const name = key?.name;
                                    const id = key?.id;
                                    const index = name?.toLowerCase()?.indexOf(provide_contact_details?.toLowerCase());
                                    return (
                                      <Link
                                        className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                        id='list'
                                        key={number}
                                        onClick={() => {
                                          setSelectedItem(number)
                                          if (number === selectedItem) {
                                            setState({ ...state, provide_contact_details: name })
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                            setShowProvideContact(false)
                                          }
                                        }}
                                      >
                                        {index !== -1 ? (
                                          < >
                                            {name.substring(0, index)}
                                            <span style={{ color: 'red' }}>{name.substring(index, index + provide_contact_details.length)}</span>
                                            {name.substring(index + provide_contact_details.length)}
                                          </>
                                        ) : (
                                          name
                                        )}
                                      </Link>
                                    );
                                  })
                                ) : (
                                  <p style={{ color: 'red' }}>No matching items found</p>
                                )}
                              </div>
                            </div>
                          </div>
                          <p className='m-0 p-0 ps-1 text-start text-info font_size'>Tax Registration Details</p>
                        </div>
                        : null
                    }
                    {
                      under === 'Capital Account' || under === 'Fixed Assets' || under === 'Secured Loans' || under === 'Unsecured Loans' || under === 'Branch/Divisions' || under === 'Current Assets' || under === 'Current Liabilities' || under === 'Deposits(Asset)' || under === 'Direct Expenses' || under === 'Direct Incomes' || under === 'Indirect Expenses' || under === 'Indirect Incomes' || under === 'Investments' || under === 'Loans & Advance (Asset)' || under === 'Loans(Liability)' || under === 'Sundry Creditors' || under === 'Sundry Debtors' ?
                        // capitalAccountRendering || branchDivisionRendering || currentAssetsRendering || currentLiabilitiesRendering || depositsAssetsRendering || directExpensesRendering || directIncomesRendering || fixedAssetsRendering || indirectExpensesRendering || indirectIncomesRendering || investmentsRendering || loanAndAdvanceAssetRendering || loanLiabilityRendering || securedLoansRendering || sundryCreditorsRendering || sundryDebtorsRendering || unsecuredLoansRendering ?
                        <div>
                          <p className='font_size'>Mailing Details</p>
                          <div className='d-flex justify-content-between align-item-center' id='name'>
                            <label className='lab-1'>Name </label>
                            <label> :</label>
                            <input
                              type="text"
                              name='mailing_name'
                              id='mailing_name'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={mailing_name_input_ref}
                              value={mailing_name || ledger_name}
                              contentEditable="true" />
                          </div>
                          <div className='d-flex justify-content-between align-item-center'
                            id='address'>
                            <label className='lab-1'>Address</label>
                            <label> :</label>
                            <input
                              type="text"
                              name='address1'
                              id='address1'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={address1_input_ref}
                              value={address1 || ""} />
                          </div>
                          <div className='d-flex justify-content-between align-item-center position-relative'
                            id='state'>
                            <label className='lab-1'> State </label>
                            <label> :</label>
                            <input
                              type='text'
                              name='state_name'
                              id='state_name'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              ref={state_name_input_ref}
                              value={state_name || ""}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                            />
                            <div id="myDropdown-list-state" className={`dropdown-content-list-for-state-component ${showListState ? 'show-list' : ''}`} >
                              <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                List Of States
                              </div>

                              <div id='data'>
                                {State.map((item, index) =>
                                  <Link className='list' id='list' key={index} >{item?.name}</Link>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className='d-flex justify-content-between align-item-center position-relative' id='country'>
                            <label className='lab-1'> Country </label>
                            <label> :</label>
                            <input
                              type='text'
                              name='country_name'
                              list='country_list'
                              ref={country_name_input_ref}
                              id='country_name'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              value={country_name || ""}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur} />
                            <div id="myDropdown-list-country" className={`dropdown-content-list-for-state-component ${showListCountry ? 'show-list' : ''}`} >
                              <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                List Of country
                              </div>

                              <div id='data'>
                                {Country1.map((item, index) =>
                                  <Link className='list' id='list' key={index}  >{item?.name}</Link>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className='d-flex justify-content-between align-item-center' id='pincode'>
                            <label className='lab-1'> Pincode </label>
                            <label> :</label>
                            <input
                              type="text"
                              name='pincode'
                              id='pincode'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={pincode_input_ref}
                              value={pincode || ""} />
                          </div>

                          <div className='d-flex justify-content-between align-item-center' id='pincode'>
                            <label className='lab-1'> Mobile </label>
                            <label> :</label>
                            <input
                              type="text"
                              name='mobile'
                              id='mobile'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={mobile_input_ref}
                              value={mobile || ""} />
                          </div>
                          <div className='d-flex justify-content-between align-item-center position-relative' id='provide-bank-details'>
                            <label className='lab-1'> Provide Contact details </label>
                            <label> :</label>
                            <input
                              type="text"
                              name='provide_contact_details'
                              id='provide_contact_details'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={provide_contact_details_input_ref}
                              value={provide_contact_details || ""} />
                            <div id="myDropdown-list" className={`dropdown_yes_no_list ${showProvideContact ? 'show-list' : ''}`}>
                              <div className='col dropdown_header'>
                                Yes / No
                              </div>
                              <div id='data'>
                                {filteredDataYesNo?.length > 0 ? (
                                  filteredDataYesNo?.map((key, number) => {
                                    const name = key?.name;
                                    const id = key?.id;
                                    const index = name?.toLowerCase()?.indexOf(provide_contact_details?.toLowerCase());
                                    return (
                                      <Link
                                        className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                        id='list'
                                        key={number}
                                        onClick={() => {
                                          setSelectedItem(number)
                                          if (number === selectedItem) {
                                            setState({ ...state, provide_contact_details: name })
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                            setShowProvideContact(false)
                                          }
                                        }}
                                      >
                                        {index !== -1 ? (
                                          < >
                                            {name.substring(0, index)}
                                            <span style={{ color: 'red' }}>{name.substring(index, index + provide_contact_details.length)}</span>
                                            {name.substring(index + provide_contact_details.length)}
                                          </>
                                        ) : (
                                          name
                                        )}
                                      </Link>
                                    );
                                  })
                                ) : (
                                  <p style={{ color: 'red' }}>No matching items found</p>
                                )}
                              </div>
                            </div>
                          </div>
                          <p className='m-0 p-0 ps-1 text-start text-info font_size'>Banking Details</p>
                          <div className='d-flex justify-content-between align-item-center position-relative' id='provide-bank-details'>
                            <label className='lab-1'> Provide bank details </label>
                            <label> :</label>
                            <input
                              type="text"
                              name='provide_bank_details'
                              id='provide_bank_details'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={provide_bank_details_input_ref}
                              value={provide_bank_details || ""} />
                            <div id="myDropdown-list" className={`dropdown_yes_no_list ${showProvideBankDetail ? 'show-list' : ''}`}>
                              <div className='col dropdown_header'>
                                Yes / No
                              </div>
                              <div id='data'>
                                {filteredDataYesNo?.length > 0 ? (
                                  filteredDataYesNo?.map((key, number) => {
                                    const name = key?.name;
                                    const id = key?.id;
                                    const index = name?.toLowerCase()?.indexOf(provide_bank_details?.toLowerCase());
                                    return (
                                      <Link
                                        className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                        id='list'
                                        key={number}
                                        onClick={() => {
                                          setSelectedItem(number)
                                          if (number === selectedItem) {
                                            setState({ ...state, provide_bank_details: name })
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                            setShowProvideBankDetail(false)
                                          }
                                        }}
                                      >
                                        {index !== -1 ? (
                                          < >
                                            {name.substring(0, index)}
                                            <span style={{ color: 'red' }}>{name.substring(index, index + provide_bank_details.length)}</span>
                                            {name.substring(index + provide_bank_details.length)}
                                          </>
                                        ) : (
                                          name
                                        )}
                                      </Link>
                                    );
                                  })
                                ) : (
                                  <p style={{ color: 'red' }}>No matching items found</p>
                                )}
                              </div>
                            </div>
                          </div>
                          <p className='m-0 p-0 ps-1 text-start text-info font_size'>Tax Registration Details</p>
                          <div className='d-flex justify-content-between align-item-center' id='pan_it_no'>
                            <label className='lab-1'> PAN/IT No. </label>
                            <label> :</label>
                            <input
                              type="text"
                              name='pan_ItNo'
                              id='pan_ItNo'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={pan_ItNo_input_ref}
                              value={pan_ItNo || ""} />
                          </div>
                          {/* <div className='d-flex justify-content-between align-item-center position-relative' id='registration-type'>
                            <label className='lab-1'> Registration Type </label>
                            <label> :</label>
                            <input
                              type='text'
                              name="registration_type"
                              ref={registration_type_input_ref}
                              id='registration_type'
                              value={registration_type || ""}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} />
                            <div id="myDropdown-list-registrationType" className={`dropdown-content-list-for-state-component ${showListRegistrationType ? 'show-list' : ''}`} >
                              <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                Registration Type
                              </div>
                              <div id='data'>
                                {registrationType.map((item, index) =>
                                  <Link className='list' id='list' key={index} >{item?.name}</Link>
                                )}
                              </div>
                            </div>
                          </div> */}
                          {/* {
                            registration_type == 'Unkown' || 'Composition' || 'Regular' || 'Unregistered' ?
                              <div className='d-flex justify-content-between align-item-center' id='gstin-uin'>
                                <label className='lab-1'> Assessee of Other Territory </label>
                                <label> :</label>
                                <input
                                  type="text"
                                  className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                  name='assessee_of_other_territory'
                                  id='assessee_of_other_territory'
                                  onKeyUp={handleChange}
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  ref={assessee_of_other_territory_input_ref}
                                  value={assessee_of_other_territory || ""} />
                              </div> : null
                          }
                          {
                            registration_type == 'Unregistered' ?
                              <div>
                                <div className='d-flex justify-content-between align-item-center' id='gstin-uin'>
                                  <label className='lab-1'> GSTIN/UIN </label>
                                  <label> :</label>
                                  <input
                                    type="number"
                                    className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                    name='gstin_uin'
                                    id='gstin_uin'
                                    onKeyUp={handleChange}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    ref={gstin_uin_input_ref}
                                    value={gstin_uin || ""} />
                                </div>
                                <div className='d-flex justify-content-between align-item-center' id='gstin-uin'>
                                  <label className='lab-1'> Use Ledger as common Party </label>
                                  <label> :</label>
                                  <input
                                    type="text"
                                    name='use_ledger_as_common_party'
                                    id='use_ledger_as_common_party'
                                    className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                    onKeyUp={handleChange}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    ref={use_ledger_as_common_party_input_ref}
                                    value={use_ledger_as_common_party || ""} />
                                </div>
                                <div className='d-flex justify-content-between align-item-center position-relative' id='set_alter-gst-details'>
                                  <label className='lab-1'> Set/Alter Additional GST Details </label>
                                  <label> :</label>
                                  <input
                                    type="text"
                                    name='set_alter_additional_gst_details'
                                    id='set_alter_additional_gst_details'
                                    className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                    onKeyUp={handleChange}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    ref={set_alter_additional_gst_details_input_ref}
                                    value={set_alter_additional_gst_details || ""} />
                                  <div id="myDropdown-list" className={`dropdown_yes_no_list ${showSetAlterAdditional ? 'show-list' : ''}`}>
                                    <div className='col dropdown_header'>
                                      Yes / No
                                    </div>
                                    <div id='data'>
                                      {filteredDataYesNo?.length > 0 ? (
                                        filteredDataYesNo?.map((key, number) => {
                                          const name = key?.name;
                                          const id = key?.id;
                                          const index = name?.toLowerCase()?.indexOf(set_alter_additional_gst_details?.toLowerCase());
                                          return (
                                            <Link
                                              className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                              id='list'
                                              key={number}
                                              onClick={() => {
                                                setSelectedItem(number)
                                                if (number === selectedItem) {
                                                  setState({ ...state, set_alter_additional_gst_details: name })
                                                  // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                                  // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                                  setShowAlterAdditional(false)
                                                }
                                              }}
                                            >
                                              {index !== -1 ? (
                                                < >
                                                  {name.substring(0, index)}
                                                  <span style={{ color: 'red' }}>{name.substring(index, index + set_alter_additional_gst_details.length)}</span>
                                                  {name.substring(index + set_alter_additional_gst_details.length)}
                                                </>
                                              ) : (
                                                name
                                              )}
                                            </Link>
                                          );
                                        })
                                      ) : (
                                        <p style={{ color: 'red' }}>No matching items found</p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className='d-flex justify-content-between align-item-center position-relative' id='set_alter-gst-details'>
                                  <label className='lab-1'> Ignore prefixes and suffixes in doc No. for reconciliation </label>
                                  <label> :</label>
                                  <input
                                    type="text"
                                    list='gst'
                                    name='ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation'
                                    id='ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation'
                                    className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                    onKeyUp={handleChange}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    ref={ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation_input_ref}
                                    value={ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation || ""} />
                                  <div id="myDropdown-list" className={`dropdown_yes_no_list ${showIgnorePrefix ? 'show-list' : ''}`}>
                                    <div className='col dropdown_header'>
                                      Yes / No
                                    </div>
                                    <div id='data'>
                                      {filteredDataYesNo?.length > 0 ? (
                                        filteredDataYesNo?.map((key, number) => {
                                          const name = key?.name;
                                          const id = key?.id;
                                          const index = name?.toLowerCase()?.indexOf(ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation?.toLowerCase());
                                          return (
                                            <Link
                                              className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                              id='list'
                                              key={number}
                                              onClick={() => {
                                                setSelectedItem(number)
                                                if (number === selectedItem) {
                                                  setState({ ...state, ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation: name })
                                                  // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                                  // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                                  setShowIgnorePrefix(false)
                                                }
                                              }}
                                            >
                                              {index !== -1 ? (
                                                < >
                                                  {name.substring(0, index)}
                                                  <span style={{ color: 'red' }}>{name.substring(index, index + ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation.length)}</span>
                                                  {name.substring(index + ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation.length)}
                                                </>
                                              ) : (
                                                name
                                              )}
                                            </Link>
                                          );
                                        })
                                      ) : (
                                        <p style={{ color: 'red' }}>No matching items found</p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div> : null
                          } */}
                        </div> : null
                    }
                    {
                      under === 'Cash-in-Hand' || under === 'Duties & Taxes' || under === 'Misc. Expenses' || under === 'Provisions' || under === 'Reserves & Surplus' ?
                        // cashInHandRendering || dutiesAndTaxesRendering || miscExpensesRendering || provisionRendering || purchaseAccountsRendering || reservesAndSurplusRendering || salesAccountsRendering || stockInHandRendering || suspenseAcRendering ?
                        <div>
                          <p className='font_size'>Mailing Details</p>
                          <div className='d-flex justify-content-between align-item-center' id='name'>
                            <label className='lab-1'>Name </label>
                            <label> :</label>
                            <input
                              type="text"
                              name='mailing_name'
                              id='mailing_name'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={mailing_name_input_ref}
                              value={mailing_name || ledger_name}
                              contentEditable="true" />
                          </div>
                          <div className='d-flex justify-content-between align-item-center'
                            id='address'>
                            <label className='lab-1'>Address</label>
                            <label> :</label>
                            <input
                              type="text"
                              name='address1'
                              id='address1'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={address1_input_ref}
                              value={address1 || ""} />
                          </div>

                          <div className='d-flex justify-content-between align-item-center' id='pincode'>
                            <label className='lab-1'> Mobile </label>
                            <label> :</label>
                            <input
                              type="text"
                              name='mobile'
                              id='mobile'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={mobile_input_ref}
                              value={mobile || ""} />
                          </div>
                          <div className='d-flex justify-content-between align-item-center position-relative' id='provide-bank-details'>
                            <label className='lab-1'> Provide Contact details </label>
                            <label> :</label>
                            <input
                              type="text"
                              name='provide_contact_details'
                              id='provide_contact_details'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={provide_contact_details_input_ref}
                              value={provide_contact_details || ""} />
                            <div id="myDropdown-list" className={`dropdown_yes_no_list ${showProvideContact ? 'show-list' : ''}`}>
                              <div className='col dropdown_header'>
                                Yes / No
                              </div>
                              <div id='data'>
                                {filteredDataYesNo?.length > 0 ? (
                                  filteredDataYesNo?.map((key, number) => {
                                    const name = key?.name;
                                    const id = key?.id;
                                    const index = name?.toLowerCase()?.indexOf(provide_contact_details?.toLowerCase());
                                    return (
                                      <Link
                                        className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                        id='list'
                                        key={number}
                                        onClick={() => {
                                          setSelectedItem(number)
                                          if (number === selectedItem) {
                                            setState({ ...state, provide_contact_details: name })
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                            setShowProvideContact(false)
                                          }
                                        }}
                                      >
                                        {index !== -1 ? (
                                          < >
                                            {name.substring(0, index)}
                                            <span style={{ color: 'red' }}>{name.substring(index, index + provide_contact_details.length)}</span>
                                            {name.substring(index + provide_contact_details.length)}
                                          </>
                                        ) : (
                                          name
                                        )}
                                      </Link>
                                    );
                                  })
                                ) : (
                                  <p style={{ color: 'red' }}>No matching items found</p>
                                )}
                              </div>
                            </div>
                          </div>
                          <p className='m-0 p-0 ps-1 text-start text-info font_size'>Banking Details</p>
                          <div className='d-flex justify-content-between align-item-center position-relative' id='provide-bank-details'>
                            <label className='lab-1'> Provide bank details </label>
                            <label> :</label>
                            <input
                              type="text"
                              name='provide_bank_details'
                              id='provide_bank_details'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={provide_bank_details_input_ref}
                              value={provide_bank_details || ""} />
                            <div id="myDropdown-list" className={`dropdown_yes_no_list ${showProvideBankDetail ? 'show-list' : ''}`}>
                              <div className='col dropdown_header'>
                                Yes / No
                              </div>
                              <div id='data'>
                                {filteredDataYesNo?.length > 0 ? (
                                  filteredDataYesNo?.map((key, number) => {
                                    const name = key?.name;
                                    const id = key?.id;
                                    const index = name?.toLowerCase()?.indexOf(provide_bank_details?.toLowerCase());
                                    return (
                                      <Link
                                        className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                        id='list'
                                        key={number}
                                        onClick={() => {
                                          setSelectedItem(number)
                                          if (number === selectedItem) {
                                            setState({ ...state, provide_bank_details: name })
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                            // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                            setShowProvideBankDetail(false)
                                          }
                                        }}
                                      >
                                        {index !== -1 ? (
                                          < >
                                            {name.substring(0, index)}
                                            <span style={{ color: 'red' }}>{name.substring(index, index + provide_bank_details.length)}</span>
                                            {name.substring(index + provide_bank_details.length)}
                                          </>
                                        ) : (
                                          name
                                        )}
                                      </Link>
                                    );
                                  })
                                ) : (
                                  <p style={{ color: 'red' }}>No matching items found</p>
                                )}
                              </div>
                            </div>
                          </div>
                          <p className='m-0 p-0 ps-1 text-start text-info font_size'>Tax Registration Details</p>
                          <div className='d-flex justify-content-between align-item-center' id='pan_it_no'>
                            <label className='lab-1'> PAN/IT No. </label>
                            <label> :</label>
                            <input
                              type="text"
                              name='pan_ItNo'
                              id='pan_ItNo'
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              ref={pan_ItNo_input_ref}
                              value={pan_ItNo || ""} />
                          </div>
                          {/* <div className='d-flex justify-content-between align-item-center position-relative' id='registration-type'>
                            <label className='lab-1'> Registration Type </label>
                            <label> :</label>
                            <input
                              type='text'
                              name="registration_type"
                              ref={registration_type_input_ref}
                              id='registration_type'
                              value={registration_type || ""}
                              onKeyUp={handleChange}
                              onChange={handleChange}
                              onFocus={handleFocus}
                              onBlur={handleBlur}
                              className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`} />
                            <div id="myDropdown-list-registrationType" className={`dropdown-content-list-for-state-component ${showListRegistrationType ? 'show-list' : ''}`} >
                              <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                                Registration Type
                              </div>
                              <div id='data'>
                                {registrationType.map((item, index) =>
                                  <Link className='list' id='list' key={index}  >{item?.name}</Link>
                                )}
                              </div>
                            </div>
                          </div>
                          {
                            registration_type == 'Unkown' || 'Composition' || 'Regular' || 'Unregistered' ?
                              <div className='d-flex justify-content-between align-item-center' id='gstin-uin'>
                                <label className='lab-1'> Assessee of Other Territory </label>
                                <label> :</label>
                                <input
                                  type="text"
                                  className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                  name='assessee_of_other_territory'
                                  id='assessee_of_other_territory'
                                  onKeyUp={handleChange}
                                  onChange={handleChange}
                                  onFocus={handleFocus}
                                  onBlur={handleBlur}
                                  ref={assessee_of_other_territory_input_ref}
                                  value={assessee_of_other_territory || ""} />
                              </div> : null
                          }
                          {
                            registration_type == 'Unregistered' ?
                              <div>
                                <div className='d-flex justify-content-between align-item-center' id='gstin-uin'>
                                  <label className='lab-1'> GSTIN/UIN </label>
                                  <label> :</label>
                                  <input
                                    type="number"
                                    className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                    name='gstin_uin'
                                    id='gstin_uin'
                                    onKeyUp={handleChange}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    ref={gstin_uin_input_ref}
                                    value={gstin_uin || ""} />
                                </div>
                                <div className='d-flex justify-content-between align-item-center' id='gstin-uin'>
                                  <label className='lab-1'> Use Ledger as common Party </label>
                                  <label> :</label>
                                  <input
                                    type="text"
                                    name='use_ledger_as_common_party'
                                    id='use_ledger_as_common_party'
                                    className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                    onKeyUp={handleChange}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    ref={use_ledger_as_common_party_input_ref}
                                    value={use_ledger_as_common_party || ""} />
                                </div>
                                <div className='d-flex justify-content-between align-item-center position-relative' id='set_alter-gst-details'>
                                  <label className='lab-1'> Set/Alter Additional GST Details </label>
                                  <label> :</label>
                                  <input
                                    type="text"
                                    name='set_alter_additional_gst_details'
                                    id='set_alter_additional_gst_details'
                                    className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                    onKeyUp={handleChange}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    ref={set_alter_additional_gst_details_input_ref}
                                    value={set_alter_additional_gst_details || ""} />
                                  <div id="myDropdown-list" className={`dropdown_yes_no_list ${showSetAlterAdditional ? 'show-list' : ''}`}>
                                    <div className='col dropdown_header'>
                                      Yes / No
                                    </div>
                                    <div id='data'>
                                      {filteredDataYesNo?.length > 0 ? (
                                        filteredDataYesNo?.map((key, number) => {
                                          const name = key?.name;
                                          const id = key?.id;
                                          const index = name?.toLowerCase()?.indexOf(set_alter_additional_gst_details?.toLowerCase());
                                          return (
                                            <Link
                                              className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                              id='list'
                                              key={number}
                                              onClick={() => {
                                                setSelectedItem(number)
                                                if (number === selectedItem) {
                                                  setState({ ...state, set_alter_additional_gst_details: name })
                                                  // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                                  // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                                  setShowAlterAdditional(false)
                                                }
                                              }}
                                            >
                                              {index !== -1 ? (
                                                < >
                                                  {name.substring(0, index)}
                                                  <span style={{ color: 'red' }}>{name.substring(index, index + set_alter_additional_gst_details.length)}</span>
                                                  {name.substring(index + set_alter_additional_gst_details.length)}
                                                </>
                                              ) : (
                                                name
                                              )}
                                            </Link>
                                          );
                                        })
                                      ) : (
                                        <p style={{ color: 'red' }}>No matching items found</p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className='d-flex justify-content-between align-item-center position-relative' id='set_alter-gst-details'>
                                  <label className='lab-1'> Ignore prefixes and suffixes in doc No. for reconciliation </label>
                                  <label> :</label>
                                  <input
                                    type="text"
                                    list='gst'
                                    name='ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation'
                                    id='ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation'
                                    className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                                    onKeyUp={handleChange}
                                    onChange={handleChange}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    ref={ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation_input_ref}
                                    value={ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation || ""} />
                                  <div id="myDropdown-list" className={`dropdown_yes_no_list ${showIgnorePrefix ? 'show-list' : ''}`}>
                                    <div className='col dropdown_header'>
                                      Yes / No
                                    </div>
                                    <div id='data'>
                                      {filteredDataYesNo?.length > 0 ? (
                                        filteredDataYesNo?.map((key, number) => {
                                          const name = key?.name;
                                          const id = key?.id;
                                          const index = name?.toLowerCase()?.indexOf(ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation?.toLowerCase());
                                          return (
                                            <Link
                                              className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                              id='list'
                                              key={number}
                                              onClick={() => {
                                                setSelectedItem(number)
                                                if (number === selectedItem) {
                                                  setState({ ...state, ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation: name })
                                                  // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                                  // method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                                  setShowIgnorePrefix(false)
                                                }
                                              }}
                                            >
                                              {index !== -1 ? (
                                                < >
                                                  {name.substring(0, index)}
                                                  <span style={{ color: 'red' }}>{name.substring(index, index + ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation.length)}</span>
                                                  {name.substring(index + ignore_prefixes_and_suffixes_in_doc_no_for_reconciliation.length)}
                                                </>
                                              ) : (
                                                name
                                              )}
                                            </Link>
                                          );
                                        })
                                      ) : (
                                        <p style={{ color: 'red' }}>No matching items found</p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div> : null
                          } */}
                        </div> : null
                    }
                  </div>
                </div>
              </div>
              <div className='row border-top border-dark m-0 p-0 pt-1'>
                <div className='col-lg-6 p-0 m-0 ps-1'>
                  <div className='d-flex justify-content-between align-item-center'>
                    <label className='lab-1'> Opening Balance</label>
                    <label> :</label>
                    <input
                      type="text"
                      name='opening_balance'
                      className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                      onKeyUp={handleChange}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      ref={opening_balance_input_ref}
                      value={opening_balance || ""} />
                    <input
                      type="text"
                      name='type'
                      className={`lab-right ${isLedgerNameEnterd ? '' : 'disable-field'}`}
                      onKeyUp={handleChange}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      ref={type_input_ref}
                      value={type || ""} />
                  </div>
                </div>
              </div>
            </div>
            <MasterCreationFooter
              setHash={setHashtag}
            />
          </form>
        </div>
      </div>
      <div className='help'>

      </div>
    </div>
  )
}
export default Ledger;
