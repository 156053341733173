import React, { forwardRef } from 'react'
import { Eye,EyeSlash } from 'react-bootstrap-icons'

const LoginInput = forwardRef(({ type, lable, name, value, handleChange, handleFocus, handleBlur,visible,setVisible,focus }, ref) => {
    return (
        <div className='row m-0 p-0'>
            <div className='col-4 m-0 p-0 text-start'>
                <label>{lable}</label>
            </div>
            <div className='col-1 m-0 p-0'>
                <label> :</label>
            </div>
            <div className='col-7 m-0 p-0 text-start' style={{ position: 'relative'}}>
                <input
                    style={{ fontSize: 13,width:'100%' }}
                    type={type}
                    autoFocus={name === 'customercode'}
                    name={name}
                    id={name}
                    // className='lab-right'
                    value={value || ""}
                    onKeyUp={handleChange}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    ref={ref}
                />
                {
                    name === 'password' && focus ?
                        <span className='input_icon_wrap_login'>
                            {
                                visible ? <Eye className='input_icon' onClick={() => { setVisible(false) }} /> : <EyeSlash className='input_icon' onClick={()=>{setVisible(!visible)}} />
                            }
                        </span> : null
                }
            </div>
        </div>
    )
})

export default React.memo(LoginInput)
