import React, { useEffect, useState } from 'react';
import Footer from '../../../../components/home_page/Footer';
import { Link, useNavigate } from 'react-router-dom';
import "./createmasters.css";
const CreateMasters = () => {
  const navigate = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowDown') {
        setSelectedIndex((prevIndex) => Math.min(prevIndex + 1, totalLinks - 1));
      } else if (event.key === 'ArrowUp') {
        setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
      } else if (event.key === 'Enter') {
        event.preventDefault();
        // You can navigate to the selected link's path here
        const selectedLink = account_master[selectedIndex] ||
          inventroy_master[selectedIndex] || statutory_details[selectedIndex];
        if (selectedLink) {
          navigate(selectedLink.to)
        }
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedIndex]);
  const account_master = [
    { to: "/create-master-group", text: "Group" },
    { to: "/create-master-ledger", text: "Ledger" },
    { to: "/create-master-currency", text: "Credit Limit" },
    { to: "/create-master-vouchertype", text: "Vouchers Type" },
  ];

  const inventroy_master = [];
  inventroy_master[4] = { to: "/create-master-invmas-stockgroup", text: "Stock Group" };
  inventroy_master[5] = { to: "/create-master-invmas-stockcategory", text: "Stock Category" };
  inventroy_master[6] = { to: "/create-master-invmas-stockcompany", text: "Stock Company" };
  inventroy_master[7] = { to: "/create-master-invmas-stockitem", text: "Stock Item" };
  inventroy_master[8] = { to: "/create-master-invmas-unit", text: "Unit" };
  inventroy_master[9] = { to: "/create-master-invmas-godown", text: "Godown" };
  inventroy_master[10] = { to: "/create-master-invmas-godown", text: "Price List" };
  const statutory_details = [];
  statutory_details[11] = { to: "/create-master-sttdet-gstdetails", text: "GST Details" };
  statutory_details[12] = { to: "/create-master-sttdet-pandetails", text: "PAN/CIN Details" };
  statutory_details[13] = { to: "/create-master-sttdet-pandetails", text: "GST Rate Setup" };
  const totalLinks = (account_master.length + inventroy_master.length + statutory_details.length) - 15;
  return (
    <div className='section'>
      <div className='detail'>
        <div className='container-fluid'>
          <div className='row d-flex justify-content-center align-content-center master_container'>
            <div className='col-4 border border-dark  p-0 m-0 master_container_color'>
              <div className='card_header'>
                <p className='m-0 p-0 ps-1 text-start'>Master Creation</p>
              </div>
              <div className='row mt-2'>
                <div className='col-12'>
                  <p className='m-0 p-0 ps-1 text-start text-info font_size'>Accounting Master</p>
                  <ul className='master_ul_link_list'>
                    {account_master.map((link, index) => (
                      <div key={index}>
                        <li className={index === selectedIndex ? 'selected' : ''}>
                          <Link to={link.to}>{link?.text}</Link>
                        </li>
                      </div>
                    ))}
                  </ul>
                </div>
                <div className='col-12'>
                  <p className='m-0 p-0 ps-1 text-start text-info font_size'>Inventory Masters</p>
                  <ul className='master_ul_link_list'>
                    {inventroy_master.map((link, index) => (
                      <div key={index}>
                        <li className={index === selectedIndex ? 'selected' : ''}>
                          <Link to={link.to}>{link?.text}</Link>
                        </li>
                      </div>
                    ))}
                  </ul>
                </div>
                <div className='col-12'>
                  <p className='m-0 p-0 ps-1 text-start text-info font_size'>Statutory Details</p>
                  <ul className='master_ul_link_list'>
                    {statutory_details.map((link, index) => (
                      <div key={index}>
                        <li className={index === selectedIndex ? 'selected' : ''}>
                          <Link to={link.to}>{link?.text}</Link>
                        </li>
                      </div>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='tomasterfooter'>
            <Footer />
          </div>
        </div>
      </div>
      <div className='help'></div>
    </div>
  )
}

export default CreateMasters;
