import React from 'react'

function FooterLayout(props) {
    const { id, name, shortcutKey,line, isDisabled, state } = props.fields
    const handleClick = () => {
        if (typeof state === 'function') {
            state(true);
        }
    }
    return (
        <div className='boxfooter'>
            <button
                disabled={isDisabled}
                className='button ps-1'
                onClick={handleClick}>
                <span className={line}>{shortcutKey}</span>{name ? ':' : null} {name}
            </button>
        </div>
    )
}

export default FooterLayout
