import React, { useState, useEffect, useRef } from 'react';
import './login.css';
import { useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import FormData from 'form-data';
import { signIn } from '../../redux/actions/action-creator';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import LoginInput from '../Input/LoginInput';
import useClickOutside from '../Custom_Hooks/useClickOutsSide';

const initialState = {
    customercode: '',
    userName: '',
    password: ''
}

const Login = ({ setProgress }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { userData = null } = useSelector(state => state.auth);

    const [loading, setLoading] = useState(true);
    const [state, setState] = useState(initialState)
    const [visible, setVisible] = useState(false);
    const [focus, setFocus] = useState(false)
    const [nameInput, setNameInput] = useState(null)

    const customercode_input_ref = useRef(null)
    const userName_input_ref = useRef(null)
    const password_input_ref = useRef(null)

    const { customercode, userName, password } = state;

    const handleClickOutside = (event) => {
        if (nameInput.current) {
            event.preventDefault();
            nameInput.current.focus();
        }
    };

    const handleClickInside = (event) => {
        let clickedElement = event.target;
        if (clickedElement?.tagName &&
            (clickedElement?.tagName.toLowerCase() === 'input' ||
                clickedElement?.tagName.toLowerCase() === 'textarea')) {
            clickedElement.focus();
        }
    };

    useClickOutside(nameInput, handleClickOutside, handleClickInside);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setState({ ...state, [name]: value });
        if (e.key === 'Enter') {
            e.preventDefault();
            if (name === 'customercode') {
                userName_input_ref?.current?.focus()
            } else if (name === 'userName') {
                password_input_ref?.current?.focus()
            } else if (name === 'password') {
                handleSubmit()
            }
        }
    }
    const handleFocus = (e) => {
        const { name, value } = e.target
        const inputRefs = {
            customercode: customercode_input_ref,
            userName: userName_input_ref,
            password: password_input_ref,
        };
        setNameInput(inputRefs[name]);
        if (name === 'customercode') {
            customercode_input_ref?.current?.setSelectionRange(0, customercode_input_ref?.current?.value?.length)
        } else if (name === 'userName') {
            userName_input_ref?.current?.setSelectionRange(0, userName_input_ref?.current?.value?.length)
        } else if (name === 'password') {
            password_input_ref?.current?.setSelectionRange(0, password_input_ref?.current?.value?.length)
            setFocus(true)
        }
    }

    const handleBlur = (e) => {
        const { name, value } = e.target;
        if (name === 'customercode') {
            if (!value) {
                customercode_input_ref?.current?.focus();
            }
        }
        if (name === 'userName') {
            if (!value) {
                userName_input_ref?.current?.focus();
            }
        }
        if (name === 'password') {
            setFocus(false)
            if (!value) {
                password_input_ref?.current?.focus();
            }
        }
    }

    const handleSubmit = (e1) => {
        if (state.customercode !== "") {
            if (state.userName !== "") {
                if (state.password !== "") {
                    setLoading(true);
                    let data = new FormData();
                    data.append('user_name', userName);
                    data.append('password', password);
                    data.append('customer_code', customercode);
                    dispatch(signIn(data)).then((response) => {
                        if (response.status) {
                            setState({ customercode: '', userName: '', password: '' })
                            toast.success(response?.message || 'Login Success!', {
                                position: toast.POSITION.TOP_CENTER
                            });
                            localStorage.setItem('authToken', userData?.id);
                            setTimeout(() => {
                                setLoading(false);
                            }, 1000);
                        } else {
                            toast.warn(response?.message || 'Login Fail !', {
                                position: toast.POSITION.TOP_CENTER
                            });
                            setLoading(false);
                        }
                    }).catch(error => {
                        console.log(error)
                        toast.error(error?.message || 'Login Failed!', {
                            position: toast.POSITION.TOP_CENTER
                        });
                        setLoading(false);
                    })
                } else {
                    toast.warn('Fill Password ...!', {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            } else {
                toast.warn('Fill Username ...!', {
                    position: toast.POSITION.TOP_CENTER
                });
            }
        } else {
            toast.warn('Fill Customer Code ...!', {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 10);

        return () => clearTimeout(timer);
    }, []);



    return (
        <div className='container-fluid' id='login-container'>
            <ToastContainer
                position="top-center"
                autoClose={1000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover
                theme="light"
            />
            {
                loading ? (
                    <div className='container'>
                        <div className='row justify-content-center align-items-center' id='row'>
                            <div className="spinner-border text-success" role="status">
                                <span className="sr-only"></span>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className='row justify-content-center align-items-center' id='row'>
                        <div className='col-lg-4 p-5'>
                            <div className='row' id='card'>
                                <div className='col'>
                                    <form method='post' className='mt-3' autoComplete='off'>
                                        <LoginInput
                                            lable='Customer Code'
                                            type='text'
                                            handleChange={handleChange}
                                            handleFocus={handleFocus}
                                            handleBlur={handleBlur}
                                            value={customercode}
                                            name='customercode'
                                            ref={customercode_input_ref}

                                        />
                                        <LoginInput
                                            lable='Username'
                                            type='text'
                                            handleChange={handleChange}
                                            handleFocus={handleFocus}
                                            handleBlur={handleBlur}
                                            value={userName}
                                            name='userName'
                                            ref={userName_input_ref}

                                        />
                                        <LoginInput
                                            lable='Password'
                                            type={visible ? 'text' : 'password'}
                                            handleChange={handleChange}
                                            handleFocus={handleFocus}
                                            handleBlur={handleBlur}
                                            value={password}
                                            name='password'
                                            ref={password_input_ref}
                                            visible={visible}
                                            setVisible={setVisible}
                                            focus={focus}
                                        />

                                        <ul className="nav">
                                            <li className="nav-item">
                                                <Link className="nav-link p-0 active forgot_text" >Forgot password?</Link>
                                            </li>
                                        </ul>
                                        <ul className="nav">
                                            <li className="nav-item">
                                                <Link to='/branch_login' className="nav-link p-0 active forgot_text">Login for Branch?</Link>
                                            </li>
                                        </ul>
                                        <div className='border'></div>
                                        <div className='input-group input-group-lg mb-3 mt-3'>
                                            <button type="button" style={{ width: '100%', fontSize: 15 }} className="btn btn-success" onClick={() => { return (navigate('/signUp')) }}><strong> Register Now</strong></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>)}
        </div>

    )
}

export default React.memo(Login);
