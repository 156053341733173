import { groupCreate, groupListCreate } from '../../../../../redux/actions/action-creator';
import YesNoModal from '../../../../../components/model/YesNoModal';
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormData from 'form-data';
import './ledger.css';
import './group.css';
import { CodeSlash } from 'react-bootstrap-icons';
import MasterCreationFooter from '../../../../../components/Footer/Master_Footer/MasterCreationFooter';
const nature_of_group_arrya =
  [
    { name: 'Assets' },
    { name: 'Income' },
    { name: 'Expenses' },
    { name: 'Liabilities' }
  ]
const yes_no_array =
  [
    { name: 'Yes' },
    { name: 'No' },
  ]
const applicable_not_applicable_array =
  [
    { name: 'Not Applicable' },
    { name: 'Appropriate by Qty' },
    { name: 'Appropriate by Value' },
  ]
function Group() {
  const str = '\u2666';
  const dispatch = useDispatch();

  const [state, setState] = useState({
    group_name: '',
    alise_name: '',
    under_id: '5',
    under: 'Capital Account',
    nature_of_group: 'Liabilities',
    group_behaves_like_a_sub_ledger: 'No',
    nett_debit_credit_balance_for_reporting: 'No',
    used_for_calculation: 'No',
    method_to_allocate_when_used_in_purchase_invoice: `${str} Not Applicable`,
  });
  // Create mutable object of all input to focus -------------------------------
  const [click, setClick] = useState(0)
  let isScrolling = false;
  const [loading, setLoading] = useState(true)
  const isMouseDownInside = useRef(false);
  const dropdownRef = useRef(null);
  const group_name_input_ref = useRef(null);
  const aliseInputRef = useRef(null);
  const inputRef = useRef(null);
  const nature_group_input_ref = useRef(null);
  const group_behaves_like_a_sub_ledger_input_ref = useRef(null);
  const nett_debit_credit_balance_for_reporting_input_ref = useRef(null);
  const used_for_calculation_input_ref = useRef(null);
  const method_to_allocate_when_used_in_purchase_invoice_input_ref = useRef(null);
  // Name input field validation to  check value not empty'state -----------------
  const [isGroupNameEntered, setIsGroupNameEntered] = useState(false);
  const [groupSubmitConfirm, setGroupSubmitConfirm] = useState('');
  const [hashtag, setHashtag] = useState(false)
  const [filteredData, setFilteredData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(0);
  const [showList, setShowList] = useState(false)
  const [backspaceCount, setBackspaceCount] = useState(0)
  const [nameInput, setNameInput] = useState(null)
  const [showNatureList, setShowNatureList] = useState(false)
  const [filteredDataNature, setFilteredDataNature] = useState([])
  const [showYesNoList, setShowYesNoList] = useState(false)
  const [showNetDebitList, setShowNetDebitList] = useState(false)
  const [showUsedForList, setShowUsedForList] = useState(false)
  const [showMethodForList, setShowMethodForList] = useState(false)
  const [filteredDataYesNo, setFilteredDataYesNo] = useState([])
  const [filteredDataMethod, setFilteredDataMethod] = useState([])
  // get redux state -----------------------------------------------------
  const data = useSelector(state => state.common.data);
  const group_all_list = useSelector(state => state.common.group_all_list);
  const { userData = null } = useSelector(state => state.auth);

  // Destructuring object from state----------------------------------------------
  const {
    group_name, alise_name, under, under_id, nature_of_group, group_behaves_like_a_sub_ledger,
    nett_debit_credit_balance_for_reporting, used_for_calculation,
    method_to_allocate_when_used_in_purchase_invoice } = state;
  useEffect(() => {
    const formData = new FormData();
    formData.append('company_id', userData?.id)
    dispatch(groupListCreate(formData)).then((response) => {
      if (response.status) {
        setLoading(false)
      }
    })
  }, [])


  function getNextInputRef(name, value) {
    switch (name) {
      case 'group_name': return aliseInputRef;
      case 'alise_name': return inputRef;
      case 'under': return value === 'Primary' ? nature_group_input_ref : group_behaves_like_a_sub_ledger_input_ref;
      case 'nature_of_group': return group_behaves_like_a_sub_ledger_input_ref;
      case 'group_behaves_like_a_sub_ledger': return nett_debit_credit_balance_for_reporting_input_ref;
      case 'nett_debit_credit_balance_for_reporting': return used_for_calculation_input_ref;
      case 'used_for_calculation': return method_to_allocate_when_used_in_purchase_invoice_input_ref;
      default: return null;
    }
  }
  function getPrevInputRef(name) {
    switch (name) {
      case 'alise_name': return group_name_input_ref;
      case 'under': return aliseInputRef;
      case 'nature_of_group': return inputRef;
      case 'group_behaves_like_a_sub_ledger': return state.under === 'Primary' ? nature_group_input_ref : inputRef;
      case 'nett_debit_credit_balance_for_reporting': return group_behaves_like_a_sub_ledger_input_ref;
      case 'used_for_calculation': return nett_debit_credit_balance_for_reporting_input_ref;
      case 'method_to_allocate_when_used_in_purchase_invoice': return used_for_calculation_input_ref;
      default: return group_name_input_ref;
    }
  }
  function getCurrentINputRef(name) {
    switch (name) {
      case 'group_name': return group_name_input_ref;
      case 'alise_name': return aliseInputRef;
      case 'under': return inputRef;
      case 'nature_of_group': return nature_group_input_ref;
      case 'group_behaves_like_a_sub_ledger': return group_behaves_like_a_sub_ledger_input_ref;
      case 'nett_debit_credit_balance_for_reporting': return nett_debit_credit_balance_for_reporting_input_ref;
      case 'used_for_calculation': return used_for_calculation_input_ref;
      case 'method_to_allocate_when_used_in_purchase_invoice': return method_to_allocate_when_used_in_purchase_invoice_input_ref;
      default: return null;
    }
  }
  const handleFocus = (e) => {
    const { name, value } = e.target;
    const ref = getCurrentINputRef(name);
    if (ref) {
      setNameInput(ref);
    }
    if (name === 'under') {
      if (group_all_list[0]?.name === `${str} Primary`) {
      } else {
        group_all_list?.unshift({ id: 'Primary', name: `${str} Primary` });
      }
      const index = group_all_list?.findIndex(item => item?.name?.toLowerCase() == state?.under?.toLowerCase());
      setSelectedItem(index)
      setShowList(true)
    }
    if (name === 'nature_of_group') {
      setShowNatureList(true)
      const index = nature_of_group_arrya?.findIndex(item => item?.name?.toLowerCase() == state?.nature_of_group?.toLowerCase());
      setSelectedItem(index)
    }
  }

  // Trigger blur on input ------------------------------------------------
  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (name === 'group_name' && value === '') {
      group_name_input_ref.current.focus();
    }
    if (name === 'under') {
      const cleanString = (str) => str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
      const matchFound = group_all_list.find(item => cleanString(item.name) === cleanString(value));
      if (matchFound) {
        setShowList(false)
      } else {
        inputRef?.current?.focus();
      }
    }
    if (name === 'nature_of_group') {
      setShowNatureList(false)
    }
    if (name === 'group_behaves_like_a_sub_ledger') {
      setShowYesNoList(false)
      const normalizedValue = value.toLowerCase().trim();
      const validPatternYes = /^(y|e|s|ye|es|yes)?$/;
      const validPatternNo = /^(n|o|no)?$/;
      if (normalizedValue === '') {
        setState({ ...state, [name]: 'No' })
      } else if (validPatternNo.test(normalizedValue)) {
        setState({ ...state, [name]: 'No' })
      } else if (validPatternYes.test(normalizedValue)) {
        setState({ ...state, [name]: 'Yes' })
      }
    }
    if (name === 'nett_debit_credit_balance_for_reporting') {
      setShowNetDebitList(false)
      const normalizedValue = value.toLowerCase().trim();
      const validPatternYes = /^(y|e|s|ye|es|yes)?$/;
      const validPatternNo = /^(n|o|no)?$/;
      if (normalizedValue === '') {
        setState({ ...state, [name]: 'No' })
      } else if (validPatternNo.test(normalizedValue)) {
        setState({ ...state, [name]: 'No' })
      } else if (validPatternYes.test(normalizedValue)) {
        setState({ ...state, [name]: 'Yes' })
      }
    }
    if (name === 'used_for_calculation') {
      setShowUsedForList(false)
      const normalizedValue = value.toLowerCase().trim();
      const validPatternYes = /^(y|e|s|ye|es|yes)?$/;
      const validPatternNo = /^(n|o|no)?$/;
      if (normalizedValue === '') {
        setState({ ...state, [name]: 'No' })
      } else if (validPatternNo.test(normalizedValue)) {
        setState({ ...state, [name]: 'No' })
      } else if (validPatternYes.test(normalizedValue)) {
        setState({ ...state, [name]: 'Yes' })
      }
    }
    if (name === 'method_to_allocate_when_used_in_purchase_invoice') {
      setShowMethodForList(false)
      const cleanString = (str) => str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
      const matchFound = applicable_not_applicable_array.find(item => cleanString(item.name) === cleanString(value));
      if (matchFound) {
      } else {
        setState({ ...state, [name]: 'Not Applicable' })
      }
    }

  }
  // Triger function whenever the change input value ------------------------
  const handleChange = (e) => {
    const { name, value } = e.target;
    const capitalizeFirstLetter = (str) => {
      return str.toLowerCase().replace(/(^|\s)\S/g,
        (firstLetter) => firstLetter.toUpperCase());
    };
    if (name === 'group_name') {
      setIsGroupNameEntered(!!value.trim());
    }
    // space key functionality
    if (e.code == 'Space') {
      e.preventDefault();
      const cursorPosition_start = e.target.selectionStart;
      if (cursorPosition_start == 1) {
        if (name === 'under') {
          setState({ ...state, [name]: '', under_id: '' })
          setSelectedItem(0)
        } else {
          setState({ ...state, [name]: '' });
        }
        return;
      }
    }
    // Enter key functionality
    if (e.key === 'Enter' && state?.group_name !== '') {
      e.preventDefault();
      const nextInputRef = getNextInputRef(name, value)
      if (name === 'under') {
        const cleanString = (str) => str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
        const matchFound = group_all_list.find(item => cleanString(item.name) === cleanString(value));
        if (matchFound) {
          if (name === 'Primary') {
            setTimeout(() => {
              nature_group_input_ref?.current?.focus()
              nature_group_input_ref?.current?.setSelectionRange(0, 0)
            }, 100);
          } else {
            group_behaves_like_a_sub_ledger_input_ref?.current?.focus();
            group_behaves_like_a_sub_ledger_input_ref?.current?.setSelectionRange(0, 0)
          }
        } else {
          inputRef?.current?.focus();
        }
      } else
        if (name === 'method_to_allocate_when_used_in_purchase_invoice') {
          const cleanString = (str) => str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
          const matchFound = applicable_not_applicable_array.find(item => cleanString(item.name) === cleanString(value));
          if (matchFound) {
            method_to_allocate_when_used_in_purchase_invoice_input_ref.current.blur()
            setGroupSubmitConfirm('Accept')
          } else {
            setState({ ...state, [name]: 'Not Applicable' })
            method_to_allocate_when_used_in_purchase_invoice_input_ref.current.blur()
            setGroupSubmitConfirm('Accept')
          }

        } else if (nextInputRef) {
          nextInputRef.current.setSelectionRange(0, 0);
          nextInputRef.current.focus();
        }
      return;
    }
    // Back space key functionality
    if (e.key === 'Backspace') {
      e.preventDefault();
      const nextInputRef = getPrevInputRef(name);
      const cursorPosition = e.target.selectionStart;
      if (cursorPosition === 0) {
        if (backspaceCount === 1) {
          setBackspaceCount(0);
          if (nextInputRef && nextInputRef.current) {
            nextInputRef.current.focus();
            nextInputRef.current.setSelectionRange(0, 0);
          }
        } else {
          if (cursorPosition === 0 && value) {
            if (nextInputRef && nextInputRef.current) {
              nextInputRef.current.focus();
              nextInputRef.current.setSelectionRange(0, 0);
            }
          } else {
            setBackspaceCount(backspaceCount + 1);
          }
        }
      }
      return;
    }

    // Set data in state accourding to name and validate the value -------
    const cleanString = (str) => str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    switch (name) {
      case 'under':
        const group = group_all_list.some(item => cleanString(item?.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (group) {
          setState({ ...state, [name]: value })
        }
        break;
      case 'nature_of_group':
        const nat_group = nature_of_group_arrya.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (nat_group) {
          setState({ ...state, [name]: value })
        }
        break;
      case 'group_behaves_like_a_sub_ledger':
        const group_behaves = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (group_behaves) {
          setState({ ...state, [name]: value })
        } else {
          setShowYesNoList(true)
        }
        break;
      case 'nett_debit_credit_balance_for_reporting':
        const nett_debit = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (nett_debit) {
          setState({ ...state, [name]: value })
        } else {
          setShowNetDebitList(true)
        }
        break;
      case 'used_for_calculation':
        const used_for = yes_no_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (used_for) {
          setState({ ...state, [name]: value })
        } else {
          setShowUsedForList(true)
        }
        break;
      case 'method_to_allocate_when_used_in_purchase_invoice':
        const method_to = applicable_not_applicable_array.some(item => cleanString(item.name).toLowerCase()?.includes(cleanString(value).toLowerCase()));
        if (method_to) {
          setState({ ...state, [name]: value })
        } else {
          setShowMethodForList(true)
        }
        break;
      default:
        const updatedValue = name === 'group_name' || name === 'alise_name' ?
          capitalizeFirstLetter(value) : value;
        setState({ ...state, [name]: updatedValue })
        break;
    }

  };
  // Reusable filtering function
  const cleanString = (str) => str?.replace(/[^a-zA-Z0-9]/g, '')?.toLowerCase();
  const filterSuggestions = useCallback((list, searchInput) => {
    return list?.filter(item => cleanString(item?.ledger_name || item?.name)?.includes(cleanString(searchInput)));
  }, []);

  useEffect(() => {
    const updateFilteredData = () => {
      const searchFields = [
        { list: group_all_list, ref: inputRef?.current, searchInput: state?.under, setFiltered: setFilteredData },
        { list: nature_of_group_arrya, ref: nature_group_input_ref?.current, searchInput: state?.nature_of_group, setFiltered: setFilteredDataNature },
        { list: applicable_not_applicable_array, ref: method_to_allocate_when_used_in_purchase_invoice_input_ref?.current, searchInput: state?.method_to_allocate_when_used_in_purchase_invoice, setFiltered: setFilteredDataMethod },
        { list: yes_no_array, ref: (showYesNoList ? group_behaves_like_a_sub_ledger_input_ref : showNetDebitList ? nett_debit_credit_balance_for_reporting_input_ref : used_for_calculation_input_ref)?.current, searchInput: (showYesNoList ? state?.group_behaves_like_a_sub_ledger : showNetDebitList ? state?.nett_debit_credit_balance_for_reporting : state?.used_for_calculation), setFiltered: setFilteredDataYesNo },
      ];

      searchFields.forEach(({ list, ref, searchInput, setFiltered }) => {
        const filteredSuggestions = filterSuggestions(list, searchInput);
        setFiltered(searchInput !== '' && ref?.selectionStart > 0 ? filteredSuggestions : list);
      });
    };
    updateFilteredData();
  }, [
    state?.under,
    state?.nature_of_group,
    state?.group_behaves_like_a_sub_ledger,
    state?.method_to_allocate_when_used_in_purchase_invoice,
    state?.nett_debit_credit_balance_for_reporting,
    state?.used_for_calculation,
    group_all_list,
    nature_of_group_arrya,
    applicable_not_applicable_array,
    yes_no_array,
    inputRef?.current?.selectionStart,
    nature_group_input_ref?.current?.selectionStart,
    method_to_allocate_when_used_in_purchase_invoice_input_ref?.current?.selectionStart,
    group_behaves_like_a_sub_ledger_input_ref?.current?.selectionStart
  ]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      let clickedElement = event?.target;
      let isInputField = false;

      while (clickedElement) {
        if (clickedElement?.tagName &&
          (clickedElement?.tagName?.toLowerCase() === 'input' ||
            clickedElement?.tagName?.toLowerCase() === 'textarea')) {
          isInputField = true;
          break;
        }
        clickedElement = clickedElement?.parentNode;
      }
      if (!isInputField) {
        if (groupSubmitConfirm === 'Accept') {
          setNameInput(null)
        } else {
          if (nameInput && nameInput?.current) {
            event?.preventDefault();
            nameInput?.current.focus();
          }
        }
      }
    };

    const handleMouseDown = () => {
      isMouseDownInside.current = true;
    };

    const handleMouseUp = () => {
      isMouseDownInside.current = false;
    };

    document?.addEventListener('mousedown', handleClickOutside);
    document?.addEventListener('mousedown', handleMouseDown);
    document?.addEventListener('mouseup', handleMouseUp);

    return () => {
      document?.removeEventListener('mousedown', handleClickOutside);
      document?.removeEventListener('mousedown', handleMouseDown);
      document?.removeEventListener('mouseup', handleMouseUp);
    };
  }, [nameInput, groupSubmitConfirm]);

  // Keyboad functionality in dropdown select and enter ---------------------------
  const handleKeyDown = useCallback((e) => {
    if (showList || showNatureList || showYesNoList || showNetDebitList || showUsedForList || showMethodForList) {
      if (e.key === 'ArrowUp') {
        e.preventDefault();
        setSelectedItem(prevState => Math.max(prevState - 1, 0));
      } else if (e.key === 'ArrowDown') {
        e.preventDefault();
        setSelectedItem(prevState => Math.min(prevState + 1, (showNatureList ? filteredDataNature.length : showYesNoList || showNetDebitList || showUsedForList ? filteredDataYesNo.length : showMethodForList ? filteredDataMethod.length : filteredData.length) - 1));
      } else if (e.key === 'Enter') {
        e.preventDefault();
        const selectedLink = showNatureList ? filteredDataNature[selectedItem] : showYesNoList || showNetDebitList || showUsedForList ? filteredDataYesNo[selectedItem] : showMethodForList ? filteredDataMethod[selectedItem] : filteredData[selectedItem];
        if (selectedLink) {
          if (showList) {
            if (selectedLink.name === 'Primary') {
              setState(prevState => ({
                ...prevState,
                under: `${str} ${selectedLink.name}`,
                under_id: selectedLink.id
              }));
              setTimeout(() => {
                nature_group_input_ref.current.focus();
                nature_group_input_ref.current.setSelectionRange(0, 0)
              }, 180);
            } else {
              setState(prevState => ({
                ...prevState,
                under: selectedLink.name,
                under_id: selectedLink.id
              }));
              setTimeout(() => {
                group_behaves_like_a_sub_ledger_input_ref.current.focus();
              }, 180);
            }
            setShowList(false);
          }
          if (showYesNoList) {
            setState(prevState => ({
              ...prevState,
              group_behaves_like_a_sub_ledger: selectedLink.name
            }));
            setShowYesNoList(false);
          }
          if (showNetDebitList) {
            setState(prevState => ({
              ...prevState,
              nett_debit_credit_balance_for_reporting: selectedLink.name
            }));
            setShowYesNoList(false);
          }
          if (showUsedForList) {
            setState(prevState => ({
              ...prevState,
              used_for_calculation: selectedLink.name
            }));
            setShowYesNoList(false);
          }
          if (showMethodForList) {
            if (selectedLink.name === 'Not Applicable') {
              setState({ ...state, method_to_allocate_when_used_in_purchase_invoice: `${str} ${selectedLink.name}` })
            } else {
              setState({ ...state, method_to_allocate_when_used_in_purchase_invoice: selectedLink.name })
            }
            setShowMethodForList(false);
          }
          if (showNatureList) {
            setState(prevState => ({
              ...prevState,
              nature_of_group: selectedLink.name
            }));
            setShowNatureList(false);
          }
        }
      }
    }
  }, [showList, showNatureList, showYesNoList, showNetDebitList, showUsedForList, showMethodForList, selectedItem, filteredData, filteredDataNature, filteredDataYesNo, filteredDataMethod]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);


  //---------------------------------------------------------------------------
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (groupSubmitConfirm === 'Accept') {
        if (e.key === 'y' || e.key === 'Y') {
          e.preventDefault();
          controlPlusASubmit();
        }
      }
      if (groupSubmitConfirm === 'Accept') {
        if (e.key === 'n' || e.key === 'N') {
          e.preventDefault();
          setGroupSubmitConfirm('')
          group_name_input_ref.current.focus();
        }
      }
    }
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [state?.group_name, groupSubmitConfirm])

  // scroll functionality ---------------------------
  useEffect(() => {
    if (dropdownRef?.current) {
      setClick(parseInt((dropdownRef?.current?.scrollHeight - dropdownRef?.current?.offsetHeight - dropdownRef?.current?.scrollTop) / 19.5))
      const selectedElement = dropdownRef?.current?.querySelector('.selected')
      if (selectedElement) {
        const boxHeight = dropdownRef?.current?.offsetHeight;
        const elementBottom = selectedElement?.offsetTop - 20
        const elementBottom1 = selectedElement?.offsetTop + selectedElement?.offsetHeight;
        if (elementBottom > boxHeight) {
          setClick(parseInt((dropdownRef.current.scrollHeight - dropdownRef.current.offsetHeight - dropdownRef.current.scrollTop) / 20.5))
          dropdownRef.current.scrollTop = elementBottom - boxHeight;
        } else {
          if (elementBottom1 > boxHeight) {
            dropdownRef.current.scrollTop = elementBottom - boxHeight;
          }
        }
      }
    }
    const handleWheel = (event) => {
      // event?.preventDefault();
      // if (isScrolling) retussssrn;
      // isScrolling = true;
      // const box = document.getElementById('box'); // Replace 'yourBoxId' with the ID of your box
      // const boxHeight = dropdownRef.current.clientHeight;
      // if (event.deltaY > 0 && selectedItem < group_all_list.length) {
      // setSelectedItem((prevIndex) => Math.min(prevIndex + 1, group_all_list.length - 1));
      // } else if (event.deltaY < 0 && selectedItem > 0) {
      // setSelectedItem((prevIndex) => Math.max(prevIndex - 1, 0));
      // }
      // isScrolling = false;
    };
    const dropdownElement = dropdownRef?.current;
    dropdownElement?.addEventListener('wheel', handleWheel, { passive: false });
    return () => {
      dropdownElement?.removeEventListener('wheel', handleWheel);
    };
  }, [selectedItem]);
  // submit form by ctr + a -------------------------------------------------
  const controlPlusASubmit = async () => {
    if (validateForm()) {
      try {
        const formData = new FormData();
        formData.append('company_id', userData?.id);
        formData.append('name', state.group_name);
        formData.append('alise_name', state.alise_name);
        formData.append('under', state.under_id);
        formData.append('nature_of_group', state.nature_of_group);
        formData.append('group_behaves_like_sub_ledger', state.group_behaves_like_a_sub_ledger);
        formData.append('nett_debit_credit_balance_for_reporting', state.nett_debit_credit_balance_for_reporting);
        formData.append('used_for_calculation', state.used_for_calculation);
        formData.append('method_to_allocate_when_used_in_purchase_invoice', state.method_to_allocate_when_used_in_purchase_invoice);
        const response = await dispatch(groupCreate(formData));
        if (response.status) {
          toast.success(response?.message || 'Group created!', {
            position: toast.POSITION.TOP_CENTER
          });
          const formData = new FormData();
          formData.append('company_id', userData?.id)
          dispatch(groupListCreate(formData))
          resetForm();
        } else {
          toast.warn(response?.message || 'Fail!', {
            position: toast.POSITION.TOP_CENTER,
          });
          const formData = new FormData();
          formData.append('company_id', userData?.id)
          dispatch(groupListCreate(formData))
          setGroupSubmitConfirm('')
          group_name_input_ref.current.focus();
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.message || 'Failed!', {
          position: toast.POSITION.TOP_CENTER,
        });
        setGroupSubmitConfirm('')
        group_name_input_ref.current.focus();
      }
    } else {
      toast.error('Please fill all the fields.', {
        position: toast.POSITION.TOP_CENTER
      });
      setGroupSubmitConfirm('')
      group_name_input_ref.current.focus();
    }
  };

  // Triggered when a form is submitted ------------------------------------
  const handleSubmit = (e) => {
    e.preventDefault();
    if (hashtag) {
      if (validateForm()) {
        const formData = new FormData();
        formData.append('company_id', userData?.id)
        formData.append('name', state.group_name)
        formData.append('alise_name', state.alise_name)
        formData.append('under', state.under_id)
        formData.append('nature_of_group', state.nature_of_group)
        formData.append('group_behaves_like_sub_ledger', state.group_behaves_like_a_sub_ledger)
        formData.append('nett_debit_credit_balance_for_reporting', state.nett_debit_credit_balance_for_reporting)
        formData.append('used_for_calculation', state.used_for_calculation)
        formData.append('method_to_allocate_when_used_in_purchase_invoice', state.method_to_allocate_when_used_in_purchase_invoice)
        dispatch(groupCreate(formData)).then((response) => {
          if (response.status) {
            const formData = new FormData();
            formData.append('company_id', userData?.id)
            dispatch(groupListCreate(formData))
            setGroupSubmitConfirm('');
            toast.success(response?.message || 'group created!', {
              position: toast.POSITION.TOP_CENTER
            });
            setState({
              group_name: '',
              alise_name: '',
              under: state?.under_id,
              under: state?.under,
              nature_of_group: '',
              group_behaves_like_a_sub_ledger: 'No',
              nett_debit_credit_balance_for_reporting: 'No',
              used_for_calculation: 'No',
              method_to_allocate_when_used_in_purchase_invoice: 'Not Applicable',
            })
            setGroupSubmitConfirm('')
            setTimeout(() => {
              group_name_input_ref.current.focus();
            }, 50)
            setIsGroupNameEntered(false)
            setHashtag(false)
          }
          else {
            toast.warn(response?.message || 'Fail !', {
              position: toast.POSITION.TOP_CENTER
            });
          }
        }).catch(error => {
          console.log(error)
          toast.error(error?.message || ' Failed!', {
            position: toast.POSITION.TOP_CENTER
          });
        })
      } else {
        toast.error('Please fill all the field.', {
          position: toast.POSITION.TOP_CENTER
        });
        group_name_input_ref.current.focus();
        setHashtag(false)
        return;
      }
    }
  }

  // Function to validate form fields---------------------------------------
  const validateForm = () => {
    return (
      userData?.id !== '' &&
      group_name !== '' &&
      under !== '' &&
      under_id !== '' &&
      group_behaves_like_a_sub_ledger !== '' &&
      nett_debit_credit_balance_for_reporting !== '' &&
      used_for_calculation !== '' &&
      method_to_allocate_when_used_in_purchase_invoice !== ''
    );
  };
  // Function to reset form fields-----------------------------------------
  const resetForm = () => {
    setState(prevState => ({
      ...prevState,
      group_name: '',
      alise_name: '',
      under_id: prevState?.under_id,
      under: prevState?.under,
      nature_of_group: '',
      group_behaves_like_a_sub_ledger: 'No',
      nett_debit_credit_balance_for_reporting: 'No',
      used_for_calculation: 'No',
      method_to_allocate_when_used_in_purchase_invoice: `${str} Not Applicable`,
    }));
    setGroupSubmitConfirm('');
    setTimeout(() => {
      group_name_input_ref.current.focus();
    }, 50);
    setIsGroupNameEntered(false);
  };
  if (loading) {
    return <div>Loading ...</div>
  }
  // If Group listing data undefined or null this return null 
  if (typeof data === 'undefined' || data === null) { return null; }
  return (
    <div className='section'>
      <div className='detail'>
        {
          groupSubmitConfirm === 'Accept' ?
            <YesNoModal>
              <div className='container'>
                <div className='row'>
                  <div className='col-12'>
                    <p className='modal_message'>
                      {groupSubmitConfirm}?
                    </p>
                  </div>
                  <div className='col-3 offset-1'>
                    <Link className="modal_link" onClick={controlPlusASubmit} >Yes</Link>
                  </div>
                  <div className='col-2'>or</div>
                  <div className='col-3'>
                    <Link className='modal_link'>No</Link>
                  </div>
                </div>
              </div>
            </YesNoModal> : null
        }
        <div className='container-fluid p-0' style={{ background: 'rgba(0,0,0,0.1)', height: '91.7vh', pointerEvents: groupSubmitConfirm === 'Accept' ? 'none' : 'auto' }} >
          <div className='row border border-dark table_date_header' >
            <div className='col-12 ps-3  d-flex justify-content-between  table_header_font'>
              <b> Group Creation </b>
            </div>
          </div>
          <div className='row'>
            <form
              autoComplete='off'
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                if (e.ctrlKey && (e.key === 'a' || e.key === 'A')) {
                  e.preventDefault();
                  controlPlusASubmit(e);
                }
              }}>
              <div className='col-9 p-1' style={{ background: '#fff' }}>
                <div className='row mt-3 mb-4'>
                  <div className='d-flex justify-content-between align-item-center'>
                    <label className='lab-1'> Name</label>
                    <label> :</label>
                    <input
                      type="text"
                      name='group_name'
                      id='group_name'
                      className={`lab-right`}
                      onKeyUp={handleChange}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      value={group_name || ""}
                      autoFocus
                      ref={group_name_input_ref}
                    />
                  </div>
                  <div className='d-flex justify-content-between align-item-center' id='alis'>
                    <label className='lab-1'> (alise) </label>
                    <label> :</label>

                    <input
                      type="text"
                      name='alise_name'
                      ref={aliseInputRef}
                      id='alise_name'
                      className={`lab-right ${isGroupNameEntered ? '' : 'disable-field'}`}
                      onKeyUp={handleChange}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      value={alise_name || ""} />
                  </div>
                  <div className='d-flex justify-content-between align-item-center'>
                    <label className='lab-1'> Under </label>
                    <label> :</label>

                    <input
                      type='text'
                      id='under'
                      name="under"
                      ref={inputRef}
                      value={under || ""}
                      onKeyUp={handleChange}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      className={`lab-right ${isGroupNameEntered ? '' : 'disable-field'}`} />

                    <div id="myDropdown-list" className={`dropdown-content-list ${showList ? 'show-list' : ''}`}>
                      <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                        List Of Group
                      </div>
                      <div className='col ' style={{ textAlign: 'right' }}>
                        <Link style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                        <div className='border'></div>
                      </div>
                      <div id='data' ref={dropdownRef} style={{ overflowY: 'auto', maxHeight: '80.5vh' }}>
                        {filteredData?.length > 0 ? (
                          filteredData?.map((key, number) => {
                            const name = key?.name === 'Primary' ? `${str} ${key?.name}` : key?.name;
                            const id = key?.id;
                            const index = name?.toLowerCase()?.indexOf(under?.toLowerCase());
                            return (
                              <Link
                                className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                id='list'
                                key={number}
                                onClick={() => {
                                  setSelectedItem(number)
                                  if (number === selectedItem) {
                                    if (name === 'Primary') {
                                      setState({ ...state, under: `${name}`, under_id: id })
                                    } else {
                                      setState({ ...state, under: name, under_id: id })
                                    }
                                    setShowList(false)
                                    if (name === 'Primary') {
                                      setTimeout(() => {
                                        nature_group_input_ref?.current?.focus()
                                        nature_group_input_ref?.current?.setSelectionRange(0, 0)
                                      }, 50);
                                    } else {
                                      group_behaves_like_a_sub_ledger_input_ref?.current?.focus();
                                      group_behaves_like_a_sub_ledger_input_ref?.current?.setSelectionRange(0, 0)
                                    }
                                  }
                                }}
                              >
                                {index !== -1 ? (
                                  < >
                                    {name.substring(0, index)}
                                    <span style={{ color: 'red' }}>{name.substring(index, index + under.length)}</span>
                                    {name.substring(index + under.length)}
                                  </>
                                ) : (
                                  name
                                )}
                              </Link>
                            );
                          })
                        ) : (
                          <p style={{ color: 'red' }}>No matching items found</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='border'></div>
                <div className='row mt-5'>
                  {
                    state.under === `${str} Primary` ?
                      <div className='d-flex justify-content-between align-item-center'>
                        <label className='lab-1 '>Nature of Group</label>
                        <label> :</label>
                        <input
                          type="text"
                          name='nature_of_group'
                          id='nature_of_group'
                          className={`lab-right ${isGroupNameEntered ? '' : 'disable-field'}`}
                          onKeyUp={handleChange}
                          onChange={handleChange}
                          onFocus={handleFocus}
                          onBlur={handleBlur}
                          value={nature_of_group || ""}
                          ref={nature_group_input_ref} />
                        <div id="myDropdown-list" className={`dropdown-content-list ${showNatureList ? 'show-list' : ''}`}>
                          <div className='col' style={{ background: 'skyblue', position: 'sticky', top: 0 }}>
                            List Of Nature of Group
                          </div>
                          <div className='col ' style={{ textAlign: 'right' }}>
                            <Link style={{ textAlign: 'right', paddingRight: 10 }}>Create</Link>
                            <div className='border'></div>
                          </div>
                          <div id='data'>
                            {filteredDataNature?.length > 0 ? (
                              filteredDataNature?.map((key, number) => {
                                const name = key?.name;
                                const index = name?.toLowerCase()?.indexOf(nature_of_group?.toLowerCase());
                                return (
                                  <Link
                                    className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                    id='list'
                                    key={number}
                                    onClick={() => {
                                      setSelectedItem(number)
                                      if (number === selectedItem) {
                                        setState({ ...state, nature_of_group: name })
                                        setShowNatureList(false)
                                        group_behaves_like_a_sub_ledger_input_ref?.current?.focus();
                                        group_behaves_like_a_sub_ledger_input_ref?.current?.setSelectionRange(0, 0)
                                      }
                                    }}
                                  >
                                    {index !== -1 ? (
                                      < >
                                        {name.substring(0, index)}
                                        <span style={{ color: 'red' }}>{name.substring(index, index + nature_of_group.length)}</span>
                                        {name.substring(index + nature_of_group.length)}
                                      </>
                                    ) : (
                                      name
                                    )}
                                  </Link>
                                );
                              })
                            ) : (
                              <p style={{ color: 'red' }}>No matching items found</p>
                            )}
                          </div>
                        </div>

                      </div> : null
                  }
                  <div className='d-flex justify-content-between align-item-center position-relative'>
                    <label className='lab-1 '>Group behaves like a sub-ledger  </label>
                    <label> :</label>
                    <input
                      type="text"
                      name='group_behaves_like_a_sub_ledger'
                      id='group_behaves_like_a_sub_ledger'
                      className={`lab_tax ${isGroupNameEntered ? '' : 'disable-field'}`}
                      onKeyUp={handleChange}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      value={group_behaves_like_a_sub_ledger || ""}
                      ref={group_behaves_like_a_sub_ledger_input_ref} />
                    <div id="myDropdown-list" className={`dropdown_yes_no_list ${showYesNoList ? 'show-list' : ''}`}>
                      <div className='col dropdown_header'>
                        Yes / No
                      </div>
                      <div id='data'>
                        {filteredDataYesNo?.length > 0 ? (
                          filteredDataYesNo?.map((key, number) => {
                            const name = key?.name;
                            const id = key?.id;
                            const index = name?.toLowerCase()?.indexOf(group_behaves_like_a_sub_ledger?.toLowerCase());
                            return (
                              <Link
                                className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                id='list'
                                key={number}
                                onClick={() => {
                                  setSelectedItem(number)
                                  if (number === selectedItem) {
                                    setState({ ...state, group_behaves_like_a_sub_ledger: name })
                                    nett_debit_credit_balance_for_reporting_input_ref.current.focus();
                                    nett_debit_credit_balance_for_reporting_input_ref.current.setSelectionRange(0, 0);
                                    setShowYesNoList(false)
                                  }
                                }}
                              >
                                {index !== -1 ? (
                                  < >
                                    {name.substring(0, index)}
                                    <span style={{ color: 'red' }}>{name.substring(index, index + group_behaves_like_a_sub_ledger.length)}</span>
                                    {name.substring(index + group_behaves_like_a_sub_ledger.length)}
                                  </>
                                ) : (
                                  name
                                )}
                              </Link>
                            );
                          })
                        ) : (
                          <p style={{ color: 'red' }}>No matching items found</p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='d-flex justify-content-between align-item-center position-relative'>
                    <label className='lab-1 '>Nett Debit/Credit Balance for Reporting </label>
                    <label> :</label>
                    <input
                      type="text"
                      name='nett_debit_credit_balance_for_reporting'
                      id='nett_debit_credit_balance_for_reporting'
                      className={`lab_tax ${isGroupNameEntered ? '' : 'disable-field'}`}
                      onKeyUp={handleChange}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      value={nett_debit_credit_balance_for_reporting || ""}
                      ref={nett_debit_credit_balance_for_reporting_input_ref} />
                    <div id="myDropdown-list" className={`dropdown_yes_no_list ${showNetDebitList ? 'show-list' : ''}`}>
                      <div className='col dropdown_header'>
                        Yes / No
                      </div>
                      <div id='data'>
                        {filteredDataYesNo?.length > 0 ? (
                          filteredDataYesNo?.map((key, number) => {
                            const name = key?.name;
                            const id = key?.id;
                            const index = name?.toLowerCase()?.indexOf(nett_debit_credit_balance_for_reporting?.toLowerCase());
                            return (
                              <Link
                                className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                id='list'
                                key={number}
                                onClick={() => {
                                  setSelectedItem(number)
                                  if (number === selectedItem) {
                                    setState({ ...state, nett_debit_credit_balance_for_reporting: name })
                                    used_for_calculation_input_ref.current.focus();
                                    used_for_calculation_input_ref.current.setSelectionRange(0, 0);
                                    setShowYesNoList(false)
                                  }
                                }}
                              >
                                {index !== -1 ? (
                                  < >
                                    {name.substring(0, index)}
                                    <span style={{ color: 'red' }}>{name.substring(index, index + nett_debit_credit_balance_for_reporting.length)}</span>
                                    {name.substring(index + nett_debit_credit_balance_for_reporting.length)}
                                  </>
                                ) : (
                                  name
                                )}
                              </Link>
                            );
                          })
                        ) : (
                          <p style={{ color: 'red' }}>No matching items found</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='d-flex justify-content-between align-item-center position-relative'>
                    <label className='lab-1 '>Used for calculation (for example: taxes, discounts) </label>
                    <label> :</label>
                    <input
                      type="text"
                      name='used_for_calculation'
                      id='used_for_calculation'
                      className={`lab_tax ${isGroupNameEntered ? '' : 'disable-field'}`}
                      onKeyUp={handleChange}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      value={used_for_calculation || ""}
                      ref={used_for_calculation_input_ref} />
                    <div id="myDropdown-list" className={`dropdown_yes_no_list ${showUsedForList ? 'show-list' : ''}`}>
                      <div className='col dropdown_header'>
                        Yes / No
                      </div>
                      <div id='data'>
                        {filteredDataYesNo?.length > 0 ? (
                          filteredDataYesNo?.map((key, number) => {
                            const name = key?.name;
                            const id = key?.id;
                            const index = name?.toLowerCase()?.indexOf(used_for_calculation?.toLowerCase());
                            return (
                              <Link
                                className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                id='list'
                                key={number}
                                onClick={() => {
                                  setSelectedItem(number)
                                  if (number === selectedItem) {
                                    setState({ ...state, used_for_calculation: name })
                                    method_to_allocate_when_used_in_purchase_invoice_input_ref.current.focus();
                                    method_to_allocate_when_used_in_purchase_invoice_input_ref.current.setSelectionRange(0, 0);
                                    setShowUsedForList(false)
                                  }
                                }}
                              >
                                {index !== -1 ? (
                                  < >
                                    {name.substring(0, index)}
                                    <span style={{ color: 'red' }}>{name.substring(index, index + used_for_calculation.length)}</span>
                                    {name.substring(index + used_for_calculation.length)}
                                  </>
                                ) : (
                                  name
                                )}
                              </Link>
                            );
                          })
                        ) : (
                          <p style={{ color: 'red' }}>No matching items found</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='d-flex justify-content-between align-item-center position-relative'>
                    <label className='lab-1 '>Method to allocate when used in purchase Invoice </label>
                    <label> :</label>
                    <input
                      type="text"
                      name='method_to_allocate_when_used_in_purchase_invoice'
                      id='method_to_allocate_when_used_in_purchase_invoice'
                      className={`lab-right ${isGroupNameEntered ? '' : 'disable-field'}`}
                      onKeyUp={handleChange}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      value={method_to_allocate_when_used_in_purchase_invoice || ""}
                      ref={method_to_allocate_when_used_in_purchase_invoice_input_ref} />
                    <div id="myDropdown-list" className={`dropdown_method ${showMethodForList ? 'show-list' : ''}`}>
                      <div className='col dropdown_header'>
                        List of Method
                      </div>
                      <div id='data'>
                        {filteredDataMethod?.length > 0 ? (
                          filteredDataMethod?.map((key, number) => {
                            const name = key?.name === 'Not Applicable' ? `${str} ${key?.name}` : key?.name;
                            const id = key?.id;
                            const index = name?.toLowerCase()?.indexOf(method_to_allocate_when_used_in_purchase_invoice?.toLowerCase());
                            return (
                              <Link
                                className={`list font-weight-bold ${number === selectedItem ? 'selected' : ''}`}
                                id='list'
                                key={number}
                                onClick={() => {
                                  setSelectedItem(number)
                                  if (number === selectedItem) {
                                    if (name === 'Not Applicable') {
                                      setState({ ...state, method_to_allocate_when_used_in_purchase_invoice: `${name}` })
                                    } else {
                                      setState({ ...state, method_to_allocate_when_used_in_purchase_invoice: name })
                                    }
                                    setShowMethodForList(false)
                                    method_to_allocate_when_used_in_purchase_invoice_input_ref.current.blur();
                                    setGroupSubmitConfirm('Accept')
                                  }
                                }}
                              >
                                {index !== -1 ? (
                                  < >
                                    {name.substring(0, index)}
                                    <span style={{ color: 'red' }}>{name.substring(index, index + method_to_allocate_when_used_in_purchase_invoice.length)}</span>
                                    {name.substring(index + method_to_allocate_when_used_in_purchase_invoice.length)}
                                  </>
                                ) : (
                                  name
                                )}
                              </Link>
                            );
                          })
                        ) : (
                          <p style={{ color: 'red' }}>No matching items found</p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <MasterCreationFooter
                setHash={setHashtag}
              />
            </form>
          </div>
        </div >
      </div >
      <div className='help' style={{ position: 'relative' }}>
      </div>
    </div >

  )
}
export default Group;























