import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { stockCompanyCreate } from '../../../../../redux/actions/action-creator';
import { useSelector, useDispatch } from 'react-redux';
import FormData from 'form-data';
import { toast } from 'react-toastify';
import '../accounting_masters/ledger.css';
import YesNoModal from '../../../../../components/model/YesNoModal';
import MasterCreationFooter from '../../../../../components/Footer/Master_Footer/MasterCreationFooter';

function StockCompany() {
  const { userData = null } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const stock_company_name_input_ref = useRef(null);
  const alias_name_input_ref = useRef(null);
  const isMouseDownInside = useRef(false);
  const [isStockCompanyNameEntered, setIsStockCampanyNameEntered] = useState(false);
  const [state, setState] = useState({ stock_company_name: '', alise_name: '' });
  const [hashtag, setHashtag] = useState(false)
  const [company_submit, setcompany_submit] = useState('')
  const [backspaceCount, setBackspaceCount] = useState(0)
  const [nameInput, setNameInput] = useState(null)
  function getCurrentInputRef(name) {
    switch (name) {
      case 'stock_company_name': return stock_company_name_input_ref;
      case 'alise_name': return alias_name_input_ref;
      default: return null;
    }
  }

  const handleFocus = (e) => {
    const { name, value } = e.target;
    const ref = getCurrentInputRef(name);
    if (ref) {
      setNameInput(ref);
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value })
    // Function to capitalize the first letter of each word
    const capitalizeFirstLetter = (str) => {
      return str.toLowerCase().replace(/(^|\s)\S/g,
        (firstLetter) => firstLetter.toUpperCase());
    };
    // To check the value is not empty of group name if empty then other field disabled
    if (name === 'stock_company_name') {
      setIsStockCampanyNameEntered(!!value.trim());
    }
    // space key functionality
    if (e.code == 'Space') {
      e.preventDefault();
      const cursorPosition_start = e.target.selectionStart;
      if (cursorPosition_start == 1) {
        setState({ ...state, [name]: '' });
        return;
      }
    }
    // Enter key functionality
    if (e.key === 'Enter' && state?.stock_company_name !== '') {
      e.preventDefault();
      const nextInputRef = name === 'stock_company_name' ? alias_name_input_ref : null;
      if (name === 'alise_name') {
        if (state.stock_company_name !== '' && e.key === 'Enter') {
          e.preventDefault();
          alias_name_input_ref.current.blur();
          setcompany_submit('Accept');
        }
      }
      if (nextInputRef) {
        nextInputRef.current.focus();
        if (nextInputRef.current) {
          nextInputRef.current.setSelectionRange(0, 0);
        }
      }
      return;
    }
    // Back space key functionality
    if (e.key === 'Backspace') {
      e.preventDefault();
      const nextInputRef = name === 'alise_name' ? stock_company_name_input_ref : stock_company_name_input_ref;
      const cursorPosition = e.target.selectionStart;
      if (cursorPosition === 0) {
        if (backspaceCount === 1) {
          setBackspaceCount(0);
          if (nextInputRef && nextInputRef.current) {
            nextInputRef.current.focus();
            nextInputRef.current.setSelectionRange(0, 0);
          }
        } else {
          if (cursorPosition === 0 && value) {
            if (nextInputRef && nextInputRef.current) {
              nextInputRef.current.focus();
              nextInputRef.current.setSelectionRange(0, 0);
            }
          } else {
            setBackspaceCount(backspaceCount + 1);
          }
        }
      }
      return;
    }


    // Capitalize the first letter of each word for certain input fields
    const updatedValue = name === 'stock_company_name' || name === 'alise_name' ?
      capitalizeFirstLetter(value) : value;
    setState({ ...state, [name]: updatedValue })

  }

  const handleClickOutside = (event) => {
    let clickedElement = event.target;
    let isInputField = false;
    while (clickedElement) {
      if ((clickedElement.tagName &&
        (clickedElement.tagName.toLowerCase() === 'input' ||
          clickedElement.tagName.toLowerCase() === 'textarea' || clickedElement.tagName.toLowerCase() === 'button'))) {
        isInputField = true;
        break;
      }
      clickedElement = clickedElement.parentNode;
    }

    if (!isInputField) {
      if (nameInput && nameInput.current) {
        event.preventDefault();
        console.log("this is name input :---", nameInput)
        nameInput.current.focus();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [nameInput])


  useEffect(() => {
    const handleClickOutside = (event) => {
      let clickedElement = event.target;
      let isInputField = false;
      while (clickedElement) {
        if ((clickedElement.tagName &&
          (clickedElement.tagName.toLowerCase() === 'input' ||
            clickedElement.tagName.toLowerCase() === 'textarea' || clickedElement.tagName.toLowerCase() === 'button'))) {
          isInputField = true;
          break;
        }
        clickedElement = clickedElement.parentNode;
      }
      if (!isInputField) {
        if (nameInput && nameInput.current) {
          event.preventDefault();
          nameInput.current.focus();
        }
      }
    };

    const handleMouseDown = () => {
      isMouseDownInside.current = true;
    };

    const handleMouseUp = () => {
      isMouseDownInside.current = false;
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [nameInput]);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (hashtag) {
      const formData = new FormData();
      formData.append('name', state.stock_company_name)
      formData.append('alias_name', state.alise_name)
      formData.append('company_id', userData?.id)
      dispatch(stockCompanyCreate(formData)).then((response) => {
        if (response.status) {
          toast.success(response?.message || 'Group created!', {
            position: toast.POSITION.TOP_CENTER
          });
          setState({ stock_company_name: '', alise_name: '', })
          setHashtag(false);
          setcompany_submit('')
          setIsStockCampanyNameEntered(false)
          stock_company_name_input_ref.current.focus();
        }
        else {
          toast.warn(response?.message || 'Fail !', {
            position: toast.POSITION.TOP_CENTER
          });
          setHashtag(false);
          setIsStockCampanyNameEntered(false)
          setcompany_submit('')
          stock_company_name_input_ref.current.focus();
        }
      }).catch(error => {
        console.log(error)
        toast.error(error?.message || ' Failed!', {
          position: toast.POSITION.TOP_CENTER
        });
        setHashtag(false);
        setIsStockCampanyNameEntered(false)
        setcompany_submit('')
        stock_company_name_input_ref.current.focus();
      })
    }
  }
  const { stock_company_name, alise_name } = state;
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (company_submit === 'Accept') {
        if (e.key === 'y' || e.key === 'Y') {
          e.preventDefault();
          controlPlusASubmit();
        }
      }
      if (company_submit === 'Accept') {
        if (e.key === 'n' || e.key === 'N') {
          e.preventDefault();
          setcompany_submit('')
          stock_company_name_input_ref.current.focus();
        }
      }
    }
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [state?.stock_company_name, company_submit])
  // submit form by ctr + a =============
  const controlPlusASubmit = async () => {
    if (validateForm()) {
      try {
        const formData = new FormData();
        formData.append('company_id', userData?.id);
        formData.append('name', state.stock_company_name);
        formData.append('alias_name', state.alise_name);
        const response = await dispatch(stockCompanyCreate(formData));
        if (response.status) {
          toast.success(response?.message || 'Group created!', {
            position: toast.POSITION.TOP_CENTER
          });
          resetForm();
        } else {
          toast.warn(response?.message || 'Fail!', {
            position: toast.POSITION.TOP_CENTER,
          });
          setcompany_submit('')
          stock_company_name_input_ref.current.focus();
        }
      } catch (error) {
        console.log(error);
        toast.error(error?.message || 'Failed!', {
          position: toast.POSITION.TOP_CENTER,
        });
        setcompany_submit('')
        stock_company_name_input_ref.current.focus();
      }
    } else {
      toast.error('Please fill all the fields.', {
        position: toast.POSITION.TOP_CENTER
      });
      setcompany_submit('')
      stock_company_name_input_ref.current.focus();
    }
  };
  // Function to validate form fields
  const validateForm = () => {
    return (
      userData?.id !== '' &&
      state?.stock_company_name !== ''
    );
  };
  // Function to reset form fields
  const resetForm = () => {
    setState(prevState => ({
      ...prevState,
      stock_company_name: '',
      alise_name: '',

    }));
    setcompany_submit('');
    setTimeout(() => {
      stock_company_name_input_ref.current.focus();
    }, 50);
    setIsStockCampanyNameEntered(false);
  };
  return (
    <div className='section'>
      <div className='detail border'>
        {
          company_submit === 'Accept' ?
            <YesNoModal>
              <div className='container'>
                <div className='row'>
                  <div className='col-12'>
                    <p className='modal_message'>
                      {company_submit}
                    </p>
                  </div>
                  <div className='col-3 offset-1'>
                    <Link className="modal_link" onClick={controlPlusASubmit} >Yes</Link>
                  </div>
                  <div className='col-2'>or</div>
                  <div className='col-3'>
                    <Link className='modal_link'>No</Link>
                  </div>
                </div>
              </div>
            </YesNoModal> : null
        }
        <div className='container-fluid p-0' style={{ background: 'rgba(0,0,0,0.4)', height: '91.7vh' }}>
          <div className='row'>
            <form
              autoComplete='off'
              onSubmit={handleSubmit}
              onKeyDown={(e) => {
                if (e.ctrlKey && (e.key === 'a' || e.key === 'A')) {
                  e.preventDefault();
                  controlPlusASubmit(e);
                }
              }}>
              <div className='col-6 p-3' style={{ background: '#fff' }}>
                <div className='row mt-3 mb-4'>
                  <div className='d-flex justify-content-between align-item-center'>
                    <label className='lab-1'> Name</label>
                    <label> :</label>
                    <input
                      type="text"
                      name='stock_company_name'
                      id='stock_company_name'
                      className='lab-right'
                      onChange={handleChange}
                      onKeyUp={handleChange}
                      onFocus={handleFocus}
                      value={stock_company_name || ""}
                      ref={stock_company_name_input_ref}
                      autoFocus />
                  </div>
                  <div className='d-flex justify-content-between align-item-center' id='alis'>
                    <label className='lab-1'> (alise) </label>
                    <label> :</label>
                    <input
                      type="text"
                      name='alise_name'
                      id='alise_name'
                      className={`lab-right ${isStockCompanyNameEntered ? '' : 'disable-field'}`}
                      onKeyUp={handleChange}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      ref={alias_name_input_ref}
                      value={alise_name || ""}
                      disabled={state.stock_company_name === ''} />
                  </div>
                </div>
              </div>
              <MasterCreationFooter
                setHash={setHashtag}
              />
            </form>
          </div>
        </div >
      </div >
      <div className='help'>
      </div>
    </div >
  )
}

export default StockCompany;
