import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './chequeregister.css';

const CheckRegister = () => {
    const location = useLocation();
    return (
        <div className='section'>
            <div className='detail'>
                <div className='title d-flex justify-content-between align-items-center text-light'>
                    <p>Cheque Register</p>
                    <p>Abc Pvt Ltd</p>
                    <button type="button" class="btn-close" aria-label="Close"></button>
                </div>
        
                <div className='container-fluid' id='reletive'>

                    <div>
                        <div className='row'>
                            <div className='col-12 p-3' style={{ textAlign: 'left' }}>
                                <b>Bank Wise Register</b>
                            </div>
                            <div className='col-12 border'>
                                <div className='row'>
                                    <div className='col-4 border'>
                                        <b>Particular</b>
                                    </div>
                                    <div className='col-1 border'>
                                        <b> </b>Availabel Cheque
                                    </div>
                                    <div className='col-5 border'>
                                        <b>For 1-Apr-22</b>
                                    </div>
                                    <div className='col-1 border'>
                                        <b> Out of Period</b>
                                    </div>
                                    <div className='col-1 border'>
                                        <b> Total Cheques</b>
                                    </div>
                                </div>

                            </div>
                        </div>



                    </div>
                </div>
                <div className='tomasterfooter'></div>
            </div>
            <div className='help'></div>
        </div>
    )
}

export default CheckRegister
