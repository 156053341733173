import React, { useEffect, useState, useCallback } from 'react';
import './section.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Banking from '../../pages/gatewayoftally/utilities/Banking';
import GateWayOfTally from '../../pages/gatewayoftally/GateWayOfTally';
import DisplayMoreReports from '../../pages/gatewayoftally/Reports/DisplayMoreReports';
import ChequePrinting from '../../pages/gatewayoftally/utilities/banking/cheque/ChequePrinting';
import PostDatedSummary from '../../pages/gatewayoftally/utilities/banking/cheque/PostDatedSummary';
import DepositSlip from '../../pages/gatewayoftally/utilities/banking/statement/DepositSlip';
import PaymentAdvice from '../../pages/gatewayoftally/utilities/banking/statement/PaymentAdvice';
import BankReconciliation from '../../pages/gatewayoftally/utilities/banking/statement/BankReconciliation';
import Period_Home from './Period_Home';
import Current_Date from './Current_Date';
import AccountBook from '../../pages/gatewayoftally/Reports/displaymore/AccountBook';
import StatementofAccount from '../../pages/gatewayoftally/Reports/displaymore/StatementofAccount';
import InventoryBook from '../../pages/gatewayoftally/Reports/displaymore/InventoryBook';
import StatementofInventory from '../../pages/gatewayoftally/Reports/displaymore/StatementofInventory';
import GstReport from '../../pages/gatewayoftally/Reports/displaymore/GstReport';
import ExceptionReports from '../../pages/gatewayoftally/Reports/displaymore/ExceptionReports';
import AnalysisVerification from '../../pages/gatewayoftally/Reports/displaymore/AnalysisVerification';
import LedgerBook from '../../pages/gatewayoftally/Reports/displaymore/account_book/LedgerBook';
import { maxDateOfEntry } from '../../redux/actions/action-creator';
import { dateDayShow, dateFormateShow } from '../date_formate/Index';

const Section = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const navigation = useNavigate();

    const { company_session } = useSelector(state => state.common);
    const { userData } = useSelector(state => state.auth);

    const [max_date, setMax_date] = useState('')
    const [loading, setloading] = useState(false)
    const [chequePrinting, setChequePrinting] = useState(false);
    const [postDatedSummary, setPostDatedSummary] = useState(false);
    const [depositSlip, setDepositSlip] = useState(false);
    const [paymentAdvice, setPaymentAdvice] = useState(false);
    const [bankReconciliation, setBankReconciliation] = useState(false);

    useEffect(() => {

        const formData = new FormData();
        formData.append('company_id', userData?.id)
        dispatch(maxDateOfEntry(formData)).then((response) => {

            if (response.status) {
                setMax_date(response?.data)
                setloading(false)
            } else {
                setMax_date('')
                setloading(false)
            }
        })

    }, [])

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === 'F2') {
                e.preventDefault();
                navigation('/current_date')
            }
            if (e.ctrlKey && e.key === 'F2') {
                e.preventDefault();
                navigation('/period')
            }
        }
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [])

    const renderDetails = useCallback(() => {
        switch (location.pathname) {
            case '/bankingutilities':
                return <Banking />;
            case '/displaymorereports':
                return <DisplayMoreReports />;
            case '/accountbook':
            case '/ledgerbook':
                return <AccountBook />;
            case '/statementofaccount':
                return <StatementofAccount />;
            case '/inventorybook':
                return <InventoryBook />;
            case '/statementofinventory':
                return <StatementofInventory />;
            case '/gstreport':
                return <GstReport />;
            case '/exceptionreports':
                return <ExceptionReports />;
            case '/analysisverification':
                return <AnalysisVerification />;
            default:
                return <GateWayOfTally />;
        }
    }, [location.pathname]);

    return (
        <div className='section' >
            <div className='detail'>
                {location.pathname === '/ledgerbook' ? <LedgerBook /> : null}
                {location.pathname === '/current_date' ? <Current_Date /> : null}
                {location.pathname === '/period' ? <Period_Home /> : null}
                {chequePrinting ? <ChequePrinting /> : null}
                {postDatedSummary ? <PostDatedSummary /> : null}
                {depositSlip ? <DepositSlip /> : null}
                {paymentAdvice ? <PaymentAdvice /> : null}
                {bankReconciliation ? <BankReconciliation /> : null}

                <div className='detail-item' id='section'>
                    <div className=' detail-1'>
                        <div className='row mt-3'>
                            <div className='col-xl btn home-btn border-0 ' style={{ pointerEvents: 'none' }} >
                                <p className='text-info text-center font_size m-0 mb-1'>MAX. DATE OF ENTRY</p>
                                <p className='font-weight-bold text-center font_size' style={{ fontWeight: 'bold' }}> {max_date ? dateDayShow(max_date) : ''}{max_date ? ', ' : ''}{max_date ? dateFormateShow(max_date) : ''} </p>
                            </div>
                            <div className='col-xl btn home-btn border-0' onClick={() => { return (navigation('/period')) }}>
                                <p className='text-info text-center font_size m-0 mb-1'><u>F2</u>:CURRENT PERIOD</p>
                                <p className='font-weight-bold text-center font_size' style={{ fontWeight: 'bold' }}>{company_session.financial_year}</p>
                            </div>
                            <div className='col-xl btn home-btn border-0' onClick={() => { return (navigation('/current_date')) }}>
                                <p className='text-info text-center font_size m-0 mb-1'> F2:CURRENT Date</p>
                                <p className='font-weight-bold text-center font_size' style={{ fontWeight: 'bold' }}>{company_session.current_date}</p>
                            </div>
                        </div>

                        <div className='border-top  pt-5 item-3'>
                            <h5 className='m-0 p-0'>{userData?.company_name}</h5>
                            <p className='m-0 p-0 font_size'>{userData?.address}</p>
                            <p className='m-0 p-0 font_size'>Mobile No.: {userData?.mobile}</p>
                        </div>
                    </div>
                    <div className='detail-2' id='detail-2'>
                        {renderDetails()}
                    </div>
                </div>

            </div>
            <div className='help'></div>
        </div >
    )
}
export default React.memo(Section);
